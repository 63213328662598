import { ExpandMore } from '@mui/icons-material'
import { AccordionDetails } from '@mui/material'
import parse from 'html-react-parser'
import { useEffect, useState } from 'react'
import { FAQAccordion, FAQAccordionSummary } from './styledComponents'

export default function FAQItem({
  isExpanded,
  handleOpenFAQ,
  item,
  itemIndex,
  lockExpand,
  hideExpandIcon,
}) {
  const [expand, setExpand] = useState(false)

  function handleExpand() {
    if (lockExpand) {
      return
    }

    if (isExpanded) {
      setExpand((prev) => !prev)
    }
    handleOpenFAQ(itemIndex)
  }

  useEffect(() => {
    setExpand(isExpanded)
  }, [isExpanded])

  return (
    <FAQAccordion disableGutters fullWidth expanded={expand} onChange={handleExpand}>
      <FAQAccordionSummary
        isExpandingLocked={lockExpand}
        expandIcon={hideExpandIcon ? <></> : <ExpandMore />}
      >
        {item?.question}
      </FAQAccordionSummary>
      <AccordionDetails>{parse(item?.answer)}</AccordionDetails>
    </FAQAccordion>
  )
}
