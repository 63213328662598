import { StyledTileMore, StyledTileTitle } from '../TileStyle'
import { TileHeadingV2Container } from './styledComponents'

export default function TileV2Header({ logicAndData }) {
  const foundTitle = logicAndData?.getTitle
    ? logicAndData?.getTitle(logicAndData?.currentSecondaryItem || logicAndData?.tileData)
    : null
  const foundAltText = logicAndData?.getAltText
    ? logicAndData?.getAltText(logicAndData?.tileData)
    : null

  const titleToShow = foundTitle || foundAltText

  return (
    <TileHeadingV2Container>
      {titleToShow && (
        <StyledTileTitle nopointer={!logicAndData.onClick} onClick={logicAndData.onClick}>
          {titleToShow}
        </StyledTileTitle>
      )}
      {logicAndData?.showAll && logicAndData.onClick && (
        <StyledTileMore onClick={logicAndData.onClick}>{'More >'}</StyledTileMore>
      )}
    </TileHeadingV2Container>
  )
}
