import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { styled as muiStyled, Drawer } from '@mui/material'

// SPLP = Service Provider Listing Page
// styles naming : (top level to bottom order : parent > container > internal container)

// 1. general styles :

///////////////////////

// 2. top level filter styles :

export const SPLPTopLevelFilterParent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.75rem;
  font-family: Outfit;
  background: rgb(213 202 253 / 18%);
  position: relative;
  min-height: 18.75rem;
  padding: 2rem 0.5rem;
  border-radius: 0% 0% 50% 50% / 0% 0% 5% 5%;
  @media (min-width: 768px) {
    gap: 2rem;
    border-radius: 0% 0% 50% 50% / 0% 0% 10% 10%;
  }
`

export const SPLPTopLevelFilterParentTextHeading = styled.div`
  font-size: ${(props) => (props?.primary ? '1.25rem' : '1rem')};
  font-weight: ${(props) => (props?.primary ? '700' : '500')};
  text-align: center;
`
export const SPLPTopLevelFilterParentTextHeadingH1 = styled.h1`
  font-size: ${(props) => (props?.primary ? '2rem' : '1rem')};
  font-weight: ${(props) => (props?.primary ? '700' : '500')};
  text-align: center;
`

export const SPLPTopLevelFilterParentActionText = styled.div`
  font-size: 0.9rem;
  font-weight: 700;
  letter-spacing: 0.1rem;
  text-decoration: underline;
  cursor: pointer;
  color: #a379fd;
  text-align: center;
  text-transform: uppercase;
`

export const SPLPTLFParentButtonContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  border: 1px solid #a379fd;
  border-radius: 2.75rem;
  font-weight: 700;
  overflow: hidden;
  @media (min-width: 768px) {
    border-radius: 1.75rem;
  }
`

export const SPLPTLFButton = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  cursor: pointer;
  background: ${(props) => (props?.selected ? '#a379fd' : 'inherit')};
  border-right: ${(props) => !props?.isLastItem && '1px solid #a379fd'};
  color: ${(props) => (props?.selected ? '#FFF' : 'inherit')};
  padding: 0.75rem 1rem;
`

///////////////////////

// 3. cards provider styles :

export const SPLPCardProviderParent = styled.div`
  width: 100%;
  display: grid;
  box-sizing: border-box;
  margin: auto;
  font-family: Outfit;
  grid-template-columns: repeat(auto-fill, 100%);
  margin-top: 0.5rem;
  row-gap: 1.5rem;
  padding: 1rem;

  @media (min-width: 768px) {
    ${(props) => props?.twoColumnDesign && 'grid-template-columns: repeat(auto-fill, 50%);'};
    max-width: 65rem;
  }
`

///////////////////////

// 4. individual card styles :

export const SPLPCardParent = styled.div`
  width: 100%;
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  border: 1px solid #182a8833;
  border-radius: 0.75rem;
  overflow: hidden;
  padding-top: 1rem;
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 5px;
  }
  @media (min-width: 768px) {
    width: ${(props) => (props?.twoColumnDesign ? '95%' : '100%')};
    ${(props) => props?.twoColumnDesign && 'margin: 1rem auto'};
  }
`

export const SPLPCardParentRecommendedTag = styled.div`
  background: #d3dfc6;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 1.25rem;
  color: #4e6137;
  display: flex;
  gap: 5px;
  font-size: 0.7rem;
  font-weight: 700;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 0.16rem;
  width: fit-content;
  height: 1.5rem;
  border-radius: 0 0.75rem;
  padding: 0.1rem 0.5rem;
`

export const SPLPCardParentPersonalDetails = styled.div`
  display: flex;
  width: 100%;
  margin-top: 0.3rem;
  max-height: 12rem;
  padding-bottom: 0.6rem;
  height: 80%;
  @media (min-width: 768px) {
    height: 75%;
    gap: 1.5rem;
  }
`

export const SPLPCardPicture = styled.img`
  width: ${(props) => (props?.twoColumnDesign ? '29%' : '19%')};
  margin: 0 0.8rem;
  min-height: 0.6rem;
  height: 100%;
  max-height: 8rem;
  border-radius: 0.6rem;
  object-fit: cover;
  @media (min-width: 768px) {
    max-height: 12rem;
  }
`

export const SPLPCardPersonalDetailsContent = styled.div`
  width: 60%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  justify-content: center;
  @media (min-width: 768px) {
    width: 65%;
  }
`

export const SPLPCardName = styled.span`
  font-weight: 700;
  font-size: 1.25rem;
  color: #3f2f63;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const SPLPDetailText = styled.span`
  font-size: 0.8rem;
  color: #4c4c4c;
  margin: 0;
  padding: 0;
  line-height: 1.5;
`

export const SPLPDetailProfessionalInPersonal = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  @media (max-width: 767px) {
    display: none;
  }
`

export const SPLPCardParentProfessionalDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin: 0.6rem;
  @media (min-width: 768px) {
    display: none;
  }
`

export const SPLPCardExpertiseSection = styled.div`
  font-size: 0.8rem;
  color: #797979;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  height: fit-content;
`

export const SPLPScrollableExpertise = styled.div`
  display: flex;
  gap: 0.5rem;
  overflow-x: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    display: none;
  }
`

export const SPLPScrollableItem = styled.div`
  color: ${(props) => (props?.selected ? 'rgb(78, 97, 55)' : '#262626')};
  background-color: ${(props) => (props?.selected ? 'rgb(211, 223, 198)' : '#f7f7f7')};
  border-color: 4px;
  font-size: 0.7rem;
  border-radius: 4px;
  width: fit-content;
  white-space: nowrap;
  padding: 0.4rem 0.7rem;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const SPLPCardParentActionsContainer = styled.div`
  padding: 1rem 0.6rem;
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  background: #f7f7f7;
  @media (min-width: 768px) {
    padding: 1rem 1.25rem;
  }
`

export const SPLPNextAvailableSlotTime = styled.span`
  color: rgb(231, 105, 67);
`

export const SPLPCardActionsNextAvailableSlot = styled.div`
  display: flex;
  flex-direction: ${(props) => (props?.singleRow ? 'row' : 'column')};
  gap: ${(props) => (props?.singleRow ? '8px' : 0)};
  color: rgb(121, 121, 121);
  font-size: 0.8rem;
  @media (max-width: 767px) {
    ${(props) => !props?.singleRow && 'display: none'};
  }
  @media (min-width: 768px) {
    ${(props) => props?.singleRow && 'display: none'};
  }
`

export const SPLPCardButtonsContainer = styled.div`
  width: 100%;
  justify-content: center;
  align-items: center;
  column-gap: 1rem;
  display: flex;
  @media (min-width: 768px) {
    width: 60%;
    max-width: 33rem;
  }
`

export const SPLPCardButton = styled(Link)`
  ${(props) => !Boolean(props?.takeFullWidthOnSmallScreen) && 'flex: 1 1 0%;'};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  white-space: nowrap;
  padding: 0.875rem 1rem;
  max-width: 19.5rem;
  width: fit-content;
  font-weight: 700;
  text-decoration: none;
  border-radius: 2rem;
  border: 1px solid #7154b0;
  overflow: hidden;
  letter-spacing: 0.08rem;
  text-transform: uppercase;
  background: ${(props) => (props?.desiredClick ? '#7154b0' : 'transparent')};
  color: ${(props) => (props?.desiredClick ? '#FFF' : '#7154b0')};
  margin: 3px 0;
  transition: all 0.1s ease-in-out;
  @media (max-width: 768px) {
    width: ${(props) => (props?.takeFullWidthOnSmallScreen ? '100%' : 'unset')};
    max-width: ${(props) => props?.takeFullWidthOnSmallScreen && 'unset'};
  }
  &:hover {
    ${(props) => !Boolean(props?.desiredClick) && 'background: #7154b0;'};
    ${(props) => !Boolean(props?.desiredClick) && 'color: #fff;'};
  }
`

export const SPLPSkeletonsContainer = styled.div`
  width: 100%;
  margin: auto;
  @media (min-width: 768px) {
    max-width: 65rem;
  }
`

export const FAQDrawer = muiStyled(Drawer)(
  ({ theme }) =>
    `
display: flex;
justify-content: center;
// backdrop-filter: blur(1px);
background-color: rgba(106, 109, 210, 0.2);
.MuiDrawer-paper {
  height: 90vh;
  width: 100%;
  left: auto;
  right: auto;
  background: #ffffff;
  background-size: 100% 100%;
  border-radius: 25px 25px 0px 0px;
  padding: 70px 40px;
  max-width: 1200px;
  ::-webkit-scrollbar {
    display: none;
  }  
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
@media (min-width: 768px) {
    .MuiDrawer-paper {
        width: 100%;
    }
  }
`,
)

export const RelatedAppFeaturesStyledHeading = styled.div`
  font-size: 16px;
  font-weight: 500;
  padding: 20px 0 0 20px;
  @media (min-width: 768px) {
    font-size: 20px;
    padding: 35px 0 0 35px;
  }
`

export const LiveSessionPermissionsParentVarOne = styled.div`
  width: 100%;
  height: 100%;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  font-family: Outfit;
  @media (min-width: 768px) {
    padding: 35px;
  }
`

export const LiveSessionPermissionsTitleVarOne = styled.div`
  font-size: ${(props) => (props?.main ? '18px' : '14px')};
  font-weight: ${(props) => (props?.main ? '500' : '400')};
  text-align: center;
  @media (min-width: 768px) {
    font-size: ${(props) => (props?.main ? '20px' : '16px')};
  }
`

export const LiveSessionPermissionsImageVarOne = styled.img`
  height: 175px;
  width: 175px;
  @media (min-width: 768px) {
    height: 230px;
    width: 230px;
  }
`

export const LiveSessionPermissionsButtonVarOne = styled.button`
  padding: ${(props) => (props?.main ? '12px 36px' : '0')};
  background: ${(props) => (props?.main ? '#1b66c9' : 'transparent')};
  color: ${(props) => (props?.main ? '#fff' : '#1b66c9')};
  font-size: 16px;
  border-radius: 28px;
`

export const LiveSessionErrorSign = styled.div`
  position: absolute;
  background: #fa7b17;
  color: #fff;
  border-radius: 50%;
  padding: 2px;
  top: -7px;
  right: -7px;
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const LandingPageBackgroundShade = styled.div`
  background: ${(props) => (props?.main ? '#fff' : '#fafafa')};
`
