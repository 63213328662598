import { styled as muiStyled } from '@mui/material'

export const PracticeTimerSquareMidCardContainer = muiStyled('div')(
  ({ theme }) => `
    ._square-mid-card-single-card-container_{
        height: 100%;
        flex-grow:1;
    }
`,
)
