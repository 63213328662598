import {apiURL} from "src/consumer/services/index";

export function getUppyUrl(){
  switch (apiURL) {
    case 'https://dev.aumhum.xyz':
      return 'https://uppy-stg.aumhum.xyz'
    case 'https://stg.aumhum.xyz':
      return 'https://uppy-prod.aumhum.xyz'
    case 'https://live.aumhum.xyz':
      return 'https://uppy-prod.aumhum.xyz'
    default:
      return 'https://uppy-prod.aumhum.xyz'
  }
}
