import { React, createContext, useEffect, useState, useMemo } from 'react'
import { requestForToken } from '../firebase'

export const FCMContext = createContext(null)

export function FCMProvider({ children }) {
  const [fcmToken, setFcmToken] = useState(null)
  const [fcmStatus, setFcmStatus] = useState(
    'Notification' in window ? Notification.permission : 'denied',
  )
  const [fetchingToken, setFetchingToken] = useState(false)
  const [notificationsAreDenied, setNotificationsAreDenied] = useState(getNotificationsAreDenied())

  const fetchFCMToken = async () => {
    setFetchingToken(true)
    setNotificationsAreDenied(getNotificationsAreDenied())
    const FCMToken = await requestForToken()
    setFcmToken(FCMToken.token)
    setFcmStatus(FCMToken.state)
    setFetchingToken(false)
  }

  useEffect(() => {
    // console.log(fcmToken)
  }, [fcmToken])

  useEffect(() => {
    if ('Notification' in window && Notification.permission === 'granted') {
      setNotificationsAreDeniedLocal(false)
      fetchFCMToken()
    } else {
      localStorage.removeItem('FCMToken')
    }
  }, [])

  return (
    <FCMContext.Provider
      value={{ fcmToken, fcmStatus, fetchFCMToken, fetchingToken, notificationsAreDenied }}
    >
      {children}
    </FCMContext.Provider>
  )
}

export function getNotificationsAreDenied() {
  return sessionStorage.getItem('notificationsAreDenied')
}
export function setNotificationsAreDeniedLocal(state) {
  return sessionStorage.setItem('notificationsAreDenied', state)
}
