import { FAQ_LAYOUTS } from '../../utils'
import { FAQItemsContainer, FAQItemsContainerTwoColumn } from './styledComponent'

export default function FAQItemsContainerWrapper({ layoutType, children }) {
  switch (layoutType) {
    case FAQ_LAYOUTS.TWO_COLUMN_SIDEBAR:
      return <FAQItemsContainerTwoColumn>{children}</FAQItemsContainerTwoColumn>
    default:
      return <FAQItemsContainer>{children}</FAQItemsContainer>
  }
}
