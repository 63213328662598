import React, { useContext } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { getData, postData } from '../services/profileService'
import { isTokenInvalidOrAbsent } from 'src/views/pages/login/ProtectedRoute'
// import { FCMContext } from "src/context/FCMProvider";
import { appInstallationId, commonHeadersWeb } from '../services'
import { FCMContext } from 'src/context/FCMProvider'
import { logoutHandler } from '../services/loginLogoutHandlers'
import { useNavigate } from 'react-router-dom'

export const ProfileContext = React.createContext()

export const ProfileContextProvider = ({ children }) => {
  const [profileData, setProfileData] = useState(null)
  const [authToken, setAuthToken] = useState()
  const { fcmToken, fcmStatus } = useContext(FCMContext)
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const fetchProfileData = async () => {
    setLoading(true)
    try {
      const resp = await getData('/api/secure/user/profile')
      if (resp?.status === 200) {
        setProfileData(resp.data)
        localStorage.setItem('authorities', JSON.stringify(resp?.data?.authorities))
      } else if (resp?.response?.status === 401) {
        logoutHandler()
        navigate('/')
      }
    } catch (error) {
      // Handle any errors from the data fetching here
    }
    setLoading(false)
  }

  useEffect(() => {
    const token = localStorage.getItem('token')
    if (isTokenInvalidOrAbsent(token)) {
      setProfileData(null)
    } else {
      fetchProfileData()
    }
  }, [authToken])

  useEffect(() => {
    ;(async () => {
      if (fcmToken && profileData) {
        await informUserDeviceToServer(fcmToken, profileData)
      }
    })()
  }, [fcmToken, profileData])

  function handleChangeStorage() {
    const storageAuthToken = localStorage.getItem('token')
    if (storageAuthToken !== authToken) {
      setAuthToken(storageAuthToken)
    }
  }

  useEffect(() => {
    handleChangeStorage() //call this when someone reloads the page or this context is mounted

    window.addEventListener('storage', handleChangeStorage)
    return () => window.removeEventListener('storage', handleChangeStorage)
  }, [])

  return (
    <ProfileContext.Provider
      value={{
        profileData,
        setProfileData,
        authToken,
        refreshProfileData: fetchProfileData,
        profileDataLoading: loading,
      }}
    >
      {children}
    </ProfileContext.Provider>
  )
}

async function informUserDeviceToServer(fcmToken, profileData) {
  // if fcm token was not submitted before
  if (!Boolean(localStorage.getItem('submittedFCMTokenForThisUser'))) {
    const body = {
      deviceId: appInstallationId,
      userId: profileData?.userId,
      platformId: 0,
      pushRegId: fcmToken,
      imei: '',
      createTime: new Date().toISOString(),
      modifiedTime: new Date().toISOString(),
      appPackage: commonHeadersWeb['ef-app-package'],
    }
    const resp = await postData('/api/secure/user/device', body)
    if (resp?.status === 200) {
      localStorage.setItem('submittedFCMTokenForThisUser', true)
    }
  }
}
