import React from 'react'
import { Navigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { isAuthoritiesInvalid, isTokenInvalidOrAbsent } from 'src/views/pages/login/ProtectedRoute'
import { logoutHandlerBusiness } from 'src/business/helpers/helperFunctions'

const ProtectedRoute = ({ children }) => {
  // const { currentUser } = UserAuth()

  const token = localStorage.getItem('token')
  const authorities = localStorage.getItem('authorities')

  if (isTokenInvalidOrAbsent(token) || isAuthoritiesInvalid(authorities)) {
    logoutHandlerBusiness()
    return <Navigate to="/login" />
  }
  return children
}

ProtectedRoute.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ProtectedRoute
