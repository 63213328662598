import { ButtonBase, styled as muiStyled } from '@mui/material'

export const FAQSidebarLayoutContainer = muiStyled('div')(
  ({ theme }) => `
    display: flex;
    max-width: 1200px;
    margin: 0 auto;
    flex-direction: column;
    align-items: center;
    padding: 20px 0px;
    .heading{
        font-size: 35px;
        color: #000;
    }
    @media(min-width: ${theme.breakpoints.values.md}px){
        .heading{
            font-size: 45px;
        }
    }
`,
)

export const FAQSidebarLayoutFAQsContainer = muiStyled('div')(
  ({ theme }) => `
display: grid;
grid-template-columns: 1fr;
width: 100%;
padding: 30px;
@media(min-width: ${theme.breakpoints.values.md}px){
  gap: 20px;
    grid-template-columns: 1fr 2fr;
}
`,
)

export const FAQCategoriesContainerDefault = muiStyled('div')(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  @media(min-width: ${theme.breakpoints.values.md}px){
    margin: 0;
  }
`,
)

export const FAQCategoriesContainerWithDropDownButton = muiStyled('div')(
  ({ theme }) => `
    background: #f3f3f3;
    display:flex;
    border-radius: 7px;
    flex-direction: column;
    overflow: hidden;
    margin: 10px 0;
    @media(min-width: ${theme.breakpoints.values.md}px){
      margin: 0;
      background: transparent;
      border-radius: unset;
      overflow: unset;
    }
`,
)

export const FAQCategoryItem = muiStyled(ButtonBase)(
  ({ theme, selected }) => `
  padding: 14px 22px;
  border-bottom: 1px solid #dbdbdb;
  justify-content: flex-start;
  background: ${selected ? '#0000000d' : 'transparent'};

 .category-name{
      font-size: 18px;
      font-weight: 500;
      margin: 0px;
      color: #000;
 }
`,
)

export const FAQItemsContainer = muiStyled('div')(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`,
)
export const FAQItemsContainerTwoColumn = muiStyled('div')(
  ({ theme }) => `
  display: flex;
  gap: 10px;
  width: 100%;
  flex-direction: column;
  @media(min-width: ${theme.breakpoints.values.xl}px){
    display: grid;
    grid-template-columns: 1fr 1fr;
}
`,
)

// export const FAQItemContainer
