import { toast } from 'react-toastify'
import { currency_list } from 'src/consumer/constants'
import { getMPData, getMPDataWithParams, getMPDataWithoutParams } from 'src/mentor/services'

const PRICING_DETAIL_OBJECT = {
  currencyId: '',
  price: '',
  discountedPrice: '',
  introductoryPrice: '',
}

export function getCurrencySymbol(reactSelectProps) {
  const currencySymbolToUse = reactSelectProps?.selectProps?.metaCountries?.find((countryItem) => {
    const currentCurrencyPricing =
      reactSelectProps?.data?.pricingMatrix?.[reactSelectProps?.selectProps?.viewPriceCountry]
        ?.currencyId ||
      reactSelectProps?.data?.pricingMatrix?.row?.currencyId ||
      reactSelectProps?.data?.currency
    return countryItem?.currency === currentCurrencyPricing
  })?.currencySymbol

  return currencySymbolToUse || ''
}
export function filterOtherSelectedCountries(countries, pricingMatrix, currentCountry) {
  return countries.filter((country) => {
    return (
      country.id === currentCountry ||
      !pricingMatrix.some((pricing) => pricing.countryCode === country.id)
    )
  })
}

export function validatePricingMatrixToSave(pricingMatrix) {
  console.log(pricingMatrix)
  return pricingMatrix?.every(
    (item) => !!item?.countryId && !!item?.currencyId && typeof item?.price === 'number',
  )
}

export function createSubmittablePricingMatrix(pricingMatrix) {
  return pricingMatrix?.reduce((acc, pricingDetails) => {
    const { countryId, ...pricingDetailsToUse } = pricingDetails

    if (
      pricingDetailsToUse.introductoryPrice == null ||
      pricingDetailsToUse.introductoryPrice === ''
    ) {
      pricingDetailsToUse.introductoryPrice = pricingDetailsToUse.price
    }

    if (pricingDetailsToUse.discountedPrice == null || pricingDetailsToUse.discountedPrice === '') {
      pricingDetailsToUse.discountedPrice = pricingDetailsToUse.price
    }
    const currencySymbol = currency_list?.find(
      (currency) => currency?.code === pricingDetailsToUse?.currencyId,
    )?.symbol

    return {
      ...acc,
      [countryId]: {
        ...pricingDetailsToUse,
        currencySymbol: currencySymbol || pricingDetailsToUse?.currencyId,
      },
    }
  }, {})
}

export async function getAllOneOnOnePlans() {
  const resp = await getMPData('/api/secure/teacher/1on1-plans')
  if (resp?.status === 200) {
    if (resp?.data) {
      return resp?.data
    }
  }
}
export async function getAllBundlePacksForCurrentMentor(mentorId) {
  const resp = await getMPDataWithParams('/api/subscription-plan/bundle')
  if (resp?.status === 200) {
    if (resp?.data) {
      return resp?.data?.filter((plan) => plan?.mentorId === mentorId)
    }
  }
}

export async function getMentorBookedSlots(loadingSetter) {
  try {
    const resp = await getMPDataWithoutParams('/api/secure/teacher/booked-slots/all/paginated')
    if (resp?.status === 200) {
      return resp?.data
    } else {
      toast.error('Error getting booked slots')
      return null
    }
  } catch (error) {
    console.error('An error occurred:', error)
    toast.error('Error getting booked slots')
  } finally {
    loadingSetter(false)
  }
}

export function getUsablePricingMatrix(pricingMatrix, mentorCurrencyId) {
  const { row, ROW, ...cleanedPricingMatrix } = {
    ...pricingMatrix,
    row: pricingMatrix?.row || null,
    ROW: pricingMatrix?.ROW || null,
  }
  const rowPricingValue = ROW || row

  return Object.entries(
    {
      ...cleanedPricingMatrix,
      row: rowPricingValue
        ? rowPricingValue
        : { ...PRICING_DETAIL_OBJECT, currencyId: mentorCurrencyId || 'usd' },
    } || {},
  ).map(([countryCode, pricingDetails]) => {
    const pricingDetailsToUse = { ...PRICING_DETAIL_OBJECT, ...pricingDetails }
    return {
      ...pricingDetailsToUse,
      countryId: countryCode.toLowerCase(),
    }
  })
}
