import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const PAFLevelOneContainer = styled.footer`
  width: 100%;
  padding: 0.5rem;
  font-size: 16px;
  font-weight: 300;
  font-family: Outfit;
  color: rgb(99, 99, 99);
  @media (min-width: 768px) {
    padding: 1rem;
  }
`

export const PAFLevelTwoContainer = styled.div`
  background: #fafafa;
  border-radius: 1.5rem;
`

export const PAFLevelThreeContainer = styled.div`
  max-width: 480px;
  width: 100%;
  margin: auto;
  @media (min-width: 768px) {
    max-width: 768px;
  }
  @media (min-width: 1024px) {
    max-width: 1200px;
  }
`

export const PAFLevelFourContainer = styled.div`
  padding: 2.5rem 1rem 2.5rem 1rem;
  @media (min-width: 768px) {
    padding: 3rem 1rem 3rem 1rem;
  }
  @media (min-width: 1024px) {
    padding: 3rem 2rem 2rem 3rem;
  }
`

export const PAFLevelFiveContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 768px) {
    flex-direction: row-reverse;
  }
`

export const PAFSectionOne = styled.div`
  margin-bottom: 1.75rem;
  @media (min-width: 768px) {
    display: none;
  }
`

export const PAFBrandLogo = styled.img`
  width: 50px;
  cursor: pointer;
  border-radius: 12px;
`

export const PAFSectionTwo = styled.div`
  margin-bottom: 3.5rem;
  display: grid;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  gap: 1.5rem;
  @media (min-width: 480px) {
    gap: 3.5rem;
  }
  @media (min-width: 768px) {
    margin-left: auto;
    margin-bottom: 0;
  }
  @media (min-width: 1024px) {
    display: flex;
  }
`

export const PAFSectionTwo1 = styled.div`
  @media (max-width: 1024px) {
    grid-column: span 3 / span 3;
  }
`

export const PAFSectionTwo2 = styled.div`
  @media (max-width: 1024px) {
    grid-column: span 3 / span 3;
  }
`

export const PAFSectionTwo3 = styled.div`
  @media (max-width: 1024px) {
    grid-column: span 6 / span 6;
  }
`

export const PAFSectionTwoItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`

export const PAFSectionThree = styled.div``

export const PAFSectionThree1 = styled.div`
  display: none;
  @media (min-width: 768px) {
    margin-bottom: 2rem;
    display: block;
  }
`

export const PAFCompanyNameSpan = styled.span`
  width: 100%;
  @media (min-width: 480px) {
    width: auto;
  }
`

export const PAFHeading = styled.h4`
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
  margin-bottom: 1rem;
  color: #1c1c1c;
`

export const PAFClickableText = styled(Link)`
  text-decoration: none;
  color: inherit;
  &:hover {
    color: #000;
  }
`

export const PAFALink = styled.a`
  text-decoration: none;
  color: inherit;
  &:hover {
    color: #000;
  }
`
