import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  courseCategories: [],
}

const coursesSlice = createSlice({
  name: 'coursesReducer',
  initialState: initialState,
  reducers: {
    setCourseCategories(state, action) {
      return { ...state, courseCategories: action.payload }
    },
  },
})

export const { setCourseCategories } = coursesSlice.actions
export const coursesReducer = coursesSlice.reducer

function getCoursesStore(state) {
  return state?.[coursesSlice.name]
}

export const selectCourseCategories = (state) => {
  return getCoursesStore(state).courseCategories
}
