import styled from "styled-components";

// layout
export const ProfessionalsListBackgroundPage = styled.div`
    width: 100%;
    height: 100%;
    background-color: #FFFFFF;
`

export const ProfessionalsListInternalPage = styled.div`
    width: 100%;
    max-width: 1020px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    padding: 0 20px;
`

// heading
export const ProfessionalsListHeading = styled.div`
    color: #4D00A0;
    font-family: Outfit;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.3px;
    text-align: center;
    font-size: 22px;
    font-weight: 500;
    padding: 20px 0 40px 0;
    @media (min-width: 768px) { 
        font-size: 30px;
        font-weight: 600;
        padding: 30px 0 80px 0;
    }
`

// main content layout

export const ProfessionalsMainContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 40px;
    @media (min-width: 1000px) {
        flex-direction: row;
        gap: 40px;
    }
`

// category selection

export const ProfessionalsCategoryContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    overflow: auto;
    gap: 10px;
    flex-shrink: 0;
    ::-webkit-scrollbar {
        display: none;
    }
  
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    @media (min-width: 1000px) {
        flex-direction: column;
        width: 25%;
    }
`

export const ProfessionalCategoryButton = styled.button`
    color: ${props => props?.active ? '#A77817' : '#696969'};
    font-family: Outfit;
    flex-shrink: 0;
    font-size: 16px;
    font-style: normal;
    font-weight: ${props => props?.active ? '600' : '400'};
    line-height: normal;
    letter-spacing: 0.16px;
    border-radius: 36px;
    background: ${props => props?.active && '#F4D28B'};
    padding: 10px 40px;
    border: 1px solid #C1C1C1;
    @media(min-width: 768px){
        border: none;
    }
`

// professional selection

export const ProfessionalsItemsListContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: flex-start;
`

export const ProfessionalsItemContainer = styled.div`
    width: 100%;
    display: flex;
    gap: 30px;
    flex-direction: column;
    align-items: center;
    border-bottom: 1px solid #CCCCCC;
    padding: 10px;
    @media (min-width: 568px) {
        flex-direction: row;
        gap: 40px;
    }
`

export const ProfessionalsItemPicture = styled.div`
    width: 175px;
    height: 206px;
    flex-shrink: 0;
    border-radius: 21px;
    background: url(${props => props?.image});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    overflow: hidden;
    cursor: ${props => props?.clickable ? 'pointer' : 'default'};
    @media (min-width: 768px) {
        width: 262px;
        height: 311px;
    }
`

export const ProfessionalItemHeading = styled.div`
    color: ${props => props?.small ? '#808080' : '#464646'};
    font-family: Outfit;
    font-style: normal;
    line-height: normal;
    cursor: default;
    font-size: ${props => props?.small ? '17px' : '20px'};
    font-weight: ${props => props?.small ? '400' : '600'};
    letter-spacing: 0.18px;
    margin-bottom: ${props => props?.small ? '10px' : '15px'};
    text-align: ${props => props?.small ? 'left' : 'center'};
    @media (min-width: 568px) {
        text-align: left;
    }
    @media(min-width: 768px){
        font-size: ${props => props?.small ? '20px' : '24px'};
        font-weight: font-weight: ${props => props?.small ? '500' : '700'};
        letter-spacing: 0.24px;
        margin-bottom: ${props => props?.small ? '12px' : '25px'};
    }
`

export const ProfessionalItemDescription = styled.div`
    color: #626262;
    font-family: Outfit;
    cursor: default;
    font-style: normal;
    line-height: normal;
    font-size: 15px;
    font-weight: ${props => props?.bold ? '600' : '300'};
    letter-spacing: 0.12px;
    margin-bottom: 5px;
    text-align: center;
    @media (min-width: 568px){
        text-align: left;
    }
    @media(min-width: 768px){
        font-size: 18px;
        letter-spacing: 0.18px;
        margin-bottom: 10px;
    }
`

export const ProfessionalEventItem = styled.div`
    border: 1px solid #808080;
    padding: 4px 8px;
    border-radius: 6px;
    font-weight: 600;
    color: #1c1c1c;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    box-shadow: 2px 2px 0 #b4b4b4;
    &:hover {
        box-shadow: 2px 2px 0 #f4d28b;
    }
`

export const ProfessionalSeparator = styled.div`
    width: 70%;
    margin: 15px auto;
    background: #b4b4b4;
    height: 1px;
`

export const ProfessionalNextAvailableBox = styled.div`
    display: flex;
    flex-direction: column;
    cursor: default;
    justify-content: center;
    align-items: center;
    border-radius: 21px;
    background: #E3EBFF;
    flex-shrink: 0;
    padding: 5px 0;
    width: 200px;
    margin: 20px auto 0 auto;
    @media (min-width: 568px) {
        margin: 20px 0 0 0;
        width: 250px;
        padding: 15px 0;
    }
`

export const ProfessionalNextAvailableText = styled.div`
    color: ${props => props?.heading ? '#567EE3' : '#152A5F'};
    font-family: Outfit;
    font-size: ${props => props?.heading ? '17px' : '15px'};
    font-style: normal;
    font-weight: ${props => props?.heading ? '600' : '400'};
    line-height: normal;
    letter-spacing: 0.14px;
    @media (min-width: 768px) {
        
    }
`

export const BookNowStrip = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: linear-gradient(144deg, #6977DF 0%, #CA98FF 100%);
    font-family: Outfit;
    font-weight: 600;
    color: #FFFFFF;
    text-align: center;
    padding: 4px 0;
    font-size: 14px;
    @media (min-width: 768px){
        padding: 13px 0;
        font-size: 18px;
    }
`