const { default: styled } = require('styled-components')

export const FCRParentContainer = styled.div`
  width: 100%;
  border: 1px solid #cecece;
  border-radius: 8px;
  max-width: 1000px;
  margin: auto;
`

export const FCRSelectButton = styled.div`
  background: ${(props) => (props?.selected ? '#cccccc' : '#fff')};
  padding: 2px 8px;
  border-radius: 8px;
  border: 1px solid #999999;
  cursor: pointer;
  &:hover {
    background: #cccccc;
  }
`

export const FCRCalendarContainer = styled.div`
  width: 220px;
  .react-calendar {
    font-size: 12px;
  }
  .react-calendar__navigation {
    font-size: 12px;
  }
  .react-calendar__navigation__label__labelText {
    font-size: 12px;
  }
  .react-calendar__navigation__label__labelText--from {
    font-size: 12px;
  }
`

export const FCRCollectionTableWrapper = styled.div`
  max-height: 500px;
  overflow-y: scroll;
`

export const FCRCollectionTableItem = styled.div`
  display: grid;
  grid-template-columns: 0.5fr 1fr 0.5fr 0.5fr 1fr 1fr 0.5fr 1fr; /* 8 columns with different sizes */
  border-bottom: 1px solid #000;
  text-align: center;
  min-width: 600px;
`
