import { StyledSelectTimeZone } from "src/consumer/components/EventBooking/EventBookingStyles";
import styled from "styled-components";

export const CalendarPageContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
`

export const CalendarOnboardingPageContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    font-family: Outfit;
`

export const CalendarOnboardingContainer = styled.div`
    width: calc(100% - 40px);
    max-width: 980px;
    border: 1px solid #CCCCCC;
    border-radius: 8px;
    margin: 0 auto 10px;
    box-shadow: 0 1px 5px rgb(0 74 116/15%);
    background: #FFF;
    margin-top: 32px;
`

export const CalendarOnboardingInfoSection = styled.div`
    border-bottom: 1px solid #CCCCCC;
    display: flex;
    flex-direction: column;
    gap: 20px;
    @media (min-width: 768px) {
        flex-direction: row;
        gap: 20px;  
        justify-content: space-between;      
    }
`

export const COInfoSectionTextPart = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 32px 10px;
    @media (min-width: 768px) {
        padding: 32px 20px;
    }
`

export const COInfoText = styled.div`
    font-size: ${props => props?.main ? '16px' : '14px'};
    font-weight: ${props => props?.main ? '500' : '400'};
    margin-bottom: ${props => props?.main ? '20px' : '0'};
    color: #000000;
`

export const COInfoImage = styled.img`
    display: none;
    object-fit: cover;
    object-position: center;
    @media (min-width: 768px) {
        display: unset;
        width: 150px;
        height: 150px;
    }
`

export const COCreateURLSection = styled(COInfoSectionTextPart)`
    border-bottom: 1px solid #CCCCCC;
`

export const COURLSectionText = styled.div`
    font-size: ${props => props?.main ? '16px' : '14px'};
    font-weight: ${props => props?.main ? '500' : '400'};
    margin-bottom: ${props => props?.main ? '10px' : '20px'};
    color: #000000;
`

export const COURLSectionTextValidation = styled.div`
    font-size: 14px;
    font-weight: 500;
    color: ${props => props?.valid ? '#2eb85c' : '#cc0000'};
`

export const SlugElementsParent = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    @media (min-width: 768px) {
        flex-direction: row;   
        align-items: center;
    }
`

export const COURLSectionSpan = styled.span`
    font-size: ${props => props?.main ? '16px' : '14px'};
    font-weight: ${props => props?.main ? '500' : '400'};
    color: #000000;
`

export const COURLSectionInputParent = styled.div`
    position: relative;
    width: 100%;
    @media (min-width: 768px) {
        margin-left: 10px;
    }
`

export const COURLSectionInput = styled.input`
    padding: 10px 50px 10px 14px;
    border: 1px solid #CCCCCC;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 500;
    width: 100%;
    color: #000000;
    &:focus {
        /* Set outline to none to hide the default outline */
        outline: none;
    
        /* Add any other styles you want to apply when the input is focused */
    }
`

export const PositionedSlugValidationStatus = styled.div`
    position: absolute;
    right: 10px;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const StyledSelectTZCO = styled(StyledSelectTimeZone)`
    width: 100%;
    max-width: 350px;
    margin: 0 auto;
    @media (min-width: 768px) {
        margin: 0;
    }
`

export const COButtonsContainer = styled.div`
    display: flex;
    justify-content: right;
    flex-wrap: wrap;
    gap: 15px;
    max-width: 980px;
    width: calc(100% - 40px);
    margin: 0 auto 10px;
    background: #fafafa;
`

export const COButton = styled.button`
    background: #0069FF;
    padding: 8px 16px;
    border-radius: 20px;
    width: auto;
    transition: all ease-in-out 0.5s;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    color: #FFFFFF;
    gap: 10px;
    justify-content: space-between;
    width: ${props => props?.continueButtonLoading ? '125px' : '100px'};
    &:disabled {
        background: #6b6b6d;
        cursor: default;
    }
`

export const COCalendarControlsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 32px 10px;
    @media (min-width: 768px) {
        padding: 32px 20px;
}
`

export const COCalendarIntegrationContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 32px 10px;
    @media (min-width: 768px) {
        padding: 32px 20px;
}
`