import React from 'react'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import { ArrowBack } from '@mui/icons-material'
import HomeOutlined from '@mui/icons-material/HomeOutlined'
import { useNavigate } from 'react-router-dom'
import { APPBackButtonContainer } from './ActivityPlanPageStyles'

export const MyPlanHeaderCircularProgress = ({ progress }) => {
  return (
    <div style={{ width: '30px' }}>
      <CircularProgressbar
        value={progress}
        background={false}
        strokeWidth={12}
        styles={buildStyles({
          // Colors
          pathColor: `rgba(19, 238, 0, ${100 / 100})`,
          trailColor: 'rgba(57, 0, 120, 0.2)',
        })}
      />
    </div>
  )
}

export const GenericHeaderBackButton = ({ home, clickfunction, styles }) => {
  const navigate = useNavigate()
  return (
    <APPBackButtonContainer
      style={{ ...styles }}
      onClick={() => {
        if (clickfunction) {
          clickfunction()
        } else {
          navigate('/')
        }
      }}
    >
      {home ? <HomeOutlined sx={{ color: '#6e6e6e' }} /> : <ArrowBack />}
    </APPBackButtonContainer>
  )
}
