import { styled as muiStyled } from '@mui/material'
import Drawer from '@mui/material/Drawer'
import styled from 'styled-components'

export const StyledPrewallDrawer = styled(Drawer)`
  display: flex;
  justify-content: center;
  // backdrop-filter: blur(1px);
  background-color: rgba(106, 109, 210, 0.2);
  .MuiPaper-root {
    height: 95vh;
    width: 95%;
    left: auto;
    right: auto;
    background: #ffffff;
    background-size: 100% 100%;
    border: 1px solid #ffffff;
    border-radius: 15px 15px 0px 0px;
    overflow: hidden;
    overflow-y: auto; /* Hide scrollbar for Chrome, Safari and Opera */
    ::-webkit-scrollbar {
      display: none;
    }
  }
`

export const CloseIconContainer = styled.div`
  display: flex;
  justify-content: right;
`
export const PrewallLanding = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 101px;
  padding: 42px;
  @media (min-width: 1700px) {
    padding: 42px 100px 42px 42px;
  }
`
export const PlanFlow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const PlanFlowTitle = styled.div`
  color: #4e2d92;
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  @media (min-width: 768px) {
    font-size: 38px;
  }
`

export const PlanFlowSubtitle = styled.div`
  color: #9588b1;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  @media (min-width: 768px) {
    font-size: 20px;
  }
`

export const PlanValiditySelection = styled.div`
  display: flex;
  justify-content: space-between;
  width: 200px;
  height: 46px;
  padding: 3px 8px;
  background-color: #9bbcfc;
  border-radius: 18px;
  box-shadow: inset 0px 0.3px 7px rgba(0, 0, 0, 0.19);
  margin-top: 14px;
  @media (min-width: 768px) {
    height: 66px;
    padding: 9px 12px;
    width: 400px;
  }
`
export const ValidityBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 36px;
  background-color: ${(props) => (props.selected ? '#ffffff' : '#9bbcfc')};
  color: ${(props) => (props.selected ? '#4d00a0' : '#2656b5')};
  border-radius: 13px;
  font-weight: ${(props) => (props.selected ? '700' : '400')};
  &:hover {
    cursor: pointer;
  }
  @media (min-width: 768px) {
    width: 160px;
    height: 48px;
  }
`

export const PlanBox = styled.div`
  position: relative;
  height: 553px;
  width: 338px;
  border-radius: 33px;
  background: #4e2d92;
  margin: 14px 0px 36px 0px;
  @media (min-width: 768px) {
    width: 555px;
    height: 600px;
    margin: 14px 0px 0px 0px;
  }

  @media (min-width: 900px) {
    width: 815px;
    height: 553px;
    margin: 14px 0px 0px 0px;
  }
`
export const PriceBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 120px;
  width: 150px;
  border-radius: 26px;
  background: #361c6c;
  margin: 33px 29px 0px 0px;
  &:hover {
    cursor: pointer;
  }
  @media (min-width: 900px) {
    height: 97px;
    width: 143px;
  }
`

export const PremiumMaskot = styled.img`
  height: 389px;
  width: 207px;
  margin-right: 27px;
  @media (max-width: 768px) {
    display: none;
  }
  @media (max-width: 900px) {
    height: 245px;
    width: 140px;
    position: absolute;
    bottom: 0;
    right: 0;
  }
`

export const PlanLogo = styled.img`
  width: 55px;
  height: 51px;
  @media (min-width: 768px) {
    width: 51px;
  }
`

export const BoxHeading = styled.div`
  font-size: 20px;
  font-weight: 500;
  color: #ffffff;
  padding-left: 21px;
  &:hover {
    cursor: default;
  }
  @media (min-width: 768px) {
    font-size: 31px;
  }
`
export const ListContainer = styled.div`
  width: 500px;
  height: 301px;
  overflow-y: auto;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    width: 5px;
    height: 20%;
  }

  ::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #fff;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: rgba(255, 255, 255, 0.7);
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
  // Hide scrollbar for IE, Edge and Firefox

  &:hover {
    cursor: default;
  }
`

export const TickList = styled.ul`
  color: #ffffff;
  list-style: ${(props) => `circle inside url(${props.tickImage})`};
  li {
    margin: 23px 0px 0px 48px;
    font-size: 18px;
    font-weight: 400;
  }
  span {
    margin: 23px 0px 0px 48px;
    color: #ccb3ff;
    font-size: 19px;
    margin-left: auto;
    font-weight: 400;
  }
  @media (min-width: 768px) {
    li {
      margin: 23px 48px 0px 48px;
      font-size: 19px;
      font-weight: 400;
    }
    span {
      margin: 23px 0px 0px 48px;
      color: #ccb3ff;
      font-size: 23px;
      font-weight: 400;
    }
  }
`

export const UpperContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  position: absolute;
  top: 0px;
`

export const PlanTitle = styled.div`
  display: flex;
  margin: 40px 0px 0px 48px;
`

export const PlanPrice = styled.h2`
  font-size: 18px;
  font-weight: 700;
  color: #ffffff;
  margin: 0px 0px 0px 0px;
  @media (min-width: 768px) {
    font-size: 32px;
  }
`
export const ValidityText = styled.h3`
  font-size: 13px;
  font-weight: 400;
  color: #9e8cc3;
  margin: 0px 0px 0px 0px;
`
export const BilledAnnualText = styled.span`
  position: absolute;
  bottom: -50px;
  font-weight: 400;
  font-size: 13px;
  text-align: center;
  color: #ffffff;
  @media (min-width: 768px) {
    font-size: 17px;
  }
  @media (min-width: 900px) {
    bottom: -20px;
  }
`

export const LowerContainer = styled.div`
  display: flex;
  position: absolute;
  bottom: 0px;
  width: 100%;
  justify-content: space-between;
`

export const PremiumButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 45px;
  border-radius: 33px;
  font-size: 22px;
  font-weight: 600;
  color: #380074;
  background: #bbe8ff;
  filter: drop-shadow(3px 4px 24px rgba(0, 0, 0, 0.25));
  &:active {
    transform: translateY(2px);
  }
  &:hover {
    cursor: pointer;
  }
  @media (min-width: 768px) {
    width: 333px;
    height: 72px;
    font-size: 18px;
    margin-top: 20px;
  }
`

export const Advertisement = styled.img`
    width: 452px;
    height: 903px;
    border-radius: 32px;
    @media (max-width: 1700px){
        display: none
`

export const VideoContainer = styled.div`
  width: 452px;
  height: 903px;
  border-radius: 32px;
  overflow: hidden;
  @media (max-width: 1700px) {
    display: none;
  }
  video {
    object-fit: cover;
  }
`

export const ComparisonTile = styled.div`
  position: relative;
  margin: 0px auto;
  width: min(1069px, 99%);
  min-height: 87px;
  display: flex;
  align-items: center;
  background: ${(props) => props.background};
  color: #000000;
  border-radius: 26px;
  @media (min-width: 768px) {
    min-height: 80px;
  }
`

export const ComparisonLeftText = styled.div`
  display: flex;
  position: absolute;
  flex-wrap: wrap;
  padding-left: 15px;
  font-weight: 600;
  width: 60%;
  left: 0%;
  font-size: 12px;
  @media (min-width: 768px) {
    font-size: 18px;
  }
`

export const ComparisonMidText = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  width: 19%;
  left: 60%;
  color: ${(props) => (props.heading ? '#939393' : '#5a5757')};
  font-size: ${(props) => (props.heading ? '15px' : '12px')};
  font-weight: ${(props) => (props.heading ? '600' : '400')};
  @media (min-width: 768px) {
    font-size: ${(props) => (props.heading ? '20px' : '18px')};
  }
`

export const ComparisonRightText = styled.div`
  position: absolute;
  display: flex;
  flex-direction: ${(props) => props.heading && 'column'};
  justify-content: center;
  align-items: ${(props) => props.heading && 'center'};
  width: 17%;
  left: 80%;
  font-weight: ${(props) => (props.heading ? '700' : '500')};
  color: ${(props) => (props.heading ? '#fa6318' : '#000000')};
  font-size: ${(props) => (props.heading ? '14px' : '12px')};
  @media (min-width: 768px) {
    font-size: ${(props) => (props.heading ? '25px' : '17px')};
  }
`

export const ComparisonContainer = styled.div`
  padding: 42px 0px 100px 0px;
`

export const ComparisonTitle = styled.div`
  text-align: center;
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 37px;
  @media (min-width: 768px) {
    font-size: 45px;
  }
`

export const ComparisonButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 200px;
  height: 52px;
  border-radius: 26px;
  font-size: 14px;
  font-weight: 600;
  color: ${(props) => (props.plan ? '#fff' : '#4d00a0')};
  background: ${(props) =>
    props.plan ? 'linear-gradient(161.37deg, #FFC101 17.11%, #FE6001 87.39%)' : '#e6f7ff'};

  filter: box-shadow: 0px 22px 24px rgba(213, 207, 177, 0.331184);
  &:active {
    transform: translateY(2px);
  }
  &:hover {
    cursor: pointer;
  }
`

export const TestimonialContainer = styled.div`
  background-color: #1a1b1d;
  padding: 35px 0px;
  @media (max-width: 500px) {
    display: none;
  }
`
export const TestimonialHeading = styled.div`
  text-align: center;
  font-weight: 700;
  font-size: 37px;
  color: #ffffff;
`

export const TestimonialSubHeading = styled.div`
  text-align: center;
  font-weight: 400;
  font-size: 17px;
  color: #ffffff;
  margin-bottom: 52px;
`

export const ReviewBox = styled.div`
  margin: 0px auto;
  padding: 0px 64px 75px 122px;
  overflow: hidden;
`
export const ReviewBoxUpper = styled.div`
  padding-top: 20px;
  display: flex;
  align-items: center;
  position: relative;
  right: 70px;
`
export const ReviewBoxImage = styled.img`
  width: ${(props) => props.width};
`
export const ReviewName = styled.h1`
  color: #000000;
  font-weight: 600;
  font-size: 24px;
  padding-left: 10px;
`
export const ReviewBoxLower = styled.div`
  color: #1a1b1d;
  font-weight: 700;
  font-size: 16px;
`
export const ReviewBoxFirst = styled(ReviewBox)`
  width: 485px;
  height: 233.16px;
  position: relative;
  z-index: 5;
  background: linear-gradient(0deg, #F7F6FB, #F7F6FB), #FFFFFF;
  box-shadow: -8px 8px 40px 5px rgba(0, 0, 0, 0.7);
  border-radius: 17.7982px;
  @media (max-width: 900px){
    left: 0px;
    bottom: 0px;
    width: 443px;
    height: 213.16px;
    margin-top: 10px;
    background: #FFFFFF;
    `
export const ReviewBoxSecond = styled(ReviewBox)`
  width: 443px;
  height: 213.16px;
  position: relative;
  left: -200px;
  bottom: 20px;
  z-index: 3;
  background: #FFFFFF;
  box-shadow: 0px 16px 40px #000000;
  border-radius: 16.2595px;
  @media (max-width: 900px){
    left: 0px;
    bottom: 0px;
    width: 443px;
    height: 213.16px;
    margin-top: 10px;
    background: #FFFFFF;
    `
export const ReviewBoxThird = styled(ReviewBox)`
  width: 545px;
  height: 262.16px;
  position: relative;
  left: 250px;
  bottom: 200px;
  z-index: 2;
  background: linear-gradient(91.77deg, #F7F6FB 1.11%, #F7F6FB 42.04%, #F7F6FB 70.51%, rgba(247, 246, 251, 0.6) 98.49%);
  box-shadow: -8px 40px 40px rgba(0, 0, 0, 0.8);
  border-radius: 20px;
  @media (max-width: 900px){
    left: 0px;
    bottom: 0px;
    width: 443px;
    height: 213.16px;
    margin-top: 10px;
    background: #FFFFFF;
    `
export const ReviewBoxFourth = styled(ReviewBox)`
  width: 420px;
  height: 202.16px;
  position: relative;
  left: -90px;
  bottom: 280px;
  z-index: 2;
  background: #9EA1A8;
  border-radius: 15.4198px;
  @media (max-width: 900px){
    left: 0px;
    bottom: 0px;
    width: 443px;
    height: 213.16px;
    margin-top: 10px;
    background: #FFFFFF;
`

export const ContainerForBusiness = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: min(1228.82px, 80%);
  min-height: 204.42px;
  padding: 10px 0px 10px 0px;
  margin: 0px auto;
  background: #343536;
  box-shadow: 6px 6px 69px rgba(0, 0, 0, 0.47);
  border-radius: 11px;
  color: white;
  @media (max-width: 900px){
    width: 90%;
    flex-direction: column;
    margin-top: 20px;
`
export const CompanyLogo = styled.img`
    width: 69px;
    height: 62px;
    border-radius: 6px;
    margin: 0px 10px 0px 42px;
    @media (max-width: 900px){
      margin: 20px 0px;
`

export const BusinessText = styled.div`
    width: 60%;
    @media (max-width: 900px){
      text-align: center;
`

export const ContactButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 207.98px;
  height: 65.91px;
  margin: 0px 64px 0px 10px;
  border: 2px solid #9BBCFC;
  border-radius: 9px;
  color: #9bbcfc;
  &:active {
    transform: translateY(2px);
  }
  &:hover {
    cursor: pointer;
  }
  @media (max-width: 900px){
    margin: 20px 0px;
`

export const BusinessTextHeading = styled.div`
  font-weight: 600;
  font-size: 44px;
  color: #fff;
`

export const BusinessTextSubHeading = styled.div`
  font-weight: 300;
  font-size: 16px;
  color: #b3b3b3;
`
export const FaqSectionContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

export const FaqContainer = styled.div`
  padding-top: 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 864px;
  margin: 0 auto;
  font-family: Outfit;
  a {
    color: #2819af;
    text-decoration: underline;
  }
  @media (min-width: 768px) {
    flex-direction: row;
  }
`
export const FaqHeading = styled.div`
  font-weight: 600;
  font-size: 24px;
  padding: 0 10px;
  color: #000;
  font-family: Outfit;
  @media (min-width: 768px) {
    text-align: center;
    font-size: 28px;
    min-width: 40%;
  }
`

export const FAQCategoryAndItemsContainer = muiStyled('div')(
  ({ theme }) => `
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        max-width: 835px;
        width: 100%;
        padding: 0px 10px;
        margin: auto;
`,
)

export const FAQCategoryTitle = muiStyled('div')(
  ({ theme }) => `
  font-size:18px;
  color: #000;
    font-weight: 600;
    width: 100%;
    margin: 0 0 12px 0px;
    padding: 0px 10px;
    `,
)

export const FAQCategoryTitleForFaqComponent = muiStyled('div')(
  ({ theme }) => `
  font-size:18px;
  color: #000;
    font-weight: 600;
    width: 100%;
    margin: 18px 0 3px 0px;
    padding: 0px 10px;
    `,
)

export const PaymentMethodsContainerInPrewall = styled.div`
  padding: 42px 5px;
  @media (min-width: 768px) {
    padding: 42px 30px;
  }
`
