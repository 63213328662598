import { ButtonBase, DialogActions, DialogContent, Paper, styled } from '@mui/material'

export const StyledPushNotificationDialog = styled(Paper)(
  ({ theme }) => `
  max-width: 600px !important;
  width: 100%;
  border-radius: 15px;
  padding: 10px;
`,
)

export const StyledPushNotificationDialogContent = styled(DialogContent)(
  ({ theme, denied }) => `
  font-size: 22px;
  display:flex;
  flex-direction:column;
  gap: 10px;
  justify-content:${denied ? 'space-between' : 'start'};
  align-items:center;
`,
)

export const StyledPushNotificationDialogText = styled('div')(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  text-align: center;

  font-size: 16px;
  font-weight: 400;
`,
)

export const StyledPushNotificationDialogActionButton = styled(ButtonBase)(
  ({ theme }) => `
  background-color: #7352ac;
  border-radius: 30px;
  padding: 10px 30px;
  width: 250px;
  color: #fff;
`,
)

export const StyledPushNotificationDialogTitle = styled('div')(
  ({ theme }) => `
  font-size: 25px;
  text-align: center;
`,
)

export const EnableNotificationIcon = styled('img')(
  ({ theme }) => `
  width: 150px;
  height: 150px;
  position: relative;
  right: 15px;
`,
)

export const PushNotificationComponentContent = styled('div')(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap:10px;
  font-family: Outfit !important;
`,
)

export const PushNotificationHeading = styled('div')(
  ({ theme }) => `
      font-size: 20px;
    font-weight: 600;
`,
)

export const PushNotificationAction = styled(DialogActions)(
  ({ theme }) => `
  display:flex;
  flex-direction:column;
  width: 100%;
  align-items:center;
  font-family: Outfit!important;
  font-size: 18px;
`,
)
