import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  serviceProvidersData: [],
}

const serviceProvidersDataSlice = createSlice({
  name: 'serviceProvidersDataStore',
  initialState: initialState,
  reducers: {
    setServiceProvidersData(state, action) {
      return { ...state, serviceProvidersData: action.payload }
    },
    addServiceProviderToData(state, action) {
      const newProvider = action.payload;
      const exists = state.serviceProvidersData.some(provider => provider.id === newProvider.id);
      if (!exists) {
        return { ...state, serviceProvidersData: [...state.serviceProvidersData, newProvider] }
      }
      
    },
  },
})

function getServiceProviderDataStore(state) {
  return state.serviceProvidersDataStore
}



export const { setServiceProvidersData, addServiceProviderToData } =
  serviceProvidersDataSlice.actions

export const serviceProvidersDataReducer = serviceProvidersDataSlice.reducer


export const selectServiceProvidersData = (state) => {
  return getServiceProviderDataStore(state).serviceProvidersData
}
