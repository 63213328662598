import React, { useContext, useEffect, useMemo, useState } from 'react'
import {
  BookNowStrip,
  ProfessionalCategoryButton,
  ProfessionalEventItem,
  ProfessionalItemDescription,
  ProfessionalItemHeading,
  ProfessionalsCategoryContainer,
  ProfessionalSeparator,
  ProfessionalsItemContainer,
  ProfessionalsItemPicture,
  ProfessionalsItemsListContainer,
  ProfessionalsListBackgroundPage,
  ProfessionalsListHeading,
  ProfessionalsListInternalPage,
  ProfessionalsMainContent,
} from './ProfessionalsListStyles'
import { useNavigate } from 'react-router-dom'
import { getFormattedCount } from '../DailyPlay/DailyPlayComponents'
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled'
import PeopleIcon from '@mui/icons-material/People'
import { getImageLink } from 'src/mentor/helpers/utilityFunctions'
import { getCategoryListMap } from 'src/consumer/helpers/courseCardHelper'
import { GenericDisplayFlex } from 'src/mentor/layout/Styles'
import Tooltip from '@mui/material/Tooltip'
import { LocalizationContext } from 'src/consumer/context/LocalizationContext'

export default function ProfessionalsListPage(props) {
  const { setupData, fromOnboarding, oneOnOneMentorsData } = props
  const navigate = useNavigate()
  const [selectedCategory, setSelectedCategory] = useState()

  const { localizedData } = useContext(LocalizationContext)
  const myLiveSessionsStrings = localizedData?.strings?.myLiveSessionsPage

  // console.log('in professional listing page, setup data is:')
  // console.log(setupData)

  // if (!Boolean(oneOnOneMentorsData)) {
  //   console.log('no one on one data')
  // }

  // const showProfessionalBoolean = (professional) =>

  const filteredMasters = oneOnOneMentorsData?.filter(
    (professional) => professional?.services?.live,
  )

  // from category Id to mentors map function
  const getCategoryToMentorsArrayObject = (filteredMasters) => {
    const finalCategoryToMentorObject = filteredMasters.reduce((acc, masterItem) => {
      masterItem?.categories?.forEach((categoryId) => {
        acc[categoryId] = [...(acc?.[categoryId] || []), masterItem]
      })
      return acc
    }, {})

    // filteredMasters?.forEach((professional) => {
    //   professional?.categories?.forEach((categoryId) => {
    //     finalCategoryToMentorObject[categoryId] = []
    //   })
    // })
    // filteredMasters?.forEach((professional) => {
    //   professional?.categories?.forEach((categoryId) => {
    //     finalCategoryToMentorObject[categoryId].push(professional)
    //   })
    // })
    return finalCategoryToMentorObject
  }

  // CALLING : from category Id to mentors map function
  const categoryToMentorObject = getCategoryToMentorsArrayObject(filteredMasters)

  const categoryListMap = useMemo(() => {
    return getCategoryListMap(setupData)
  }, [setupData])

  const allAvailableAllowedCategories = Object.keys(categoryToMentorObject).filter(
    (item) => categoryListMap?.get(item)?.name && categoryListMap?.get(item)?.enabled,
  )

  // setting first selected category
  useEffect(() => {
    const arrayOfItems = Object.keys(categoryToMentorObject).filter(
      (item) => categoryListMap?.get(item)?.name && categoryListMap?.get(item)?.enabled,
    )
    if (arrayOfItems?.includes('therapist')) {
      setSelectedCategory('therapist')
    } else {
      setSelectedCategory(arrayOfItems?.[0])
    }
  }, [])

  // useEffect(() => {
  //   console.log({
  //     categoryToMentorObject,
  //     categoryListMap,
  //     allAvailableAllowedCategories,
  //     getCategoryToMentorsArrayObject,
  //     setupData,
  //     filteredMasters,
  //   })
  // }, [
  //   categoryToMentorObject,
  //   categoryListMap,
  //   allAvailableAllowedCategories,
  //   getCategoryToMentorsArrayObject,
  //   setupData,
  //   filteredMasters,
  // ])

  return (
    <ProfessionalsListBackgroundPage>
      <ProfessionalsListInternalPage>
        <ProfessionalsListHeading>
          {myLiveSessionsStrings?.choose_a_professional || 'Choose a Professional'}
        </ProfessionalsListHeading>
        <ProfessionalsMainContent>
          {/* headings */}
          <ProfessionalsCategoryContainer>
            <ProfessionalCategoryLister
              allAllowedCategories={allAvailableAllowedCategories}
              categoryListMap={categoryListMap}
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
            />

            {/* {allAvailableAllowedCategories?.map((item, index) => {
              return (
                <ProfessionalCategoryButton
                  key={index}
                  active={item === selectedCategory}
                  onClick={() => setSelectedCategory(item)}
                >
                  {categoryListMap?.get(item)?.name}
                </ProfessionalCategoryButton>
              )
            })} */}
          </ProfessionalsCategoryContainer>

          {/* listing */}
          <ProfessionalsItemsListContainer>
            {categoryToMentorObject?.[selectedCategory]?.map((item, index) => {
              let mentorDataOfNeededId = oneOnOneMentorsData?.find(
                (mentorItem) => mentorItem?.id === item?.id,
              )
              let displayEventTypes = getDisplayEventTypesOfMentor(mentorDataOfNeededId)

              return (
                <ProfessionalsItemContainer key={index}>
                  <ProfessionalsItemPicture
                    clickable={displayEventTypes && displayEventTypes?.length > 0}
                    onClick={() => {
                      if (displayEventTypes && displayEventTypes?.length > 0) {
                        if (fromOnboarding) {
                          navigate(`/mentor/${item?.slug}/events`, {
                            state: { fromOnboarding: true },
                          })
                        } else {
                          navigate(`/mentor/${item?.slug}/events`)
                        }
                      }
                    }} // also add option to change chosen event type
                    image={getImageLink(item?.profilePicId)}
                  >
                    {displayEventTypes && displayEventTypes?.length > 0 && (
                      <BookNowStrip>{myLiveSessionsStrings?.book_now || 'Book now'}</BookNowStrip>
                    )}
                  </ProfessionalsItemPicture>
                  <div style={{ width: '100%' }}>
                    <ProfessionalItemHeading>{item?.name}</ProfessionalItemHeading>
                    <ProfessionalItemDescription>
                      {item?.shortDesc || item?.longDesc}
                    </ProfessionalItemDescription>
                    <ProfessionalItemDescription bold>
                      <GenericDisplayFlex sb>
                        {Boolean(item?.hrsTaught) && item?.hrsTaught !== 0 && (
                          <GenericDisplayFlex style={{ gap: '3px' }}>
                            {getFormattedCount(item?.hrsTaught / 60)} hours taught{' '}
                            <AccessTimeFilledIcon sx={{ fontSize: 20, color: 'gray' }} />
                          </GenericDisplayFlex>
                        )}
                        {Boolean(item?.studentsCount) && item?.studentsCount !== 0 && (
                          <GenericDisplayFlex style={{ gap: '3px' }}>
                            {getFormattedCount(item?.studentsCount)} students{' '}
                            <PeopleIcon sx={{ fontSize: 20, color: 'gray' }} />
                          </GenericDisplayFlex>
                        )}
                      </GenericDisplayFlex>
                    </ProfessionalItemDescription>

                    {displayEventTypes && displayEventTypes?.length > 0 && (
                      <ProfessionalSeparator />
                    )}

                    {displayEventTypes && displayEventTypes?.length > 0 && (
                      <ProfessionalItemHeading small>
                        {myLiveSessionsStrings?.services || 'Services'}
                        {':'}
                      </ProfessionalItemHeading>
                    )}

                    <GenericDisplayFlex style={{ flexWrap: 'wrap', gap: '20px' }}>
                      {displayEventTypes?.map((eventItem, index) => {
                        return (
                          <Tooltip title={eventItem.description} key={index}>
                            <ProfessionalEventItem
                              key={index}
                              onClick={() => {
                                if (fromOnboarding) {
                                  navigate(`/mentor/${item?.slug}/events/${eventItem?.uid}`, {
                                    state: { fromOnboarding: true },
                                  })
                                } else {
                                  navigate(`/mentor/${item?.slug}/events/${eventItem?.uid}`)
                                }
                              }}
                            >
                              {eventItem?.title}{' '}
                              {eventItem?.free && (
                                <span
                                  style={{
                                    background: '#f4d28b',
                                    borderRadius: '10px',
                                    padding: '2px 5px',
                                  }}
                                >
                                  FREE
                                </span>
                              )}
                            </ProfessionalEventItem>
                          </Tooltip>
                        )
                      })}
                    </GenericDisplayFlex>

                    {/* <ProfessionalNextAvailableBox>
                                            <ProfessionalNextAvailableText heading>Next Available</ProfessionalNextAvailableText>
                                            <ProfessionalNextAvailableText>15 August 23, 12:30pm</ProfessionalNextAvailableText>
                                        </ProfessionalNextAvailableBox> */}
                  </div>
                </ProfessionalsItemContainer>
              )
            })}
          </ProfessionalsItemsListContainer>
        </ProfessionalsMainContent>
      </ProfessionalsListInternalPage>
    </ProfessionalsListBackgroundPage>
  )
}

export function getDisplayEventTypesOfMentor(mentorDataOfNeededId) {
  return mentorDataOfNeededId?.eventTypes?.filter(
    (eventItem) =>
      eventItem?.uid &&
      eventItem?.publicEvent &&
      eventItem?.enabled &&
      !Boolean(eventItem?.contextId),
  )
}

function ProfessionalCategoryLister({
  allAllowedCategories,
  categoryListMap,
  selectedCategory,
  setSelectedCategory,
}) {
  // console.log({
  //   allAllowedCategoriesInListerComponent: allAllowedCategories,
  //   categoryListMapInLister: categoryListMap,
  // })

  let modifiedAllowedCategories = [allAllowedCategories]

  if (allAllowedCategories?.includes('therapist')) {
    allAllowedCategories.splice(allAllowedCategories.indexOf('therapist'), 1)
    modifiedAllowedCategories = ['therapist', ...allAllowedCategories]
  }

  return modifiedAllowedCategories?.map((item, index) => {
    return (
      <ProfessionalCategoryItem
        key={item}
        active={item === selectedCategory}
        onClick={() => setSelectedCategory(item)}
        getCategoryTitle={(item) => item?.name}
        item={categoryListMap?.get(item)}
      />
    )
  })
}

function ProfessionalCategoryItem({ active, getCategoryTitle, onClick, item }) {
  return (
    <ProfessionalCategoryButton
      // active={item === selectedCategory}
      active={active}
      // onClick={() => setSelectedCategory(item)}
      onClick={onClick}
    >
      {getCategoryTitle(item)}
    </ProfessionalCategoryButton>
  )
}
