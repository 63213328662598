import styled from 'styled-components'
import React from 'react'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

export const CardListContainer = styled.div`
  position: relative;
`

export const FilterButtonBackground = styled.div`
  width: 40px;
  height: 100px;
  border-radius: ${(props) => (props?.right ? '15px 0 0 15px' : '0 15px 15px 0')};
  background: #0000008c;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  ${(props) => (props?.right ? 'right: 0px;' : 'left: 0px;')};
  top: calc(50% - 50px);
  z-index: 2;
  cursor: pointer;
  backdrop-filter: blur(5px);
  box-shadow: 0 0 10px -3px #000;
`

export const HorizontalScrollLeftButton = (props) => {
  return (
    <FilterButtonBackground onClick={props?.onClickFunction}>
      <ArrowBackIosNewIcon sx={{ color: 'white', fontSize: 40 }} />
    </FilterButtonBackground>
  )
}

export const HorizontalScrollRightButton = (props) => {
  return (
    <FilterButtonBackground right onClick={props?.onClickFunction}>
      <ArrowForwardIosIcon sx={{ color: 'white', fontSize: 40 }} />
    </FilterButtonBackground>
  )
}
