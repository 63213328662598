import styled from 'styled-components'

export const ImgProfile = styled.img`
  width: 60%;
  position: relative;
  box-shadow: 0 0.375rem 0 #963fa2;
  border-radius: 50%;
  transition: box-shadow 0.5s ease-in-out;

  &:hover {
    box-shadow: 0.375rem 0.375rem 0 #7450aa;
  }
`
