import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CloseIcon from '@mui/icons-material/Close'
import moment from 'moment'
import { useEffect, useState } from 'react'
import {
  boldifyAsteriskText,
  getInitialsForChat,
  linkifyTheText,
} from 'src/mentor/helpers/utilityFunctions'
import {
  ChatAvatarContainer,
  ChatHeader,
  ChatMesageOtherUser,
  ChatMessageThisUser,
  ConversationListItemContainer,
} from './ChatStyles'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import DoneIcon from '@mui/icons-material/Done'
import { FeatureCardComponent } from 'src/consumer/components/Tile/components/layoutCards/FeatureCard/FeatureCardComponent'
import { useNavigate } from 'react-router-dom'
import { FeatureCardActionButton } from 'src/consumer/components/Tile/components/layoutCards/FeatureCard/featureCardComponentStyles'
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications'
import { AGORA_CHAT_MESSAGE_CUSTOM_EXTS_TYPE } from 'src/consumer/constants'

export const AvatarComponentChat = ({
  imageInHeader,
  nameInHeader,
  size,
  showPresence,
  presence,
}) => {
  return (
    <ChatAvatarContainer backgroundImage={imageInHeader} small={size === 'small'}>
      {imageInHeader ? '' : getInitialsForChat(nameInHeader)}
      {showPresence && (
        <div
          style={{
            width: '10px',
            height: '10px',
            borderRadius: '50%',
            background: presence ? '#32CD32' : 'grey',
            position: 'absolute',
            right: 0,
            bottom: 0,
          }}
        ></div>
      )}
    </ChatAvatarContainer>
  )
}

export const ChatHeaderComponent = ({
  backFunction,
  nameInHeader,
  imageInHeader,
  showPresence,
  presence,
  relativeRole,
}) => {
  return (
    <ChatHeader>
      {backFunction && (
        <ArrowBackIcon style={{ color: '#FFF', cursor: 'pointer' }} onClick={backFunction} />
      )}
      <AvatarComponentChat
        imageInHeader={imageInHeader}
        nameInHeader={nameInHeader}
        showPresence={showPresence}
        presence={presence}
      />
      <div>
        <div>{nameInHeader}</div>
        {relativeRole && <div style={{ fontSize: '12px', color: '#818181' }}>{relativeRole}</div>}
      </div>
    </ChatHeader>
  )
}

export const ConversationListHeaderComponent = ({ closeFunction }) => {
  return (
    <ChatHeader style={{ minHeight: '56px' }}>
      <div style={{ fontSize: '22px', width: '100%', textAlign: 'center' }}>Chat</div>
      {closeFunction && (
        <CloseIcon
          style={{ color: '#FFF', cursor: 'pointer', position: 'absolute', right: '8px' }}
          onClick={closeFunction}
        />
      )}
    </ChatHeader>
  )
}

export const ConversationListItemComponent = ({
  imageLink,
  name,
  chatId,
  clickFunction,
  showPresence,
  presence,
  relativeRole,
  lastMessage,
  unreadCount,
}) => {
  return (
    <>
      <ConversationListItemContainer onClick={() => clickFunction(chatId)}>
        <AvatarComponentChat
          imageInHeader={imageLink}
          nameInHeader={name}
          showPresence={showPresence}
          presence={presence}
        />
        <div style={{ flex: '1 1 250px', overflow: 'hidden', position: 'relative' }}>
          <div style={{ color: '#000' }}>
            {name}
            {relativeRole && (
              <span style={{ fontSize: '12px', color: '#818181' }}> ({relativeRole})</span>
            )}
          </div>
          {lastMessage && (
            <div
              style={{
                fontSize: '12px',
                color: unreadCount > 0 ? '#000' : '#818181',
                fontWeight: unreadCount > 0 ? 500 : 400,
                width: 'calc(100% - 50px)',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
            >
              {lastMessage}
            </div>
          )}
          {unreadCount > 0 && (
            <div
              style={{
                position: 'absolute',
                background: 'red',
                width: '16px',
                height: '16px',
                color: '#fff',
                right: '16px',
                top: '8px',
                bottom: 0,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '50%',
                fontSize: '12px',
                fontWeight: '500',
              }}
            >
              {unreadCount}
            </div>
          )}
        </div>
      </ConversationListItemContainer>
      <div
        style={{
          borderBottom: '1px solid rgb(182,194,200)',
          width: '95%',
          margin: 'auto',
          height: '1px',
        }}
      ></div>
    </>
  )
}

export const ChatMessageOtherUserWrapper = ({ showTime, image, name, chatMessageItem }) => {
  let momentForDate
  if (showTime && chatMessageItem?.time) {
    momentForDate = moment?.(chatMessageItem?.time)
  }
  const createMarkup = (html) => {
    return { __html: html }
  }
  return (
    <div style={{ display: 'flex', gap: '4px' }}>
      {(image || name) && (
        <AvatarComponentChat imageInHeader={image} nameInHeader={name} size={'small'} />
      )}
      <ChatMesageOtherUser>
        <div
          dangerouslySetInnerHTML={createMarkup(
            boldifyAsteriskText(linkifyTheText(chatMessageItem?.msg)),
          )}
        />
        {/* dangerouslySetInnerHTML should not be used. we are susceptible to xss attacks. we can use dompurify to fix */}
        {momentForDate && (
          <div style={{ position: 'absolute', bottom: '2px', right: '2px', fontSize: '10px' }}>
            {momentForDate?.format('HH:mm')}
          </div>
        )}
      </ChatMesageOtherUser>
    </div>
  )
}

export const ChatMessageThisUserWrapper = ({ showTime, image, name, chatMessageItem }) => {
  let momentForDate
  if (showTime && chatMessageItem?.time) {
    momentForDate = moment?.(chatMessageItem?.time)
  }
  const createMarkup = (html) => {
    return { __html: html }
  }
  return (
    <div style={{ display: 'flex', gap: '4px' }}>
      <ChatMessageThisUser>
        <div
          dangerouslySetInnerHTML={createMarkup(
            boldifyAsteriskText(linkifyTheText(chatMessageItem?.msg)),
          )}
        />
        {/* dangerouslySetInnerHTML should not be used. we are susceptible to xss attacks. we can use dompurify to fix */}
        {momentForDate && (
          <div
            style={{
              position: 'absolute',
              bottom: '2px',
              right: '2px',
              fontSize: '10px',
              display: 'flex',
              gap: '2px',
              alignItems: 'center',
            }}
          >
            <div>{momentForDate?.format('HH:mm')}</div>
            <div>
              <StatusIcon status={chatMessageItem?.localSendState} />
            </div>
          </div>
        )}
      </ChatMessageThisUser>
      {(image || name) && (
        <AvatarComponentChat imageInHeader={image} nameInHeader={name} size={'small'} />
      )}
    </div>
  )
}

export const ChatCustomCardMessageOtherUserWrapper = ({
  showTime,
  image,
  name,
  chatMessageItem,
}) => {
  const navigate = useNavigate()
  let momentForDate
  if (showTime && chatMessageItem?.time) {
    momentForDate = moment?.(chatMessageItem?.time)
  }
  return (
    <div style={{ display: 'flex', gap: '4px' }}>
      {(image || name) && (
        <AvatarComponentChat imageInHeader={image} nameInHeader={name} size={'small'} />
      )}
      <ChatMesageOtherUser>
        <div>{chatMessageItem?.customExts?.msg}</div>
        <FeatureCardComponent
          item={chatMessageItem?.customExts}
          getTitleField={(data) => data?.title}
          getDescriptionField={(data) => data?.description}
          getImageField={(data) => data?.image}
          getActionButtonLogic={(data) => {
            if (data?.uri) {
              return {
                text: 'Open',
                onClick: () => navigate('/article'),
              }
            } else {
              return null
            }
          }}
        />
        {momentForDate && (
          <div style={{ position: 'absolute', bottom: '2px', right: '2px', fontSize: '10px' }}>
            {momentForDate?.format('HH:mm')}
          </div>
        )}
      </ChatMesageOtherUser>
    </div>
  )
}

export const ChatCustomCardMessageThisUserWrapper = ({
  showTime,
  image,
  name,
  chatMessageItem,
}) => {
  const navigate = useNavigate()
  let momentForDate
  if (showTime && chatMessageItem?.time) {
    momentForDate = moment?.(chatMessageItem?.time)
  }
  return (
    <div style={{ display: 'flex', gap: '4px' }}>
      <ChatMessageThisUser>
        <div>{chatMessageItem?.customExts?.msg}</div>
        <FeatureCardComponent
          item={chatMessageItem?.customExts}
          getTitleField={(data) => data?.title}
          getDescriptionField={(data) => data?.description}
          getImageField={(data) => data?.image}
          getActionButtonLogic={(data) => {
            if (data?.uri) {
              return {
                text: 'Open',
                onClick: () => navigate('/article'),
              }
            } else {
              return null
            }
          }}
        />
        {momentForDate && (
          <div
            style={{
              position: 'absolute',
              bottom: '2px',
              right: '2px',
              fontSize: '10px',
              display: 'flex',
              gap: '2px',
              alignItems: 'center',
            }}
          >
            <div>{momentForDate?.format('HH:mm')}</div>
            <div>
              <StatusIcon status={chatMessageItem?.localSendState} />
            </div>
          </div>
        )}
      </ChatMessageThisUser>
      {(image || name) && (
        <AvatarComponentChat imageInHeader={image} nameInHeader={name} size={'small'} />
      )}
    </div>
  )
}

export const ChatBookingRequestMessageOtherUserWrapper = ({
  showTime,
  image,
  name,
  chatMessageItem,
}) => {
  let momentForDate
  if (showTime && chatMessageItem?.time) {
    momentForDate = moment?.(chatMessageItem?.time)
  }
  const navigate = useNavigate()

  return (
    <div style={{ display: 'flex', gap: '4px' }}>
      {(image || name) && (
        <AvatarComponentChat imageInHeader={image} nameInHeader={name} size={'small'} />
      )}
      <ChatMesageOtherUser>
        <CircleNotificationsIcon />
        <div style={{ fontSize: '10px' }}>
          Request Id: {chatMessageItem?.customExts?.bookingRequest?.id}
        </div>
        {chatMessageItem?.customExts?.type ===
          AGORA_CHAT_MESSAGE_CUSTOM_EXTS_TYPE.INCOMING_BOOKING_REQUEST && (
          <div>Received booking request</div>
        )}
        {chatMessageItem?.customExts?.type ===
          AGORA_CHAT_MESSAGE_CUSTOM_EXTS_TYPE.BOOKING_REQUEST_CANCELLED && (
          <div>This booking request was cancelled</div>
        )}
        {chatMessageItem?.customExts?.type ===
          AGORA_CHAT_MESSAGE_CUSTOM_EXTS_TYPE.BOOKING_REQUEST_ACCEPTED && (
          <div>This booking request has been accepted by a mentor</div>
        )}

        {chatMessageItem?.customExts?.type ===
          AGORA_CHAT_MESSAGE_CUSTOM_EXTS_TYPE.INCOMING_BOOKING_REQUEST && (
          <FeatureCardActionButton onClick={() => navigate('/booking-requests-for-mentors/active')}>
            Go to Booking Requests Section
          </FeatureCardActionButton>
        )}
        {momentForDate && (
          <div style={{ position: 'absolute', bottom: '2px', right: '2px', fontSize: '10px' }}>
            {momentForDate?.format('HH:mm')}
          </div>
        )}
      </ChatMesageOtherUser>
    </div>
  )
}

export const ChatBookingRequestMessageThisUserWrapper = ({
  showTime,
  image,
  name,
  chatMessageItem,
}) => {
  let momentForDate
  if (showTime && chatMessageItem?.time) {
    momentForDate = moment?.(chatMessageItem?.time)
  }

  return (
    <div style={{ display: 'flex', gap: '4px' }}>
      <ChatMessageThisUser>
        <CircleNotificationsIcon />
        <div style={{ fontSize: '10px' }}>
          Booking Request Id: {chatMessageItem?.customExts?.bookingRequest?.id}
        </div>
        {chatMessageItem?.customExts?.type ===
          AGORA_CHAT_MESSAGE_CUSTOM_EXTS_TYPE.INCOMING_BOOKING_REQUEST && (
          <div>Received booking request</div>
        )}
        {chatMessageItem?.customExts?.type ===
          AGORA_CHAT_MESSAGE_CUSTOM_EXTS_TYPE.BOOKING_REQUEST_CANCELLED && (
          <div>This booking request was cancelled</div>
        )}
        {chatMessageItem?.customExts?.type ===
          AGORA_CHAT_MESSAGE_CUSTOM_EXTS_TYPE.BOOKING_REQUEST_ACCEPTED && (
          <div>This booking request has been accepted by a mentor</div>
        )}
        {momentForDate && (
          <div
            style={{
              position: 'absolute',
              bottom: '2px',
              right: '2px',
              fontSize: '10px',
              display: 'flex',
              gap: '2px',
              alignItems: 'center',
            }}
          >
            <div>{momentForDate?.format('HH:mm')}</div>
            <div>
              <StatusIcon status={chatMessageItem?.localSendState} />
            </div>
          </div>
        )}
      </ChatMessageThisUser>
      {(image || name) && (
        <AvatarComponentChat imageInHeader={image} nameInHeader={name} size={'small'} />
      )}
    </div>
  )
}

export const ChatBoxDateComponent = ({ time = 0 }) => {
  const currentDate = moment()
  const momentForDate = moment?.(time)
  if (currentDate.isSame(momentForDate, 'day')) {
    return <DateBox actualText={'Today'} />
  } else if (currentDate.subtract(1, 'day').isSame(momentForDate, 'day')) {
    return <DateBox actualText={'Yesterday'} />
  } else if (currentDate.isoWeek() === momentForDate.isoWeek()) {
    return <DateBox actualText={momentForDate.format('dddd')} />
  } else {
    return <DateBox actualText={moment?.(time)?.format('DD-MM-YYYY')} />
  }
}

const DateBox = ({ actualText }) => {
  return (
    <div
      style={{
        background: '#c3c5c7',
        padding: '2px 8px',
        fontSize: '14px',
        borderRadius: '4px',
        width: 'fit-content',
        margin: '0 auto',
        marginBottom: '16px',
      }}
    >
      {actualText}
    </div>
  )
}

const StatusIcon = ({ status }) => {
  if (status) {
    if (status === 'waiting') {
      return <AccessTimeIcon style={{ fontSize: '10px', position: 'relative', bottom: '2px' }} />
    } else if (status === 'failure') {
      return (
        <ErrorOutlineOutlinedIcon
          style={{ fontSize: '10px', position: 'relative', bottom: '2px', color: 'red' }}
        />
      )
    } else {
      return <DoneIcon style={{ fontSize: '10px', position: 'relative', bottom: '2px' }} />
    }
  } else {
    return <DoneIcon style={{ fontSize: '10px', position: 'relative', bottom: '2px' }} />
  }
}
