import { ButtonBase, styled as muiStyled } from '@mui/material'
import PhoneInput from 'react-phone-number-input'
import Select from 'react-select'
import styled from 'styled-components'

export const BusinessOnboardingPage = styled.div`
  width: 100%;
  height: auto;
  min-height: 100vh;
`

export const BusinessOnboardingMain = styled.div`
  flex: 0 0 auto;
  flex-direction: column;
  align-items: center;
`

// header things:
export const BusinessOnboardingHeader = styled.div`
  width: 354px;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  gap: 5px;
  /* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  @media (min-width: 768px) {
    width: 480px;
    margin-top: 40px;
  }
`

export const BusinessOnboardingHeaderTabs = styled.div`
  background: ${(props) =>
    props?.selected ? 'linear-gradient(to right, #6977DF, #CA98FF)' : '#f5f7fa'};
  color: ${(props) => (props?.selected ? '#fff' : 'rgb(89, 110, 146)')};
  height: 30px;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  cursor: ${(props) => (props?.onClick ? 'pointer' : 'default')};
  align-items: center;
  border-radius: 10px;
  ${(props) =>
    props.onClick
      ? `&:hover {
    background: ${props?.selected ? 'linear-gradient(to right, #6977DF, #CA98FF)' : '#00000055'};
  
  }`
      : ''}
`

// body things:
export const BusinessOnboardingBody = muiStyled('div')(
  ({ theme, useMaxWidth = false, maxWidth = '576px' }) => `
    margin: 0 auto;
    width: ${useMaxWidth ? '100% !important' : '335px'};
    ${useMaxWidth && `max-width: ${maxWidth} !important;`}
    @media (min-width: 768px) {
      width: ${useMaxWidth ? '100% !important' : '475px'};
    }
  `,
)

export const BusinessOnboardingBodyHeading = styled.h1`
  margin-bottom: 19px;
  font-weight: 700;
  font-size: 29px;
  line-height: 38px;
  color: rgb(34, 47, 45);
`

export const BusinessOnboardingBodyDescription = styled.p`
  font-size: 16px;
  line-height: 20px;
  color: rgb(69, 74, 94);
  margin-bottom: 31px;
`

export const BusinessOnboardingBodyPlan = styled.div`
  width: 100%;
  padding: 15px 23px;
  display: flex;
  flex-direction: column;
  border: 1px solid rgb(224, 232, 243);
  border-radius: 15px;
  position: relative;
  transition: all 0.5s ease-in-out;
  margin-bottom: 15px;
  cursor: ${(props) => (props?.notselected ? 'pointer' : 'default')};
`

export const ClinicPlanBodyTitle = styled.span`
  font-weight: 700;
  font-size: 23px;
  color: rgb(34, 47, 45);
`

export const BusinessOnboardingBodyPriceSpan = styled.span`
  font-weight: ${(props) => (props?.main ? '700' : '400')};
  font-size: ${(props) => (props?.main ? '24px' : '16px')};
  line-height: ${(props) => (props?.main ? '38px' : '20px')};
  color: ${(props) => (props?.main ? 'rgb(34, 47, 45)' : 'rgb(69, 74,94)')};
  margin-right: ${(props) => (props?.main ? '5px' : '0')};
  margin-bottom: ${(props) => (props?.main ? '0' : '3px')};
`

export const BusinessOnboardingBodyDescriptionList = styled.div`
  margin-bottom: ${(props) => (props?.heading ? '10px' : '0')};
  font-size: 16px;
  line-height: 20px;
  font-weight: ${(props) => (props?.heading ? '500' : '400')};
  color: ${(props) => (props?.heading ? 'rgb(34,47,45)' : 'rgb(69,74,94)')};
`

export const BOBPlanAppAccessContainer = styled.div`
  width: 100%;
  border-radius: 9px;
  padding: 13px;
  border: 1px solid rgb(113, 127, 157);
  margin-bottom: 20px;
`

export const BOBPlanRecommendedTag = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 0 15px 0 15px;
  background: #c395fc;
  color: #fff;
  font-weight: 500;
  padding: 3px 6px;
`

// selection component

export const BusinessOnboardingSingleSelectContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid rgb(117, 138, 174);
  min-height: 55px;
  color: rgb(34, 47, 45);
  font-size: 16px;
  font-weight: 400px;
  border-radius: 9px;
  padding: 0 17px;
  cursor: pointer;
`

export const BusinessOnboardingSingleSelectOptionsContainer = styled.div`
  border-radius: 9px;
  padding: 5px;
  width: ${(props) => (props?.considerPadding ? '289px' : '335px')};
  @media (min-width: 768px) {
    width: ${(props) => (props?.considerPadding ? '429px' : '475px')};
  }
`

export const BusinessOnboardingBodyPlanSelectorArrow = styled.img`
  width: 15px;
  transform: ${(props) => props?.menuOpen && 'rotate(-180deg)'};
  transition: all 0.3s ease-in-out;
`

export const BusinessOnboardingBodyPlanSelectorText = styled.span`
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  color: rgb(34, 47, 45);
  margin-right: 9px;
  ${(props) => props?.bold && 'font-weight: 500;'};
  ${(props) => props?.gray && 'color: rgb(89,110,146);'};
  ${(props) => props?.green && 'color: rgb(71,162,3);'};
`

export const BusinessOnboardingSingleSelectOption = styled.div`
  display: flex;
  align-items: center;
  background: ${(props) => (props?.selected ? '#EFEFEF' : '#FFF')};
  height: 55px;
  color: rgb(34, 47, 45);
  font-size: 16px;
  font-weight: 400px;
  cursor: pointer;
  padding: 17px;
  border-radius: 5px;
  &:hover {
    background: #0098ff;
    color: #fff;
    ${BusinessOnboardingBodyPlanSelectorText} {
      color: #fff;
    }
  }
`

// input

export const BusinessOnboardingInput = styled.input`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid rgb(117, 138, 174);
  height: 55px;
  color: rgb(34, 47, 45);
  font-size: 16px;
  font-weight: 400px;
  border-radius: 9px;
  padding: 0 17px;
  cursor: pointer;
  &:focus {
    outline: none;
  }
`

export const BusinessOnboardingInputContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  border: 1px solid rgb(117, 138, 174);
  height: 55px;
  color: rgb(34, 47, 45);
  font-size: 16px;
  font-weight: 400px;
  border-radius: 9px;
  padding: 0 17px;
  cursor: pointer;
`

// submit button

export const BusinessOnboardingSubmitButton = styled.button`
  background: #737be3;
  color: #fff;
  width: 100%;
  cursor: pointer;
  font-weight: 700;
  border-radius: 10px;
  padding: 0 5px;
  font-size: 17px;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  transition: all 0.3s ease-in-out;
  &:disabled {
    background: #bdc0e3;
  }
`

export const StyledPhoneNumberInput = muiStyled(PhoneInput)(
  ({ theme }) => `
    font-family: Outfit;
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 15px 17px;
    border: 1px solid rgb(117, 138, 174);
    border-radius: 7px;
    outline: none;
`,
)

export const StyledOnboardingSelect = muiStyled(Select)`
    text-align: start;
    > .react-select__control{
      background:transparent;
        border:1px solid rgb(117,138,174);
        padding: 7px 0px;
        border-radius: 7px;
        box-shadow: unset !important;
    };
    > .react-select__control .react-select__value-container .react-select__multi-value{
      background-color: rgb(200 200 200);
      margin: 0px 10px 0px 0px;
      border-radius: 3px;
    };
    > .react-select__control .react-select__indicators .react-select__indicator-separator{
      background-color:rgb(117,138,174);
    }
    > .react-select__control .react-select__indicators .react-select__indicator{
      color:#a57cd6;
    }
`

export const StyledSectionHeading = muiStyled('div')(
  ({ theme, error }) => `
  font-size: 20px;
  margin-bottom: 7px;
  font-weight: 600;
  color: ${error && theme?.palette.error.main}
`,
)

export const StyledSectionSubHeading = muiStyled('div')(
  ({ theme }) => `
  font-size: 16px;
  margin-bottom:15px
`,
)
export const StyledStaffPhoneNumberInput = muiStyled(PhoneInput)(
  ({ theme }) => `
  font-family: Outfit !important;
  padding: 4px;
  width: 100%;
  .PhoneInputInput {
    font-family: Outfit !important;
    background: transparent;
    border: none;
    outline: none;
    width: 100%;
  }
`,
)

export const StyledStaffInputComponent = muiStyled('div')(
  ({ theme, invalid }) => `
  display: flex;
  justify-content: stretch;
  align-items: center;
  width: 100%;
  gap: 0.5rem;
  background: ${invalid ? '#edbbbb' : '#ebebeb'};
  border-radius: 7px;
  padding: 4px;
`,
)
export const StyledClinicBrandStaffInputComponent = muiStyled('div')(
  ({ theme, invalid }) => `
  display: flex;
  justify-content: stretch;
  align-items: center;
  width: 100%;
  gap: 0.5rem;
  border: 1px solid #0000005e;
  background: ${invalid ? '#edbbbb' : '#fff'};
  border-radius: 7px;
  padding: 15px 17px;
`,
)

export const StyledPhoneInputContainer = styled.div`
  display: flex;
  justify-content: stretch;
  align-items: center;
  width: 100%;
  gap: 0.5rem;
  background: ${({ invalid }) => (invalid ? '#edbbbb' : '#ebebeb')};
  border-radius: 7px;
`

export const StyledOnboardingTextArea = styled.textarea`
  outline: none;
  padding: 10px;
  border: 1px solid rgb(117, 138, 174);
  background: ${({ invalid }) => (invalid ? '#edbbbb' : 'transparent')};
  border-radius: 7px;
  min-height: ${({ theme, minHeight }) => (!!minHeight ? minHeight : 120)}px;
  width: 100%;
`

export const StyledAddStaffButton = muiStyled(ButtonBase)(
  ({ theme, btnColor }) => `
  display: flex;
  justify-content:center;
  align-items: center;
  padding: 7px 13px;
  gap:7px;
  border-radius: 10px;
  background: ${btnColor === 'none' ? 'transparent' : '#737be3'};
  color: ${btnColor === 'none' ? 'black' : 'white'};
  &:hover{
    background-color: ${btnColor === 'none' ? 'rgba(0,0,0,0.3)' : '#737be3db'};
  }
  transition: all 150ms ease-in-out;
`,
)

export const StyledClinicStaffForm = muiStyled('form')(staffCards)
export const StyledClinicStaffView = muiStyled('div')(staffCards)

function getStaffBackground(viewType) {
  switch (viewType) {
    case 'prospect':
      return '#fff2b0'
    case 'approved':
      return '#b0ffb0'
    default:
      return '#ffffff'
  }
}

function staffCards({ theme, viewType }) {
  return `
  background: ${getStaffBackground(viewType)};
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #00000059;
`
}

export const CompleteValidaitonStaffMember = muiStyled('div')(
  ({ theme }) => `
  color: #5e5eff;
  display:flex;
  align-items:center;
  gap: 5px;
`,
)
