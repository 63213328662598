import { ButtonBase, styled as muiStyled, Paper } from "@mui/material";

export const WhyScreeningNotBookedButton = muiStyled(ButtonBase)(({theme, selected})=>`
    background-color: ${selected ? '#e3e3e3' : theme.palette.background.paper};
    color: #000;
    border-radius: 8px;
    border: 1px solid ${theme.palette.divider};
    padding: 5px 10px;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 620px;
    padding:10px 10px;
    justify-content: center;
    align-items: start;
    text-align: left;
    
`)

export const ReasonOptionsDialogPaper = muiStyled(Paper)(({theme})=>`
    width: 100%;
    height: 100%;
    max-height: 726px!important;
`)