import moment from 'moment'

export function prepareMessagesArray(inputArray) {
  const deepCopyInputArray = [...inputArray]

  function sortBasedOnTimeStamp(a, b) {
    return a?.time - b?.time
  }

  deepCopyInputArray?.sort(sortBasedOnTimeStamp)

  const newArray = []
  let lastAddedDate = null // DD-MM-YYYY

  while (deepCopyInputArray.length > 0) {
    const firstMessage = deepCopyInputArray.shift()
    let momentForDate = moment?.(firstMessage?.time)
    if (momentForDate && momentForDate?.format('DD-MM-YYYY') === lastAddedDate) {
    } else {
      newArray.push({ renderType: 'date', time: firstMessage?.time })
      lastAddedDate = momentForDate?.format('DD-MM-YYYY')
    }
    newArray.push(firstMessage)
  }
  return newArray
}

export function sortMessagesArray(inputArray) {
  const deepCopyInputArray = [...inputArray]

  function sortBasedOnTimeStamp(a, b) {
    return a?.time - b?.time
  }

  deepCopyInputArray?.sort(sortBasedOnTimeStamp)
  return deepCopyInputArray
}

export const isOnlineNow = (lastPresenceUpdateTime) => {
  if (moment().unix() - lastPresenceUpdateTime < 30) {
    return true
  } else {
    return false
  }
}

export function giveUnreadCount(allMessagesOrderedTimeAscFilteredFromOther, lastMessageSeenId) {
  let unreadCount = 0
  if (
    Array.isArray(allMessagesOrderedTimeAscFilteredFromOther) &&
    allMessagesOrderedTimeAscFilteredFromOther.length > 0 &&
    lastMessageSeenId
  ) {
    const indexOfThisMessage = allMessagesOrderedTimeAscFilteredFromOther.findIndex(
      (message) => message.id === lastMessageSeenId,
    )
    if (indexOfThisMessage >= 0) {
      unreadCount = allMessagesOrderedTimeAscFilteredFromOther?.length - indexOfThisMessage - 1
    }
  }
  return unreadCount
}

export function replaceTriedSentMessageWithResultantMessage(array, refIdToReplace, resultantMessage){
  const deepCopyArray = [...array]
  const foundIndex = deepCopyArray.findIndex(item => item?.id === refIdToReplace)
  if (foundIndex > -1){
    deepCopyArray[foundIndex] = resultantMessage
  }
  return deepCopyArray
}