import styled from 'styled-components'
import Modal, { BaseModalBackground } from 'styled-react-modal'
import { Paper, styled as muiStyled } from '@mui/material'

export const FadingBackground = styled(BaseModalBackground)`
  opacity: ${(props) => props.opacity};
  transition: all 0.3s ease-in-out;
  z-index: 1000;
`

export const StyledInformationModal = Modal.styled`
  height: 650px;
  width: 900px;
  border-radius: 36px;
  opacity: ${(props) => props.opacity};
  transition : all 0.3s ease-in-out;
  position: relative;
  overflow: hidden;
  @media (max-height: 650px) {
    height: 80vh;
  }
  @media (max-width: 900px) {
    width: 80vw;
  }
`

export const StyledInformationModalMUIPaper = muiStyled(Paper)(
  ({ theme }) => `
  max-width: unset !important;
  max-height: unset !important;
height: 650px;
width: 900px;
border-radius: 36px;
opacity: ${(props) => props.opacity};
position: relative;
overflow: hidden;
border-radius: 10px;
@media (max-height: 650px) {
  height: 80vh;
}
@media (max-width: 900px) {
  width: 80vw;
}
`,
)
