import React, { useEffect, useRef, useState } from 'react'
import { Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material'
import { ReasonOptionsDialogPaper, WhyScreeningNotBookedButton } from './styledComponents'
import { CustomTextArea } from 'src/consumer/components/helperComponents'
import { Close, Send } from '@mui/icons-material'
import { sleep } from 'src/mentor/helpers/utilityFunctions'
import { ExtendableTextArea } from 'src/consumer/components/helperComponentsStyles'

export default function NewBookingDialogReasonForNotDoingScreeningForm({
  reasonForNotBooking,
  setReasonForNotBooking,
  reasonForNotBookingScreeningId,
  setReasonIdForNotDoingScreening,
  screenerAvailable,
}) {
  const [showReasonDialog, setShowReasonDialog] = useState(false)

  const reasonTextToShow =
    screeningNotRequestedReasonsOptionsArray.find(
      (option) => option.id === reasonForNotBookingScreeningId,
    )?.translation || reasonForNotBooking

  function handleClickOpen() {
    setShowReasonDialog(true)
  }
  function handleClose() {
    setShowReasonDialog(false)
  }

  useEffect(() => {
    if (!screenerAvailable) {
      setReasonIdForNotDoingScreening('no_screener_available')
      setReasonForNotBooking('No Screener Available')
    }
    return () => {
      setReasonIdForNotDoingScreening('')
      setReasonForNotBooking('')
    }
  }, [])

  useEffect(() => {
    console.log({ reasonForNotBookingScreeningId })
  }, [reasonForNotBookingScreeningId])

  return (
    <div style={{ maxWidth: '600px' }}>
      <span>
        Reason for not booking screening{' '}
        {reasonForNotBooking !== 'No Screener Available' && (
          <span
            style={{ textDecoration: 'underline', cursor: 'pointer' }}
            onClick={handleClickOpen}
          >
            (Edit)
          </span>
        )}
        : {reasonTextToShow}
      </span>
      <Dialog
        PaperComponent={ReasonOptionsDialogPaper}
        open={showReasonDialog}
        onClose={handleClose}
      >
        <ReasonDialog
          reasonForNotBooking={reasonForNotBooking}
          handleClose={handleClose}
          setReasonForNotBooking={setReasonForNotBooking}
          reasonForNotBookingScreeningId={reasonForNotBookingScreeningId}
          setReasonIdForNotDoingScreening={setReasonIdForNotDoingScreening}
        />
      </Dialog>
    </div>
  )
}

function ReasonDialog({
  reasonForNotBooking,
  setReasonForNotBooking,
  reasonForNotBookingScreeningId,
  setReasonIdForNotDoingScreening,
  handleClose,
}) {
  const textAreaRef = useRef(null)

  const handleReasonChange = (event) => {
    setReasonForNotBooking(event.target.value)
  }

  return (
    <>
      <DialogTitle
        sx={{
          alignItems: 'center',
          justifyContent: 'space-between',
          display: 'flex',
          padding: '7px 24px',
        }}
      >
        <span>Your reason for not selecting a reason...</span>
        <IconButton onClick={handleClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div className="d-flex flex-column justify-content-center flex-wrap gap-2">
          {screeningNotRequestedReasonsOptionsArray
            ?.filter((option) => !option?.hidden)
            .map((option, idx) => (
              <WhyScreeningNotBookedButton
                key={idx}
                selected={reasonForNotBookingScreeningId === option?.id}
                onClick={() => {
                  setReasonForNotBooking(option?.title)
                  setReasonIdForNotDoingScreening(option?.id)
                  handleClose()
                }}
              >
                <Typography sx={{ textAlign: 'left' }} variant="body1">
                  {option?.translation}
                </Typography>
                <Typography variant="body1">({option?.title})</Typography>
              </WhyScreeningNotBookedButton>
            ))}
          <WhyScreeningNotBookedButton
            selected={
              !screeningNotRequestedReasonsOptionsArray
                ?.map((option) => option?.title)
                .includes(reasonForNotBooking)
            }
            onClick={async () => {
              setReasonForNotBooking('')
              setReasonIdForNotDoingScreening('other')
              await sleep(200)
              textAreaRef.current.scrollIntoView({ behavior: 'smooth' })
            }}
          >
            <Typography variant="body1">Other (अन्य)</Typography>
          </WhyScreeningNotBookedButton>
          <div
            className={`mt-2 d-flex align-items-start ${
              screeningNotRequestedReasonsOptionsArray
                ?.map((optn) => optn?.id)
                .includes(reasonForNotBookingScreeningId) && 'd-none'
            }`}
          >
            <div className="w-100">
              <CustomTextArea
                customTextAreaComponent={ExtendableTextArea}
                textAreaRef={textAreaRef}
                value={reasonForNotBooking}
                onChange={handleReasonChange}
                placeholder="Enter Reason"
              />
            </div>
            <IconButton onClick={handleClose}>
              <Send />
            </IconButton>
          </div>
        </div>
      </DialogContent>
    </>
  )
}
export const screeningNotRequestedReasonsOptionsArray = [
  {
    id: 'patient_refused_screening',
    title: 'Patient Refused Screening',
    translation: 'मरीज़ ने स्क्रीनिंग करवाने से मना किया',
  },
  {
    id: 'patient_already_screened_today',
    title: 'Patient Already Screened previously today',
    translation: 'मरीज़ की पिछले 1 दिन में स्क्रीनिंग हो चुकी है',
  },
  {
    id: 'emergency_appointment',
    title: 'Emergency Appointment - Immediate Doctor Consultation Needed',
    translation: 'आपातकालीन स्थिति - तुरंत डॉक्टर से परामर्श आवश्यक है',
  },
  {
    id: 'doctors_instruction_to_skip',
    title: "Doctor's Instruction to Skip Screening for This Patient",
    translation: 'डॉक्टर के निर्देश पर इस मरीज़ की स्क्रीनिंग नहीं की जा रही है',
  },
  {
    id: 'screening_system_unavailable',
    title: 'Screening System Temporarily Unavailable',
    translation: 'स्क्रीनिंग सिस्टम अस्थायी रूप से उपलब्ध नहीं है',
  },
  {
    id: 'patient_age_condition_not_suitable',
    title: 'Patient’s Age/Condition Not Suitable for Screening',
    translation: 'मरीज़ की उम्र या स्थिति स्क्रीनिंग के लिए उपयुक्त नहीं है',
  },
  // {
  //   id: 'repeat_follow_up_appointment',
  //   title: 'Repeat Follow-up Appointment - Screening Not Required',
  //   translation: 'फॉलो-अप अपॉइंटमेंट - स्क्रीनिंग की आवश्यकता नहीं है',
  // },
  {
    id: 'patient_in_distress',
    title: 'Patient in Distress - Referred Directly to Doctor',
    translation: 'मरीज़ असहज स्थिति में है - सीधा डॉक्टर को भेजा गया',
  },
  {
    id: 'no_screener_available',
    title: 'No Screener Available',
    translation: 'कोई स्क्रीनर उपलब्ध नहीं है',
    hidden: true,
  },
]
