import { motion } from 'framer-motion'
import styled, { keyframes } from 'styled-components'
import Modal, { BaseModalBackground } from 'styled-react-modal'
import purpleWatercolorBackground from 'src/assets/purple_watercolor_background.png'
import { Tooltip } from 'react-bootstrap'
import Lottie from 'lottie-react'
import { Drawer } from '@mui/material'

export const FormBackground = styled.div`
  overflow-y: scroll;
`

export const FormBody = styled(motion.div)`
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  padding: ${(props) => (props?.hasContinueButton ? '75px 16px 150px' : '75px 16px 50px')};
  display: flex;
  flex-direction: column;
  background: #e5e5e5;
  // background: url(${purpleWatercolorBackground});
  background-repeat: no-repeat;
  background-size: cover;
  overflow: auto;
  overflow-x: hidden;
  scrollbar-gutter: stable;

  @media (min-width: 768px) {
    padding: ${(props) => (props?.hasContinueButton ? '75px 5% 150px' : '75px 5% 50px')};
  }
`

export const FormContentContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: #00000080 #ddd;
  /* Width of the scrollbar */
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Background color of the scrollbar thumb */
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.4);
    border-radius: 5px;
  }

  /* Background color of the scrollbar track */
  ::-webkit-scrollbar-track {
    background: #ddd;
    border: 1px solid #ccc;
  }

  /* Hide the scrollbar buttons */
  ::-webkit-scrollbar-button {
    display: none;
  }
`

export const FormContent = styled.div`
  margin: auto 0;
`

export const FormContentHeadings = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 30px;
`

export const FormContentBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const QuestionSupertext = styled.div`
  font-family: Outfit;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  color: rgb(40, 44, 47);
  text-transform: uppercase;
  margin-bottom: 8px;
  letter-spacing: 1px;
`

export const QuestionHeading = styled.div`
  font-family: Outfit;
  font-size: 24px;
  font-weight: 400;
  text-align: center;
  color: rgb(40, 44, 47);
  @media (min-width: 768px) {
    font-size: 32px;
  }
`

export const QuestionSubtext = styled.div`
  font-family: Outfit;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  color: rgb(40, 44, 47);
  margin-top: 16px;
`

//for lottie

export const CustomLottie = styled(Lottie)`
  width: 200px;
  height: 200px;
`

//for tooltip

export const CustomTooltip = styled(Tooltip)`
  .tooltip-inner {
    background-color: ${(props) =>
      props?.valid ? '#000' : '#000'}; /* Set your desired background color */
    color: ${(props) => (props?.valid ? '#fff' : '#fff')}; /* Set your desired text color */
  }
  .tooltip-arrow {
    display: none;
  }
`

//for inputbox:

export const InputBoxContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 570px;
  height: 87px;
  background-color: #fff;
  border-radius: 7px;
  border: 3px solid rgb(67, 78, 100);
  display: flex;
  align-items: center;
  padding: 24px 0 24px 32px;
  @media (min-width: 768px) {
    height: 103px;
  }
`

export const InputBoxInsideContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
`

export const InputBox = styled.input`
  color: #000;
  font-family: Outfit;
  font-size: 26px;
  width: 100%;
  outline: none;
  padding-right: 32px;
  @media (min-width: 768px) {
    height: 34px;
  }
`

export const NextIcon = styled.button`
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
  background-color: #434e64;
  border: 3px solid #434e64;
  border-radius: 50%;
  transition: all 0.3s linear;

  &:disabled {
    background-color: #7f848b;
    border: 3px solid #7f848b;
  }
`

//for single select only title(OT) , options greater than or equal to 3 (OGTTH)

export const SelectOTOGTTHContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  @media (min-width: 680px) {
    max-width: 760px;
    margin: 0 auto;
    gap: 18px;
    flex-direction: row;
    flex-wrap: wrap;
  }
`

export const SelectOptionDot = styled.div`
  background: ${(props) => (props?.selected ? '#434e64' : '#FFF')};
  height: 30px;
  width: 30px;
  flex-shrink: 0;
  border: ${(props) => (props?.selected ? '2px solid #434e64' : '2px solid rgb(245, 241, 237)')};
  border-radius: 50%;
  margin-right: 12px;
  transition: border-color 0.5s ease 0s, background 0.5s ease 0s;
`

export const SelectOptionContainerOTOGTTH = styled.button`
  display: flex;
  align-items: center;
  background: #fff;
  border: ${(props) => (props?.selected ? '2px solid #434e64' : '2px solid #FFF')};
  border-radius: 100px;
  padding: 18px;
  cursor: pointer;
  width: 100%;
  transition: border 0.5s ease 0s;

  @media (min-width: 680px) {
    width: 288px;
  }
  @media (min-width: 1024px) {
    width: 368px;
  }

  &:hover {
    border: 2px solid #434e64;
    ${SelectOptionDot} {
      border: 2px solid #434e64;
    }
  }
`

export const SelectOptionTextOTOGTTH = styled.p`
  color: rgb(40, 44, 47);
  font-family: Outfit;
  text-align: left;
  font-size: 18px;
  margin: 0;
  padding: 0;
  @media (min-width: 1024px) {
    font-size: 20px;
  }
`

//for single select only Title(OT), options equal 2 (OET)

export const SingleSelectOTOETContainer = styled.div`
  display: flex;
  justify-content: center;
`

export const SingleSelectOptionContainerOTOET = styled.button`
  background: #fff;
  border: ${(props) => (props?.selected ? '2px solid #434e64' : '2px solid rgb(245, 241, 237)')};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 130px;
  width: 130px;
  margin: 10px;
  transition: border 0.5s ease 0s;
  &:hover {
    border: 2px solid #434e64;
  }
  @media (min-width: 768px) {
    height: 160px;
    width: 160px;
  }
`

export const SingleSelectOptionTextOTOET = styled.p`
    color: rgb(40, 44, 47);
    font-size: 18px;
    font-family: Outfit;
    font-weight: 400;
    margin: 0;
    @media (min-width: 768px){
        font-size: 20px;
    }
}
`

//for select, image, title and subtext(ITS), items greater than or equal to 3 (OGTTH)

export const SelectITSContainerOGTTH = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  margin: 0 auto;
  width: 100%;
  // @media (min-width: 768px) {
  //     gap: 18px;
  //     max-width: 372px;
  // }
  // @media (min-width: 867px) {
  //     flex-direction: row;
  //     flex-wrap: wrap;
  //     max-width: 1200px;
  // }
  @media (min-width: 768px) {
    gap: 18px;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 1200px;
  }
`

export const SelectOptionContainerITSOGTTH = styled.button`
  display: flex;
  align-items: center;
  background: #fff;
  border: ${(props) => (props?.selected ? '2px solid #434e64' : '2px solid rgb(255, 255, 255)')};
  border-radius: 8px;
  padding: 16px;
  transition: border 0.5s ease 0s;
  cursor: pointer;
  width: calc(100% - 15px);
  position: relative;
  // @media (min-width: 768px) {
  //     padding: 18px;
  //     width: 270px;
  // }
  // @media (min-width: 867px) {
  //     width: 380px;
  // }
  @media (min-width: 768px) {
    padding: 18px;
    width: 270px;
  }
  &:hover {
    border: 2px solid #434e64;
  }
`

export const SelectedTick = styled.img`
  width: 25px;
  height: 25px;
  position: absolute;
  top: -10px;
  right: -10px;
`

export const SelectOptionImageITSOGTTH = styled.img`
  height: 64px;
  width: 64px;
  margin: 0 16px;
  border-radius: 4px;
  @media (min-width: 768px) {
    height: 64px;
    width: 64px;
  }
`

export const SelectOptionTextContainerOGTTH = styled.div`
  display: flex;
  text-align: left;
  flex: 1 1 0;
  flex-direction: column;
  justify-content: center;
`

export const SelectOptionTitleITSOGTTH = styled.p`
  color: rgb(40, 44, 47);
  font-family: Outfit;
  font-size: 18px;
  line-height: 1.4;
  font-weight: 400;
  margin: 0;
  @media (min-width: 768px) {
    font-size: 20px;
    margin-bottom: 4px;
  }
`

export const SelectOptionSubtextITSOGTTH = styled.p`
  color: rgb(127, 127, 127);
  font-family: Outfit;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;
  margin: 0;
`

//for select: image, title and subtext(ITS), items equal 2

export const SelectITSContainerVertDes = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  @media (min-width: 480px) {
    flex-direction: row;
    width: 100%;
  }
`

export const SelectOptionContainerVertDes = styled.button`
  background: #fff;
  border: ${(props) => (props?.selected ? '2px solid #434e64' : '2px solid #FFF')};
  border-radius: 8px;
  display: flex;
  align-items: center;
  margin: 8px 0;
  padding: 16px;
  transition: border 0.5s ease 0s;
  @media (min-width: 480px) {
    margin: 16px;
    padding: 32px;
    flex-direction: column;
    justify-content: center;
    flex-basis: 256px;
  }
  @media (min-width: 1440px) {
    padding: 72px 46px;
    flex-basis: 320px;
  }
  &:hover {
    border: 2px solid #434e64;
  }
`

export const SelectOptionImageVertDes = styled.img`
  width: 64px;
  height: 64px;
  margin-right: 16px;
  @media (min-width: 480px) {
    margin-right: 0px;
  }
  @media (min-width: 768px) {
    width: 140px;
    height: 140px;
  }
`

export const SelectOptionInternalContainerVertDes = styled.div`
  width: 100%;
  text-align: left;
  @media (min-width: 480px) {
    text-align: center;
  }
`

export const SelectOptionTitleVertDes = styled.h6`
  font-size: 18px;
  font-family: Outfit;
  color: rgb(40, 44, 47);
  margin: 0;
  font-weight: 600;
  @media (min-width: 768px) {
    font-size: 20px;
    margin: 26px 0 12px;
  }
`

export const SelectOptionSubtextVertDes = styled.p`
  font-family: Outfit;
  font-size: 13px;
  margin: 6px 0;
  line-height: 1.5;
  color: rgb(40, 44, 47);
  @media (min-width: 768px) {
    font-size: 16px;
  }
`

//for text with underline effect

export const UnderlineEffectTextContainer = styled.div`
  position: absolute;
  bottom: 10px;
  display: flex;
  justify-content: center;
  left: 0;
  right: 0;
`

const slideAnimation = keyframes`
  0% {
    transform: translateX(-100%);
    visibility: hidden;
  }
  100% {
    transform: translateX(0);
    visibility: visible;
  }
`

export const UnderlineEffectTextSpan = styled.span`
  position: relative;
  display: inline-block;
  overflow: hidden;

  &:hover::after {
    transform: translateX(0);
    animation: ${slideAnimation} 0.3s linear;
    width: 100%;
    visibility: visible;
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    height: 0.125rem;
    width: 100%;
    background-color: rgb(40, 44, 47);
    transform: translateX(0);
    visibility: visible;
  }
`

export const UnderlineEffectTextButton = styled.button`
  font-family: Outfit;
  font-size: 16px;
  line-height: 1.72;
  color: #000;
  font-weight: 400;
`

//for continue button

export const FormButtonBelowContent = styled.button`
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Outfit;
  font-size: 18px;
  line-height: 1.5;
  border-radius: 100px;
  position: absolute;
  margin: 0 auto;
  left: 0;
  right: 0;
  bottom: 75px;
  padding: 14px 34px;
  background-color: rgb(40, 44, 47);
  color: rgb(255, 255, 255);

  &:disabled {
    background-color: rgb(127, 132, 139);
    color: rgb(255, 255, 255);
    cursor: default;
  }

  &:not(:disabled):hover {
    background-color: #fff;
    color: #000;
  }
`

//for modal

export const FormInfoModal = Modal.styled`
    width: 50px;
    height: 50px;
    transition : all 0.3s ease-in-out;
    position: relative;
    display: flex;
    justify-content: center;
    background-color: rgb(255, 250, 247);
    width: 85%;
    max-width: 480px;
    height: 428px;
    border-radius: 8px;
    @media (min-width: 768px) {
        border-radius: 12px;
        height: 480px;
    }
`

export const FadingBackground = styled(BaseModalBackground)`
  opacity: ${(props) => props.opacity};
  transition: all 0.3s ease-in-out;
`

// for professional needed drawer

export const ProfessionalsDrawer = styled(Drawer)`
  display: flex;
  justify-content: center;
  // backdrop-filter: blur(1px);
  background-color: rgba(106, 109, 210, 0.2);
  .MuiPaper-root {
    height: 90vh;
    width: 98%;
    left: auto;
    right: auto;
    background: #ffffff;
    background-size: 100% 100%;
    border-radius: 25px 25px 0px 0px;
    padding: 50px 0;
    ::-webkit-scrollbar {
      display: none;
    }
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
`
