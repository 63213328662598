import React, { useState, useEffect } from 'react'
import { isTokenInvalidOrAbsent } from 'src/views/pages/login/ProtectedRoute'
import { getData } from '../services/profileService'
import { logoutHandler } from '../services/loginLogoutHandlers'
import { useNavigate } from 'react-router-dom'

export const SetupContext = React.createContext()

export const SetupContextProvider = ({ children }) => {
  const [setupData, setSetupData] = useState(null)
  const [setupLoading, setSetupLoading] = useState(false)
  const [setupError, setSetupError] = useState()
  const navigate = useNavigate()
  useEffect(() => {
    setSetupError()
    const token = localStorage.getItem('token')
    if (!isTokenInvalidOrAbsent(token)) {
      const fetchSetupData = async () => {
        try {
          setSetupLoading(true)
          const resp = await getData('/api/secure/setup')
          setSetupLoading(false)
          if (resp?.status === 200) {
            setSetupData(resp.data)
          } else if (resp?.status === 401) {
            logoutHandler()
            navigate('/')
          }
        } catch (error) {
          setSetupError(error)
          // Handle any errors from the data fetching here
        }
      }
      fetchSetupData()
    }
  }, []) // The empty dependency array ensures this effect runs only once

  useEffect(() => {
    // console.log(setupData)
  }, [setupData])

  return (
    <SetupContext.Provider value={{ setupLoading, setupData, setupError }}>
      {children}
    </SetupContext.Provider>
  )
}
