import { ButtonBase, DialogContent, Paper, styled as muiStyled } from '@mui/material'
import styled from 'styled-components'

export const LayoutContainer = styled.div`
  width: 100%;
  display: flex;
  font-family: Outfit;
`

export const GenericDisplayFlex = styled.div`
  display: flex;
  align-items: center;
  ${(props) => props?.gap && `gap: ${props?.gap}px;`};
  ${(props) => props?.sb && 'justify-content: space-between;'};
  ${(props) => props?.center && 'justify-content: center;'};
  width: ${(props) => (props?.width ? props.width : 'unset')};
`

export const GenericDisplayFlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  ${(props) => props?.aic && 'align-items: center;'};
  ${(props) => props?.jcc && 'justify-content: center;'};
`
export const KycPendingPaper = muiStyled(Paper)`
    font-family: 'Outfit';
    height: 100%;
    width: 100%;
    max-height: 500px !important;
    max-width: 530px !important;
    border-radius:15px ;
    text-align: center;
`
export const KycPendingContent = muiStyled(DialogContent)`
display: flex;
flex-direction: column;
justify-content: center;
gap: 40px;
align-items: center
`
export const KycIcon = muiStyled('img')`
    height: 100px;
    width: 100px;
`
export const KycText = muiStyled('div')`
    font-size: 20px;
    font-weight: 600;
`
export const KycSubText = muiStyled('div')`
    font-size: 16px;
`
export const KycProceedButton = muiStyled(ButtonBase)`
display: flex;
align-items: center;
justify-content: center;
gap: 10px;
background: #a864ff;
padding: 10px 58px;
color: white;
border-radius: 40px;
`
export const KycExploreAppButton = muiStyled(ButtonBase)`
padding: 10px 58px;
text-decoration: underline;
border-radius: 40px;
`
