import React from 'react'
import { Navigate } from 'react-router-dom'
import { UserAuth } from '../../../context/AuthContext'
import PropTypes from 'prop-types'
import { logoutHandler } from 'src/consumer/services/loginLogoutHandlers'

export const isTokenInvalidOrAbsent = (token) => {
  if (token === null || token === undefined) {
    return true;
  }
  return false;
}

export const isAuthoritiesInvalid = (authorities) => {
  if (authorities === null || authorities === undefined) {
    return true;
  }
  return false;
}

const ProtectedRoute = ({ children }) => {
  // const { currentUser } = UserAuth()

  const token = localStorage.getItem('token')
  const authorities = localStorage.getItem('authorities')

  if (isTokenInvalidOrAbsent(token) || isAuthoritiesInvalid(authorities)) {
    logoutHandler()
    return <Navigate to="/login" />
  }
  // if (!role_array.find((element) => element === 'ROLE_BUSINESS_CUSTOMER')) {
  //   console.log('found')
  //   return <Navigate to="/403" />
  // }
  return children
}

ProtectedRoute.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ProtectedRoute
