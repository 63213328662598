import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { SEOComponent } from 'src/consumer/helpers/utilFunctions'
import { getDataWithParams } from 'src/consumer/services/profileService'
import { getImageLink } from 'src/mentor/helpers/utilityFunctions'
import { ImgProfile } from '../medicalBoardGrid/styledComponent'
import { ProfileCardComponent, ProfileContainer } from './stylecomponent'
import { Spinner } from 'react-bootstrap'

export default function BoardMemberProfile({ id }) {
  const [boardData, setBoardData] = useState(null)
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()

  const getBoardMember = async () => {
    const res = await getDataWithParams(`/api/medical-board/details`, { id: id })
    setLoading(false)
    if (res.status !== 200) {
      navigate('/404')
    } else {
      return res
    }
  }

  useEffect(() => {
    getBoardMember().then((res) => {
      setBoardData(res.data)
    })
  }, [])

  return (
    <>
      <SEOComponent
        title={`${boardData?.name} | AUMHUM`}
        description={boardData?.description}
        image={getImageLink(boardData?.coverIcon)}
        keywordsArr={[boardData?.name, boardData?.designation, 'Medical Board']}
      />
      <ProfileContainer className="shadow p-3 rounded-lg">
        <ProfileCard
          loading={loading}
          name={boardData?.name}
          designation={boardData?.designation}
          description={boardData?.description}
          img={boardData?.coverIcon}
        />
      </ProfileContainer>
    </>
  )
}
export function ProfileCard({ name, designation, img, description, loading }) {
  return (
    <ProfileCardComponent className="rounded-lg">
      {loading ? (
        <Spinner />
      ) : (
        <>
          <ImgProfile
            style={{ maxWidth: '20vw' }}
            className="img-fluid m-4"
            src={getImageLink(img)}
          />
          <h3>{name}</h3>
          <p>{designation}</p>
          <div style={{ width: '75%', textAlign: 'justify' }}>{description}</div>
        </>
      )}
    </ProfileCardComponent>
  )
}
