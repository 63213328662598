import SendIcon from '@mui/icons-material/Send'
import moment from 'moment/moment'
import { useEffect, useState } from 'react'
import {
  ChatContainer,
  ChatExternalContainer,
  ChatMessageInput,
  ChatMessageInputContainer,
  ChatMessageInputSameSizeDiv,
} from './ChatStyles'
import {
  ChatBookingRequestMessageOtherUserWrapper,
  ChatBookingRequestMessageThisUserWrapper,
  ChatBoxDateComponent,
  ChatCustomCardMessageOtherUserWrapper,
  ChatCustomCardMessageThisUserWrapper,
  ChatHeaderComponent,
  ChatMessageOtherUserWrapper,
  ChatMessageThisUserWrapper,
} from './chatComponents'
import { prepareMessagesArray } from './helperFunctions'
import { toast } from 'react-toastify'
import { Spinner } from 'react-bootstrap'
import { sendAgoraChatMessage, sendAgoraCustomMessage } from 'src/consumer/services/featureServices'
import { isCommonResponseSuccessful } from 'src/mentor/helpers/utilityFunctions'
import { v4 as uuidv4 } from 'uuid'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import { getDataWithParams } from 'src/consumer/services/profileService'
import { isSameUserId } from '../FloatingIntercom/helperFunctions'
import { AGORA_CHAT_MESSAGE_CUSTOM_EXTS_TYPE } from 'src/consumer/constants'

export default function TwoPeopleChatUI({
  agoraChatServerConnection,
  thisUserChatId,
  thisUserChatToken,
  thisUserNickname,
  thisUserAvatarUrl,
  otherUserChatId,
  otherUserName,
  otherUserPresence,
  otherUserRelativeRole,
  otherUserAvatarUrl,
  arrayOfAllMessages,
  addLocalMessageToArray,
  showNotificationsOnIncomingMessage,
  backFunction,
  scrollTriggerBoolean,
  chatsLoading = false,
  loadOlderChatsWithUserFunc,
  replaceSpecificTempMessageWithResultMessage,
  canAttachFeatures = false,
}) {
  const [newInputMessage, setNewInputMessage] = useState('')

  const [metaCategories, setMetaCategories] = useState([])
  const [attachmentMenuState, setAttachmentMenuState] = useState(null) // other values - "selectFeature", "selectItem"

  // special event handler that shows notifications
  useEffect(() => {
    if (agoraChatServerConnection) {
      agoraChatServerConnection?.addEventHandler?.('someevent', {
        onTextMessage: function (message) {
          if (showNotificationsOnIncomingMessage) {
            toast.info(`New Message: ${message?.msg}`, {
              position: 'bottom-right',
            })
          }
        },
      })
    }
    return () => {
      if (agoraChatServerConnection) {
        agoraChatServerConnection?.removeEventHandler?.('someevent')
      }
    }
  }, [agoraChatServerConnection, showNotificationsOnIncomingMessage])

  // send text message function
  const handleSendPeerMessage = async () => {
    if (newInputMessage) {
      const peerId = otherUserChatId
      const tempMsg = {
        chatType: 'singleChat',
        from: thisUserChatId,
        id: uuidv4(),
        msg: newInputMessage,
        time: moment().toDate().getTime(),
        to: peerId,
        type: 'txt',
        localSendState: 'waiting',
      }
      addLocalMessageToArray(tempMsg)
      setNewInputMessage('')
      const response = await sendAgoraChatMessage({ sendTo: peerId, message: newInputMessage })
      if (
        response?.status === 200 &&
        isCommonResponseSuccessful(response?.data?.code) &&
        response?.data?.data?.sentMessageData
      ) {
        const sentMsg = response?.data?.data?.sentMessageData
        playChatMessageSound()
        console.log(sentMsg)
        // find the message in array that has temp uuid in id -> replace it with sentMsg -> put a localSendState also as success or failure
        replaceSpecificTempMessageWithResultMessage(otherUserChatId, tempMsg?.id, {
          ...sentMsg,
          localSendState: 'success',
        })
      } else {
        // find the message in array that has temp uuid in id -> replace it with sentMsg -> put a localSendState also as success or failure
        replaceSpecificTempMessageWithResultMessage(otherUserChatId, tempMsg?.id, {
          ...tempMsg,
          localSendState: 'failure',
        })
        console.log('chat message could not be sent')
      }
    }
  }

  // send custom message function
  const handleSendCustomMessage = async () => {
    const peerId = otherUserChatId
    const tempMsg = {
      chatType: 'singleChat',
      from: thisUserChatId,
      customExts: {
        description:
          "Unveil the hidden forces driving smoking addiction and learn why it's so hard to quit despite knowing the risks. Explore the psychological and physical dependencies that keep millions hooked, and discover innovative strategies that can finally help you break free.",
        image: 'https://media.aumhum.xyz/images/prod/MEDIA-ff5a6e93c98c45b8b75b83cd5f381063',
        msg: 'Explore this',
        title: 'Understanding and Overcoming Smoking Addiction',
        uri: 'https://aumhum.com/article/mind-over-smoke-understanding-and-overcoming-smoking-addiction',
        type: 'feature',
      },
      id: uuidv4(),
      time: moment().toDate().getTime(),
      to: peerId,
      type: 'custom',
      localSendState: 'waiting',
    }
    addLocalMessageToArray(tempMsg)
    const response = await sendAgoraCustomMessage({
      sendTo: peerId,
      metaCategory: 'somemetacategory',
      itemId: 'someitemId',
    })
    if (
      response?.status === 200 &&
      isCommonResponseSuccessful(response?.data?.code) &&
      response?.data?.data?.sentMessageData
    ) {
      const sentMsg = response?.data?.data?.sentMessageData
      playChatMessageSound()
      console.log(sentMsg)
      // find the message in array that has temp uuid in id -> replace it with sentMsg -> put a localSendState also as success or failure
      replaceSpecificTempMessageWithResultMessage(otherUserChatId, tempMsg?.id, {
        ...sentMsg,
        localSendState: 'success',
      })
    } else {
      // find the message in array that has temp uuid in id -> replace it with sentMsg -> put a localSendState also as success or failure
      replaceSpecificTempMessageWithResultMessage(otherUserChatId, tempMsg?.id, {
        ...tempMsg,
        localSendState: 'failure',
      })
      console.log('chat message could not be sent')
    }
  }

  // auto scroll to bottom on new message (cue from scrollTrigger)
  useEffect(() => {
    const divToScroll = document.getElementById('chatbox')
    if (divToScroll) {
      divToScroll.scrollBy({
        top: divToScroll.scrollHeight,
        behavior: 'smooth',
      })
    }
  }, [scrollTriggerBoolean])

  // initial auto scroll to bottom on mount
  useEffect(() => {
    const divToScroll = document.getElementById('chatbox')
    if (divToScroll) {
      divToScroll.scrollBy({
        top: divToScroll.scrollHeight,
      })
    }
  }, [])

  // call function loadOlderChatsWithUserFunc when scrolled to top
  useEffect(() => {
    if (loadOlderChatsWithUserFunc) {
      const divToScroll = document.getElementById('chatbox')
      const handleScroll = () => {
        if (divToScroll && divToScroll.scrollTop === 0) {
          if (!chatsLoading) {
            loadOlderChatsWithUserFunc(otherUserChatId)
          }
        }
      }

      if (divToScroll) {
        divToScroll.addEventListener('scroll', handleScroll)
        return () => {
          divToScroll.removeEventListener('scroll', handleScroll)
        }
      }
    }
  }, [loadOlderChatsWithUserFunc])

  // updating read message
  useEffect(() => {
    const otherUserMsgsArray = arrayOfAllMessages?.filter(
      (message) =>
        isSameUserId(message.from, otherUserChatId) && isSameUserId(message.to, thisUserChatId),
    )
    if (otherUserMsgsArray.length > 0) {
      // Get the last message ID of the conversation from localStorage
      const lastMessageSeenOfOtherUsers =
        JSON.parse(localStorage.getItem('lastMessageSeenOfOtherUsers')) || {}

      // Update the last message ID for this conversation
      lastMessageSeenOfOtherUsers[otherUserChatId] =
        otherUserMsgsArray[otherUserMsgsArray.length - 1].id

      // Store the updated data back to localStorage
      localStorage.setItem(
        'lastMessageSeenOfOtherUsers',
        JSON.stringify(lastMessageSeenOfOtherUsers),
      )
    }
  }, [arrayOfAllMessages])

  // getting meta categories for sending
  async function getAndSetMetaCategories() {
    if (canAttachFeatures) {
      const resp = await getDataWithParams('/api/secure/teacher/meta-categories', {
        enabledForDailyPlay: true,
      })
      if (resp?.status === 200 && resp?.data) {
        setMetaCategories(resp?.data)
      }
    }
  }
  useEffect(() => {
    getAndSetMetaCategories()
  }, [])

  // chat sound function
  const playChatMessageSound = async () => {
    const soundModule = await import('src/assets/audio/chat_tick.mp3');
    const sound = new Audio(soundModule.default);
    sound.play();
  };

  return (
    <>
      {thisUserChatId && thisUserChatToken && agoraChatServerConnection && (
        <ChatExternalContainer>
          <ChatHeaderComponent
            backFunction={backFunction}
            nameInHeader={otherUserName || 'User'}
            imageInHeader={otherUserAvatarUrl}
            presence={otherUserPresence}
            showPresence={true}
            relativeRole={otherUserRelativeRole}
          />

          <ChatContainer id="chatbox">
            <div style={{ margin: 'auto', width: '100%', textAlign: 'center' }}>
              {chatsLoading && <Spinner animation="border" variant="dark" size="sm" />}
            </div>
            {arrayOfAllMessages?.length > 0 ? (
              prepareMessagesArray(arrayOfAllMessages)?.map((item, index) => {
                if (item?.renderType === 'date') {
                  return <ChatBoxDateComponent key={index} time={item?.time} />
                } else {
                  if (item?.type === 'txt') {
                    if (
                      isSameUserId(item?.from, otherUserChatId) &&
                      isSameUserId(item?.to, thisUserChatId)
                    ) {
                      return (
                        <ChatMessageOtherUserWrapper
                          key={index}
                          showTime={true}
                          image={otherUserAvatarUrl}
                          name={otherUserName || 'User'}
                          chatMessageItem={item}
                        />
                      )
                    } else if (
                      isSameUserId(item?.from, thisUserChatId) &&
                      isSameUserId(item?.to, otherUserChatId)
                    ) {
                      return (
                        <ChatMessageThisUserWrapper
                          key={index}
                          showTime={true}
                          image={thisUserAvatarUrl}
                          name={thisUserNickname || 'User'}
                          chatMessageItem={item}
                        />
                      )
                    }
                    return <></>
                  } else if (
                    item?.type === 'custom' &&
                    item?.customExts?.type === AGORA_CHAT_MESSAGE_CUSTOM_EXTS_TYPE.FEATURE
                  ) {
                    if (
                      isSameUserId(item?.from, otherUserChatId) &&
                      isSameUserId(item?.to, thisUserChatId)
                    ) {
                      return (
                        <ChatCustomCardMessageOtherUserWrapper
                          key={index}
                          showTime={true}
                          image={otherUserAvatarUrl}
                          name={otherUserName || 'User'}
                          chatMessageItem={item}
                        />
                      )
                    } else if (
                      isSameUserId(item?.from, thisUserChatId) &&
                      isSameUserId(item?.to, otherUserChatId)
                    ) {
                      return (
                        <ChatCustomCardMessageThisUserWrapper
                          key={index}
                          showTime={true}
                          image={thisUserAvatarUrl}
                          name={thisUserNickname || 'User'}
                          chatMessageItem={item}
                        />
                      )
                    }
                    return <></>
                  } else if (
                    item?.type === 'custom' &&
                    item?.customExts?.type !== AGORA_CHAT_MESSAGE_CUSTOM_EXTS_TYPE.FEATURE
                  ) {
                    if (
                      isSameUserId(item?.from, otherUserChatId) &&
                      isSameUserId(item?.to, thisUserChatId)
                    ) {
                      return (
                        <ChatBookingRequestMessageOtherUserWrapper
                          key={index}
                          showTime={true}
                          image={otherUserAvatarUrl}
                          name={otherUserName || 'User'}
                          chatMessageItem={item}
                        />
                      )
                    } else if (
                      isSameUserId(item?.from, thisUserChatId) &&
                      isSameUserId(item?.to, otherUserChatId)
                    ) {
                      return (
                        <ChatBookingRequestMessageThisUserWrapper
                          key={index}
                          showTime={true}
                          image={thisUserAvatarUrl}
                          name={thisUserNickname || 'User'}
                          chatMessageItem={item}
                        />
                      )
                    }
                    return <></>
                  }
                }
              })
            ) : (
              <div style={{ margin: 'auto', width: '100%', textAlign: 'center' }}>
                {!chatsLoading && 'No messages here'}
              </div>
            )}
            {/* {attachmentMenuState && <div style={{position: 'absolute', bottom: '8px', width: '90%', margin: 'auto' }}>HI</div>} */}
          </ChatContainer>

          <ChatMessageInputContainer>
            <ChatMessageInputSameSizeDiv>
              <ChatMessageInput
                value={newInputMessage}
                onChange={(e) => setNewInputMessage(e.target.value)}
                placeholder="Message..."
                maxRows={7}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleSendPeerMessage()
                    e.preventDefault()
                  }
                }}
              />
              <div
                style={{
                  position: 'absolute',
                  right: '8px',
                  bottom: '6px',
                  margin: 'auto',
                  display: 'flex',
                  gap: '8px',
                  justifyContent: 'right',
                }}
              >
                {Array.isArray(metaCategories) && metaCategories?.length > 0 && (
                  <AttachFileIcon
                    onClick={() => {
                      // if (attachmentMenuState) {
                      //   setAttachmentMenuState(null)
                      // } else {
                      //   setAttachmentMenuState("selectFeature")
                      // }
                      handleSendCustomMessage()
                    }}
                    style={{ cursor: 'pointer' }}
                  />
                )}
                <SendIcon
                  style={{
                    cursor: newInputMessage ? 'pointer' : 'default',
                    color: newInputMessage ? 'black' : '#d3d3d3',
                    transition: 'all 0.6s ease-in-out',
                  }}
                  onClick={handleSendPeerMessage}
                />
              </div>
            </ChatMessageInputSameSizeDiv>
          </ChatMessageInputContainer>
        </ChatExternalContainer>
      )}
    </>
  )
}
