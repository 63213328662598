import axios from 'axios'
import { commonHeadersWeb, getApiServerUrlObject } from 'src/consumer/services'

export const businessApiURL = getApiServerUrlObject().url

export function getLanguageCode() {
  return sessionStorage.getItem('languageCode')
}

export function getCountryCode() {
  return localStorage.getItem('countryCode')
}

export function getToken() {
  return localStorage.getItem('token')
}

export const getBPDataWithoutParams = async (url) => {
  const token = localStorage.getItem('token')
  try {
    const res = await axios.get(`${businessApiURL}${url}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        ...commonHeadersWeb,
        'ef-user-country-code': localStorage.getItem('countryCode'),
        'ef-app-language': sessionStorage.getItem('languageCode'),
      },
    })
    return res
  } catch (err) {
    console.log(err)
    return err
  }
}

export async function postBPDataWithoutParams(url, data) {
  const token = localStorage.getItem('token')
  try {
    const res = await axios.post(`${businessApiURL}${url}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        ...commonHeadersWeb,
        'ef-user-country-code': localStorage.getItem('countryCode'),
        'ef-app-language': sessionStorage.getItem('languageCode'),
      },
    })
    return res
  } catch (err) {
    console.log(err)
    return err
  }
}
export async function postBPDataWithParams(url, data, params) {
  const token = localStorage.getItem('token')
  try {
    const res = await axios.post(`${businessApiURL}${url}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        ...commonHeadersWeb,
        'ef-user-country-code': localStorage.getItem('countryCode'),
        'ef-app-language': sessionStorage.getItem('languageCode'),
      },
      params: params,
    })
    return res
  } catch (err) {
    console.log(err)
    return err
  }
}

export async function putBPDataWithoutParams(url, data) {
  const token = localStorage.getItem('token')
  try {
    const res = await axios.put(`${businessApiURL}${url}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        ...commonHeadersWeb,
        'ef-user-country-code': localStorage.getItem('countryCode'),
        'ef-app-language': sessionStorage.getItem('languageCode'),
      },
    })
    return res
  } catch (err) {
    console.log(err)
    return err
  }
}
