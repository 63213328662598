import { ButtonBase, styled as muiStyled } from '@mui/material'
import Lottie from 'lottie-react'
import styled, { keyframes } from 'styled-components'

export const EventBookingPageBackground = styled.div`
  width: 100%;
  min-height: calc(100vh - 64px);
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Outfit;
  display: flex;
  flex-direction: column;
`

export const EventBookingInteractionPage = styled.div`
  width: 100%;
  max-width: ${({ dontMakeItARow }) => (dontMakeItARow ? '600px' : '1100px')};
  min-height: 500px;
  background: #ffffff;
  border-radius: 6px;
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.02);
  display: flex;
  flex-direction: column;
  padding: 20px;
  @media (min-width: 980px) {
    ${({ dontMakeItARow }) => !dontMakeItARow && `flex-direction: row;`}
  }
  @media (min-height: 800px) {
    min-height: 600px;
  }
`
// styles for details part

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 660px) {
    flex-direction: row;
  }
  @media (min-width: 980px) {
    flex-direction: column;
    ${({ isNotInARow }) => !isNotInARow && 'border-right: 1px solid #e3e3e3; max-width: 380px;'}
  }
`

export const ProfessionalPicture = styled.img`
  width: 64px;
  height: 64px;
  border-radius: 50%;
  object-fit: cover;
  @media (min-width: 660px) {
    width: 80px;
    height: 80px;
    margin: 0 0 15px 0;
  }
`

export const ProfessionalHeading = styled.div`
  font-size: ${(props) => (props?.main ? '18px' : '14px')};
  font-weight: ${(props) => (props?.main ? '600' : '500')};
  color: #4a4a4a;
  @media (min-width: 660px) {
    font-size: ${(props) => (props?.main ? '21px' : '16px')};
  }
  @media (min-width: 980px) {
    font-size: ${(props) => (props?.main ? '24px' : '18px')};
  }
`

export const ProfessionalText = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: ${(props) => (props?.main ? '#515151' : '#7e7e7e')};
  @media (min-width: 660px) {
    font-size: 14px;
    margin-top: 10px;
  }
  @media (min-width: 980px) {
    font-size: 14px;
  }
`

export const ContainerOne = styled.div`
  ${({ noBorder }) => (noBorder ? '' : 'border-bottom: 1px solid #e3e3e3;')}
  padding: 0 0 10px 0;
  @media (min-width: 660px) {
    ${({ noBorder }) => (noBorder ? '' : 'border-left: 1px solid #e3e3e3;')}
    width: ${({ fullWidth }) => (fullWidth ? '100%' : '50%')};
    padding: 0 0 0 20px;
  }
  @media (min-width: 980px) {
    width: auto;
    border-bottom: none;
    border-left: none;
    padding: 0;
  }
`

export const ContainerTwo = muiStyled('div')(
  ({ theme, onWebView }) => `
border-bottom: 1px solid #e3e3e3;
padding: ${onWebView ? '46px' : '64px'} 0 16px 0;
display: flex;
flex-direction: row;
gap: 15px;
@media (min-width: 660px) {
  width: 50%;
  padding: 16px 20px 16px 0;
  flex-direction: column;
  gap: 0;
}
@media (min-width: 980px) {
  width: auto;
  padding: 16px 20px 16px 0;
}
`,
)

//styles for calendar part

export const CalendarPartContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${({ noMargin }) => (noMargin ? '' : 'margin: 0 auto;')}
  @media (min-width: 660px) {
    flex-direction: row;
    padding: ${(props) => (!!props?.noPadding ? '0px' : '0 60px')};
    gap: 20px;
  }
  @media (min-width: 980px) and (max-width: 1050px) {
    padding: ${(props) => (!!props?.noPadding ? '0px' : '0 10px')};
  }
`

export const CalendarContainer = styled.div`
  width: 100%;
  max-width: 350px;
  flex-shrink: 0;
  z-index: 1;
`

export const SelectDateHeading = styled(ProfessionalHeading)`
  margin: 25px 0 0 15px;
  text-align: left;
  padding: 0;
  margin: 20px 0;
  @media (min-width: 980px) {
    ${({ isNotInARow }) => !isNotInARow && `padding: 0 60px;`}
  }
  @media (min-width: 980px) and (max-width: 1050px) {
    ${({ isNotInARow }) => !isNotInARow && `padding: 0 10px;`}
  }
`

export const SignedInUserName = styled.span`
  font-size: 14px;
  font-weight: ${(props) => (props?.main ? '600' : '400')};
  color: ${(props) => (props?.main ? '#000000' : '#4a4a4a')};
  margin-bottom: 10px;
  cursor: default;
  @media (min-width: 660px) {
    font-size: 16px;
  }
  @media (min-width: 980px) {
    font-size: 18px;
    ${({ noPadding = false, main }) => (noPadding ? '' : `padding-left: ${!main && '60px'};`)}
  }
  @media (min-width: 980px) and (max-width: 1050px) {
    ${({ noPadding = false }) => (noPadding ? '' : 'padding: 0 10px;')}
  }
`

export const StyledSelectTimeZone = styled.select`
  border: 1px solid #e3e3e3;
  border-radius: 10px;
  font-weight: 500;
  height: 30px;
  width: 100%;
  margin: 20px 0;
  padding-left: 5px;
`

export const TimeChoosingContainer = styled.div`
  width: 100%;
  flex-shrink: 0;
   {
    ${(props) => !props?.selectedDateValue && 'display: none;'}
  }
  z-index: 0;
  @media (min-width: 660px) {
    width: 200px;
  }
`

// Keyframes animation definition
const slideInFromLeft = keyframes`
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
`

export const AnimatedContainerTimeSlots = styled.div`
  animation: ${slideInFromLeft} 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  height: 235px;
  overflow: auto;
  /* For WebKit-based browsers (Chrome, Safari) */
  ::-webkit-scrollbar {
    width: 3px; /* Set the width of the scrollbar */
  }
  ::-webkit-scrollbar-thumb {
    background-color: #88888866; /* Set the color of the scrollbar thumb */
  }
  scrollbar-width: thin;
  scrollbar-color: #888 #fff;
`

export const TimeChoosingContainerHeading = styled.div`
  width: 100%;
  padding: 10px 0 16px;
  border-bottom: 1px solid #e3e3e3;
  font-family: Outfit;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 16px;
`

export const TimeButton = styled.div`
  width: 100%;
  border: 1px solid #b7d7f9;
  background: ${(props) => (props?.confirm ? '#0967d2' : '#f0f7ff')};
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: ${(props) => (props?.confirm ? '#FFFFFF' : '#0967d2')};
  font-weight: 500;
  font-size: 16px;
  height: 32px;
  margin-bottom: 8px;
  &:hover {
    background: ${(props) => (props?.confirm ? '#064d9f' : '#b7d7f9')};
  }
`

// for payment flow:

export const PaymentsContainer = styled.div`
  padding: 40px 0;
  @media (min-width: 768px) {
    padding: 20px 30px;
  }
`

export const HeadingPaymentsPage = styled.div`
  color: #2e2d2d;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.2px;
  cursor: default;
  @media (min-width: 768px) {
    color: #2e2d2d;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.32px;
  }
`

export const SubheadingPaymentsPage = styled.div`
  color: #676262;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  align-items: center;
  letter-spacing: 0.14px;
  cursor: default;
  @media (min-width: 768px) {
    color: #636363;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 0.17px;
  }
`

export const PayLineSpan = styled.span`
  color: ${(props) => (props?.amount ? '#00A424' : '#2E2D2D')};
  font-size: 14px;
  font-style: normal;
  font-weight: ${(props) => (props?.amount ? '700' : '400')};
  line-height: normal;
  letter-spacing: 0.14px;
  cursor: default;
  @media (min-width: 768px) {
    font-size: 17px;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.17px;
  }
`

export const PaymentMethodBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
  border-radius: 14px;
  border: 1.5px solid #d9d9d9;
  background: #fff;
  cursor: pointer;
  gap: 10px;
  margin: 6px 0;
  @media (min-width: 768px) {
    padding: 16px 24px;
    margin: 11px 0;
    gap: 20px;
  }
`

export const PaymentOptionImage = styled.img`
  width: 55px;
  margin-right: 20px;
  @media (min-width: 768px) {
    width: 50px;
    height: 50px;
  }
  @media (max-width: 450px) {
    ${(props) => props?.hideOnSmall && 'display: none;'};
  }
`

export const PaymentOptionName = styled.div`
  color: #535353;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.15px;
  width: 180px;
  @media (min-width: 768px) {
    font-size: 18px;
    width: 260px;
  }
`

export const GiftCodeInputContainer = styled.div`
  width: 322.923px;
  height: 34px;
  flex-shrink: 0;
  border-radius: 9px;
  border: 1.5px solid #d9d9d9;
  margin-top: 53px;
  position: relative;
  overflow: hidden;
  @media (min-width: 768px) {
    width: 522.923px;
    height: 54px;
    border-radius: 14px;
    margin-top: 73px;
  }
`

export const GiftCodeSubmitButton = styled.img`
  height: 34px;
  width: 34px;
  border-radius: 9px;
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  @media (min-width: 768px) {
    height: 54px;
    width: 54px;
    border-radius: 14px;
  }
`

export const GiftCardInput = styled.input`
  padding: 0 70px 0 30px;
  height: 100%;
  width: 100%;
  color: #515151;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.22px;
  @media (min-width: 768px) {
    font-size: 22px;
  }
  &:focus {
    /* Set outline to none to hide the default outline */
    outline: none;

    /* Add any other styles you want to apply when the input is focused */
  }
`

export const ErrorMessage = styled.div`
  margin-top: 5px;
  font-weight: 500;
  color: #e50000;
  font-size: 14px;
`

// booking confirmation page

export const ConfirmationContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 25px;
  @media (min-width: 768px) {
    padding: 40px 35px;
  }
`

export const HeadingConfirmationPage = styled(HeadingPaymentsPage)`
  color: #008b2f;
  text-align: center;
`

export const SubheadingConfirmationPage = styled(SubheadingPaymentsPage)`
  text-align: center;
`

export const TickLottie = styled(Lottie)`
  width: 100px;
  height: 100px;
  @media (min-width: 768px) {
    width: 75px;
    height: 75px;
  }
`

export const SessionDetailsContainer = styled.div``

export const ActionButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 80px;
  height: 43px;
  @media (min-width: 568px) {
    flex-direction: row;
    margin-top: 50px;
    gap: 20px;
  }
`

export const ActionButton = styled.button`
    border-radius: 47px;
    background: ${(props) => (props?.suggested ? '#4D00A0' : '#FFFFFF')};
    border: 1px solid #4D00A0;
    color: ${(props) => (props?.suggested ? '#FFFFFF' : '#4D00A0')};
    font-weight: ${(props) => (props?.suggested ? '600' : '300')};
    width: 100%;
    padding: 5px 0;
    @media (min-width: 568px){
        width: 200px;
    )
`

export const EBBundleItemsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: center;
    align-items: unset;
    flex-wrap: wrap;
  }
`

export const EBBundleItemParent = styled.div`
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  position: relative;
  cursor: default;
  box-shadow: rgba(0, 0, 0, 0.12) 1px 1px 2px;
  width: 100%;
  max-width: 300px;
  min-height: 200px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (min-width: 768px) {
    max-width: 195px;
  }
`

export const EBBundleItemTitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  font-weight: 500;
  background: ${(props) => props?.themeColor || '#c6c6c6'};
  @media (min-width: 768px) {
    min-height: 80px;
    border-radius: 0% 0% 50% 50% / 0% 0% 20% 20%;
  }
`

export const EBBundleItemConfirmButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  background: ${(props) => props?.themeColor || '#c6c6c6'};
  cursor: pointer;
`

export const NextFewSlotsContainer = muiStyled('div')(
  ({ theme }) => `
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  width:100%;
  gap: 12px;
  
`,
)

export const NextFewSlotsItem = muiStyled(ButtonBase)(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 0px;
  background: #adadff94;
  border-radius: 7px;
  .main-heading {
    font-size: 18px;
    font-weight: 600;
  }
  .secondary-heading {
    font-size: 16px;
  }
`,
)
