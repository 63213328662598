import { useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { getFaq } from 'src/consumer/services/getFaq'
import { isCommonResponseSuccessful } from 'src/mentor/helpers/utilityFunctions'
import {
  FAQCategoryAndItemsContainer,
  FAQCategoryTitle,
  FAQCategoryTitleForFaqComponent,
  FaqContainer,
  FaqHeading,
  FaqSectionContainer,
} from '../../../PrewallAndPaywallStyles'
import { formFAQDataWithRespectiveFAQCategories } from '../../../faq_utils'
import FAQItem from '../../common/FAQItem'

export default function DefaultFaqLayout({
  faqCategories,
  loadingCategories,
  renderSpinner,
  keepAllClosedOnRender,
}) {
  const [faqData, setFaqData] = useState({})
  const [loading, setLoading] = useState(true)
  const [openFAQData, setOpenFAQData] = useState({ index: 0, categoryId: null })

  function handleOpenFAQItem(idx, categoryId) {
    setOpenFAQData({ categoryId, index: idx })
  }

  async function getAndSetFaqData() {
    setLoading(true)
    let searchString = ''
    if (faqCategories?.length > 0) {
      searchString = faqCategories?.map((cat) => cat?.id).join(',')
    }
    if (searchString) {
      let response = await getFaq(searchString, { withCategoryDetails: false, size: 999 })
      if (response?.status === 200 && isCommonResponseSuccessful(response.data.code)) {
        const faqData = response?.data?.data?.faqPage?.content
        const faqCategoriesData = faqCategories
        const formedFaqData = formFAQDataWithRespectiveFAQCategories(faqData, faqCategoriesData)
        setFaqData(formedFaqData)
        if (!keepAllClosedOnRender) {
          setOpenFAQData({
            index: 0,
            categoryId: formedFaqData?.find((faqData) => !!faqData?.faqs)?.id,
          })
        }
      } else {
        console.log('Error getting FaqData')
      }
    }
    setLoading(false)
  }

  useEffect(() => {
    getAndSetFaqData()
  }, [faqCategories])

  return (
    <>
      {faqData?.length > 0 && (
        <FaqContainer>
          <FaqHeading>FAQs</FaqHeading>
          <div style={{ width: '100%', paddingBottom: '30px' }}>
            {faqData?.map((faqCategory) => {
              return (
                <div key={faqCategory?.id}>
                  {faqCategory?.faqs?.length > 0 && (
                    <FaqSectionContainer>
                      <FAQCategoryAndItemsContainer>
                        <FAQCategoryTitleForFaqComponent>
                          {faqCategory?.title}
                        </FAQCategoryTitleForFaqComponent>
                        {faqCategory?.faqs?.map((itemCollection, itemCollectionIndex) => {
                          return (
                            <>
                              <FAQItem
                                isExpanded={
                                  itemCollectionIndex === openFAQData?.index &&
                                  openFAQData?.categoryId === faqCategory?.id
                                }
                                item={itemCollection}
                                itemIndex={itemCollectionIndex}
                                handleOpenFAQ={(idx) => handleOpenFAQItem(idx, faqCategory?.id)}
                              />
                            </>
                          )
                        })}
                      </FAQCategoryAndItemsContainer>
                    </FaqSectionContainer>
                  )}
                </div>
              )
            })}
          </div>
        </FaqContainer>
      )}
      {(loading || loadingCategories) && renderSpinner && (
        <FaqContainer
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '0p 0px 20px 0px',
          }}
        >
          <Spinner animation="border" variant="dark" size="md" />
        </FaqContainer>
      )}
    </>
  )
}

// function FAQItem({ isExpanded, handleOpenFAQ, item, itemIndex }) {
//   const [expand, setExpand] = useState(false)

//   function handleExpand() {
//     if (isExpanded) {
//       setExpand((prev) => !prev)
//     }
//     handleOpenFAQ(itemIndex)
//   }

//   useEffect(() => {
//     setExpand(isExpanded)
//   }, [isExpanded])

//   return (
//     <FAQAccordion disableGutters fullWidth expanded={expand} onChange={handleExpand}>
//       <FAQAccordionSummary expandIcon={<ExpandMore />}>{item?.question}</FAQAccordionSummary>
//       <AccordionDetails>{parse(item?.answer)}</AccordionDetails>
//     </FAQAccordion>
//   )
// }
