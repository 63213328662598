import React, { useState, useEffect } from 'react'
import {
  PAHHeightCompensation,
  PAHContainer,
  PAHLogo,
  PAHItemsContainerRight,
  PAHActionButton,
  PAHSecondaryActionButton,
  PAHOverlayMenu,
  PAHLinkInHeader,
  PAHTextInHeader,
  PAHOverlayMenuContainer,
  PAHMegaDropdownMenu,
  PAHCaretDownDefault,
  PAHFinalParent,
  PAHMegaDropdownContainer,
  PAHMegaDropdownText,
  PAHMegaDropdownLink,
  PAHLOGOLink,
} from './PublicAppHeaderStyles'
import { isTokenInvalidOrAbsent } from 'src/views/pages/login/ProtectedRoute'
import { Link, useNavigate } from 'react-router-dom'
import { logoutHandler } from 'src/consumer/services/loginLogoutHandlers'
import { useDocumentSize, useScreenSize } from 'src/customHooks/customHooks'
import { AnimatedHamburgerIcon } from '../helperComponents'
import { DisplayFlexColumn } from '../DailyPlay/DailyPlayStyles'
import {
  PAFALink,
  PAFClickableText,
  PAFHeading,
  PAFSectionTwoItemsContainer,
} from '../PublicAppFooter/PublicAppFooterStyles'
import { APP_CONSTANTS, APP_HEADER_DATA } from 'src/consumer/constants'
import { GenericHeaderBackButton } from '../ActivityPlansPage/MyPlanComponents'

export default function PublicAppHeader({
  config,
  loginFunction,
  registerFunction,
  backButtonFunction,
}) {
  const [headerConfig, setHeaderConfig] = useState({
    showLinks: config?.showLinks || false,
    showLogo: config?.showLogo || true,
    showBackButton: config?.showBackButton || false,
    showLoginButton: false,
    showGetStartedButton: false,
    showLogoutButton: false,
  })

  const navigate = useNavigate()
  const { width } = useScreenSize()
  const { documentHeight } = useDocumentSize()

  const [fullHeightMenuOpen, setFullHeightMenuOpen] = useState(false)
  const [megaDropdownOpen, setMegaDropdownOpen] = useState(false)

  async function setup() {
    const token = localStorage.getItem('token')
    if (isTokenInvalidOrAbsent(token)) {
      setHeaderConfig((prev) => {
        return {
          ...prev,
          showLoginButton: true,
          showGetStartedButton: true,
          showLogoutButton: false,
        }
      })
    } else {
      setHeaderConfig((prev) => {
        return {
          ...prev,
          showLinks: false,
          showLoginButton: false,
          showGetStartedButton: false,
          showLogoutButton: true,
        }
      })
    }
  }

  useEffect(() => {
    setup()
  }, [])

  useEffect(() => {
    if (width > 991) {
      setFullHeightMenuOpen(false)
    }
  }, [width])

  return (
    <>
      <PAHFinalParent>
        <PAHContainer>
          <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
            {headerConfig?.showBackButton && (
              <GenericHeaderBackButton
                clickfunction={backButtonFunction}
                styles={{ cursor: 'pointer' }}
              />
            )}
            {headerConfig?.showLogo && (
              <PAHLOGOLink to="/">
                <PAHLogo src={APP_CONSTANTS.APP_LOGO_LINK} alt='aumhumLogo' />
              </PAHLOGOLink>
            )}
          </div>
          <PAHItemsContainerRight>
            {headerConfig?.showLinks && (
              <>
                <DisplayFlexColumn
                  style={{ justifyContent: 'center', height: '100%' }}
                  onMouseEnter={() => {
                    setMegaDropdownOpen(true)
                  }}
                  onMouseLeave={() => setMegaDropdownOpen(false)}
                  onClick={() => {
                    setMegaDropdownOpen(true)
                  }}
                >
                  <PAHTextInHeader hideOnSmall>
                    For Individuals
                    <PAHCaretDownDefault isDown={!megaDropdownOpen} />
                  </PAHTextInHeader>
                </DisplayFlexColumn>
                {/* <PAHLinkInHeader hideOnSmall to={'/mentors/join'}>
                  For Therapists
                </PAHLinkInHeader> */}
                <PAHLinkInHeader hideOnSmall to={'/aumhum-for-workplace'}>
                  For Companies
                </PAHLinkInHeader>
              </>
            )}

            {/* Login button */}
            {headerConfig?.showLoginButton &&
              (loginFunction ? (
                <PAHTextInHeader hideOnSmall onClick={loginFunction}>
                  Log in
                </PAHTextInHeader>
              ) : (
                <PAHTextInHeader hideOnSmall as={Link} to="/login">
                  Log in
                </PAHTextInHeader>
              ))}

            {/* Logout Button */}
            {headerConfig?.showLogoutButton && (
              <PAHTextInHeader
                onClick={() => {
                  logoutHandler()
                  window.location.reload()
                }}
              >
                Logout
              </PAHTextInHeader>
            )}

            {/* Get Started / Register Button */}
            {headerConfig?.showGetStartedButton &&
              (registerFunction ? (
                <PAHActionButton onClick={registerFunction}>Get Started</PAHActionButton>
              ) : (
                <PAHActionButton as={Link} to="/onboarding">
                  Get Started
                </PAHActionButton>
              ))}

            {/* menu for smaller devices */}
            {width < 991 && (
              <AnimatedHamburgerIcon
                openNow={fullHeightMenuOpen}
                clickFunction={() => setFullHeightMenuOpen((prev) => !prev)}
              />
            )}
          </PAHItemsContainerRight>
        </PAHContainer>
        {/* menu UI for smaller devices */}
        <PAHOverlayMenuContainer
          fullHeightValue={documentHeight}
          fullHeightMenuOpen={fullHeightMenuOpen}
        >
          <PAHOverlayMenu>
            <div style={{ marginBottom: '50px' }}>
              <PAFHeading>{APP_HEADER_DATA.sectionOne.heading}</PAFHeading>
              <PAFSectionTwoItemsContainer>
                {APP_HEADER_DATA.sectionOne.links.map((item, index) => {
                  if (item.type === 'relative') {
                    return (
                      <PAFClickableText key={index} to={item.link}>
                        {item.name}
                      </PAFClickableText>
                    )
                  } else {
                    return (
                      <PAFALink key={index} href={item.link}>
                        {item.name}
                      </PAFALink>
                    )
                  }
                })}
              </PAFSectionTwoItemsContainer>
            </div>
            <div style={{ marginBottom: '50px' }}>
              <PAFHeading>{APP_HEADER_DATA.sectionTwo.heading}</PAFHeading>
              <PAFSectionTwoItemsContainer>
                {APP_HEADER_DATA.sectionTwo.links.map((item, index) => {
                  if (item.type === 'relative') {
                    return (
                      <PAFClickableText key={index} to={item.link}>
                        {item.name}
                      </PAFClickableText>
                    )
                  } else {
                    return (
                      <PAFALink key={index} href={item.link}>
                        {item.name}
                      </PAFALink>
                    )
                  }
                })}
              </PAFSectionTwoItemsContainer>
            </div>
            <div style={{ marginBottom: '50px' }}>
              <PAFHeading>{APP_HEADER_DATA.sectionThree.heading}</PAFHeading>
              <PAFSectionTwoItemsContainer>
                {APP_HEADER_DATA.sectionThree.links.map((item, index) => {
                  if (item.type === 'relative') {
                    return (
                      <PAFClickableText key={index} to={item.link}>
                        {item.name}
                      </PAFClickableText>
                    )
                  } else {
                    return (
                      <PAFALink key={index} href={item.link}>
                        {item.name}
                      </PAFALink>
                    )
                  }
                })}
              </PAFSectionTwoItemsContainer>
            </div>
          </PAHOverlayMenu>
        </PAHOverlayMenuContainer>
        {/* menu UI for larger devices */}
        <PAHMegaDropdownMenu
          open={megaDropdownOpen}
          onMouseEnter={() => setMegaDropdownOpen(true)}
          onMouseLeave={() => setMegaDropdownOpen(false)}
        >
          <PAHMegaDropdownContainer>
            <div>
              <img
                src={APP_HEADER_DATA.image}
                alt="feature"
                style={{ width: '250px', height: '150px', borderRadius: '5px' }}
              />
            </div>
            <div>
              <PAHMegaDropdownText main>{APP_HEADER_DATA.sectionOne.heading}</PAHMegaDropdownText>
              {APP_HEADER_DATA.sectionOne.links.map((item, index) => (
                <div key={index}>
                  <PAHMegaDropdownLink to={item.link}>{item.name}</PAHMegaDropdownLink>
                </div>
              ))}
            </div>
            <div>
              <PAHMegaDropdownText main>{APP_HEADER_DATA.sectionTwo.heading}</PAHMegaDropdownText>
              {APP_HEADER_DATA.sectionTwo.links.map((item, index) => (
                <div key={index}>
                  <PAHMegaDropdownLink to={item.link}>{item.name}</PAHMegaDropdownLink>
                </div>
              ))}
            </div>
            <div>
              <PAHMegaDropdownText main>{APP_HEADER_DATA.sectionThree.heading}</PAHMegaDropdownText>
              {APP_HEADER_DATA.sectionThree.links.map((item, index) => (
                <div key={index}>
                  <PAHMegaDropdownLink to={item.link}>{item.name}</PAHMegaDropdownLink>
                </div>
              ))}
            </div>
          </PAHMegaDropdownContainer>
        </PAHMegaDropdownMenu>
      </PAHFinalParent>
      {/* don't move this : */}
      <PAHHeightCompensation />
    </>
  )
}
