import styled from "styled-components";

// new goals styles:
export const GoalsContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 25px;
`;

export const GoalsHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin-bottom: 29px;
    @media (min-width: 768px) {
        margin-bottom: 41px;
    }
`;

export const GoalsHeading = styled.div`
    color: #FFF;
    font-size: 22px;
    font-family: Outfit;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    cursor: default;
`

export const EditGoalsButton = styled.div`
    color: #FFF;
    text-align: center;
    font-size: 14px;
    font-family: Outfit;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    border-radius: 52px;
    border: 1px solid #FFF;
    padding: 4.8px 9.5px;
    cursor: pointer;
    @media (min-width: 768px) {
        font-weight: 500;
        padding: 11px 27px;
    }
`

export const SaveGoalsButton = styled.div`
    color: #FFF;
    text-align: center;
    font-size: 12px;
    font-family: Outfit;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border-radius: 52px;
    padding: 4.8px 9.5px;
    cursor: pointer;
    background: ${props => props?.disabled ? '#4D4D4D' : '#09B41A'};
    @media (min-width: 768px) {
        font-weight: 500;
        padding: 11px 27px;
    }
`

export const CancelButtonGoals = styled.div`
    color: #FFF;
    text-align: center;
    font-size: 12px;
    font-family: Outfit;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin-right: 10px;
`

export const GoalItemsContainer = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 25px 0;
`

//

export const StyledSwitch = styled.div`
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-direction: row;
    background: #2B2C30;
    box-shadow: 7px 4px 20px rgba(0, 0, 0, 0.61);
    border-radius: 31px;
    padding: 10px;
`;

export const StyledSwitchOption = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 230px;
    height: 37px;
    padding: 0px 10px 0px 10px;
    background: ${(props) => props.isSelected ? '#FFFFFF' : 'none'};
    border-radius: 31px;
    font-family: 'heroregular';
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 30px;
    letter-spacing: 0.04em;
    color: ${(props) => props.isSelected ? '#3D3D3D' : '#FFFFFF'};
    &:hover {
        cursor: pointer;
    }
`;