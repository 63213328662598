import React, { useState } from 'react'
import {
  HeaderContainer,
  AuthorNameText,
  QuoteBackground,
  QuoteTextArea,
  FavoriteIcon,
  ModalInsideContainer,
  ReflectionSubmitButton,
  DrawerRuledLines,
  ReflectiveQuestionHeading,
  ReflectionTimeAndDate,
  RuledTextArea,
} from './QuoteAndReflectionStyles'
import { ModalProvider } from 'styled-react-modal'
import { StyledQuestionModal, FadingBackground, ModalCloseButton } from './QuoteAndReflectionStyles'
import {
  makeQuoteFavorite,
  makeQuoteUnfavorite,
  saveJournal,
} from 'src/consumer/services/featureServices'
import moment from 'moment'
import Spinner from 'react-bootstrap/Spinner'
import { useContext } from 'react'
import { JournalContext } from 'src/consumer/context/JournalContext'
import { getBodyForSavingJournal } from 'src/consumer/helpers/dailyPlayHelper'
import { CongratulatoryCardContext } from 'src/consumer/context/CongraulatoryCardContext'

export const QuoteComponent = (props) => {
  const { data, isFavorite, toggleFavorite } = props

  return (
    <QuoteBackground>
      <HeaderContainer>
        <img src={require('src/assets/quote_icon.png')} alt='quoteicon'/>
      </HeaderContainer>
      <QuoteTextArea>{`"${data?.msg}"`}</QuoteTextArea>
      <div className="d-flex align-items-center justify-content-between">
        <AuthorNameText>{data?.author}</AuthorNameText>
        {!isFavorite && (
          <FavoriteIcon
            src={require('src/assets/empty_heart.png')}
            onClick={() => {
              toggleFavorite()
              makeQuoteFavorite(data?.id)
            }}
          />
        )}
        {isFavorite && (
          <FavoriteIcon
            src={require('src/assets/filled_heart.png')}
            onClick={() => {
              toggleFavorite()
              makeQuoteUnfavorite(data?.id)
            }}
          />
        )}
      </div>
    </QuoteBackground>
  )
}

export const ReflectiveQuestion = ({
  questionOpen,
  setIsQuestionOpen,
  questionData,
  toggleQuoteModal,
  callSource,
  callSourceId,
  showCongratulatoryMessage,
  afterSubmissionFunction = () => {},
  congratulatoryCardData,
  setCongratulatoryCardData,
  ...props
}) => {
  const [answerValue, setAnswerValue] = useState(questionData?.journalQusAns?.[0]?.answere || '')
  const [opacity, setOpacity] = useState(0)
  const [submitWaiting, setSubmitWaiting] = useState(false)
  const startingTime = moment()

  function toggleModal(e) {
    setOpacity(0)
    setIsQuestionOpen(!questionOpen)
  }

  function afterOpen() {
    setTimeout(() => {
      setOpacity(1)
    }, 100)
  }

  function beforeClose() {
    return new Promise((resolve) => {
      setOpacity(0)
      setTimeout(resolve, 300)
    })
  }

  async function handleSubmit() {
    let timeOnSubmit = moment()
    let duration = timeOnSubmit.diff(startingTime, 'milliseconds')
    setSubmitWaiting(true)

    const quesAns = [
      {
        qusId: questionData?.journalQusAns?.[0]?.qusId,
        question: questionData?.journalQusAns?.[0]?.question,
        subtext: questionData?.journalQusAns?.[0]?.subtext,
        answere: answerValue,
        answer: answerValue,
        hints: questionData?.journalQusAns?.[0]?.hints,
      },
    ]

    let body

    if (props?.edit === true) {
      let jetID = questionData?.id
      body = getBodyForSavingJournal(
        questionData?.journalId,
        quesAns,
        questionData?.journalType,
        startingTime,
        duration,
        jetID,
      )
    } else {
      body = getBodyForSavingJournal(
        questionData?.id,
        quesAns,
        questionData?.journalType,
        startingTime,
        duration,
      )
    }

    let response = await saveJournal(questionData?.id, body, callSource, callSourceId)

    if (response.status === 200) {
      if (props?.edit === true) {
        toggleModal()
        setSubmitWaiting(false)
      } else {
        setSubmitWaiting(false)
        showCongratulatoryMessage &&
          !!questionData?.coins &&
          setCongratulatoryCardData({
            showCard: true,
            data: {
              coins: questionData?.coins,
              completed: questionData?.completed,
              completedItemType: 'reflection',
              itemData: questionData,
            },
          })
        toggleQuoteModal()
        toggleModal()
      }
      await afterSubmissionFunction()
    }
  }

  return (
    <ModalProvider backgroundComponent={FadingBackground}>
      <StyledQuestionModal
        isOpen={questionOpen}
        afterOpen={afterOpen}
        beforeClose={beforeClose}
        onBackgroundClick={toggleModal}
        onEscapeKeydown={toggleModal}
        opacity={opacity}
        backgroundProps={{ opacity }}
      >
        <ModalInsideContainer>
          <ModalCloseButton
            src={require('src/assets/close_icon_purple.png')}
            onClick={toggleModal}
          />
          <ReflectionTimeAndDate>{startingTime?.format('h:mm a, DD MMM')}</ReflectionTimeAndDate>
          <ReflectiveQuestionHeading>
            {questionData?.journalQusAns?.[0]?.question}
          </ReflectiveQuestionHeading>
          <RuledTextArea
            autoFocus
            placeholder="Your thoughts here..."
            value={answerValue}
            onChange={(e) => setAnswerValue(e.target.value)}
          ></RuledTextArea>
        </ModalInsideContainer>
        <ReflectionSubmitButton disabled={answerValue === ''} onClick={handleSubmit}>
          {submitWaiting ? <Spinner animation="border" variant="light" size="md" /> : 'Submit'}
        </ReflectionSubmitButton>
      </StyledQuestionModal>
    </ModalProvider>
  )
}
