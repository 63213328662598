import { Add } from '@mui/icons-material'
import { Grid, IconButton } from '@mui/material'
import { FEATURE_BUNDLE_TYPES_ARRAY } from 'src/consumer/constants'
import FeatureBundleComponent from './featureBundles/FeatureBundleComponent'

export default function BundlePackCustomPriceComponent({
  bundle,
  setSelectedPlan,
  allOneOnOnePlans,
}) {
  function setBundle(value) {
    setSelectedPlan((prev) => {
      if (typeof value === 'function') {
        // If the value is a function, call it with the previous bundle object
        return { ...(prev || {}), bundle: value(prev?.bundle || []) }
      } else {
        // Otherwise, merge the value object into the bundle object
        return { ...(prev || {}), bundle: { ...(prev?.bundle || []), ...value } }
      }
    })
  }

  function deleteBundle(idx) {
    setSelectedPlan((prev) => {
      const newBundles = [...prev.bundle]
      newBundles.splice(idx, 1)
      return { ...prev, bundle: newBundles }
    })
  }

  function changeBundle(eve, idx) {}

  function addBundle() {
    setBundle((prev) =>
      prev
        ? [...prev, { type: FEATURE_BUNDLE_TYPES_ARRAY?.[0]?.id, maxCount: 0 }]
        : [{ type: FEATURE_BUNDLE_TYPES_ARRAY?.[0]?.id, maxCount: 0 }],
    )
  }

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
      >
        <strong>Bundles</strong>
        <IconButton onClick={addBundle}>
          <Add />
        </IconButton>
      </Grid>
      {bundle?.map((bndl, idx) => (
        <Grid item xs={12} key={idx}>
          <FeatureBundleComponent
            bundle={bndl}
            deleteBundle={() => {
              deleteBundle(idx)
            }}
            changeBundle={(eve) => changeBundle(eve, idx)}
            disabled={false}
            subscriptionPlansToUse={allOneOnOnePlans}
          />
        </Grid>
      ))}
    </Grid>
  )
}
