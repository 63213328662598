import {
  Button,
  ButtonBase,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputBase,
  Link,
  Paper,
} from '@mui/material'
import { styled as muiStyled } from '@mui/system'
import styled from 'styled-components'

export const MentorPublicPageLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 50px 12px 0;
  @media (min-width: 768px) {
    padding: 150px 30px 0;
  }
`

export const MentorPageSubtitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  @media (min-width: 480px) {
    font-size: 20px;
  }
`

export const MentorPageTitle = styled.div`
  font-size: 38px;
  line-height: 48px;
  font-weight: 500;
  @media (min-width: 480px) {
    font-size: 74px;
    line-height: 84px;
  }
  @media (min-width: 768px) {
    font-size: 74px;
    line-height: 84px;
    width: 50%;
  }
`
export const MentorPageTitleH1 = styled.h1`
  font-size: 38px;
  line-height: 48px;
  font-weight: 500;
  @media (min-width: 480px) {
    font-size: 74px;
    line-height: 84px;
  }
  @media (min-width: 768px) {
    font-size: 74px;
    line-height: 84px;
    width: 50%;
  }
`

export const MentorPageInfo = styled.div`
  font-size: 17px;
  line-height: 23px;
  width: 80%;
  margin-top: 20px;
  margin-bottom: 32px;
  @media (min-width: 768px) {
    width: 50%;
  }
`

export const MentorPageBecomeMentorButton = muiStyled(ButtonBase)(
  ({ theme }) => `
  padding: 12px 27px;
  font-size: 20px;
  background-color: #8247f5;
  border-radius: 7px;
  color: white;
  z-index: 2;
`,
)

export const MentorPagePrimaryWidgetItemsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  @media (min-width: 1080px) {
    margin-bottom: 100px;
  }
`

export const MentorPagePrimaryWidgetBackground = styled.img`
  width: 100%;
  position: relative;
  bottom: 80px;
  max-width: 632px;
  @media (min-width: 768px) {
    position: absolute;
    bottom: unset;
    right: 0;
    bottom: 0;
    width: 50%;
  }
  @media (min-width: 992px) {
    bottom: unset;
    top: 0;
  }
`

export const MentorPublicPageContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`

export const MentorPublicPageJoinMentorInput = muiStyled(InputBase)(
  ({ theme }) => `
  border-radius: 20px;
  border: 1px solid green;
`,
)

export const MentorOnboardingFormComponent = muiStyled('form')(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: stretch;
  gap: 20px;
`,
)

export const MentorJoinModalContent = muiStyled(DialogContent)(
  ({ theme }) => `
`,
)

export const StyledDialogPaper = muiStyled(Paper)`
position: relative;
text-align: center;
border-radius: 20px;
padding: 25px 13px 26px;
font-family: Outfit !important;
width: 550px;
max-height: none !important;
@media (min-width: 576px) {
  padding: 62px 90px 86px;
}
`

export const StyledDialogTitle = muiStyled(DialogTitle)`
    font-family: inherit !important;
    font-size: 1.75rem !important;
`

export const StyledDialogActions = muiStyled(DialogActions)`
    display: flex;
    align-items: center;
    justify-content: stretch;
`

export const StyledDialogSingleAction = muiStyled('div')`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
    width: 100%;
`

export const StyledEmailNotVerifiedMessage = muiStyled('div')(
  ({ theme, verifyEmail }) => `
  color: ${verifyEmail ? 'black' : '#ff3939ed'};
  padding: 12px 0px 0px 0px;
`,
)

export const StyledDialogJoinButton = muiStyled(Button)`
background: ${({ theme, disabled }) =>
  disabled ? '#a09e9e' : 'linear-gradient(149deg, #6977df 0%, #ca98ff 100%)'};
color: ${({ theme, disabled }) => (disabled ? '#e5e5e5' : '#fff')} !important;
font-family: inherit;
text-transform: none;
width: 100%;
padding: 10px;
font-size: 16px;
font-weight: 400;
border-radius: 13px;
transition: background-color 0.3s ease;
`

export const StyledDialogVerifiedButton = muiStyled(Button)`
background: ${({ theme, disabled }) => (disabled ? '#a09e9e' : '#8247f5')};
color: ${({ theme, disabled }) => (disabled ? '#e5e5e5' : '#fff')} !important;
font-family: inherit;
text-transform: none;
width: 100%;
padding: 10px;
font-size: 16px;
font-weight: 400;
border-radius: 13px;
:hover {
  background-color: #905bf7;
};
`

export const StyledMentorJoinVerifyLink = muiStyled(Link)`
cursor: pointer;
`

export const StyledMentorJoinVerifyLinkAfter = muiStyled('div')`
`

export const StyledMentorEmailInputAndMessageContainer = muiStyled('div')`
  display: flex;
  justify-content: stretch;
  align-items: center;
  width: 100%;
  gap: 20px;
  background: ${({ invalid }) => (invalid ? '#edbbbb' : '#ebebeb')};
  padding: 10px;
  border-radius: 7px;
`
