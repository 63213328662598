import * as React from 'react'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import { CenteredLogo, SplashScreenBackground } from './LoaderStyles'

export const LoadingSpinner = ({ bgColor, dark }) => {
  const backgroundColor = bgColor || '#313238'
  let loaderColor

  if (dark) {
    loaderColor = '#313238'
  } else {
    loaderColor = '#FFFFFF'
  }

  return (
    <Backdrop sx={{ color: loaderColor, backgroundColor: backgroundColor }} open>
      <CircularProgress color="inherit" />
    </Backdrop>
  )
}

export const SplashScreen = () => {
  return (
    <SplashScreenBackground>
      <CenteredLogo src={require('src/assets/logo_brand.png')} />
    </SplashScreenBackground>
  )
}

export const EmptyLoadingScreen = () => {
  return <SplashScreenBackground></SplashScreenBackground>
}
