// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getToken, getMessaging, isSupported } from "firebase/messaging";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration

export const MESSAGING_VAPID_KEY = 'BPnFwPlLaGpHNZpY_gNy3L0kU4hsG4OfwBZe5_5iVpIC1CT4mTVBzjqBlWxraXSXqUlzR2nkN9pkCBTvHsWAbZY' // Voluntary Application server identification

const firebaseConfig = {
  apiKey: 'AIzaSyAUFbTasBKIWJIrFUqRcqDd2bYHHqmZ2NQ',
  authDomain: 'auth.aumhum.xyz',
  databaseURL: 'https://aumhum-e45f3.firebaseio.com',
  projectId: 'aumhum-e45f3',
  storageBucket: 'aumhum-e45f3.appspot.com',
  messagingSenderId: '75080491693',
  appId: '1:75080491693:web:61f2acf1ecbff5c51f9c24',
  measurementId: 'G-5MNTMYMR46',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
export default app
export const auth = getAuth(app)
export const messaging = (async () => {
  try {
    const isSupportedBrowser = await isSupported();
    if (isSupportedBrowser) {
      return getMessaging(app);
    }
    console.log("Firebase is not supported in this browser");
    return null;
  } catch (err) {
    console.log(err);
    return null;
  }
})();

// getOrRegisterServiceWorker function is used to try and get the service worker if it exists, otherwise it will register a new one.
export const getOrRegisterServiceWorker = () => {
  if ("serviceWorker" in navigator && typeof window.navigator.serviceWorker !== "undefined") {
    return window.navigator.serviceWorker.getRegistration("/firebase-push-notification-scope")
      .then((serviceWorker) => {
        if (serviceWorker) return serviceWorker;
        return window.navigator.serviceWorker.register("/firebase-messaging-sw.js", { scope: "/firebase-push-notification-scope" });
      });
  }
  throw new Error("The browser doesn`t support service worker.");
};

// requestForToken function generates the FCM token 
export const requestForToken = async (statusSetter = () => { }) => {
  try {
    const messagingResolve = await messaging;
    if (messagingResolve) {
      return getOrRegisterServiceWorker().then((serviceWorkerRegistration) => {
        return getToken(messagingResolve, { vapidKey: MESSAGING_VAPID_KEY, serviceWorkerRegistration })
          .then((currentToken) => {
            if (currentToken) {
              localStorage.setItem('FCMToken', currentToken)
              console.log('FCMToken', currentToken)
              statusSetter('active')
              return { token: currentToken, state: 'granted' }
            } else {
              statusSetter('asking_permission')
              console.log('No registration token available. Request permission to generate one.')
              return { token: null, state: 'default' }
            }
          })
          .catch((err) => {
            statusSetter('denied')
            console.log('An error occurred while retrieving token. ', err)
            return { token: null, state: 'denied' }
          })
      });
    }
  } catch (error) {
    console.log("An error occurred while retrieving token. ", error);
  }
};

const UrlFirebaseConfig = new URLSearchParams(
  firebaseConfig.toString()
);

const swUrl = `${window.location.origin}/firebase-messaging-sw.js?${UrlFirebaseConfig}`;




// export const reCAPTCHAKey = process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY // Replace with your reCAPTCHA site key