import styled from 'styled-components'
import PhoneInput from 'react-phone-input-2'
// import "react-phone-input-2/lib/material.css";

// CAMC: Clubbed Auth Main Component

export const CAMCParent = styled.div`
  width: 310px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  @media (min-width: 768px) {
    width: 600px;
  }
`

export const CAMCHeading = styled.div`
  font-size: ${(props) => (props.secondary ? '26px' : '32px')};
  font-weight: 700;
  max-width: 315px;
  text-align: center;
  @media (min-width: 768px) {
    text-align: left;
    font-size: ${(props) => (props.secondary ? '34px' : '40px')};
  }
`

export const CAMCMaxWithRelativeContainer = styled.div`
  width: 100%;
  max-width: 315px;
  position: relative;
`

export const CAMCMethodButtonContainer = styled.button`
  width: 100%;
  max-width: 315px;
  font-weight: 500;
  border-radius: 500px;
  text-align: center;
  text-decoration: none;
  touch-action: manipulation;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #242424;
  border: 1px solid #707070;
  padding: 7px 31px 7px 55px;
  position: relative;
  transition: all 0.3s ease-in-out;
  &:hover {
    border-color: #000;
  }
`

export const CAMCMethodButtonImage = styled.img`
  position: absolute;
  left: 22px;
  width: 22px;
`

export const CAMCGeneralButton = styled(CAMCMethodButtonContainer)`
  padding: 7px 31px 7px 31px;
  min-height: 40px;
  ${(props) => props?.success && 'background: #514387'};
  ${(props) => props?.success && 'color: #fff'};
`

export const CAMCSeparator = styled.div`
  background: #707070;
  width: 100%;
  height: 1px;
`

export const CAMCGeneralInputLabel = styled.label`
  font-weight: 600;
  color: #242424;
`

export const CAMCGeneralInput = styled.input`
  width: 100%;
  border-radius: 5px;
  display: flex;
  font-weight: 500;
  justify-content: center;
  align-items: center;
  color: #242424;
  border: 1px solid #707070;
  padding: 7px 35px 7px 7px;
  position: relative;
  transition: all 0.3s ease-in-out;
  &:hover {
    border-color: #000;
  }
  &:focus {
    outline: none;
  }
`

export const CAMCErrorText = styled.div`
  width: 100%;
  max-width: 315px;
  color: #cc0000;
  display: flex;
  align-items: center;
  gap: 5px;
`

export const CAMCPhoneInput = styled(PhoneInput)`
  width: 100%;
  max-width: 315px;
  .form-control {
    border: 1px solid #707070;
    border-radius: 5px;
    color: #242424;
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
`

export const CAMCCaptcha = styled.div`
  display: flex;
  box-sizing: border-box;
  align-items: center;
  flex-direction: column;
`
