export const getCourseCardDetails = (cardData, categoryList) => {
  const courseTitle = cardData?.course?.title || ''
  const courseIconImage = cardData?.course?.coverIcon
  const coverIconColor = cardData?.course?.coverIconColor
  const categoryId = cardData?.course?.category?.[0]
  const courseCategory = categoryList
    ? categoryList?.get(categoryId)?.singleTitle || categoryList?.get(categoryId)?.name
    : '' //
  const courseDescription = cardData?.course?.shortDesc
  const courseLongDescription = cardData?.course?.longDesc
  const courseId = cardData?.course?.id
  const courseType = cardData?.course?.courseType
  const courseSubscriptionId = cardData?.course?.subscription?.id
  const heroCardLandscapeImage = cardData?.course?.blogBanner || cardData?.course?.coverImage
  const heroCardPortraitImage = cardData?.course?.heroCardImage
  const verticalIconImg =
    cardData?.course?.verticalRectangle ||
    cardData?.course?.coverImage ||
    cardData?.course?.coverIcon
  return {
    courseTitle,
    courseIconImage,
    courseCategory,
    courseDescription,
    courseLongDescription,
    courseId,
    courseType,
    heroCardLandscapeImage,
    heroCardPortraitImage,
    coverIconColor,
    courseSubscriptionId,
    verticalIconImg,
  }
}

export const getSessionDetails = (cardData, ongoingSession = 0) => {
  const sessionsCount = cardData?.course?.sessions?.length
  const selectedSessionId = cardData?.course?.sessions[ongoingSession]?.sessionId
  const sessionTitle = cardData?.course?.sessions[ongoingSession]?.title
  const sessionDesc = cardData?.course?.sessions[ongoingSession]?.longDesc
  const sessionCoverImg = cardData?.course?.sessions[ongoingSession]?.coverImage
  const sessionsList = cardData?.course?.sessions
  return {
    sessionsCount,
    selectedSessionId,
    sessionTitle,
    sessionDesc,
    sessionCoverImg,
    sessionsList,
  }
}

export const getCourseURLs = (data) => {
  const playSequence = ['preInstructions', 'preparatory', 'meditate', 'postInstructions']
  const URLsList = []
  playSequence.forEach((element) => {
    if (data && data[element]) {
      URLsList.push(data[element])
    }
  })
  return { URLsList }
}

export const getCategoryListMap = (data, categoryField = 'metaCategories') => {
  const categoryList = data?.[categoryField] || []
  const categoryIdToListMap = new Map()
  // const categoryIdToListObject = categoryList.reduce((acc, category) => {
  //    acc[category?.id] = category
  //    return acc
  // }, {})
  // const categoryIdToListObject = categoryList.reduce((acc, category) => {
  //    acc[category?.id] = category
  //    return acc
  // }, {})
  Array.isArray(categoryList) &&
    categoryList.forEach((category) => {
      categoryIdToListMap.set(category?.id, category)
    })
  return categoryIdToListMap
}
