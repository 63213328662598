import {
  FeatureCardActionButton,
  FeatureCardContainer,
  FeatureCardDescription,
  FeatureCardImage,
  FeatureCardTitle,
} from './featureCardComponentStyles'

export function FeatureCardComponent({
  item,
  getTitleField,
  getDescriptionField,
  getImageField,
  getActionButtonLogic,
  withBorderAndShadow = false,
}) {
  function actionButtonOnClick() {
    if (getActionButtonLogic(item).onClick) {
      getActionButtonLogic(item).onClick(item)
    } else {
      console.log('no action defined')
    }
  }

  return (
    <FeatureCardContainer withBorderAndShadow={withBorderAndShadow}>
      <div style={{ display: 'flex', gap: '10%', alignItems: 'center', marginBottom: '16px' }}>
        {getImageField(item) && <FeatureCardImage src={getImageField(item)} />}
        {getTitleField(item) && <FeatureCardTitle>{getTitleField(item)}</FeatureCardTitle>}
      </div>
      <div>
        {getDescriptionField(item) && (
          <FeatureCardDescription>{getDescriptionField(item)}</FeatureCardDescription>
        )}
        {getActionButtonLogic(item)?.text && (
          <FeatureCardActionButton onClick={actionButtonOnClick}>
            {getActionButtonLogic(item)?.text}
          </FeatureCardActionButton>
        )}
      </div>
    </FeatureCardContainer>
  )
}
