import styled from 'styled-components'
import { styled as muiStyled } from '@mui/material'

export const TileHeadingV2 = styled.div`
  background: rgba(0, 0, 0, 0.36);
  backdrop-filter: blur(26px);
  border-radius: 47px;
  width: fit-content;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${(props) => (props.nopointer ? 'default' : 'pointer')};
  margin: 0 0 0 15px;
  font-weight: 700;
  font-size: 22px;
  padding: 2px 10px;
  @media (min-width: 768px) {
    font-size: 28px;
    padding: 3px 15px;
    margin: 0 0 0 15px;
  }
  @media (min-width: 1024px) {
    font-size: 32px;
    padding: 5px 20px;
    margin: 0 0 0 15px;
  }
`

export const TileHeadingV2Container = muiStyled('div')(
  ({ theme }) => `
padding: 0px 20px;
@media(min-width: 768px){
    padding: 0px 35px;
}
@media(min-width: 1024px){
    padding: 0px 50px;
}
`,
)
