import { useMemo } from 'react'
import DefaultFaqLayout from './layouts/Default/DefaultFaqLayout'
import SidebarLayout from './layouts/SidebarLayout/SidebarLayout'
import { FAQ_LAYOUTS } from './utils'

export default function FaqUIController({ categories, fullWidth, layoutType, loading,renderSpinner, keepAllClosedOnRender }) {
  const ComponentToRender = useMemo(() => {
    switch (layoutType) {
      case FAQ_LAYOUTS.TWO_COLUMN_SIDEBAR:
      case FAQ_LAYOUTS.SIDEBAR:
        return SidebarLayout
      default:
        return DefaultFaqLayout
    }
  }, [layoutType])

  return (
    <ComponentToRender
      faqCategories={categories}
      loadingCategories={loading}
      layoutType={layoutType}
      renderSpinner={renderSpinner}
      keepAllClosedOnRender={keepAllClosedOnRender}
    />
  )
}
