import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  receivedBookingRequests: [],
}

const bookingRequestSlice = createSlice({
  name: 'bookingRequestStore',
  initialState: initialState,
  reducers: {
    setReceivedBookingRequests(state, action) {
      return { ...state, receivedBookingRequests: action.payload }
    },
    addReceivedBookingRequest(state, action) {
      return {
        ...state,
        receivedBookingRequests: [...(state.receivedBookingRequests || []), action.payload],
      }
    },
    deleteBookingRequest(state, action) {
      const oldArray = [...(state?.receivedBookingRequests || [])]
      const newArray = oldArray.filter((item) => item?.id !== action.payload || '')
      return { ...state, receivedBookingRequests: newArray }
    },
  },
})

export const { setReceivedBookingRequests, addReceivedBookingRequest, deleteBookingRequest } =
  bookingRequestSlice.actions
export const bookingRequestReducer = bookingRequestSlice.reducer

function getBookingRequestStore(state) {
  return state.bookingRequestStore
}

export const selectReceivedBookingRequests = (state) => {
  return getBookingRequestStore(state).receivedBookingRequests
}
