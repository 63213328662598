import { platformWeb } from "../services";

export const getSessionDetails = (sessions, index = 0) => {
  const sessionsCount = sessions?.length;
  const selectedSessionId = sessions[index]?.sessionId;
  const sessionTitle = sessions[index]?.title;
  const sessionDesc = sessions[index]?.longDesc;
  const sessionCoverImg = sessions[index]?.coverImage;
  return { sessionsCount, selectedSessionId, sessionTitle, sessionDesc, sessionCoverImg };
}

export const getSessionProgressData = ({ courseId, sessionId, targetDuration, completedDuration, completed, rating,
  deviceDetials, clientSessionId, clientCreatedTime, userAgent, planId }) => {
  const { client, os, device } = deviceDetials;
  return {
    "clientCreatedTime": clientCreatedTime,
    "clientSessionId": clientSessionId,
    "sessionId": sessionId,
    "serviceType": "track",
    "sessionDuration": completedDuration,
    "isCompleted": completed,
    "planId": planId,
    "targetDuration": targetDuration,
    "sliderPosition": "100.0",
    "meditationDuration": completedDuration,
    "feedbackSubmitted": false,
    "courseId": courseId,
    "sessionActivities": {
      "steps": {
        "meditate": {
          "actualDuration": targetDuration,
          "completed": true,
          "completedDuration": completedDuration
        },
        "postInstructions": {
          "actualDuration": 0,
          "completed": true,
          "completedDuration": 0,
          "skiped": true
        },
        "preInstructions": {
          "actualDuration": 0,
          "completed": true,
          "completedDuration": 0,
          "skiped": true
        },
        "preparatory": {
          "actualDuration": 0,
          "completed": true,
          "completedDuration": 0,
          "skiped": true
        }
      }
    },
    "userDevice": {
      "appPackage": "com.aumhum.aumhum",
      "appVersion": "10.0.0",
      "manufacturer": device.brand,
      "modelNumber": device.model,
      "deviceType": device.type,
      "osVersion": os.version,
      "osName": os.name,
      "platform": platformWeb,
      "browserVersion": client.version,
      "browserName": client.name,
      "browserType": client.type,
      "userAgent": userAgent
    },
    // "dailyFeatureId": "",
    "feedback": {
      "rating": rating,
      "reasons": [
        // {
        //   "type": "Course",
        //   "id": "db5f66a6-2a3c-11eb-adc1-0242ac120002",
        //   "name": "Difficult to understand"
        // }
      ]
    },
    // "repeated": true,
    // "status": "",
  }
}

export const resetPlayerData = (playerData) => {
  if (playerData?.showPIP) {
    return {
      ...playerData,
      showPIP: false,
      showLeavingEarly: true,
      source: '',
      id: '',
      otherData: {}
    };
  }
  return {
    ...playerData,
    source: '',
    id: '',
    otherData: {}
  }

}