import React, { useContext, useEffect, useState } from 'react'
import { isTokenInvalidOrAbsent } from 'src/views/pages/login/ProtectedRoute'
import { getDataWithParams } from '../services/profileService'
// import { FCMContext } from "src/context/FCMProvider";
import { useNavigate } from 'react-router-dom'
import { logoutHandler } from '../services/loginLogoutHandlers'
import { ProfileContext } from './ProfileContext'
import { putDataWithParams } from '../services/featureServices'
import { isCommonResponseSuccessful } from 'src/mentor/helpers/utilityFunctions'
import { toast } from 'react-toastify'
import { messaging } from 'src/firebase'
import { onMessage } from 'firebase/messaging'

export const NotificationContext = React.createContext()

export const NotificationContextProvider = ({ children }) => {
  const { authToken } = useContext(ProfileContext)

  const [notificationList, setNotificationList] = useState([])
  const [showNotificationDrawer, setShowNotificationDrawer] = useState(false)
  const [page, setPage] = useState(1)
  const [hasNextPage, setHasNextPage] = useState(true)
  const navigate = useNavigate()
  const [latestNotificationPayload, setLatestNotificationPayload] = useState(null)
  const pageSize = 10

  const fetchNotificationList = async () => {
    try {
      const resp = await getDataWithParams('/api/secure/user/notifications/paginated', {
        page: 1,
        size: pageSize,
      })
      if (resp?.status === 200) {
        if (resp?.data && resp?.data?.length > 0) {
          setPage(1)
          setNotificationList(resp?.data)
          setHasNextPage(true)
        } else {
          // If there's no more data to load, set hasNextPage to false
          setHasNextPage(false)
        }
      } else {
        setHasNextPage(false)
      }
    } catch (error) {
      // Handle any errors from the data fetching here
    }
  }
  const fetchNextPageOfNotificationList = async () => {
    const nextPageNumber = page + 1
    try {
      const resp = await getDataWithParams('/api/secure/user/notifications/paginated', {
        page: nextPageNumber,
        size: pageSize,
      })

      if (resp?.status === 200) {
        if (resp?.data && resp?.data?.length > 0) {
          setPage(nextPageNumber)
          setNotificationList((prev) => [...prev, ...resp?.data])
        } else {
          // If there's no more data to load, set hasNextPage to false
          setHasNextPage(false)
        }
      } else {
        setHasNextPage(false)
      }
    } catch (error) {
      // Handle any errors from the data fetching here
    }
  }
  async function markNotificationAsRead(notificationId) {
    const resp = await putDataWithParams(
      '/api/secure/user/notification/update-read',
      {},
      {
        notificationId: notificationId,
      },
    )

    if (resp.status === 200 && isCommonResponseSuccessful(resp?.data?.code)) {
      setNotificationList((prev) =>
        prev.map((notification) => {
          if (notification.notificationId === notificationId) {
            return {
              ...notification,
              read: true,
            }
          } else {
            return notification
          }
        }),
      )
    } else {
      toast.error('Could not mark notification as read. Please try again.')
    }
  }

  function payloadReceiver(payload) {
    setLatestNotificationPayload(payload)
    console.log('payload from firebase notification is')
    console.log(payload)
    authToken && fetchNotificationList()
    // toast.info("Notif recieved")
  }

  const setupNotificationListener = async () => {
    const messagingResolve = await messaging
    if (messagingResolve) {
      return onMessage(messagingResolve, payloadReceiver)
    }
    return () => {}
  }

  useEffect(() => {
    let unsub = () => {}

    if (isTokenInvalidOrAbsent(authToken)) {
      setNotificationList([])
      // No need to subscribe, so unsubscribe
      return () => {
        unsub && unsub()
      }
    } else {
      fetchNotificationList()
      setupNotificationListener()
        .then((unsubscribeFromMessaging) => {
          unsub = unsubscribeFromMessaging
        })
        .catch((err) => {
          console.log(err)
          unsub = () => {}
        })
    }
    // Unsubscribe when the component is unmounted or when the dependency values change
    return () => {
      unsub && unsub()
    }
  }, [authToken])

  useEffect(() => {
    console.log(notificationList)
  }, [notificationList])

  return (
    <NotificationContext.Provider
      value={{
        notificationList,
        showNotificationDrawer,
        setShowNotificationDrawer,
        fetchNextPageOfNotificationList,
        markNotificationAsRead,
        updateNotifications: fetchNextPageOfNotificationList,
        refreshNotifications: fetchNotificationList,
        moreNotificationsAvailable: hasNextPage,
        latestNotificationPayload,
      }}
    >
      {children}
    </NotificationContext.Provider>
  )
}
