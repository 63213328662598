export function formFAQDataWithRespectiveFAQCategories(faqs, faqCategories) {
  return faqCategories?.map((faqCat, idx) => {
    const currentFAQs = faqs?.filter((faq) =>
      Object.keys(faq?.categoryRankMap).includes(faqCat?.id),
    )
    return {
      id: faqCat?.id,
      title: faqCat?.title,
      scopeRank: faqCat?.scopeRankMap,
      faqs: currentFAQs,
    }
  })
}

export function updateFAQDataWithRespectiveFAQCategories(prevFAQData, newFaqs) {
  return prevFAQData?.map((faqCat, idx) => {
    const newCategoryRespectiveFAQs = newFaqs?.filter((faq) =>
      Object.keys(faq?.categoryRankMap).includes(faqCat?.id),
    )
    return {
      id: faqCat?.id,
      title: faqCat?.title,
      faqs: faqCat?.faqs?.concat(newCategoryRespectiveFAQs),
    }
  })
}
