import { styled as muiStyled } from '@mui/material'

export const BundlePackHeadingContainer = muiStyled('div')(
  ({ theme }) => `
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    margin-bottom: 8px;
    margin-top :16px;
`,
)

export const ActualBundlePacksContainer = muiStyled('div')(
  ({ theme }) => `
    display: flex;
    flex-direction: column;
    gap: 10px;
    
`,
)

export const BundlePackItem = muiStyled('div')(
  ({ theme }) => `
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0;
    `,
)
