import styled from 'styled-components'

export const ChambersListRow = styled.div`
  display: flex;
  flex-direction: row;
  margin: 4px 0 16px;
  overflow-x: scroll;
  gap: 4px;
  padding: 12px 0 24px;
`

export const ChamberRoomParent = styled.div`
  border: ${(props) => (props?.selected ? '1px solid green' : '1px solid #f5f3f0')};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 500px;
`

export const BookingRequestCreationButton = styled.button`
  background: green;
  color: #fff;
  padding: 4px 8px;
  border-radius: 4px;
  margin: 8px auto;
  width: fit-content;
  &:disabled {
    background: gray;
  }
`
