import CloseIcon from '@mui/icons-material/Close'
import { TextField } from '@mui/material'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import dayjs from 'dayjs'
import moment from 'moment'
import { useContext, useEffect, useRef, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { components } from 'react-select'
import { AsyncPaginate } from 'react-select-async-paginate'
import { toast } from 'react-toastify'
import {
  addServiceProviderToData,
  selectServiceProvidersData,
} from 'src/clinic/reducers/serviceProviders/serviceProvidersReducer'
import { getCPDataWithoutParams } from 'src/clinic/services'
import { appVersion, platformWeb } from 'src/consumer/services'
import {
  getBookedSessionsInSelectedChamberRoomsBwUtcTime,
  getBookingRequestsInSelectedChamberRoomsBwUtcTime,
  saveBookingRequest,
} from 'src/consumer/services/featureServices'
import { ClinicDataContext } from 'src/context/ClinicDataContext'
import {
  getLabelForAlreadyPatientOption,
  loadOptionsForExistingPatient,
} from './ClinicManageBookingComponents'
import {
  BookingRequestCreationButton,
  ChamberRoomParent,
  ChambersListRow,
} from './therapyRequestBookingStyles'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { getDefaultScheduleFromSchedules } from 'src/mentor/helpers/utilityFunctions'
import {
  getAvailabilityForDayCalendar,
  getSlotGroupsForChamberRoomDayCalendar,
} from './ClinicManageBookingsHelper'
import InfoIcon from '@mui/icons-material/Info'
import { BootstrapTooltip } from 'src/consumer/components/helperComponentsStyles'

const utcPlugin = require('dayjs/plugin/utc')
dayjs.extend(utcPlugin)

export const BookingRequestComponentWithPatientSelectionOption = ({
  clinicLocationId,
  clinicBrandId,
  clinicLocationData,
  bookingRequestsData,
  bookingRequestsDataLoading,
  setFetchDataTriggerForBookingRequests,
}) => {
  const [selectedPatient, setSelectedPatient] = useState()

  return (
    <div>
      <div style={{ width: '100%', marginBottom: '24px' }}>
        <AsyncPaginate
          value={selectedPatient}
          loadOptions={loadOptionsForExistingPatient}
          components={{ NoOptionsMessageComponent }}
          styles={{
            menuList: (provided, state) => ({
              ...provided,
              maxHeight: '200px', // Set the desired height here
              overflowY: 'scroll',
            }),
          }}
          getOptionValue={(option) => option.id}
          getOptionLabel={(option) => getLabelForAlreadyPatientOption(option)}
          onChange={setSelectedPatient}
          debounceTimeout={500}
          additional={{
            page: 1,
            clinicId: clinicLocationId,
            clinicBrandId: clinicBrandId,
            setAutoFillStuffForNewPatientString: () => {},
          }}
          isClearable
        />
      </div>

      {selectedPatient ? (
        <>
          <CompositeBookingRequestCreation
            clinicLocationId={clinicLocationId}
            clinicBrandId={clinicBrandId}
            clinicLocationData={clinicLocationData}
            selectedPatient={selectedPatient}
            bookingRequestsData={bookingRequestsData}
            bookingRequestsDataLoading={bookingRequestsDataLoading}
            setFetchDataTriggerForBookingRequests={setFetchDataTriggerForBookingRequests}
          />
        </>
      ) : (
        <>Select a patient to book therapy</>
      )}
    </div>
  )
}

export const CompositeBookingRequestCreation = ({
  clinicLocationId,
  clinicBrandId,
  clinicLocationData,
  selectedPatient,
  bookedSlot,
  neededTherapyBookingDataForThisEvent,
  handleCloseDialog,
  bookingRequestsData,
  bookingRequestsDataLoading,
  setFetchDataTriggerForBookingRequests,
}) => {
  const [clientLocation, setClientLocation] = useState('offline') // aumhum, offline

  function switchToOnline() {
    setClientLocation('aumhum')
  }

  function switchToOffline() {
    setClientLocation('offline')
  }

  const patientName = bookedSlot?.userName || selectedPatient?.name
  const appointmentNumber = bookedSlot?.appointmentNumber
  const requestedByDoctor = bookedSlot?.mentorName

  return (
    <div>
      {handleCloseDialog && (
        <div className="w-full d-flex" style={{ justifyContent: 'right', marginBottom: '16px' }}>
          <CloseIcon style={{ cursor: 'pointer' }} onClick={handleCloseDialog} />
        </div>
      )}
      <div style={{ display: 'flex', justifyContent: 'space-between', gap: '16px' }}>
        <div>
          <b>Booking therapy for {patientName}</b>
        </div>
        <div>
          {appointmentNumber && <div>From appointment number: {appointmentNumber}</div>}
          {requestedByDoctor && <div>Requested by: {requestedByDoctor}</div>}
        </div>
      </div>
      <div style={{ padding: '16px 0' }}>
        {/* heading */}
        <div
          style={{
            textDecoration: 'underline',
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '8px',
          }}
        >
          Client Location
        </div>
        {/* toggle button */}
        <div
          style={{
            width: 'fit-content',
            display: 'flex',
            border: '1px solid #cecece',
            borderRadius: '4px',
            margin: 'auto',
          }}
        >
          <div
            style={{
              padding: '4px 8px',
              borderRight: '1px solid #cecece',
              cursor: 'pointer',
              background: clientLocation === 'offline' ? '#b3c7cc' : '#fff',
            }}
            onClick={switchToOffline}
          >
            In Chamber
          </div>
          <div
            style={{
              padding: '4px 8px',
              cursor: 'pointer',
              background: clientLocation === 'aumhum' ? '#b3c7cc' : '#fff',
            }}
            onClick={switchToOnline}
          >
            On patient&apos;s own device
          </div>
        </div>

        {clientLocation === 'offline' && (
          <div>
            <ChamberPartInsideComposite
              clinicLocationId={clinicLocationId}
              clinicLocationData={clinicLocationData}
              handleCloseDialog={handleCloseDialog}
              bookedSlot={bookedSlot}
              selectedPatient={selectedPatient}
              neededTherapyBookingDataForThisEvent={neededTherapyBookingDataForThisEvent}
              setFetchDataTriggerForBookingRequests={setFetchDataTriggerForBookingRequests}
              bookingRequestsData={bookingRequestsData}
            />
          </div>
        )}
        {clientLocation === 'aumhum' && (
          <VideoTherapyPartInsideComposite
            bookedSlot={bookedSlot}
            handleCloseDialog={handleCloseDialog}
            setFetchDataTriggerForBookingRequests={setFetchDataTriggerForBookingRequests}
            neededTherapyBookingDataForThisEvent={neededTherapyBookingDataForThisEvent}
            selectedPatient={selectedPatient}
            clinicLocationData={clinicLocationData}
          />
        )}
      </div>
    </div>
  )
}

////

const VideoTherapyPartInsideComposite = ({
  bookedSlot,
  handleCloseDialog,
  selectedPatient,
  clinicLocationData,
  setFetchDataTriggerForBookingRequests,
}) => {
  const [chosenTime, setChosenTime] = useState('now') // now, custom
  const [chosenStartTime, setChosenStartTime] = useState(dayjs().add(15, 'minutes')) // ssot
  const [creatingRequestLoading, setCreatingRequestLoading] = useState(false)

  async function createBookingRequest() {
    if (chosenStartTime.isBefore(dayjs())) {
      toast.warn('Selected start time has already passed. Please choose a future time.')
      return
    }

    setCreatingRequestLoading(true)

    const patientId = bookedSlot?.patientId || selectedPatient?.id
    const suggestedByMentorId = bookedSlot?.mentorId
    const suggestedByMentorName = bookedSlot?.mentorName
    const suggestedInSessionId = bookedSlot?.id
    const clinicBrandId = clinicLocationData?.clinicBrandId

    const response = await saveBookingRequest(
      {
        eventType: 'therapy-session',
        mode: 'aumhum',
        clinicBrandId: clinicBrandId,
        clinicLocationId: clinicLocationData?.id,
      },
      {
        apiParam: 'booking-request',
        startTime: chosenStartTime.utc().format('YYYY-MM-DD HH:mm:ss'),
        patientId: patientId,
        suggestedByMentorId: suggestedByMentorId,
        suggestedByMentorName: suggestedByMentorName,
        // suggestedTherapies:
        suggestedInSessionId: suggestedInSessionId,
      },
    )
    setCreatingRequestLoading(false)
    if (response?.status === 200) {
      handleCloseDialog && handleCloseDialog()
      setFetchDataTriggerForBookingRequests &&
        setFetchDataTriggerForBookingRequests((prev) => !prev)
    } else {
      toast.warn('Unable to create booking request')
    }
  }

  return (
    <div>
      {/* heading */}
      <div
        style={{
          textDecoration: 'underline',
          display: 'flex',
          justifyContent: 'center',
          marginTop: '8px',
          marginBottom: '8px',
        }}
      >
        Session start time
      </div>

      {/* toggle buttons */}
      <div
        style={{
          width: 'fit-content',
          display: 'flex',
          border: '1px solid #cecece',
          borderRadius: '4px',
          margin: 'auto',
        }}
      >
        <div
          style={{
            padding: '4px 8px',
            borderRight: '1px solid #cecece',
            cursor: 'pointer',
            background: chosenTime === 'now' ? '#b3c7cc' : '#fff',
          }}
          onClick={() => {
            setChosenTime('now')
            setChosenStartTime(dayjs().add(15, 'minutes'))
          }}
        >
          Within next 15 mins
        </div>
        <div
          style={{
            padding: '4px 8px',
            cursor: 'pointer',
            background: chosenTime === 'custom' ? '#b3c7cc' : '#fff',
          }}
          onClick={() => setChosenTime('custom')}
        >
          Custom
        </div>
      </div>

      {chosenTime === 'now' && <div style={{ marginBottom: '16px' }}></div>}
      {chosenTime === 'custom' && (
        <div
          style={{
            display: 'grid',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '16px',
            padding: '0 20px 20px',
          }}
        >
          <div style={{ marginBottom: '8px' }}>Select Date and Time</div>
          <DateTimePicker
            renderInput={(props) => <TextField {...props} />}
            value={chosenStartTime}
            onChange={(newValue) => setChosenStartTime(newValue)}
            style={{ marginBottom: '16px', marginTop: '16px' }}
          />
        </div>
      )}

      <div className="text-center">
        <div>Sending request to eligible therapists in this clinic location. </div>
        <div>Start time for session is {chosenStartTime.format('DD MMM h:mm A')}</div>
        <BookingRequestCreationButton onClick={createBookingRequest}>
          Create Request{' '}
          {creatingRequestLoading && <Spinner animation="border" variant="light" size="sm" />}
        </BookingRequestCreationButton>
      </div>
    </div>
  )
}

const ChamberPartInsideComposite = ({
  clinicLocationId,
  clinicLocationData,
  handleCloseDialog,
  bookedSlot,
  selectedPatient,
  neededTherapyBookingDataForThisEvent,
  setFetchDataTriggerForBookingRequests,
  bookingRequestsData,
}) => {
  const dispatch = useDispatch()
  const roomsListDivRef = useRef()
  const { assistantData } = useContext(ClinicDataContext)
  const serviceProviders = useSelector(selectServiceProvidersData)

  const [chosenStartTime, setChosenStartTime] = useState(dayjs().add(15, 'minutes')) // ssot
  const [creatingRequestLoading, setCreatingRequestLoading] = useState(false)
  const [chosenChamberRoom, setChosenChamberRoom] = useState('')
  const [dataLoading, setDataLoading] = useState(false)
  const [chamberToBookedSessionsMap, setChamberToBookedSessionsMap] = useState({})
  const [chamberToBookingRequestsMap, setChamberToBookingRequestsMap] = useState({})

  const timeZone = encodeURIComponent(moment().format('Z'))
  const [roomListDivScrollStatus, setRoomListDivScrollStatus] = useState({
    scrollLeft: 0,
    scrollWidth: 0,
    clientWidth: 0,
  })

  const scheduleForThisClinicLocation = getDefaultScheduleFromSchedules(
    clinicLocationData?.schedules,
  )

  const patientId = bookedSlot?.patientId || selectedPatient?.id
  const suggestedByMentorId = bookedSlot?.mentorId
  const suggestedByMentorName = bookedSlot?.mentorName
  const suggestedInSessionId = bookedSlot?.id
  const clinicBrandId = clinicLocationData?.clinicBrandId

  async function createBookingRequest() {
    if (chosenStartTime.isBefore(dayjs())) {
      toast.warn('Selected start time has already passed. Please choose a future time.')
      return
    }
    setCreatingRequestLoading(true)
    const response = await saveBookingRequest(
      {
        eventType: 'therapy-session',
        mode: 'offline',
        clinicBrandId: clinicBrandId,
        clinicLocationId: clinicLocationId,
      },
      {
        apiParam: 'booking-request',
        startTime: chosenStartTime.utc().format('YYYY-MM-DD HH:mm:ss'),
        patientId: patientId,
        suggestedByMentorId: suggestedByMentorId,
        suggestedByMentorName: suggestedByMentorName,
        // suggestedTherapies:
        suggestedInSessionId: suggestedInSessionId,
        chamberRoomId: chosenChamberRoom,
        chamberRoomName: getChamberRoomTitle(
          chosenChamberRoom,
          allChamberRoomsOfThisClinicLocation,
        ),
      },
    )
    setCreatingRequestLoading(false)
    if (response?.status === 200) {
      handleCloseDialog && handleCloseDialog()
      setFetchDataTriggerForBookingRequests &&
        setFetchDataTriggerForBookingRequests((prev) => !prev)
    } else {
      toast.warn('Unable to create booking request')
    }
  }

  async function getMentors() {
    if (
      Array.isArray(allChamberRoomsOfThisClinicLocation) &&
      allChamberRoomsOfThisClinicLocation?.length > 0
    ) {
      const allMentorIds = []
      allChamberRoomsOfThisClinicLocation.forEach((chamberRoom) => {
        allMentorIds.push(...(chamberRoom?.inPersonMentorsIds || []))
      })
      allMentorIds?.forEach(async (mentorId) => {
        const foundSvcProviderInRedux = serviceProviders?.find((item) => item?.id === mentorId)
        if (!Boolean(foundSvcProviderInRedux)) {
          const response = await getCPDataWithoutParams(
            `/api/secure/clinic-assistant/service-provider?id=${mentorId}&slug=&zoneOffset=${encodeURIComponent(
              moment().format('Z'),
            )}&appVersion=${appVersion}&platformType=${platformWeb}`,
          )
          if (response.status === 200) {
            dispatch(addServiceProviderToData(response?.data))
          }
        }
      })
    }
  }

  useEffect(() => {
    getMentors()
  }, [assistantData])

  const allChamberRoomsOfThisClinicLocation = assistantData?.associatedChamberRoomsData?.filter(
    (item) => item?.clinicLocationId === clinicLocationId,
  )

  const selectedChamberTherapistsNotHavingSatelliteCall = getListOfTherapistsNotHavingSatellite(
    chosenChamberRoom,
    allChamberRoomsOfThisClinicLocation,
    serviceProviders,
    assistantData?.clinicAssistantProfileData?.find((item) => item?.clinicId === clinicLocationId)
      ?.id,
    assistantData?.associatedClinicChambersData || [],
    clinicLocationId,
  )

  // get and set bookings and booking requests data of selected day - to show in UI - to prevent overlap
  useEffect(() => {
    const fetchData = async () => {
      const selectedDateStartTime = moment(
        chosenStartTime.format('DD-MM-YYYY'),
        'DD-MM-YYYY',
      )?.format(`YYYY-MM-DDTHH:mm:ss[${decodeURIComponent(timeZone)}]`)
      const selectedDateEndTime = moment(chosenStartTime.format('DD-MM-YYYY'), 'DD-MM-YYYY')
        ?.endOf('day')
        ?.format(`YYYY-MM-DDTHH:mm:ss[${decodeURIComponent(timeZone)}]`)
      const selectedDateUtcStartTime = moment
        ?.utc(selectedDateStartTime)
        ?.format(`YYYY-MM-DDTHH:mm:ss[Z]`)
      const selectedDateUtcEndTime = moment
        ?.utc(selectedDateEndTime)
        ?.format(`YYYY-MM-DDTHH:mm:ss[Z]`)
      if (allChamberRoomsOfThisClinicLocation) {
        setDataLoading(true)
        const responeBookings = await getBookedSessionsInSelectedChamberRoomsBwUtcTime(
          allChamberRoomsOfThisClinicLocation?.map((item) => item?.id),
          selectedDateUtcStartTime,
          selectedDateUtcEndTime,
        )
        let dataMap
        if (responeBookings?.status === 200 && Array.isArray(responeBookings?.data)) {
          dataMap = createMapChamberIdToData(
            allChamberRoomsOfThisClinicLocation?.map((item) => item?.id),
            responeBookings?.data,
            'chamberRoomId',
          )
        } else {
          dataMap = createMapChamberIdToData(
            allChamberRoomsOfThisClinicLocation?.map((item) => item?.id),
            [],
            'chamberRoomId',
          )
        }
        setChamberToBookedSessionsMap(dataMap)
        const responseBR = await getBookingRequestsInSelectedChamberRoomsBwUtcTime(
          allChamberRoomsOfThisClinicLocation?.map((item) => item?.id),
          selectedDateUtcStartTime,
          selectedDateUtcEndTime,
        )
        let dataMapBR
        if (responseBR?.status === 200 && Array.isArray(responseBR?.data)) {
          dataMapBR = createMapChamberIdToData(
            allChamberRoomsOfThisClinicLocation?.map((item) => item?.id),
            responseBR?.data,
            'chamberRoomId',
          )
        } else {
          dataMapBR = createMapChamberIdToData(
            allChamberRoomsOfThisClinicLocation?.map((item) => item?.id),
            [],
            'chamberRoomId',
          )
        }
        setChamberToBookingRequestsMap(dataMapBR)
        setDataLoading(false)
      }
    }
    fetchData()

    const intervalId = setInterval(() => {
      fetchData()
    }, 45000)
    return () => clearInterval(intervalId)
  }, [chosenStartTime])

  function updateScrollStatus() {
    if (roomsListDivRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = roomsListDivRef.current
      setRoomListDivScrollStatus({
        scrollLeft: scrollLeft,
        scrollWidth: scrollWidth,
        clientWidth: clientWidth,
      })
    }
  }

  useEffect(() => {
    const div = roomsListDivRef.current
    if (div) {
      const { scrollLeft, scrollWidth, clientWidth } = roomsListDivRef.current
      setRoomListDivScrollStatus({
        scrollLeft: scrollLeft,
        scrollWidth: scrollWidth,
        clientWidth: clientWidth,
      })
      div.addEventListener('scroll', updateScrollStatus)
      return () => div.removeEventListener('scroll', updateScrollStatus)
    }
  }, [])

  const tooltipCompNoSatelliteCallsWarn = (
    <div style={{ textAlign: 'left' }}>
      <div style={{ marginBottom: '16px' }}>
        Following people in selected chamber do not have satellite call configured with you. They
        will not receive this request even if they are eligible. To create a booking with them,
        directly book using Day Calendar :
      </div>
      <div>{selectedChamberTherapistsNotHavingSatelliteCall?.join(', ')}</div>
    </div>
  )

  const tooltipCompBRCriteria = (
    <div style={{ textAlign: 'left' }}>
      <div>
        <strong>Please note: </strong>
      </div>
      <div>1. The request will only go to therapists.</div>
      <div>2. You must be the booking manager for the therapists.</div>
      <div>3. The therapists need to have event of requested mode. (Offline/Video Call)</div>
      <div>4. The therapists need to have a satellite call configured with you, if session mode is offline.</div>
    </div>
  )

  console.log(chamberToBookedSessionsMap)
  console.log(chamberToBookingRequestsMap)

  return (
    <div>
      <div
        style={{
          display: 'grid',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '16px',
          padding: '0 20px 20px',
        }}
      >
        <div style={{ marginBottom: '8px' }}>Select Date and Time</div>
        <DateTimePicker
          renderInput={(props) => <TextField {...props} />}
          value={chosenStartTime}
          onChange={(newValue) => setChosenStartTime(newValue)}
          style={{ marginBottom: '16px', marginTop: '16px' }}
        />
      </div>
      <div className="w-full d-flex justify-content-end" style={{ height: '16px' }}>
        {dataLoading && (
          <div>
            <Spinner animation="border" variant="dark" size="sm" />
            <span>{' Updating data'}</span>
          </div>
        )}
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '16px' }}>
        <div>{roomListDivScrollStatus.scrollLeft === 0 ? '' : <ArrowBackIcon />}</div>
        <div>
          {roomListDivScrollStatus.scrollLeft ===
          roomListDivScrollStatus.scrollWidth - roomListDivScrollStatus.clientWidth ? (
            ''
          ) : (
            <ArrowForwardIcon />
          )}
        </div>
      </div>
      <ChambersListRow ref={roomsListDivRef}>
        {allChamberRoomsOfThisClinicLocation?.map((chamberRoom, chamberRoomIdx) => {
          return (
            <ChamberRoomComponent
              chamberRoomData={chamberRoom}
              chosenChamberRoom={chosenChamberRoom}
              clinicLocationData={clinicLocationData}
              clickFunction={(chamberRoomId) => setChosenChamberRoom(chamberRoomId)}
              key={chamberRoomIdx}
            />
          )
        })}
      </ChambersListRow>
      <div className="text-center">
        {!Boolean(chosenChamberRoom) && (
          <div style={{ margin: '8px auto', maxWidth: '300px' }} className="text-danger">
            Please choose a chamber room to continue
          </div>
        )}
        <div className="text-primary" style={{ margin: 'auto' }}>
          <BootstrapTooltip title={tooltipCompBRCriteria}>
            <div>
              <InfoIcon /> Criteria Information
            </div>
          </BootstrapTooltip>
        </div>
        {Boolean(chosenChamberRoom) && selectedChamberTherapistsNotHavingSatelliteCall?.length > 0 && (
          <div className="text-warning" style={{ maxWidth: '300px', margin: 'auto' }}>
            <BootstrapTooltip title={tooltipCompNoSatelliteCallsWarn}>
              <div>
                <InfoIcon /> Satellite Call warning
              </div>
            </BootstrapTooltip>
          </div>
        )}
        {Boolean(chosenChamberRoom) && (
          <div style={{ maxWidth: '300px', margin: 'auto' }}>
            Sending request to eligible satellite therapists associated with{' '}
            {getChamberRoomTitle(chosenChamberRoom, allChamberRoomsOfThisClinicLocation)}
          </div>
        )}
        {Boolean(chosenChamberRoom) && Boolean(chosenStartTime) && (
          <div style={{ maxWidth: '300px', margin: 'auto' }}>
            Start time for session is {chosenStartTime.format('DD MMM h:mm A')}
          </div>
        )}
        {!Boolean(scheduleForThisClinicLocation) && (
          <div style={{ margin: '8px auto', maxWidth: '300px' }} className="text-danger">
            This clinic location does not have a valid schedule
          </div>
        )}
        <BookingRequestCreationButton
          onClick={createBookingRequest}
          disabled={
            !(
              Boolean(chosenChamberRoom) &&
              Boolean(chosenStartTime) &&
              Boolean(scheduleForThisClinicLocation)
            )
          }
        >
          Create Request{' '}
          {creatingRequestLoading && <Spinner animation="border" variant="light" size="sm" />}
        </BookingRequestCreationButton>
      </div>
    </div>
  )
}

///// UI:

export const ChamberRoomComponent = ({
  chamberRoomData,
  clickFunction,
  clinicLocationData,
  chosenChamberRoom,
}) => {
  const serviceProviders = useSelector(selectServiceProvidersData)

  const scheduleForThisRoom = getDefaultScheduleFromSchedules(clinicLocationData?.schedules)
  const availabilityOfDay = getAvailabilityForDayCalendar(
    moment().format('DD-MM-YYYY'),
    encodeURIComponent(moment().format('Z')),
    {
      timeZone: encodeURIComponent(moment().format('Z')),
      ...scheduleForThisRoom,
    },
  )
  const slotGroups = getSlotGroupsForChamberRoomDayCalendar(
    availabilityOfDay,
    30,
    encodeURIComponent(moment().format('Z')),
  )

  // console.log(slotGroups)

  return (
    <ChamberRoomParent
      onClick={() => clickFunction(chamberRoomData?.id)}
      selected={chosenChamberRoom === chamberRoomData?.id}
    >
      <div style={{ textAlign: 'center', borderBottom: '1px solid #f5f3f0', padding: '8px' }}>
        {chamberRoomData?.title || chamberRoomData?.id}
      </div>
      <div style={{ height: '100px', borderBottom: '1px solid #f5f3f0', padding: '8px' }}>
        {Array.isArray(chamberRoomData?.inPersonMentorsIds) &&
        chamberRoomData?.inPersonMentorsIds?.length > 0 ? (
          <div style={{ textAlign: 'center' }}>
            <div>Therapists/Doctors allowed:</div>
            <div>
              {chamberRoomData?.inPersonMentorsIds
                ?.map(
                  (mentorId) =>
                    serviceProviders?.find((item) => item?.id === mentorId)?.name || mentorId,
                )
                .join(', ')}
            </div>
          </div>
        ) : (
          <div>No providers are assocated with this chamber.</div>
        )}
      </div>
      <div>Coming Soon...</div>
    </ChamberRoomParent>
  )
}

////// extras:

const NoOptionsMessageComponent = (props) => {
  return (
    <components.NoOptionsMessage {...props}>
      Click on &apos;Add New&apos; Button to add new patient
    </components.NoOptionsMessage>
  )
}

export const getChamberRoomTitle = (chosenChamberRoomId, allChamberRoomsOfThisClinicLocation) => {
  const seletedChamber = allChamberRoomsOfThisClinicLocation?.find(
    (item) => item?.id === chosenChamberRoomId,
  )

  return seletedChamber?.title || chosenChamberRoomId
}

function getListOfTherapistsNotHavingSatellite(
  chosenChamberRoom,
  allChamberRoomsOfThisClinicLocation,
  serviceProviders,
  assistantIdInThisClinic,
  allChambersForThisAssistant,
  clinicLocationId,
) {
  const toReturn = []

  const allMentorIds = allChamberRoomsOfThisClinicLocation?.find(
    (item) => item?.id === chosenChamberRoom,
  )?.inPersonMentorsIds

  allMentorIds?.forEach((mentorId) => {
    if (
      !mentorHasClinicChamberWithThisAssistantInThisLocation(
        mentorId,
        assistantIdInThisClinic,
        clinicLocationId,
        allChambersForThisAssistant,
      )
    ) {
      toReturn.push(serviceProviders?.find((item) => item?.id === mentorId)?.name || mentorId)
    }
  })

  return toReturn
}

function mentorHasClinicChamberWithThisAssistantInThisLocation(
  mentorId,
  clinicAssistantId,
  clinicLocationId,
  allClinicChambers,
) {
  const foundClinicChamber = allClinicChambers?.find(
    (item) =>
      item?.mentorId === mentorId &&
      item?.assistantId === clinicAssistantId &&
      item?.clinicId === clinicLocationId,
  )
  return Boolean(foundClinicChamber)
}

function createMapChamberIdToData(allKeys, allDataArray, fieldNameInDataToCompare) {
  const toReturn = {}
  allKeys?.forEach((key) => {
    toReturn[key] = allDataArray?.filter((items) => items?.[fieldNameInDataToCompare] === key)
  })
  return toReturn
}
