import { ButtonBase, Drawer, IconButton, Paper, Switch, styled as muiStyled } from '@mui/material'
import styled from 'styled-components'
import Modal from 'styled-react-modal'

export const getGradient = (color) => {
  let newColor = color + '00'

  return `linear-gradient(180deg, ${newColor} 0%, ${color} 68.54%)`
}

export const DailyPlayColors = {
  // to stay
  activeSlotBlurColor: getGradient('#424572'),
  activeSlotProgressTrackColor: '#FFFFFF',
  activeSlotProgressFillColor: '#FD7600',
  activeSlotItemPlayButtonBgColor: '#FFFFFF',
  activeSlotMoreButtonBgColor: '#FF7E00',
  inactiveSlotHeadlineGemContainerColor: '#FFFFFF',
  inactiveSlotProgressTrackColor: '#FD760026',
  inactiveSlotProgressFillColor: '#FD7600',
  inactiveSlotSummaryUnderlineColor: '#FFFFFF',

  // to go
  activeSlotCongratulatoryCardBgColor: '#FFF1D9',
  activeSlotCongratulatoryCardBorderColor: '#FFE6BA',
  activeSlotCongratulatoryCardActionButtonBgColor: '#FFFFFF',
  activeSlotCompletedItemGemsContainerBgColor: '#490093',
  activeSlotCompletedItemGemsContainerBorderColor: '#7351AB',
  noActiveCardCallToActionButtonBgColor: '#7854A9',
}

export const DailyPlayContainer = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  padding: 50px 0;
  align-items: center;
`

export const SlotCardsParent = styled.div`
  flex: 0 0 auto;
  min-height: 720px;
  width: 100%;
  max-width: 1512px;
  position: absolute;
  top: 32px;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const CheatDayCard = styled.div`
  height: 778.28px;
  width: 453.13px;
  border-radius: 24px;
  background: ${({ theme }) => theme?.themeC1};
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  overflow: hidden;

  @media (max-height: 880px) {
    height: 600px;
  }

  @media (max-height: 780px) {
    height: 500px;
  }

  @media (min-width: 3160px) {
    height: 1017.23px;
  }

  @media (max-width: 1296px) {
    width: 350px;
  }

  @media (max-width: 520px) {
    width: 280px;
    height: 500px;
  }
`

export const CheatDayImage = styled.img`
  width: 100%;
`

export const PositionedStreakContainer = styled.div`
  width: 85px;
  position: absolute;
  left: 0;
  margin-top: auto;
  margin-bottom: auto;
  border-radius: 0 30px 30px 0;
  box-shadow: 0px -5px 16px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme?.themeC2p5};
  gap: 18px;
  padding-bottom: 11px;

  @media (max-height: 880px) {
    scale: 0.8;
    left: -15px;
  }

  @media (max-height: 780px) {
    scale: 0.6;
    left: -25px;
  }

  @media (min-width: 3160px) {
    scale: 1.5;
  }

  @media (max-width: 820px) {
    display: none;
  }
`

export const DayInStreakContainer = styled.div`
  height: ${(props) => (props?.active ? '69px' : '49px')};
  width: ${(props) => (props?.active ? '69px' : '49px')};
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

export const DayInStreakCircleBackground = styled.div`
  height: 89px;
  width: 89px;
  border-radius: 50%;
  background: ${({ theme }) => theme?.themeC2p5};
  position: relative;
  left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const GemImageStreak = styled.div`
  height: ${(props) => (props?.active ? '59px' : '49px')};
  width: ${(props) => (props?.active ? '59px' : '49px')};
  border-radius: 50%;
  cursor: pointer;
  background: ${({ theme }) => theme?.themeC1};
  display: flex;
  justify-content: center;
  align-items: center;
`

export const TasksLeftContainer = styled.div`
  height: 100%;
  width: 120px;
  display: flex;
  flex-direction: column;
  margin-right: 8px;
  justify-content: center;
`

export const TasksLeftProgressContainer = styled.div`
  width: 80%;
  margin: 10px 0;
  height: 4.42px;
  border-radius: 5px;
`

// connectors are being replaced with thread design

export const CardBodyConnectors = styled.div`
  @media (max-width: 1296px) {
    display: none;
  }
`

export const VerticalItemConnector = styled.div`
  height: 312.78px;
  width: 6.8px;
  background-color: ${({ theme }) => theme?.themeC7};
  z-index: 1;
  position: absolute;
  left: calc(50% - 222.5px);
  top: ${(props) => props.propheight};
  display: ${(props) => props.displayNone && 'none'};
`

export const HorizontalItemConnector = styled.div`
  height: 6.8px;
  width: 80px;
  background-color: ${({ theme }) => theme?.themeC7};
  z-index: 1;
  position: absolute;
  left: calc(50% - 222.5px);
  top: ${(props) => props.propheight};
`

// thread design styles

export const ThreadAndCardContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
`

export const ThreadAndFlagContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  position: absolute;
  top: 0;
  left: 0;
  @media (min-width: 1300px) {
    left: 50px;
  }
`

export const DotForCard = styled.div`
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background: ${(props) => (props?.completed ? '#24AE5F' : props?.theme?.themeC7)};
  flex-shrink: 0;
  position: relative;
  left: 2px;
  @media (min-width: 1300px) {
    width: 32px;
    height: 32px;
  }
`

export const ThreadForCard = styled.div`
  width: 5px;
  height: 100%;
  border-right: 2px ${(props) => (props?.completed ? 'solid' : 'dashed')}
    ${(props) => (props?.completed ? '#24AE5F' : props?.theme?.themeC7)};
`

// end

export const InactiveCard = styled.div`
  height: 537.32px;
  background: ${({ theme }) => theme?.themeC2p5};
  transition: all 0.5s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Outfit;
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
  color: #fff;
  padding: 15px 0;

  @media (max-height: 880px) {
    height: 550px;
  }

  @media (max-height: 780px) {
    height: 450px;
  }

  @media (max-width: 760px) {
    display: none;
  }
`

export const ClosedCardImgAndText = styled.div`
  margin: 0 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Outfit;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  gap: 5px;
`

export const EarnedGemsContainer = styled.div`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: none;
`

export const PrevCardGemContainer = styled.div`
  height: 66.4px;
  width: 74.52px;
  border-radius: 7px;
  background: ${({ theme }) => theme?.themeC1};
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

export const EarnedGemsText = styled.div`
  font-family: Outfit;
  font-size: ${(props) => (props?.main ? '22px' : '16px')};
  font-weight: ${(props) => (props?.main ? '600' : '300')};
  line-height: ${(props) => (props?.main ? '27.72px' : '39px')};
  text-align: center;
`

export const SlotTimeContainer = styled.div`
  gap: 5px;
  align-items: center;
  flex-direction: row;
  display: flex;

  @media (max-width: 1296px) {
    font-size: 18px;
  }
`

export const UnlockingText = styled.div`
  font-family: Outfit;
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
`

export const UnlockingTimeContainer = styled.div`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: none;
  gap: 5px;
`

export const CardOverviewContainer = styled.div`
  height: 55.07px;
  width: 243.38px;
  background-color: ${({ theme }) => theme?.themeC1};
  border-radius: ${(props) => (props?.next ? '11px 20px 20px 11px' : '20px 11px 11px 20px')};
  display: none;
  position: relative;
  padding: 0 14px;
  align-items: center;
  justify-content: ${(props) => (props?.next ? 'right' : 'left')};
  @media (max-width: 1296px) {
    width: 200px;
  }
`

export const GemContainer = styled.div`
  height: 55.07px;
  width: 61.13px;
  background: ${DailyPlayColors.inactiveSlotHeadlineGemContainerColor};
  border-radius: ${(props) =>
    props?.next ? '11px 27.539px 27.539px 11px' : '27.539px 11px 11px 27.539px'};
  position: absolute;
  ${(props) => props?.next && `left: 0;`};
  ${(props) => !props?.next && `right: 0;`};
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${(props) => (props?.next ? 'center' : 'flex-end')};
  padding: 0 6px;
  @media (max-width: 1296px) {
    width: 61.13px;
  }
`

export const PrevGemContainerText = styled.div`
  font-family: Outfit;
  font-size: ${(props) => (props?.main ? '16px' : '9px')};
  font-weight: ${(props) => (props?.main ? '700' : '400')};
  line-height: ${(props) => (props?.main ? '20px' : '11px')};
  text-align: ${(props) => (props?.next ? 'center' : 'right')};
  color: #7248ad;
`

export const CardOverviewTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props?.next ? 'flex-end' : 'flex-start')};
`

export const PrevSlotName = styled.div`
  font-family: Outfit;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
  @media (max-width: 1296px) {
    font-size: 12px;
  }
`

export const IconImage = styled.img`
  width: ${(props) => `${props?.w}px`};
  height: ${(props) => `${props?.h}px`};
  border-radius: ${(props) => `${props?.b}px`};
`

export const CardSummary = styled.div`
  height: 224.03px;
  width: 90%;
  border-radius: 17px;
  background: ${({ theme }) => theme?.themeC3};
  display: none;
  flex-direction: column;
  position: relative;
  @media (max-height: 880px) {
    height: 190px;
  }
`

export const SummaryTitle = styled.div`
  font-family: Outfit;
  font-size: ${(props) => (props?.item ? '13px' : '17px')};
  font-weight: ${(props) => (props?.item ? '500' : '600')};
  line-height: ${(props) => (props?.item ? '16.3px' : '21px')};
  color: #ffffff;
  margin: ${(props) => !props?.item && '10px auto 0px auto'};
  @media (max-height: 880px) {
    margin: ${(props) => !props?.item && '5px auto 0px auto'};
  }
`

export const SummaryUnderline = styled.div`
  height: 1px;
  width: 20%;
  margin: 5px auto 20px auto;
  background-color: ${DailyPlayColors.inactiveSlotSummaryUnderlineColor};
  @media (max-height: 880px) {
    margin: 5px auto 5px auto;
  }
`

export const SummaryItemsContainer = styled.div`
  width: 100%;
  height: 150px;
  overflow: auto;
  /* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`

export const SummaryItemEntry = styled.div`
  width: 100%;
  min-height: 50px;
  padding: 0 10px;
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 10px;
  cursor: ${(props) => (props?.next ? 'default' : 'pointer')};
`

export const SocialComparisonContainer = styled.div`
  flex-direction: column;
  gap: 2px;
  align-items: center;
  display: none;
`

export const LeftSideCard = styled(InactiveCard)`
  border-radius: 27px 0 0 27px;
  align-items: flex-start;
  width: 258px;
  position: absolute;
  right: calc(50% + 325px - 258px + 90px);

  &:hover {
    right: calc(50% + 325px);
    align-items: center;
    justify-content: space-between;
  }
  &:hover ${CardOverviewContainer} {
    display: flex;
  }
  &:hover ${CardSummary} {
    display: flex;
  }
  &:hover ${SocialComparisonContainer} {
    display: flex;
  }
  &:hover ${ClosedCardImgAndText} {
    display: none;
  }

  @media (max-width: 1296px) {
    right: calc(50% + 175px - 200px + 90px);
    &:hover {
      right: calc(50% + 175px);
    }
    width: 200px;
  }
`

export const RightSideCard = styled(InactiveCard)`
  border-radius: 0 27px 27px 0;
  align-items: flex-end;
  width: 258px;
  position: absolute;
  left: calc(50% + 325px - 258px + 90px);

  &:hover {
    left: calc(50% + 325px);
    align-items: center;
    justify-content: space-between;
  }
  &:hover ${CardOverviewContainer} {
    display: flex;
  }
  &:hover ${CardSummary} {
    display: flex;
  }
  &:hover ${UnlockingTimeContainer} {
    display: flex;
  }
  &:hover ${ClosedCardImgAndText} {
    display: none;
  }

  @media (max-width: 1296px) {
    left: calc(50% + 175px - 200px + 90px);
    &:hover {
      left: calc(50% + 175px);
    }
    width: 200px;
  }
`

export const CardBodyBlur = styled.div`
  position: absolute;
  bottom: 0px;
  height: 175px;
  width: 100%;
  background: ${DailyPlayColors?.activeSlotBlurColor};
  border-radius: 27px;
  z-index: 12;
`

export const MoreItemsButton = styled.div`
  height: 53.73px;
  width: 155.41px;
  border-radius: 30px;
  background: ${DailyPlayColors.activeSlotMoreButtonBgColor};
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: heroregular;
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  position: absolute;
  bottom: 37.64px;
  z-index: 13;
`

export const ActiveCardContainer = styled.div`
  height: 717.23px;
  width: 650px;
  border-radius: 27px;
  background-color: ${({ theme }) => theme?.themeC5};
  position: relative;
  display: flex;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.65) 0px 5px 15px;

  &:hover ${CardBodyBlur} {
    display: none;
  }

  &:hover ${MoreItemsButton} {
    display: none;
  }

  @media (max-height: 880px) {
    height: 600px;
  }

  @media (max-height: 780px) {
    height: 500px;
  }

  @media (min-width: 3160px) {
    height: 1017.23px;
  }

  @media (max-width: 1296px) {
    width: 350px;
  }

  @media (max-width: 520px) {
    width: 280px;
    height: 500px;
  }
`

export const CardHeader = styled.div`
  width: 95%;
  height: 65.56px;
  border-radius: 47px;
  background-color: ${({ theme }) => theme?.themeC1};
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 25px;
  color: #fff;
  padding: 0px 25px;
  margin: 14.5px auto 0px auto;

  @media (max-width: 1296px) {
    padding: 0px 5px;
    height: 45.56px;
  }
`

export const SlotEndsInText = styled.div`
  font-family: Outfit;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  text-align: center;
  @media (max-width: 1296px) {
    font-size: 9px;
  }
`

export const SlotEndsInTime = styled.div`
  font-family: Outfit;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
  @media (max-width: 1296px) {
    font-size: 9px;
    font-weight: 500;
    line-height: 15px;
  }
`

export const ScoreContainer = styled.div`
  height: 40.19px;
  min-width: 65.12px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-family: Outfit;
  font-size: 12px;
  font-weight: 400;
  line-height: 11px;
  color: #fff;
  @media (max-width: 1296px) {
    min-height: 30.19px;
    min-width: 45.12px;
    font-size: 10px;
    line-height: 8px;
  }
`

export const ScoreProgressContainer = styled.div`
  margin-top: 4px;
  width: ${(props) => (props?.prev ? '60%' : '80%')};
  height: 2px;
  border-radius: 9px;
  background-color: ${(props) =>
    props?.prev
      ? DailyPlayColors.inactiveSlotProgressTrackColor
      : DailyPlayColors.activeSlotProgressTrackColor};
`

export const ScoreProgressFilled = styled.div`
  background-color: ${(props) =>
    props?.prev
      ? DailyPlayColors.inactiveSlotProgressFillColor
      : DailyPlayColors.activeSlotProgressFillColor}; ;
`

export const CongratulatingCard = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 191.96px;
  width: 473.62px;
  border-radius: 11px;
  background: ${DailyPlayColors.activeSlotCongratulatoryCardBgColor};
  border: 2px solid ${DailyPlayColors.activeSlotCongratulatoryCardBorderColor};
  padding: 23.76px 6px 23.76px 26.14px;
  @media (max-width: 1296px) {
    width: 90%;
    padding: 16px 5px;
    min-height: 151.96px;
  }
`

export const CongratulatingCardTitle = styled.div`
  font-family: Outfit;
  font-size: ${(props) => (props?.main ? '25px' : '18px')};
  font-weight: ${(props) => (props?.main ? '600' : '300')};
  line-height: ${(props) => (props?.main ? '27px' : '18px')};
  color: ${(props) => (props?.main ? '#BC8E3D' : '#D8AB5D')};
  width: 244px;
  margin-bottom: 4.84px;
  @media (max-width: 1296px) {
    font-size: ${(props) => (props?.main ? '22px' : '15px')};
    font-weight: ${(props) => (props?.main ? '600' : '300')};
    line-height: ${(props) => (props?.main ? '24px' : '16px')};
    width: 200px;
  }
`

export const CongratulatingCardMaskot = styled.img`
  height: 89px;
  @media (max-width: 1296px) {
    height: 49px;
  }
  @media (max-width: 520px) {
    display: none;
  }
`

export const SuggestionCard = styled.div`
  height: 32.5498046875px;
  width: 200.5068359375px;
  border-radius: 26px;
  background: ${DailyPlayColors.activeSlotCongratulatoryCardActionButtonBgColor};
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  margin: 10px auto 0 auto;
  cursor: pointer;
  text-decoration: none;
  @media (max-width: 1296px) {
    margin: 30px auto 0 auto;
    width: 90%;
  }
`

export const SuggestionCardText = styled.div`
  font-family: Outfit;
  font-size: 12px;
  line-height: 13px;
  font-weight: ${(props) => (props?.main ? '600' : '300')};
  color: ${(props) => (props?.main ? '#51277E' : '#B4A893')};
`

export const CompletedItemCardContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr 5fr 2fr; /* Four columns with different sizes */
  gap: 10px;
  align-items: center;
  cursor: pointer;
  padding: 0 10px;
  width: 100%;
  @media (max-width: 520px) {
    grid-template-columns: 1fr 0.1fr 5fr 2fr; /* three columns with different sizes */
  }
`

export const CompletedItemCardContainerElement = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props?.title ? 'flex-start' : 'center')};
  justify-content: center;
`

export const CompletedCardItemPicture = styled.div`
  width: 126.56px;
  height: 126.56px;
  border-radius: 12px;
  background-color: ${(props) => props?.backgroundColor};
  ${(props) => props?.backgroundImage && `background: url(${props.backgroundImage});`};
  background-position: ${(props) => props?.itemType === 'quote' && 'right'};
  background-repeat: no-repeat;
  background-size: cover;
  filter: drop-shadow(3px 7px 5px rgba(0, 0, 0, 0.38));
  @media (max-width: 1296px) {
    flex: 0 0 76.56px;
    width: 76.56px;
    height: 76.56px;
  }
  @media (max-width: 520px) {
    display: none;
  }
`

export const CompletedCardTitle = styled.div`
  font-family: Outfit;
  font-size: ${(props) => (props?.sub ? '15px' : '25px')};
  font-weight: ${(props) => (props?.sub ? '300' : '600')};
  line-height: ${(props) => (props?.sub ? '18.9px' : '32px')};
  color: ${(props) => (props?.sub ? '#cccccc' : '#FFF')};
  @media (max-width: 1296px) {
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
  }
`

export const CompletedCardGemsContainer = styled.div`
  height: 33.57px;
  min-width: 69.53px;
  border-radius: 28px;
  background-color: ${DailyPlayColors.activeSlotCompletedItemGemsContainerBgColor};
  border: 1px solid ${DailyPlayColors.activeSlotCompletedItemGemsContainerBorderColor};
  font-family: Outfit;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  @media (max-width: 1296px) {
    height: 29.63px;
    min-width: 59.06px;
    border-radius: 16px;
    font-size: 16px;
    font-weight: 400;
  }
`

export const CardBody = styled.div`
  width: 90%;
  height: 100%;
  margin: 0 auto 0 auto;
  padding-top: 75px;
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 7.78px;
  overflow: auto;
  position: relative;

  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1);
    border-radius: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 5px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.2);
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
  // Hide scrollbar for IE, Edge and Firefox

  @media (max-height: 780px) {
    padding-top: 25px;
  }
  @media (max-width: 1300px) {
    padding-top: 25px;
  }
`

export const CardHeaderLeftIcon = styled.img`
  height: ${(props) => (props?.inactive ? '56px' : '30px')};
  @media (max-width: 520px) {
    height: 21px;
  }
`

export const DailyPlayButton = styled.div`
  display: flex;
  width: 150px;
  height: 40px;
  margin-top: 12px;
  background: ${DailyPlayColors.activeSlotItemPlayButtonBgColor};
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  font-family: 'heroregular';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.01em;
  color: #000000;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  @media (max-width: 520px) {
    width: 120px;
    height: 30px;
    font-size: 12px;
    line-height: 15px;
  }
`

export const PlayItemTitleContainer = styled.div`
  display: flex;
  width: 80%;
  flex-direction: column;
  align-items: center;
  font-family: Outfit;
  font-style: normal;
  font-weight: 400;
  color: #ffffff;
  position: absolute;
  bottom: 10%;
`

export const AppExclusiveContainer = styled.div`
  display: flex;
  width: 80%;
  flex-direction: column;
  align-items: center;
  position: absolute;
  bottom: 0;
  font-family: Outfit;
  font-weight: 600;
  font-size: 28px;
  line-height: 35px;
  text-align: center;
  color: #ffffff;
  transform: translateY(50px);
  transition: transform 0.5s ease-in-out;
  @media (max-width: 1296px) {
    font-size: 14px;
    line-height: 15px;
  }
`

export const AppExclusiveText = styled.div`
  background: rgba(0, 0, 0, 0.44);
  backdrop-filter: blur(8.5px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 25px;
  padding: 5px 10px;
  text-align: center;
  margin-top: 20px;
  font-size: 18px;
  display: flex;
  align-items: center;
  gap: 3px;
  @media (max-width: 1296px) {
    font-size: 14px;
    padding: 5px 8px;
  }
`

export const CardItemsContainer = styled.div`
  flex: 0 0 auto;
  width: 285px;
  height: 285px;
  border-radius: 18px;
  background-color: ${(props) => props?.backgroundColor};
  ${(props) =>
    props?.backgroundImage &&
    `background: url(${props.backgroundImage}), ${props?.backgroundColor}`};
  background-position: ${(props) => props?.itemType === 'quote' && 'right'};
  background-repeat: no-repeat;
  background-size: cover;
  filter: drop-shadow(3px 7px 5px rgba(0, 0, 0, 0.38));
  overflow: hidden;
  position: relative;
  display: flex;
  z-index: 5;
  justify-content: center;
  margin-bottom: 20px;
  &:hover {
    cursor: pointer;
  }
  &:hover ${AppExclusiveContainer} {
    transform: translateY(-20px);
  }
  &:hover ${DailyPlayButton} {
    opacity: 1;
  }

  @media (max-width: 1296px) {
    width: 220px;
    height: 220px;
  }

  @media (max-width: 520px) {
    width: 185px;
    height: 185px;
  }
`

export const CardItemDarkOverlay = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.05);
`

export const ItemIconImage = styled.img`
  height: 60px;
  margin-top: auto;
  margin-bottom: auto;
`

export const TopRightContainer = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  justify-content: right;
`

export const CompletedContainer = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: heroregular;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  gap: 5.63px;
  background: #00000063;
  color: #ffffff;
  padding: 9px 14px;
  border-radius: 28px;
`

export const TopRightBlurCircle = styled.div`
  width: 22px;
  height: 22px;
  background: #8d8d8d1f;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-weight: 600;
  font-size: 12px;
  border-radius: 50%;
  box-shadow: 2px 3px 10px rgba(0, 0, 0, 0.47);
`

export const TopLeftContainer = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  display: flex;
  align-items: center;
`

export const CircularContainer = styled.div`
  height: ${(props) => (props?.big ? '30.16px' : '22.73px')};
  width: ${(props) => (props?.big ? '30.16px' : '22.73px')};
  border-radius: 50%;
  border: 2px solid #ffffff;
  background: ${(props) => (props?.picture ? `url(${props?.picture})` : '#000')};
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-weight: 200;
  font-size: ${(props) => (props?.big ? '10px' : '6px')};
  position: relative;
  right: ${(props) => props?.num && `${props?.num * 4}px`};
`

export const StartedText = styled.div`
  font-family: Outfit;
  font-size: 10px;
  font-weight: 600;
  position: relative;
  background: #fff;
  padding: 4px 2px;
  position: relative;
  right: 18px;
  border-radius: 13px;
  color: ${({ theme }) => theme?.themeC1};
  letter-spacing: 1px;
  @media (max-width: 520px) {
    display: none;
  }
`

export const QuoteCardHeadingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  left: 20px;
  top: 0;
  bottom: 0;
`

export const QuoteCardHeading = styled.div`
  font-family: Outfit;
  font-size: 27px;
  font-weight: 700;
  line-height: 34px;
  color: #ffffff;
  @media (max-width: 1296px) {
    font-size: 20px;
    font-weight: 500;
    line-height: 25px;
  }
`

export const QuoteCardSubheading = styled.div`
  font-family: Outfit;
  font-size: 12px;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: 0.06em;
  color: #ffffff;
  @media (max-width: 1296px) {
    line-height: 27px;
  }
`

export const CheatCardHeading = styled.div`
  width: 100%;
  font-family: Outfit;
  font-size: ${(props) => (props?.small ? '36px' : '52px')};
  font-weight: ${(props) => (props?.small ? '700' : '500')};
  line-height: ${(props) => (props?.small ? '60px' : '41px')};
  text-align: center;
  color: #fff;
`

export const CheatCardText = styled.div`
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  color: #bc91eb;
`

export const NoActiveCardItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
  height: 100%;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
`

export const NoActiveCardText = styled.div`
  font-family: Outfit;
  font-size: ${(props) => (props?.small ? '16px' : '36px')};
  font-weight: ${(props) => (props?.small ? '300' : '700')};
  line-height: ${(props) => (props?.small ? '20.6px' : '45px')};
  color: ${(props) => (props?.small ? '#45286D' : '#FFF')};
  text-align: center;
  margin: ${(props) => !props?.small && '25px 0 70px 0'};
`

export const NoActiveCardItemsPicture = styled.img`
  width: 100px;
  height: 100px;
  @media (min-width: 768px) {
    width: 150px;
    height: 150px;
  }
`

export const BrowseAppButton = styled.button`
  height: 55.84423828125px;
  width: 233.5390625px;
  border-radius: 40px;
  background: ${DailyPlayColors.noActiveCardCallToActionButtonBgColor};
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Outfit;
  font-size: 21px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
  color: #fff;
  margin-top: 10px;
`

export const ActiveCardEndItem = styled.div`
  position: relative;
  z-index: 2;
  min-height: 178.12px;
  min-width: 90%;
  background: ${({ theme }) => theme?.themeC2};
  border-radius: 22px;
  padding: 5%;
  box-shadow: 1px 2px 12px rgba(0, 0, 0, 0.16);
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const ActiveCardEndItemText = styled.div`
  width: 100%;
  font-family: Outfit;
  font-size: ${(props) => (props?.heading ? '31px' : '17px')};
  font-weight: ${(props) => (props?.heading ? '700' : '300')};
  line-height: 40px;
  color: ${(props) => (props?.heading ? '#FFF' : '#E0C9FF')};
  text-align: left;
  @media (max-width: 1296px) {
    font-size: ${(props) => (props?.heading ? '21px' : '14px')};
    line-height: 30px;
  }
`

export const ActiveCardEndItemImage = styled.img`
  position: absolute;
  right: 10px;
  top: 0;
  bottom: 0;
  margin: auto 0;
  width: 100px;
  @media (max-width: 1296px) {
    width: 70px;
  }
`
/// daily play settings :

export const StyledDailyPlaySettingsModal = Modal.styled`
  width: 90vw;
  height: 90vh; 
  background: ${({ theme }) => theme?.themeC4};
  opacity: ${(props) => props.opacity};
  transition : all 0.3s ease-in-out;
  position: relative;
  border-radius: 30px;
`

export const DailyPlaySettingsDrawer = styled(Drawer)`
    display: flex;
    justify-content: center;
    // backdrop-filter: blur(1px);
    background-color: rgba(106, 109, 210, 0.2);
    .MuiPaper-root {
      height: 90vh;
      width: 100%;
      left: auto;
      right: auto;
      background: ${({ theme }) => theme?.themeC4};
      background-size: 100% 100%;
      border-radius: 25px 25px 0px 0px;
`

export const DailyPlaySettingsContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  padding: 23px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 21px;
  overflow: auto;
  /* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  @media (min-width: 1300px) {
    padding: 24px 35px;
    gap: 35px;
  }
`

export const DailyPlaySettingsHeading = styled.div`
  cursor: default;
  color: ${(props) => (props?.main ? '#FFF' : '#c1c1c1')};
  font-family: Outfit;
  text-align: center;
  font-size: ${(props) => (props?.main ? '26px' : '14px')};
  font-style: normal;
  font-weight: ${(props) => (props?.main ? '600' : '300')};
  line-height: normal;
  ${(props) => !props?.main && 'margin-top: 3px'};
  @media (min-width: 1300px) {
    font-size: ${(props) => (props?.main ? '28px' : '16px')};
    ${(props) => !props?.main && 'margin-top: 4px'};
  }
`

export const DailyPlaySettingsCloseButton = styled.img`
  position: absolute;
  top: 23px;
  right: 23px;
  width: 18px;
  cursor: pointer;
  @media (min-width: 1300px) {
    width: 24px;
    right: 35px;
  }
`

export const DailyPlaySettingsContentContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  flex-shrink: 0;
  @media (min-width: 800px) {
    flex-direction: row;
  }
`

export const DailyPlaySettingContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
`

export const DailyPlaySettingContentItemContainer = styled.div`
  width: 100%;
  border-radius: 14px;
  background: ${({ theme }) => theme?.themeC2};
  padding: 21px 26px;
  display: flex;
  flex-direction: column;
  @media (min-width: 1300px) {
    padding: 21px 29px;
  }
`

export const IosSwitch = muiStyled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 32,
  height: 17,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 3,
    transitionDuration: '400ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 11,
    height: 11,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}))

export const DailyPlaySettingPlayTypeContainer = styled.div`
  background: ${({ theme }) => theme?.themeC4};
  width: 100%;
  border-radius: 10px;
  margin: 5px 0;
  padding: 15px;
  cursor: pointer;
`

export const DisplayFlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`

export const EnableDisableDot = styled.div`
  width: 19px;
  height: 19px;
  flex-shrink: 0;
  border-radius: 50%;
  background: ${(props) => (props?.enabled ? '#73e857' : '#d9d9d9')};
`

export const DailyPlaySettingPlayTypeText = styled.div`
  font-family: Outfit;
  font-style: normal;
  line-height: normal;
  color: ${(props) => (props?.main ? '#FFF' : '#BBB')};
  font-size: ${(props) => (props?.main ? '18px' : '9px')};
  font-weight: ${(props) => (props?.main ? '700' : '300')};
  @media (min-width: 800px) {
    font-size: ${(props) => (props?.main ? '22px' : '14px')};
  }
`

export const PreferencesButton = styled.button`
  background: ${({ theme }) => theme?.themeC1};
  color: #fff;
  font-size: 20px;
  font-family: Outfit;
  font-weight: 500;
  border-radius: 40px;
  padding: 5px 20px;
  display: flex;
  justify-content: center;
  gap: 4px;
  align-items: center;
`

// mood card styles:

export const MoodCardContainer = styled.div`
  border-radius: 13px;
  background: #424572;
  box-shadow: 1px 3px 9px 0px rgba(0, 0, 0, 0.25);
  padding: 20px 5px;
  width: 185px;
  margin-bottom: 20px;
  @media (min-width: 520px) {
    width: 220px;
  }
  @media (min-width: 1296px) {
    padding: 25px 5px;
    width: 285px;
  }
`

export const MoodCardQuestion = styled.div`
  color: #fff;
  text-align: center;
  font-family: Outfit;
  font-size: 14px;
  line-height: 15px;
  cursor: default;
  @media (min-width: 1296px) {
    font-size: 28px;
    line-height: 35px;
  }
`

export const MoodCardOptionImage = styled.img`
  width: 30px;
  cursor: pointer;
  @media (min-width: 1296px) {
    width: 45px;
  }
`

export const MoodCardAnswer = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  height: 100%;
`

export const MentalStateTypeQuestion = styled.div`
  color: #fff;
  text-align: center;
  font-family: Outfit;
  font-size: 14px;
  cursor: default;
  @media (min-width: 768px) {
    font-size: 22px;
  }
  @media (min-width: 1080px) {
    font-size: 28px;
  }
`

export const MentalStateTypeModalMUIPaper = muiStyled(Paper)(
  ({ theme, opacity }) => `
    background: #424572;
    max-width: 400px !important;
    max-height: 165px !important;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    margin: 0!important;
    border-radius: 36px;
    opacity: ${opacity};
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    padding:20px;
    @media(min-width: 768px){
        max-width: 500px !important;
        max-height: 200px !important;
    }
  `,
)

export const MentalStateTypeButton = muiStyled(ButtonBase)(
  ({ theme }) => `
        font-family: 'Outfit';
        font-size: 14px;
        font-weight: 500;
        width: 100%;
        height: 100%;
        max-width: 150px;
        max-height: 40px;
        display: flex;
        justify-content: center;
        align-items:center;
        align-self: center;
        border-radius: 8px;
        background: #fff;
        color: #000;
        border: 1px #000 solid;
        transition: all 400ms ${theme.transitions.easing.quickInOut};
        gap: 8px;
        @media (min-width: 768px) {
            font-size: 18px;
            max-width: 130px;
            max-height: 50px !important;
        }
        &:hover{
            background: #d9dff0;
            border: 1px #d9dff0 solid;
        }
  `,
)

export const MentalStateTypeBackButtonContainer = muiStyled(IconButton)(
  ({ theme }) => `

`,
)
