import moment from 'moment'
import 'react-quill/dist/quill.bubble.css'
import { withAsyncPaginate } from 'react-select-async-paginate'
import Creatable from 'react-select/creatable'
import { getCPDataWithoutParams } from 'src/clinic/services'
import { GENDER_LIST_ONBOARDING } from 'src/consumer/constants'
import { getAgeFromDobWithTime } from 'src/consumer/helpers/profilePageHelper'
import { getParseDataFromHtmlString } from 'src/consumer/helpers/utilFunctions'
import styled from 'styled-components'
import {
  PMPHeaderDetails,
  PMPHeadingBanner,
  PMPSectionTextAreaBox,
} from '../PrescriptionMakingPage/PrescriptionMakingPageStyles'
import { getSectionsAndQuestionsBasedOnFilledAnswers } from './util'
import PdfReportSectionHandler from './pdfReport/PdfReportSectionHandler'

export function getDateOfScreeningReport(screeningReportData, withTime) {
  let format = 'DD/MM/YYYY'
  if (withTime) {
    format = 'DD/MM/YYYY h:mmA'
  }
  if (screeningReportData?.createdTime) {
    return moment
      .utc(
        screeningReportData?.createdTime || screeningReportData?.modifiedTime,
        'YYYY-MM-DD HH:mm:ss',
      )
      .local()
      .format(format)
  }
  return moment().format(format)
}

export const ScreeningReportHeader = ({
  screeningReportData,
  screenerName,
  doctorName,
  timesVisit,
  clinicBrand,
}) => {
  return (
    <div style={{ background: '#FFF' }}>
      <PMPHeadingBanner noColor={true}>Screening Report</PMPHeadingBanner>
      <PMPHeaderDetails noColor={true}>
        <div>
          <b>Patient Name:</b>{' '}
          <span style={{ textTransform: 'capitalize' }}>{screeningReportData?.patientName}</span>
        </div>
        <div>
          <b>Age/Gender:</b> {getAgeFromDobWithTime(screeningReportData?.patientDateOfBirth)}/
          {
            GENDER_LIST_ONBOARDING?.find(
              (item) => item.value === screeningReportData?.patientGender,
            )?.label
          }
        </div>
        <div>
          <b>Date :</b> {getDateOfScreeningReport(screeningReportData, true)}
        </div>
        {clinicBrand && (
          <div>
            <span>
              <b>Clinic Brand : </b>
              {clinicBrand}
            </span>
          </div>
        )}
        {doctorName && (
          <div>
            <span>
              <b>Screening consulted for : </b>
              {doctorName}
            </span>
          </div>
        )}
        {screenerName && (
          <div>
            <span>
              <b>Screened by : </b>
              {screenerName}
            </span>
          </div>
        )}
        {!!timesVisit && (
          <div>
            <span>
              <b>Visits: </b>
              {timesVisit}
            </span>
          </div>
        )}
      </PMPHeaderDetails>
    </div>
  )
}

export const ScreeningReportBodyFirstSection = ({
  observationAndAdvice,
  selectedComplaints,
  selectedDiagnosis,
  screeningQuestionAnswers,
  screeningSections,
}) => {
  const sectionsWithAnswers = getSectionsAndQuestionsBasedOnFilledAnswers({
    sections: screeningSections,
    answers: screeningQuestionAnswers,
  })

  console.log({ sectionsWithAnswers })

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', paddingTop: '10px' }}>
      {sectionsWithAnswers?.map((section) => (
        <PdfReportSectionHandler key={section?.id} section={section} />
      ))}
      {observationAndAdvice?.observation && (
        <div>
          <div style={{ fontWeight: '600' }}>Observations</div>
          <div style={{ whiteSpace: 'pre-wrap' }}>{observationAndAdvice?.observation}</div>
        </div>
      )}
      {Array.isArray(selectedComplaints) && selectedComplaints?.length > 0 && (
        <div>
          <div style={{ fontWeight: '600' }}>Complaints</div>
          <div>{selectedComplaints.map((complaint) => complaint.label).join(', ')}</div>
        </div>
      )}
      {Array.isArray(selectedDiagnosis) && selectedDiagnosis?.length > 0 && (
        <div>
          <div style={{ fontWeight: '600' }}>Diagnosis</div>
          <div>{selectedDiagnosis.map((diagnosisItem) => diagnosisItem.label).join(', ')}</div>
        </div>
      )}
    </div>
  )
}

export const ScreeningReportBody = ({ observationAndAdvice, content, suggestedBookingTherapy }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', paddingTop: '20px' }}>
      {observationAndAdvice?.advice && (
        <div>
          <div style={{ fontWeight: '500', fontStyle: 'italic' }}>Advice</div>
          <div style={{ whiteSpace: 'pre-wrap' }}>{observationAndAdvice?.advice}</div>
        </div>
      )}
      {content && (
        <div>
          <div style={{ fontWeight: '500', fontStyle: 'italic' }}>Notes</div>
          <div>
            <PrescriptionWrapperForRichText>
              {getParseDataFromHtmlString(content, { format: false })}
            </PrescriptionWrapperForRichText>
          </div>
        </div>
      )}
      {suggestedBookingTherapy && (
        <div style={{ fontWeight: '500', fontStyle: 'italic', background: 'yellow' }}>
          Therapy Recommended (and requested): {suggestedBookingTherapy ? 'Yes' : 'No'}
        </div>
      )}
    </div>
  )
}

const commonStyles = {
  menuList: (provided, state) => ({
    ...provided,
    maxHeight: '200px',
    overflowY: 'scroll',
    scrollbarWidth: 'thin',
    '::-webkit-scrollbar': {
      width: '5px',
    },
    '::-webkit-scrollbar-track': {
      background: '#fafafa',
    },
    '::-webkit-scrollbar-thumb': {
      background: '#888',
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
  }),
  menu: (provided, state) => ({
    ...provided,
    borderRadius: 0,
  }),
  control: (provided, state) => ({
    ...provided,
    borderRadius: 0,
    backgroundColor: state.menuIsOpen ? '#fff8dc' : '#FFF',
    border: state.isFocused ? '1px solid green' : provided.border,
    boxShadow: 'none',
    '&:hover': {
      border: '1px solid green',
    },
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none', // Hide the default arrow separator
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    display: 'none', // Hide the default dropdown arrow
  }),
}

export const ScreeningReportTextAreaComponent = ({ inputValue, setInputValue }) => {
  return (
    <PMPSectionTextAreaBox value={inputValue} onChange={(e) => setInputValue(e.target.value)} />
  )
}

const loadOptionsComplaints = async (search, loadedOptions, { page, clinicId }) => {
  const response = await getCPDataWithoutParams(
    `/api/secure/doctor/health-complaint/list?search=${search}&clinicId=${clinicId}&page=${page}&pageSize=5`,
  )
  var hasMore = true
  var optionsArray = []

  if (response.status === 200 && Array.isArray(response.data) && response.data.length > 0) {
    optionsArray = response?.data?.map((option, index) => {
      return {
        ...option,
        value: option?.title,
        label: option?.title,
      }
    })
  } else {
    hasMore = false
  }

  return {
    options: optionsArray,
    hasMore,
    additional: {
      page: page + 1,
      clinicId,
    },
  }
}

const loadOptionsDiagnosis = async (search, loadedOptions, { page, clinicId }) => {
  const response = await getCPDataWithoutParams(
    `/api/secure/doctor/diagnosis/list?search=${search}&clinicId=${clinicId}&page=${page}&pageSize=5`,
  )
  var hasMore = true
  var optionsArray = []

  if (response.status === 200 && Array.isArray(response.data) && response.data.length > 0) {
    optionsArray = response?.data?.map((option, index) => {
      return {
        ...option,
        value: option?.title,
        label: option?.title,
      }
    })
  } else {
    hasMore = false
  }

  return {
    options: optionsArray,
    hasMore,
    additional: {
      page: page + 1,
      clinicId,
    },
  }
}

export const DiagnosisSelectMenu = ({ clinicId, selectedDiagnosis, setSelectedDiagnosis }) => {
  const CreatableAsyncPaginate = withAsyncPaginate(Creatable)

  return (
    <div style={{ width: '100%' }}>
      <CreatableAsyncPaginate
        id="diagnosisSelection"
        placeholder=""
        isMulti={true}
        isClearable={true}
        isSearchable={true}
        defaultOptions={false}
        loadOptions={loadOptionsDiagnosis}
        getOptionValue={(option) => option?.value}
        getOptionLabel={(option) => option?.label}
        value={selectedDiagnosis}
        onChange={(value) => {
          setSelectedDiagnosis(value)
        }}
        debounceTimeout={500}
        additional={{ page: 1, clinicId: clinicId }}
        styles={commonStyles}
      />
    </div>
  )
}

///// helper functions:

// complaints array related:

export function getComplaintsArrayForSubmission(complaintsArray, clinicId) {
  const toReturn = []
  if (Array.isArray(complaintsArray) && complaintsArray.length > 0) {
    complaintsArray?.forEach((complaintItem) => {
      if (complaintItem?.label) {
        toReturn.push({
          apiParam: 'health-complaint',
          clinicId: clinicId,
          title: complaintItem?.label,
        })
      }
    })
  }
  return toReturn
}

export const getSRInitialDataForSettingExistingSelectedComplaints = (complaintsFromServer) => {
  const toReturn = []
  if (Array.isArray(complaintsFromServer) && complaintsFromServer.length > 0) {
    complaintsFromServer?.forEach((item) => {
      toReturn.push({ label: item?.title, value: item?.title })
    })
  }
  return toReturn
}

// diagnosis array related:

export function getDiagnosisArrayForSubmission(diagnosisArray, clinicId) {
  const toReturn = []
  if (Array.isArray(diagnosisArray) && diagnosisArray.length > 0) {
    diagnosisArray?.forEach((diagnosisItem) => {
      if (diagnosisItem?.label) {
        toReturn.push({
          apiParam: 'diagnosis',
          clinicId: clinicId,
          title: diagnosisItem?.label,
        })
      }
    })
  }
  return toReturn
}

export const getSRInitialDataForSettingExistingSelectedDiagnosis = (diagnosisFromServer) => {
  const toReturn = []
  if (Array.isArray(diagnosisFromServer) && diagnosisFromServer.length > 0) {
    diagnosisFromServer?.forEach((item) => {
      toReturn.push({ label: item?.title, value: item?.title })
    })
  }
  return toReturn
}

// next date related

export const calculateNextVisitDate = (nextVisitInputValue, nextVisitInputType, format) => {
  const currentDate = moment() // Get the current date

  // Map the input type to the corresponding moment method
  const methodMap = {
    days: 'add',
    months: 'add',
    weeks: 'add',
  }

  // Calculate the next visit date based on input type and value
  if (methodMap[nextVisitInputType]) {
    currentDate[methodMap[nextVisitInputType]](nextVisitInputValue, nextVisitInputType)
  } else {
    console.error('Invalid input type')
  }

  if (format) {
    return currentDate.format(format)
  }
  // Return the formatted date (you can adjust the format based on your needs)
  return currentDate.format('DD/MM/YYYY')
}

export const getNextVisitDateForSubmission = (
  nextVisitInputValue,
  nextVisitInputType,
  nextVisitCalendarInput,
) => {
  const currentDate = moment() // Get the current date

  // Map the input type to the corresponding moment method
  const methodMap = {
    days: 'add',
    months: 'add',
    weeks: 'add',
  }

  // Calculate the next visit date based on input type and value
  if (methodMap[nextVisitInputType]) {
    currentDate[methodMap[nextVisitInputType]](nextVisitInputValue, nextVisitInputType)
  } else {
    console.error('Invalid input type')
  }
  return currentDate?.format('YYYY-MM-DDTHH:mm:ss[Z]')
}

export const getInitialDataForSettingDaysOfNextVisit = (nextDate, createdTime) => {
  if (nextDate) {
    if (createdTime) {
      return moment?.(nextDate)?.diff(moment(createdTime, 'YYYY-MM-DD HH:mm:ss'), 'days')
    } else {
      return moment?.(nextDate)?.diff(moment(), 'days')
    }
  }
  return null
}

export const PrescriptionWrapperForRichText = styled.div`
  position: relative;
  width: 100%;
  ${(props) => props?.premium && 'height: 300px; overflow: hidden;'};
  color: #242424;
  font-size: 13px;
  p {
    font-size: 1rem;
    strong {
      font-weight: 500;
    }
  }
  h1 {
    font-size: 1.375rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
    line-height: 1.2;
  }
  h2 {
    font-size: 1.325rem;
    font-weight: 500;
    line-height: 1.2;
    margin-bottom: 0.5rem;
  }
  h3 {
    font-size: 1.3rem;
    font-weight: 500;
    line-height: 1.2;
    margin-bottom: 0.5rem;
  }
  h4 {
    font-size: 1.275rem;
    font-weight: 500;
    line-height: 1.2;
    margin-bottom: 0.5rem;
  }
  h5 {
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.2;
    margin-bottom: 0.5rem;
  }
  h6 {
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.2;
    margin-bottom: 0.5rem;
  }
  img {
    margin: auto;
    border: 1px solid #f4f4f4;
  }
  a {
    font-size: 1rem;
  }
  img {
    width: 100%;
  }
  blockquote {
    font-size: 1rem;
    margin-bottom: 1rem;
    padding-left: 5px;
  }
  ol {
    list-style-type: decimal;
    padding-left: 20px;
    margin: 16px 0;
  }
  ul {
    list-style-type: disc;
    padding-left: 20px;
    margin: 16px 0;
  }
  li {
    font-size: 1rem;
  }
  a {
    color: #2819af;
    text-decoration: underline;
  }
  p,
  strong,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  img,
  a,
  blockquote,
  ol,
  ul,
  li {
    font-size: 13px;
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
  }

  @media (min-width: 1200px) {
    font-size: 13px;
    p {
      strong {
        font-weight: 500;
      }
    }
    h1 {
      font-size: 2.5rem;
      font-weight: 500;
      margin-bottom: 0.5rem;
      line-height: 1.2;
    }
    h2 {
      font-size: 2rem;
      font-weight: 500;
      line-height: 1.2;
      margin-bottom: 0.5rem;
    }
    h3 {
      font-size: 1.75rem;
      font-weight: 500;
      line-height: 1.2;
      margin-bottom: 0.5rem;
    }
    h4 {
      font-size: 1.5rem;
      font-weight: 500;
      line-height: 1.2;
      margin-bottom: 0.5rem;
    }
    p,
    strong,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    img,
    a,
    blockquote,
    ol,
    ul,
    li {
      font-size: 13px;
      margin-top: 0;
      margin-bottom: 0;
      padding-top: 0;
      padding-bottom: 0;
    }
  }
`
