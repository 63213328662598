import { Dialog } from '@mui/material'
import { LocalizationProvider as MuiLocalizationProvider } from '@mui/x-date-pickers'
// import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { isSupported } from 'firebase/messaging'
import moment from 'moment'
import React, { Suspense, useContext, useEffect, useState } from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { BrowserRouter, Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { ThemeProvider } from 'styled-components'
import { v4 as uuidv4 } from 'uuid'
import { analytics } from './analytics'
import { default as BusinessProtectedRoute } from './business/views/pages/login/ProtectedRoute'
import AppUpdatePopup from './components/AppUpdate/AppUpdatePopup'
import FloatingIntercomButton from './components/FloatingIntercom/FloatingIntercomButton'
import { EmptyLoadingScreen, SplashScreen } from './consumer/components/Loaders'
import { bookedPageViewTypes } from './consumer/components/MyLiveSessions/my-live-sessions-utils'
import { PushNotificationComponent } from './consumer/components/PushNotificationsContent/PushNotificationComponent'
import { StyledPushNotificationDialog } from './consumer/components/PushNotificationsContent/styledPushNotificationsComponent'
import MedicalBoardMemberPage from './consumer/components/ServiceTermsAndLegal/MedicalBoardMemberPage'
import { APP_CONSTANTS, BOOKING_REQUEST_STATUS, STORAGE_KEYS } from './consumer/constants'
import {
  LocalizationContext,
  LocalizationContextProvider,
} from './consumer/context/LocalizationContext'
import { NotificationContextProvider } from './consumer/context/NotificationContextProvider'
import { ProfileContext, ProfileContextProvider } from './consumer/context/ProfileContext'
import {
  ThemeDataContext,
  ThemeDataContextProvider,
} from './consumer/context/ThemeDataContextProvider'
import {
  UnsecureSetupContext,
  UnsecureSetupContextProvider,
} from './consumer/context/UnsecureSetupContext'
import { SEOComponent } from './consumer/helpers/utilFunctions'
import ConsumerLayout from './consumer/layout/ConsumerLayout'
import { GenericHideChildrenOnPrintWrapper } from './consumer/layout/ConsumerLayoutStyles'
import { getBookingRequestsForMentor } from './consumer/services/featureServices'
import { setSessionCreationTime, setSessionId } from './consumer/services/localstorageServices'
import { AuthContextProvider } from './context/AuthContext'
import { FCMContext, FCMProvider, setNotificationsAreDeniedLocal } from './context/FCMProvider'
import { isAppRunningInProduction } from './helper'
import {
  getImageLink,
  isCommonResponseSuccessful,
  isValidHttpUrl,
} from './mentor/helpers/utilityFunctions'
import './scss/style.scss'
import ProtectedRoute from './views/pages/login/ProtectedRoute'
import AuthorityCheck from './views/pages/login/authorityCheck'
// Containers
const TermsPage = React.lazy(() => import('./consumer/components/ServiceTermsAndLegal/TermsPage'))
const GuestMemberProfile = React.lazy(() =>
  import('./consumer/components/MentalHealthMedicalBoardComponents/boardMember/GuestMemberProfile'),
)
const PrivacyPolicyOne = React.lazy(() =>
  import('./consumer/components/ServiceTermsAndLegal/PrivacyPolicyOne'),
)
const AboutPage = React.lazy(() => import('./consumer/components/ServiceTermsAndLegal/AboutPage'))
const PrivacyPage = React.lazy(() =>
  import('./consumer/components/ServiceTermsAndLegal/PrivacyPage'),
)
const CopyOfPrivacyPolicy = React.lazy(() =>
  import('./consumer/components/ServiceTermsAndLegal/CopyOfPrivacyPolicy'),
)
const MentalHealthMedicalBoard = React.lazy(() =>
  import('./consumer/components/ServiceTermsAndLegal/MentalHealthMedicalBoard'),
)
const LegalPage = React.lazy(() => import('./consumer/components/ServiceTermsAndLegal/LegalPage'))

const BookingSuccessfulPage = React.lazy(() =>
  import('./consumer/components/BookingSuccessfulPage'),
)

const CookiePolicy = React.lazy(() =>
  import('./consumer/components/ServiceTermsAndLegal/CookiePolicy'),
)
const InstructorTerms = React.lazy(() =>
  import('./consumer/components/ServiceTermsAndLegal/InstructorTerms'),
)

const ManageDataAndAccount = React.lazy(() =>
  import('./consumer/components/ManageAccountData/ManageDataAndAccount'),
)
const GiftCard = React.lazy(() => import('./GiftCard/components/GiftCardHomepage'))
const GiftCardRedeem = React.lazy(() => import('./GiftCard/components/GiftCardRedeem'))
// const ProfileLayout = React.lazy(() => import('./layout/ProfileLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/signIn'))
const MobileSignIn = React.lazy(() => import('./consumer/components/SignIn/MobileSignIn'))
const ForgotPassword = React.lazy(() => import('./views/pages/login/forgotPassword'))
const ChangePassword = React.lazy(() => import('./views/pages/login/changePassword'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))
const Onboarding = React.lazy(() => import('./consumer/components/Onboarding'))
const HelpCenter = React.lazy(() => import('./consumer/components/HelpCenter'))
const DownloadAppPage = React.lazy(() => import('./consumer/components/DownloadApp/index'))
const LeaderboardPageWebview = React.lazy(() =>
  import('./consumer/components/LeaderboardPage/indexWebview'),
)
const MyLiveSessionsWrapperForWebview = React.lazy(() =>
  import('./consumer/components/MyLiveSessions/MyLiveSessionsWrapperForWebview'),
)

const PatientHomeWrapperForWebview = React.lazy(() =>
  import('./consumer/components/TilesPagePatient/PatientHomeWrapperForWebview'),
)

const HelpCenterCategoryPage = React.lazy(() =>
  import('./consumer/components/HelpCenter/HelpCenterCategoryPage'),
)

const ClinicOwnerLayout = React.lazy(() => import('src/clinic/layout/ClinicOwnerLayout'))
const CALayout = React.lazy(() => import('src/clinic/layout/CALayout'))
const CSLayout = React.lazy(() => import('src/chamberScreen/layout/CSLayout'))
// more layouts here: pharmacist, therapist, doctor
const MentorLayout = React.lazy(() => import('src/mentor/layout'))
const BusinessLayout = React.lazy(() => import('src/business/layout/BusinessLayout'))

//activity plan pages
const ActivityPlanPageWebView = React.lazy(() =>
  import('./consumer/components/ActivityPlansPage/ActivityPlanWebViewWrapper'),
)

const ActivityPlanListingPageWebview = React.lazy(() =>
  import('./consumer/components/ActivityPlanPublicListing/ActivityPlanPublicListing'),
)

//User activity plan pages

const UserActivityPageWebview = React.lazy(() =>
  import('./consumer/components/UserActivityPage/UserActivityWebviewWrapper'),
)

const UserActivityListingPageWebview = React.lazy(() =>
  import('./consumer/components/UserActivityListingPage/UserActivityListingPageWebviewWrapper'),
)

const App = () => {
  // Calling Analytics Tool
  useEffect(() => {
    analytics(window, document, 'script', 'dataLayer', 'GTM-PB5QCGXH')
  }, [])

  // local and session storage setting
  const restApiURL = localStorage.getItem(STORAGE_KEYS.restApiURL)
  if (restApiURL && isValidHttpUrl(restApiURL)) {
  } else {
    if (isAppRunningInProduction()) {
      localStorage.setItem(STORAGE_KEYS.restApiURL, APP_CONSTANTS.API_CALLS_DOMAIN)
    } else {
      localStorage.setItem(STORAGE_KEYS.restApiURL, APP_CONSTANTS.API_CALLS_PRE_PRD_DOMAIN)
    }
    window.location.reload()
  }

  // making countrycode lowercase if it is not already
  useEffect(() => {
    let countryCode = localStorage.getItem('countryCode')
    if (typeof countryCode === 'string') {
      const lowerCaseCountryCode = countryCode.toLowerCase()
      if (countryCode !== lowerCaseCountryCode) {
        localStorage.setItem('countryCode', lowerCaseCountryCode)
      }
    }
  }, [])

  const urlParams = new URLSearchParams(window.location.search)

  if (urlParams?.has('lang')) {
    sessionStorage.setItem('languageCode', urlParams.get('lang'))
  } else {
    sessionStorage.setItem(
      'languageCode',
      sessionStorage.getItem('languageCode') ||
        (navigator?.language || navigator?.userLanguage).split('-')[0],
    )
  }

  let uniqueIdForSession = uuidv4()
  setSessionId(uniqueIdForSession)

  let sessionCreatedTime = moment()
  setSessionCreationTime(sessionCreatedTime.format('YYYY-MM-DD HH:mm:ss'))

  if (!localStorage.getItem('appInstallationId')) {
    let uniqueId = uuidv4()
    localStorage.setItem('appInstallationId', 'web' + uniqueId)
  }

  const floatingIntercomInDOM = Boolean(localStorage.getItem('token'))

  return (
    <HelmetProvider>
      <BrowserRouter>
        <Suspense fallback={<EmptyLoadingScreen />}>
          <AuthContextProvider>
            <UnsecureSetupContextProvider>
              <LocalizationContextProvider>
                <ThemeDataContextProvider>
                  <MuiLocalizationProvider dateAdapter={AdapterDayjs}>
                    <FCMProvider>
                      <ProfileContextProvider>
                        <NotificationContextProvider>
                          <RenderRoutes />
                          {floatingIntercomInDOM && <FloatingIntercomButton />}
                          <AppUpdatePopup />
                          <BookingRequestNavigationAndToastComponent />
                        </NotificationContextProvider>
                      </ProfileContextProvider>
                    </FCMProvider>
                    <GenericHideChildrenOnPrintWrapper>
                      <ToastContainer
                        position="top-center"
                        autoClose={5000}
                        hideProgressBar={true}
                        newestOnTop
                        closeOnClick
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                      />
                    </GenericHideChildrenOnPrintWrapper>

                    <SEOComponent
                      title={APP_CONSTANTS.DEFAULT_TITLE}
                      description={APP_CONSTANTS.DEFAULT_DESCRIPTION}
                      image={APP_CONSTANTS.APP_LOGO_LINK}
                    />
                  </MuiLocalizationProvider>
                </ThemeDataContextProvider>
              </LocalizationContextProvider>
            </UnsecureSetupContextProvider>
          </AuthContextProvider>
        </Suspense>
      </BrowserRouter>
    </HelmetProvider>
  )
}

export default App

const RenderRoutes = () => {
  const { unSecureSetupLoading, unSecureSetupData, unSecureSetupError } =
    useContext(UnsecureSetupContext)
  const { themeDataLoading, themeData, businessTheme, themeDataError } =
    useContext(ThemeDataContext)
  const { localizedDataLoading, localizedData, localizedDataError } =
    useContext(LocalizationContext)

  const { fcmStatus, notificationsAreDenied } = useContext(FCMContext)
  const [showPushNotificationDialog, setShowPushNotificationDialog] = useState(false)

  useEffect(() => {
    const checkSupportAndShowPopup = async () => {
      if ((await isSupported()) && 'Notification' in window) {
        if (
          (fcmStatus === 'denied' && notificationsAreDenied) ||
          sessionStorage.getItem('notificationsAreDenied')
        ) {
          setShowPushNotificationDialog(false)
        } else {
          setShowPushNotificationDialog(fcmStatus !== 'granted')
        }
      } else {
        setShowPushNotificationDialog(false)
      }
    }
    checkSupportAndShowPopup()
  }, [fcmStatus, notificationsAreDenied])

  useEffect(() => {
    if (process.env.REACT_APP_ENV) {
      sessionStorage.setItem('env', process.env.REACT_APP_ENV)
    }
  }, [])

  function closePushNotificationDialog() {
    setShowPushNotificationDialog(false)
    setNotificationsAreDeniedLocal(true)
  }

  return (
    <>
      {(unSecureSetupLoading || themeDataLoading || localizedDataLoading) &&
      !window.location.href.includes('/webview/') &&
      !window.location.href.includes('embedded') ? (
        <SplashScreen />
      ) : (
        <>
          <Routes>
            {/* public pages :  */}

            <Route exact path="/login" name="Login Page" element={<Login />} />
            <Route exact path="/onboarding" name="Onboarding" element={<Onboarding />} />

            {/* Legal, and Legacy things */}
            <Route exact path="/about" name="About" element={<AboutPage />} />
            <Route exact path="/privacy" name="Privacy" element={<PrivacyPage />} />
            <Route exact path="/terms" name="Terms" element={<TermsPage />} />
            <Route exact path="/cookie-policy" name="Terms" element={<CookiePolicy />} />
            <Route exact path="/instructor-terms" name="Terms" element={<InstructorTerms />} />
            <Route exact path="/legal" name="Legal" element={<LegalPage />} />

            {/* probably temp */}
            <Route
              exact
              path="/event-booking-successful"
              name="Booking Successful"
              element={<BookingSuccessfulPage />}
            />

            <Route
              exact
              path="/privacy-policy-1"
              name="Privacy Policy 1"
              element={<PrivacyPolicyOne />}
            />
            <Route
              exact
              path="/copy-of-privacy-policy"
              name="Privacy Policy 1"
              element={<CopyOfPrivacyPolicy />}
            />
            <Route
              exact
              path="/mental-health-medical-board/:id"
              name="Mental Health Medical Board"
              element={<MedicalBoardMemberPage />}
            />
            <Route
              exact
              path="/mental-health-medical-board"
              name="Mental Health Medical Board"
              element={<MentalHealthMedicalBoard />}
            />
            {/* ends : Legal, and Legacy things */}
            <Route exact path="/guest/:slug" name="Guests" element={<GuestMemberProfile />} />
            <Route
              exact
              path="/account/recover/:token"
              name="Change Password"
              token="qwert"
              element={<ChangePassword />}
            />
            <Route
              exact
              path="/forgot-password"
              name="Forgot Password"
              element={<ForgotPassword />}
            />
            <Route exact path="/help-center" name="Help Center" element={<HelpCenter />} />
            <Route
              exact
              path="/help-center-embedded"
              name="Help Center"
              element={<HelpCenter embedded={true} />}
            />
            <Route exact path="/download" name="Download AUMHUM" element={<DownloadAppPage />} />
            <Route
              exact
              path="/manage-data-and-account"
              name="Manage Data and Account"
              element={<ManageDataAndAccount />}
            />
            <Route
              exact
              path="/help-center/category/:categoryId"
              name="Help Center"
              element={<HelpCenterCategoryPage />}
            />
            <Route
              exact
              path="/help-center-embedded/category/:categoryId"
              name="Help Center"
              element={<HelpCenterCategoryPage embedded={true} />}
            />
            <Route exact path="/gift-card" name="Gift Card" element={<GiftCard />} />
            <Route exact path="/giftcard" name="Gift Card" element={<GiftCard />} />
            <Route exact path="/gift" name="Gift Card" element={<GiftCard />} />
            <Route exact path="/redeem" name="Gift Card Redeem" element={<GiftCardRedeem />} />
            <Route
              exact
              path="/gift-card/redeem"
              name="Gift Card Redeem"
              element={<GiftCardRedeem />}
            />
            <Route
              exact
              path="/offers/vf"
              name="VF Card Redeem Page"
              element={<GiftCardRedeem heading="Welcome VF Team" />}
            />
            <Route
              exact
              path="/swish-club"
              name="Swish Club - Redeem Page"
              element={
                <GiftCardRedeem
                  heading=" "
                  imageLink={getImageLink(APP_CONSTANTS.SWISH_CLUB_LOGO)}
                  subHeading={'Redeem your Swish Club code'}
                  inputPlaceholder={'Enter code here'}
                  seoTitle={'Redeem your code | AUMHUM & Swish Club'}
                  seoDescription={
                    'Enter your code to unlock benefits at AUMHUM | AUMHUM & Swish Club'
                  }
                  supportEmail={'swish-club-support@aumhum.com'}
                  addItemsToSessionStorage={{
                    acquisitionReferenceRefId: 'swish-club',
                    acquisitionReferenceRefType: 'type-affiliate',
                  }}
                  benefitsActivatedLine={'your Swish Club benefits have been activated.'}
                  showStepsToRedeemVideo={true}
                  stepsToRedeemVideoText={'Video Walkthrough'}
                  stepsToRedeemVideoLink={APP_CONSTANTS.SWISH_CLUB_CODE_REDEEM_VIDEO_LINK}
                  showFaqs={true}
                  blockRedemptionIfPremium={true}
                />
              }
            />
            <Route
              exact
              path="/register"
              name="Register Page"
              element={<Navigate to="/onboarding" />}
            />
            <Route path="/reset-storage" element={<ResetStorage />} />
            <Route exact path="/404" name="Page 404" element={<Page404 />} />
            <Route exact path="/403" name="Page 403" element={<AuthorityCheck />} />
            <Route exact path="/error" name="Page 500" element={<Page500 />} />

            {/* webview pages :  */}

            <Route path="/webview/leaderboard" element={<LeaderboardPageWebview />} />
            <Route
              element={
                <MyLiveSessionsWrapperForWebview
                  pageViewType={bookedPageViewTypes.booked_sessions}
                />
              }
              path={`/webview/my-live-sessions/${bookedPageViewTypes.booked_sessions}/:eventId`}
            />
            <Route
              element={
                <MyLiveSessionsWrapperForWebview
                  pageViewType={bookedPageViewTypes.booked_sessions}
                />
              }
              path={`/webview/my-live-sessions/${bookedPageViewTypes.booked_sessions}`}
            />
            <Route
              element={<MyLiveSessionsWrapperForWebview pageViewType={bookedPageViewTypes.new} />}
              path={`/webview/my-live-sessions/${bookedPageViewTypes.new}`}
            />
            <Route element={<MyLiveSessionsWrapperForWebview />} path="/webview/my-live-sessions" />
            <Route element={<PatientHomeWrapperForWebview />} path={`/webview/patient-home`} />

            {/* ACTIVITY PLAN WEBVIEW ROUTES */}

            <Route element={<ActivityPlanListingPageWebview isWebView />} path="/webview/plan" />
            <Route element={<ActivityPlanPageWebView />} path="/webview/plan/:planId" />
            <Route element={<ActivityPlanPageWebView useId />} path="/webview/plan/id/:planId" />

            {/* ACTIVITY PLAN LISTING WEBVIEW ROUTES */}
            <Route element={<UserActivityListingPageWebview />} path="/webview/my-activity-plans" />
            <Route element={<ActivityPlanPageWebView />} path="/webview/plan/:planId" />
            <Route element={<ActivityPlanPageWebView useId />} path="/webview/plan/id/:planId" />

            {/* USER ACTIVITY PLAN WEBVIEW ROUTES */}
            <Route
              element={<UserActivityPageWebview idType="slug" />}
              path="/webview/my-activity/:planId"
            />

            <Route
              element={<UserActivityPageWebview idType="planId" />}
              path="/webview/my-activity/id/:planId"
            />

            {/* removeIntent pages:  */}
            <Route
              exact
              path="/mobileSignIn"
              name="Mobile SignIn Page"
              element={<MobileSignIn />}
            />

            {/* different flows : */}

            <Route
              path="/workplace/*"
              name="Business Home"
              element={
                <ThemeProvider theme={businessTheme}>
                  <BusinessProtectedRoute>
                    <BusinessLayout />
                  </BusinessProtectedRoute>
                </ThemeProvider>
              }
            />

            {/* clinic assistant flow: */}
            <Route
              path="/ca-home/*"
              name="Clinic Home"
              element={
                <ProtectedRoute>
                  <CALayout />
                </ProtectedRoute>
              }
            />

            {/* chamber screen flow: */}
            <Route
              path="/chamber-screen-home/*"
              name="Chamber Screen Home"
              element={
                <ProtectedRoute>
                  <CSLayout />
                </ProtectedRoute>
              }
            />

            {/* clinic owner flow/ clinic prospect/ clinic admin: */}
            <Route
              path="/clinic-home/*"
              name="Clinic Home"
              element={
                <ProtectedRoute>
                  <ClinicOwnerLayout />
                </ProtectedRoute>
              }
            />

            {/* needed here: doctor home and therapist home: */}
            <Route
              path="/mentor-home/*"
              name="Mentor Home"
              element={
                <ProtectedRoute>
                  <MentorLayout />
                </ProtectedRoute>
              }
            />

            <Route
              path="*"
              name="Consumer Layout"
              element={
                <ThemeProvider theme={themeData || {}}>
                  <ConsumerLayout />
                </ThemeProvider>
              }
            />

            {/* removeIntent pages:  */}
            {/* <Route path="/userProfile/*" name="Profile" element={<ProfileLayout />} /> */}
          </Routes>
          <Dialog
            // open={showPushNotificationDialog}
            open={false}
            onClose={closePushNotificationDialog}
            PaperComponent={StyledPushNotificationDialog}
          >
            <PushNotificationComponent closeFunction={closePushNotificationDialog} />
          </Dialog>
        </>
      )}
    </>
  )
}

const ResetStorage = () => {
  const navigate = useNavigate()

  // Reset local storage and session storage
  const handleResetStorage = () => {
    localStorage.clear()
    sessionStorage.clear()
    // Redirect to the login page or any other desired location
    navigate('/login')
  }

  useEffect(() => {
    handleResetStorage()
  }, [])

  return (
    // You can customize the UI for the reset storage page
    <div>
      <p>This page is for resetting storage. Are you sure you want to proceed?</p>
      <button onClick={handleResetStorage}>Reset Storage</button>
    </div>
  )
}

const BookingRequestNavigationAndToastComponent = () => {
  const { profileData } = useContext(ProfileContext)
  const navigate = useNavigate()

  const [data, setData] = useState([])

  async function getAllInitiatedBookingRequests() {
    let toReturn = []

    if (profileData && profileData?.authorities?.includes('ROLE_TEACHER')) {
      let response = await getBookingRequestsForMentor(
        moment()?.utc()?.subtract(24, 'hours')?.format('YYYY-MM-DDTHH:mm:ss[Z]'),
        true,
      )

      if (response?.status === 200 && isCommonResponseSuccessful(response?.data?.code)) {
        const initiatedRequests = (response?.data?.data?.bookingRequests || []).filter(
          (item) => item?.status === BOOKING_REQUEST_STATUS.BOOKING_INITIATED,
        )
        toReturn = initiatedRequests
      }
      setData(toReturn)
    }

    return
  }

  function navigateToThePage() {
    if (data?.length > 0) {
      toast.success('You have unanswered Booking Requests')
      navigate('/booking-requests-for-mentors/all')
    }
  }

  useEffect(() => {
    getAllInitiatedBookingRequests()
  }, [profileData])

  useEffect(() => {
    navigateToThePage()
  }, [data])

  return <></>
}
