import axios from 'axios'
import moment from 'moment'
import { useContext, useEffect, useState } from 'react'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import { useNavigate } from 'react-router-dom'
import backgroundWithCross from 'src/assets/background_with_cross.svg'
import Star_Image from 'src/assets/unlimited_star.png'
import { FALLBACK_CARD_IMAGE, FALLBACK_ICON_ID } from 'src/consumer/constants'
import { InformationContext } from 'src/consumer/context/InformationContext'
import { JournalContext } from 'src/consumer/context/JournalContext'
import { PlayerContext } from 'src/consumer/context/PlayerContext'
import { SetupContext } from 'src/consumer/context/SetupContext'
import { ThemeDataContext } from 'src/consumer/context/ThemeDataContextProvider'
import { getCategoryListMap } from 'src/consumer/helpers/courseCardHelper'
import {
  getCateogryTitleText,
  getDurationText,
  giveCompletionNumbers,
  itemDataExtractor,
} from 'src/consumer/helpers/dailyPlayHelper'
import { resetPlayerData } from 'src/consumer/helpers/playerHelper'
import { apiURL, commonHeadersWeb, mediaURL } from 'src/consumer/services'
import { getImageLink } from 'src/mentor/helpers/utilityFunctions'
import { ModalProvider } from 'styled-react-modal'
import {
  StyledCardInfoSeparator,
  StyledCourseCategory,
  StyledCourseTitle,
} from '../BrowserByCategory/BrowserByCategoryStyle'
import { SaveGoalsButton } from '../ConsumerProfilePage/GoalsStyle'
import { DisplayFlex } from '../ConsumerProfilePage/ProfileLeftPannelStyle'
import { CoinsOnWhiteBg } from '../GlobalHeader/GlobalNavStyles'
import {
  FadingBackground,
  JournalCategory,
  JournalDetailsContainer,
  JournalTitle,
  StyledLine,
  StyledSeparatorJournal,
} from '../Journal/JournalStyles'
import { DisplayFlexSpaceBetween } from '../LeaderboardPage/LeaderboardPageStyles'
import { SquareMidCardSingleCard } from '../Tile/components/layoutCards/SquareMidCard'
import {
  ActiveCardEndItem,
  ActiveCardEndItemText,
  AppExclusiveContainer,
  AppExclusiveText,
  CardHeaderLeftIcon,
  CardItemDarkOverlay,
  CardItemsContainer,
  CardOverviewContainer,
  CardOverviewTextContainer,
  CardSummary,
  CheatCardHeading,
  CheatCardText,
  CircularContainer,
  CompletedCardGemsContainer,
  CompletedCardItemPicture,
  CompletedCardTitle,
  CompletedItemCardContainer,
  CompletedItemCardContainerElement,
  DailyPlayButton,
  DailyPlayColors,
  DailyPlaySettingContent,
  DailyPlaySettingContentItemContainer,
  DailyPlaySettingPlayTypeContainer,
  DailyPlaySettingPlayTypeText,
  DailyPlaySettingsCloseButton,
  DailyPlaySettingsContainer,
  DailyPlaySettingsContentContainer,
  DailyPlaySettingsHeading,
  DayInStreakCircleBackground,
  DayInStreakContainer,
  DisplayFlexColumn,
  EarnedGemsText,
  EnableDisableDot,
  GemContainer,
  GemImageStreak,
  IconImage,
  IosSwitch,
  MoodCardAnswer,
  MoodCardContainer,
  MoodCardOptionImage,
  MoodCardQuestion,
  PlayItemTitleContainer,
  PositionedStreakContainer,
  PrevCardGemContainer,
  PrevGemContainerText,
  PrevSlotName,
  QuoteCardHeading,
  QuoteCardHeadingsContainer,
  QuoteCardSubheading,
  ScoreContainer,
  ScoreProgressContainer,
  ScoreProgressFilled,
  SlotEndsInText,
  SlotEndsInTime,
  SlotTimeContainer,
  SocialComparisonContainer,
  StartedText,
  StyledDailyPlaySettingsModal,
  SummaryItemEntry,
  SummaryItemsContainer,
  SummaryTitle,
  SummaryUnderline,
  TopLeftContainer,
  UnlockingText,
  UnlockingTimeContainer,
} from './DailyPlayStyles'
import QuoteAndReflection from './QuoteAndReflection'
import { PracticeTimerSquareMidCardContainer } from './components/practiceTimers/styledComponents'

export function getFormattedCount(number) {
  let parsedNumber = parseInt(number)
  if (parsedNumber) {
    let newNumber
    if (parsedNumber >= 1000000) {
      newNumber = (parsedNumber / 1000000)?.toFixed(1)
      if (newNumber - Math.floor(parsedNumber / 1000000) === 0) {
        newNumber = Math.floor(newNumber)
      }
      return `${newNumber}M`
    } else if (parsedNumber >= 1000) {
      newNumber = (parsedNumber / 1000)?.toFixed(1)
      if (newNumber - Math.floor(parsedNumber / 1000) === 0) {
        newNumber = Math.floor(newNumber)
      }
      return `${newNumber}K`
    } else {
      return `${parsedNumber?.toFixed(0)}`
    }
  } else {
    return '0'
  }
}

export const UnlockingTimeClock = (props) => {
  return (
    <UnlockingTimeContainer>
      <UnlockingText>{props?.text}</UnlockingText>
      <IconImage src={require('src/assets/clock_with_bg.png')} w={60} />
      <UnlockingText>{props?.time}</UnlockingText>
    </UnlockingTimeContainer>
  )
}

export const SlotTime = (props) => {
  return (
    <SlotTimeContainer>
      {props?.haveIcon && <CardHeaderLeftIcon src={props?.img} />}
      {props?.text}
    </SlotTimeContainer>
  )
}

export const DetailedOverview = (props) => {
  const { text, img, scoreData } = props

  return (
    <CardOverviewContainer next={props?.next}>
      <GemContainer next={props?.next}>
        <PrevGemContainerText main next={props?.next}>
          <div className="d-flex align-items-center">
            <IconImage w={15.36} h={12.48} src={require('src/assets/gem.png')} />
            {!props?.next && `${scoreData?.currentScore}/${scoreData?.maxScore}`}
            {props?.next && scoreData?.maxScore}
          </div>
        </PrevGemContainerText>

        {!props?.next && <PrevGemContainerText>Collected</PrevGemContainerText>}

        {!props?.next && (
          <ScoreProgressContainer className="progress" prev>
            <ScoreProgressFilled
              prev
              className="progress-bar"
              role="progressbar"
              style={{ width: `${(100 * scoreData?.currentScore) / scoreData?.maxScore}%` }}
            />
          </ScoreProgressContainer>
        )}
      </GemContainer>

      <CardOverviewTextContainer next={props?.next}>
        {Boolean(img) && <IconImage w={20} src={img} />}
        <PrevSlotName>{text}</PrevSlotName>
      </CardOverviewTextContainer>
    </CardOverviewContainer>
  )
}

export const SlotSummary = (props) => {
  const { setupData } = useContext(SetupContext)
  const { text, items } = props

  return (
    <CardSummary>
      <SummaryTitle>{text}</SummaryTitle>
      <SummaryUnderline />
      <SummaryItemsContainer>
        {items?.map((cardItem, index) => {
          return (
            <SummaryItem
              key={index}
              itemData={itemDataExtractor(cardItem, setupData)}
              next={props?.next}
            />
          )
        })}
      </SummaryItemsContainer>
    </CardSummary>
  )
}

export const SummaryItem = (props) => {
  const [isQuoteModalOpen, setIsQuoteModalOpen] = useState(false)
  const { setupData } = useContext(SetupContext)
  const { playerData, setPlayerData } = useContext(PlayerContext)
  const { journalData, setJournalData } = useContext(JournalContext)
  const { informationModalData, setInformationModalData } = useContext(InformationContext)

  const itemType = props?.itemData?.itemType

  if (itemType === 'mental-state-type') {
    return null
  }

  if (!props?.itemData?.iconId) {
    props.itemData.iconId = FALLBACK_ICON_ID
  }

  let title = getCateogryTitleText(props?.itemData)
  let duration = getDurationText(props?.itemData)

  return (
    <>
      <SummaryItemEntry
        next={props?.next}
        onClick={() => {
          if (!props?.next) {
            if (itemType === 'course') {
              setPlayerData({
                ...resetPlayerData(playerData),
                showPlayer: true,
                selectedCard: playerData.showPIP
                  ? playerData?.selectedCard
                  : props?.itemData?.cardData,
                source: 'daily-play',
                id: props?.itemData?.dfid,
                otherData: {
                  coins: props?.itemData?.coins,
                  completed: props?.itemData?.completed,
                  category: props?.itemData?.cardData?.course?.courseCategory,
                },
              })
            }
            if (itemType === 'quote') {
              setIsQuoteModalOpen(true)
            }
            if (itemType === 'journal') {
              setJournalData({
                showJournal: true,
                callSource: 'daily-play',
                data: {
                  ...props?.itemData?.journalData,
                  callSourceId: props?.itemData?.dfid,
                  sourceName: 'daily-play',
                  coins: props?.itemData?.coins,
                  completed: props?.itemData?.completed,
                  category: props?.itemData?.category,
                },
                pageState: '',
                subscription: props?.itemData?.journalData?.subscription?.id,
              })
            }
            if (itemType === 'practice' || itemType === 'breathe') {
              if (setupData?.clientType === 'mobileBrowser') {
                window.open('https://aumhum.page.link/app')
              } else {
                setInformationModalData({ showModal: true, data: { toShow: 'app_exclusive' } })
              }
            }
          }
        }}
      >
        {props?.itemData?.completed && !props?.next && (
          <IconImage w={11.27} h={11.27} src={require('src/assets/green_filled_tick.png')} />
        )}
        {!props?.itemData?.completed && !props?.next && (
          <IconImage w={11.27} h={11.27} src={require('src/assets/incomplete_dot.png')} />
        )}

        <IconImage w={40.73} height={40.73} b={5} src={`${mediaURL}${props?.itemData?.iconId}`} />

        <div className="d-flex flex-column">
          <SummaryTitle item>{title}</SummaryTitle>
          <SummaryTitle item>{duration}</SummaryTitle>
        </div>
      </SummaryItemEntry>

      {itemType === 'quote' && (
        <QuoteAndReflection
          isQuoteModalOpen={isQuoteModalOpen}
          setIsQuoteModalOpen={setIsQuoteModalOpen}
          data={props?.itemData}
          callSourceId={props?.itemData?.dfid}
        />
      )}
    </>
  )
}

export const SlotEndsIn = (props) => {
  return (
    <div className="d-flex flex-column">
      <SlotEndsInText>{props?.text}</SlotEndsInText>
      <SlotEndsInTime>{props?.time}</SlotEndsInTime>
    </div>
  )
}

export const SocialComparison = (props) => {
  const { score, items } = props

  let participantsNumber = 0

  const participantPics = items?.[0]?.participantPics?.slice(0, 3)

  items?.forEach((item, index) => {
    participantsNumber += item?.participants
  })

  return (
    <SocialComparisonContainer>
      <div className="d-flex justify-center">
        {participantPics?.map((item, index) => {
          return <CircularContainer big num={index + 1} picture={mediaURL + item} key={index} />
        })}
        <CircularContainer big num={items?.[0]?.participantPics?.length + 1}>{`+${getFormattedCount(
          participantsNumber,
        )}`}</CircularContainer>
      </div>

      <EarnedGemsText>Participated & Earned</EarnedGemsText>

      <PrevCardGemContainer>
        <IconImage src={require('src/assets/gem.png')} w={28.82} h={23.06} />
        <EarnedGemsText main>
          {getFormattedCount(participantsNumber * score?.maxScore)}
        </EarnedGemsText>
      </PrevCardGemContainer>
    </SocialComparisonContainer>
  )
}

export const CompletedCardItem = (props) => {
  const itemType = props?.itemData?.itemType

  const [isQuoteModalOpen, setIsQuoteModalOpen] = useState(false)
  const { playerData, setPlayerData } = useContext(PlayerContext)
  const { journalData, setJournalData } = useContext(JournalContext)

  let imgUrl
  let title
  let itemBackgroundColor = '#FFFDD0'

  if (itemType === 'course') {
    imgUrl = `${mediaURL}${props?.itemData?.imgId}`
    title = props?.itemData?.cardData?.course?.title
  }
  if (itemType === 'quote') {
    imgUrl = backgroundWithCross
    if (props?.itemData?.altText) {
      title = props?.itemData?.altText
    } else {
      title = `${props?.slot} Reflection`
    }
  }
  if (itemType === 'journal') {
    imgUrl = `${mediaURL}${props?.itemData?.imgId}`
    title = props?.itemData?.journalData?.title
  }

  let duration = getDurationText(props?.itemData)

  if (itemType === 'mental-state-type') {
    return null
  }

  return (
    <>
      <CompletedItemCardContainer
        onClick={() => {
          if (itemType === 'course') {
            setPlayerData({
              ...resetPlayerData(playerData),
              showPlayer: true,
              selectedCard: playerData.showPIP
                ? playerData?.selectedCard
                : props?.itemData?.cardData,
              source: 'daily-play',
              id: props?.itemData?.dfid,
              otherData: {
                coins: props?.itemData?.coins,
                completed: props?.itemData?.completed,
                category: props?.itemData?.cardData?.course?.courseCategory,
              },
            })
          }
          if (itemType === 'quote') {
            setIsQuoteModalOpen(true)
          }
          if (itemType === 'journal') {
            setJournalData({
              showJournal: true,
              callSource: 'daily-play',
              data: {
                ...props?.itemData?.journalData,
                callSourceId: props?.itemData?.dfid,
                sourceName: 'daily-play',
                coins: props?.itemData?.coins,
                completed: props?.itemData?.completed,
                category: props?.itemData?.category,
              },
              pageState: '',
              subscription: props?.itemData?.journalData?.subscription?.id,
            })
          }
        }}
      >
        <CompletedItemCardContainerElement>
          <IconImage w={20.81} h={20.81} src={require('src/assets/green_filled_tick.png')} />
        </CompletedItemCardContainerElement>

        <CompletedItemCardContainerElement>
          <CompletedCardItemPicture
            backgroundColor={itemBackgroundColor}
            backgroundImage={imgUrl}
            itemType={itemType}
          />
        </CompletedItemCardContainerElement>

        <CompletedItemCardContainerElement title>
          <CompletedCardTitle>{title}</CompletedCardTitle>
          <CompletedCardTitle sub>{duration}</CompletedCardTitle>
        </CompletedItemCardContainerElement>

        <CompletedItemCardContainerElement>
          <CompletedCardGemsContainer>
            <IconImage src={require('src/assets/gem.png')} w={20.96} h={16.77} />
            {`+${props?.itemData?.coins}`}
          </CompletedCardGemsContainer>
        </CompletedItemCardContainerElement>
      </CompletedItemCardContainer>

      {itemType === 'quote' && (
        <QuoteAndReflection
          isQuoteModalOpen={isQuoteModalOpen}
          setIsQuoteModalOpen={setIsQuoteModalOpen}
          data={props?.itemData}
          callSourceId={props?.itemData?.dfid}
        />
      )}
    </>
  )
}

export const ScoreInfo = (props) => {
  const { currentScore, maxScore } = props?.scoreData

  return (
    <ScoreContainer>
      <div className="d-flex align-items-center gap-1">
        <IconImage w={15.36} h={12.48} src={require('src/assets/gem.png')} />
        <SlotEndsInTime>{`${currentScore}/${maxScore}`}</SlotEndsInTime>
      </div>
      <div>Collected</div>
      <ScoreProgressContainer className="progress">
        <ScoreProgressFilled
          className="progress-bar"
          role="progressbar"
          style={{ width: `${(currentScore / maxScore) * 100}%` }}
        />
      </ScoreProgressContainer>
    </ScoreContainer>
  )
}

export const TitleAndPlay = (props) => {
  return (
    <PlayItemTitleContainer hasText={props?.hasText}>
      <StyledCourseCategory>{props?.category}</StyledCourseCategory>
      {!props?.hasText && <StyledCardInfoSeparator />}
      {!props?.hasText && <StyledCourseTitle>{props?.title}</StyledCourseTitle>}
      <DailyPlayButton>{props?.altActionButtonText || 'Play'}</DailyPlayButton>
    </PlayItemTitleContainer>
  )
}

export const AppExclusive = (props) => {
  return (
    <AppExclusiveContainer>
      <StyledCourseCategory>{props?.category}</StyledCourseCategory>
      <StyledCardInfoSeparator />
      <StyledCourseTitle>{props?.title}</StyledCourseTitle>
      <AppExclusiveText>
        {props?.text} <IconImage src={Star_Image} w={20} h={20} />{' '}
      </AppExclusiveText>
    </AppExclusiveContainer>
  )
}

export const QuoteCardDetails = (props) => {
  const { heading, subheading } = props

  const wordsArray = heading?.split(' ')
  const temp = Math.floor(wordsArray?.length / 2) || []

  const array1 = wordsArray.slice(0, temp)
  const array2 = wordsArray.slice(temp)

  return (
    <QuoteCardHeadingsContainer>
      <QuoteCardHeading>{array1.join(' ')}</QuoteCardHeading>
      <QuoteCardHeading>{array2.join(' ')}</QuoteCardHeading>
      <QuoteCardSubheading>{subheading}</QuoteCardSubheading>
    </QuoteCardHeadingsContainer>
  )
}

export const CardItem = (props) => {
  const [isQuoteModalOpen, setIsQuoteModalOpen] = useState(false)
  const { playerData, setPlayerData } = useContext(PlayerContext)
  const { journalData, setJournalData } = useContext(JournalContext)
  const { informationModalData, setInformationModalData } = useContext(InformationContext)
  const { setupData } = useContext(SetupContext)
  const categoryMap = getCategoryListMap(setupData)
  const journalCategory = categoryMap?.get('myJournal')
  const assessmentCategory = categoryMap?.get('assessment')

  const navigate = useNavigate()
  const itemType = props?.itemData?.itemType
  const completed = props?.itemData?.completed

  const participantPics = props?.itemData?.participantPics?.slice(0, 3)

  let itemBackgroundColor = '#7248ad'
  let imgUrl

  if (
    itemType === 'course' ||
    itemType === 'practice' ||
    itemType === 'breathe' ||
    itemType === 'journal' ||
    itemType === 'assessment' ||
    itemType === 'article'
  ) {
    imgUrl = getImageLink(props?.itemData?.imgId, FALLBACK_CARD_IMAGE)
  }
  if (itemType === 'quote') {
    imgUrl = backgroundWithCross
  }

  let participantNumber = props?.itemData?.participants - 3

  return (
    <>
      <CardItemsContainer
        backgroundColor={itemBackgroundColor}
        backgroundImage={imgUrl}
        itemType={itemType}
        onClick={() => {
          if (itemType === 'course') {
            setPlayerData({
              ...resetPlayerData(playerData),
              showPlayer: true,
              selectedCard: playerData.showPIP
                ? playerData?.selectedCard
                : props?.itemData?.cardData,
              source: 'daily-play',
              id: props?.itemData?.dfid,
              otherData: {
                coins: props?.itemData?.coins,
                completed: props?.itemData?.completed,
                category: props?.itemData?.cardData?.course?.courseCategory,
              },
            })
          }
          if (itemType === 'quote') {
            setIsQuoteModalOpen(true)
          }
          if (itemType === 'journal') {
            setJournalData({
              showJournal: true,
              callSource: 'daily-play',
              data: {
                ...props?.itemData?.journalData,
                callSourceId: props?.itemData?.dfid,
                sourceName: 'daily-play',
                coins: props?.itemData?.coins,
                completed: props?.itemData?.completed,
                category: props?.itemData?.category,
              },
              pageState: '',
              subscription: props?.itemData?.journalData?.subscription?.id,
            })
          }
          if (itemType === 'assessment') {
            navigate(`/assessment/${props?.itemData?.assessmentData?.slug}`)
          }
          if (itemType === 'breathe') {
            if (setupData?.clientType === 'mobileBrowser') {
              window.open('https://aumhum.page.link/app')
            } else {
              setInformationModalData({ showModal: true, data: { toShow: 'app_exclusive' } })
            }
          }
          if (itemType === 'article') {
            if (props?.itemData?.articleData?.slug) {
              navigate(`/article/${props?.itemData?.articleData?.slug}`, {
                state: { source: 'daily-play', id: props?.itemData?.dfid },
              })
            } else if (props?.itemData?.articleData?.id) {
              navigate(`/article/id/${props?.itemData?.articleData?.id}`, {
                state: { source: 'daily-play', id: props?.itemData?.dfid },
              })
            }
          }
          if (itemType === 'practice') {
            navigate(
              `/timer/i/${props?.itemData?.meditation?.id}?src=daily-play&src-id=${props?.itemData?.dfid}`,
              {
                state: {
                  item: props?.itemData?.meditation,
                },
              },
            )
          }
        }}
      >
        {(itemType === 'course' || itemType === 'practice' || itemType === 'article') && (
          <CardItemDarkOverlay />
        )}

        {itemType === 'course' && (
          <TitleAndPlay
            title={props?.itemData?.cardData?.course?.title}
            category={
              props?.itemData?.altText ||
              props?.itemData?.altTextObject?.[props?.slotId] ||
              props?.itemData?.cardData?.course?.courseCategory
            }
            hasText={props?.itemData?.imgHasText}
          />
        )}

        {itemType === 'practice' && (
          <PracticeTimerSquareMidCardContainer>
            <SquareMidCardSingleCard
              item={props?.itemData}
              getActionButtonLogic={() => ({ text: 'Start', onClick: () => {} })}
              getTitleField={(itm) => itm?.meditation?.title}
              getSecondaryText={(itm) => 'Practice Timer'}
              getMainImage={(itm) => itm?.meditation?.imgId}
            />
          </PracticeTimerSquareMidCardContainer>
        )}

        {itemType === 'breathe' && (
          <AppExclusive
            title={props?.itemData?.title}
            category={
              props?.itemData?.altText ||
              props?.itemData?.altTextObject?.[props?.slotId] ||
              props?.itemData?.category
            }
            text="App Exclusive"
          />
        )}

        {itemType === 'quote' && (
          <QuoteCardDetails
            heading={props?.itemData?.altText ? props?.itemData?.altText : 'Daily Reflection'}
            subheading="Explore →"
          />
        )}

        {itemType === 'journal' && (
          <JournalDetailsContainer>
            <JournalCategory>
              {props?.itemData?.altText ||
                props?.itemData?.altTextObject?.[props?.slotId] ||
                journalCategory?.singleTitle ||
                journalCategory?.name}
            </JournalCategory>

            <StyledSeparatorJournal />

            <JournalTitle>{props?.itemData?.title}</JournalTitle>

            <StyledLine />
            <StyledLine />
            <StyledLine />
          </JournalDetailsContainer>
        )}

        {itemType === 'assessment' && (
          <JournalDetailsContainer>
            <JournalCategory>
              {props?.itemData?.altText ||
                props?.itemData?.altTextObject?.[props?.slotId] ||
                assessmentCategory?.singleTitle ||
                assessmentCategory?.name}
            </JournalCategory>

            <StyledSeparatorJournal />

            <JournalTitle>{props?.itemData?.title}</JournalTitle>

            <StyledLine />
            <StyledLine />
            <StyledLine />
          </JournalDetailsContainer>
        )}

        {itemType === 'article' && (
          <TitleAndPlay
            title={props?.itemData?.articleData?.title}
            category={
              props?.itemData?.altText ||
              props?.itemData?.altTextObject?.[props?.slotId] ||
              props?.itemData?.cardData?.course?.courseCategory
            }
            hasText={false}
            altActionButtonText="Read"
          />
        )}

        {/* {!completed &&
                    <TopRightContainer>
                        {trickArray?.map((item, index) => {
                            return (
                                <TopRightBlurCircle key={index}>
                                    <img src={require("src/assets/gem.png")} />
                                </TopRightBlurCircle>
                            )
                        })}
                    </TopRightContainer>
                } */}

        {/* {completed &&
                    <CompletedContainer>
                        <IconImage src={require("src/assets/green_filled_tick.png")} w={17.37} h={17.37} />
                        Completed
                    </CompletedContainer>
                } */}

        <TopLeftContainer>
          {participantPics?.map((item, index) => {
            return <CircularContainer num={index + 1} picture={mediaURL + item} key={index} />
          })}
          {/* <CircularContainer num={participantPics?.length + 1} >{`+${getFormattedCount(participantNumber)} `}</CircularContainer> */}
          <StartedText>{`+${getFormattedCount(participantNumber)} checked-in`}</StartedText>
        </TopLeftContainer>
      </CardItemsContainer>

      {itemType === 'quote' && (
        <QuoteAndReflection
          isQuoteModalOpen={isQuoteModalOpen}
          setIsQuoteModalOpen={setIsQuoteModalOpen}
          data={props?.itemData}
          callSourceId={props?.itemData?.dfid}
        />
      )}
    </>
  )
}

export async function postMentalState(type, value, src, srcId) {
  const token = localStorage.getItem('token')
  const data = {}
  try {
    // const os = `${platform.os.family}`.toLowerCase()
    const res = await axios.post(
      `${apiURL}/api/secure/user/stat?type=${type}&value=${value}&zoneOffset=${encodeURIComponent(
        moment().format('Z'),
      )}&src=${src}&src-id=${srcId}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          ...commonHeadersWeb,
          'ef-user-country-code': localStorage.getItem('countryCode'),
          'ef-app-language': sessionStorage.getItem('languageCode'),
        },
      },
    )
    return res
  } catch (err) {
    console.log(err)
    return err
  }
}

export const CardItemMood = ({ itemData, slotId, timeSlot, getDailyPlayProgress }) => {
  let question
  let answer

  if (itemData?.qaTexts?.[timeSlot]) {
    question = itemData?.qaTexts?.[timeSlot]?.question || itemData?.question
    answer = itemData?.qaTexts?.[timeSlot]?.answer || itemData?.answer
  }

  const [choiceMadeBool, setChoiceMadeBool] = useState(false)
  const [chosenOption, setChosenOption] = useState()

  useEffect(() => {
    if (itemData?.completedValue) {
      const dataOfLastSelectedOption = itemData?.options?.find(
        (item) => item?.id === itemData?.completedValue,
      )
      setChosenOption(dataOfLastSelectedOption)
      setChoiceMadeBool(true)
    }
  }, [itemData])

  const handleOptionClick = async (item, itemData) => {
    setChoiceMadeBool(true)
    setChosenOption(item)
    await postMentalState(item?.type, item?.id, 'daily-play', itemData?.dfid)
    await getDailyPlayProgress()
  }

  return (
    <MoodCardContainer>
      {!choiceMadeBool && (
        <DisplayFlexColumn style={{ gap: '30px' }}>
          <MoodCardQuestion>{question}</MoodCardQuestion>
          <DisplayFlex style={{ justifyContent: 'space-around', flexWrap: 'wrap' }}>
            {itemData?.options?.map((item, index) => {
              return (
                <MoodCardOptionImage
                  key={index}
                  src={`${mediaURL}${item?.iconId || item?.icon}`}
                  onClick={() => {
                    handleOptionClick(item, itemData)
                  }}
                />
              )
            })}
          </DisplayFlex>
        </DisplayFlexColumn>
      )}

      {choiceMadeBool && (
        <MoodCardAnswer>
          <MoodCardQuestion style={{ textAlign: 'left', width: '100%' }}>
            {answer?.replace('{1}', chosenOption?.name)}
          </MoodCardQuestion>
          <MoodCardOptionImage src={`${mediaURL}${chosenOption?.iconId || chosenOption?.icon}`} />
        </MoodCardAnswer>
      )}
    </MoodCardContainer>
  )
}

export const StreakComponent = (props) => {
  const { completionData, chosenDay, setChosenDay, daysData, userScoreData, profileData } = props
  const { themeDataLoading, themeData, themeDataError } = useContext(ThemeDataContext)
  const navigate = useNavigate()

  const daysDataKeysOrdered = Object.keys(daysData)
    .sort(function (a, b) {
      return parseInt(a) - parseInt(b)
    })
    .reverse()

  const renderDayInStreakComponent = (dayId) => {
    let dayText
    if (dayId === '0') {
      dayText = 'Today'
    } else {
      dayText = moment().add(parseInt(dayId), 'day').format('dddd').substr(0, 3)
    }

    const { totalTasksCompleted, totalDaySupportedItems } = giveCompletionNumbers(
      daysData,
      dayId,
      completionData,
    )
    const percentageDayTasks = (totalTasksCompleted / totalDaySupportedItems) * 100

    if (parseInt(dayId) > 0) {
      if (dayId === chosenDay) {
        return (
          <DayInStreakCircleBackground onClick={() => setChosenDay(dayId)}>
            <GemImageStreak
              backgroundColor={DailyPlayColors.activeSlotHeadlineBgColor}
              active={dayId === chosenDay}
            >
              <img src={require('src/assets/gem.png')} style={{ width: '60%' }} alt="gem" />
            </GemImageStreak>
          </DayInStreakCircleBackground>
        )
      } else {
        return (
          <GemImageStreak
            backgroundColor={DailyPlayColors.activeSlotHeadlineBgColor}
            onClick={() => setChosenDay(dayId)}
          >
            <img src={require('src/assets/gem.png')} style={{ width: '60%' }} alt="gem" />
          </GemImageStreak>
        )
      }
    } else {
      if (dayId === chosenDay) {
        return (
          <DayInStreakCircleBackground>
            <DayInStreakContainer active={dayId === chosenDay} onClick={() => setChosenDay(dayId)}>
              <CircularProgressbar
                value={percentageDayTasks}
                text={dayText}
                background
                styles={buildStyles({
                  // Text size
                  textSize: '21px',
                  // Colors
                  pathColor: `rgba(19, 238, 0, ${100 / 100})`,
                  textColor: '#ffffff',
                  trailColor: 'rgba(255, 255, 255, 1)',
                  backgroundColor: themeData?.themeC1,
                })}
              />
            </DayInStreakContainer>
          </DayInStreakCircleBackground>
        )
      } else {
        return (
          <DayInStreakContainer onClick={() => setChosenDay(dayId)}>
            <CircularProgressbar
              value={percentageDayTasks}
              text={dayText}
              background
              styles={buildStyles({
                // Text size
                textSize: '21px',
                // Colors
                pathColor: `rgba(19, 238, 0, ${100 / 100})`,
                textColor: '#ffffff',
                trailColor: 'rgba(255, 255, 255, 1)',
                backgroundColor: themeData?.themeC1,
              })}
            />
          </DayInStreakContainer>
        )
      }
    }
  }

  return (
    <PositionedStreakContainer>
      <CoinsOnWhiteBg style={{ cursor: 'pointer' }} onClick={() => navigate('/leaderboard')}>
        <img alt="gem_icon" src={require('src/assets/gem.png')} style={{ width: '24px' }} />
        {getFormattedCount(userScoreData?.coins || profileData?.coins)}
      </CoinsOnWhiteBg>

      {daysData.hasOwnProperty('2') ? null : (
        <GemImageStreak
          backgroundColor={DailyPlayColors.activeSlotHeadlineBgColor}
          style={{ cursor: 'default' }}
        >
          <img src={require('src/assets/gem.png')} style={{ width: '60%' }} />
        </GemImageStreak>
      )}

      {daysData.hasOwnProperty('1') ? null : (
        <GemImageStreak
          backgroundColor={DailyPlayColors.activeSlotHeadlineBgColor}
          style={{ cursor: 'default' }}
        >
          <img src={require('src/assets/gem.png')} style={{ width: '60%' }} />
        </GemImageStreak>
      )}

      {daysDataKeysOrdered?.map((item, index) => {
        return <div key={index}>{renderDayInStreakComponent(item)}</div>
      })}

      {/* 

            <DayInStreakCircleBackground>

                <DayInStreakContainer active>
                    <CircularProgressbar value={todayProgress} text={"Today"} background styles={buildStyles({
                        // Text size
                        textSize: '21px',
                        // Colors
                        pathColor: `${todayProgress > 50 ? 'rgb(19, 238, 0)' : 'rgb(255, 172, 54)'} `,
                        textColor: '#ffffff',
                        trailColor: 'rgba(255, 255, 255, 1)',
                        backgroundColor: DailyPlayColors.activeSlotHeadlineBgColor
                    })} />
                </DayInStreakContainer>

            </DayInStreakCircleBackground> */}
    </PositionedStreakContainer>
  )
}

export const BottomTextComponent = (props) => {
  return (
    <>
      <CheatCardHeading small>{props?.textOne}</CheatCardHeading>
      <CheatCardHeading>{props?.textTwo}</CheatCardHeading>\
      <CheatCardText>{props?.textThree}</CheatCardText>
    </>
  )
}

export const SentinelItem = (props) => {
  const { headings, text, imgId } = props

  return (
    <ActiveCardEndItem>
      {headings?.map((item, index) => {
        return (
          <ActiveCardEndItemText heading key={index}>
            {item}
          </ActiveCardEndItemText>
        )
      })}
      <ActiveCardEndItemText>{text}</ActiveCardEndItemText>
      {/* <ActiveCardEndItemImage src={imgId ? `${mediaURL}${imgId}` : require("src/assets/thumbs_up.png")} /> */}
    </ActiveCardEndItem>
  )
}

export const DailyPlaySettingsModalComponent = (props) => {
  const { open } = props

  const [opacity, setOpacity] = useState(0)

  function toggleModal(e) {
    setOpacity(0)
  }
  function afterOpen() {
    setTimeout(() => {
      setOpacity(1)
    }, 100)
  }
  function beforeClose() {
    return new Promise((resolve) => {
      setOpacity(0)
      setTimeout(resolve, 300)
    })
  }

  return (
    <ModalProvider backgroundComponent={FadingBackground}>
      <StyledDailyPlaySettingsModal
        isOpen={open}
        afterOpen={afterOpen}
        beforeClose={beforeClose}
        onEscapeKeydown={toggleModal}
        opacity={opacity}
        backgroundProps={{ opacity }}
      >
        {props.children}
      </StyledDailyPlaySettingsModal>
    </ModalProvider>
  )
}

export const cleanDataFunction = (data, repeatingKey = 'id') => {
  const visitedIds = new Set()
  return data?.filter((item) => {
    if (visitedIds?.has(item?.[repeatingKey])) {
      return false // skip repeating items
    }
    visitedIds?.add(item?.[repeatingKey])
    return true
  })
} // cleans array data and returns it by removing items with same value of repeatingKey

export const getSamePlayTypeItems = (timeslotData, playType) => {
  const tempArrayTitles = []
  const tempArrayIcons = []
  timeslotData?.forEach((item) => {
    if (item?.playType === playType) {
      if (item?.title) {
        tempArrayTitles.push(item?.title)
      }
      if (item?.iconId) {
        tempArrayIcons.push(item?.iconId)
      }
    }
  })
  return { titlesArray: tempArrayTitles, iconsArray: tempArrayIcons }
}

export const DailyPlaySettingsComponent = (props) => {
  const {
    closeFunction,
    dailyPlayContentTypesSectionWise,
    playTypes,
    userPlayConfig,
    setUserPlayConfig,
    timeSlotsData,
    renderedInProfileSettings,
  } = props

  const handleContentTypeClick = (event) => {
    setUserPlayConfig((prev) => {
      const tempPrev = prev?.enabledContentTypes
      const finalContentType = event.target.checked
        ? [...tempPrev, event.target.id]
        : tempPrev?.filter((contentType) => {
            return contentType !== event.target.id
          })
      return { ...prev, enabledContentTypes: finalContentType }
    })
  }

  return (
    <DailyPlaySettingsContainer>
      {renderedInProfileSettings ? (
        <SaveGoalsButton onClick={closeFunction}>Save</SaveGoalsButton>
      ) : (
        <DailyPlaySettingsCloseButton
          onClick={closeFunction}
          src={require('src/assets/close_icon_gray.png')}
        />
      )}
      <div>
        <DailyPlaySettingsHeading main>Personalize</DailyPlaySettingsHeading>
        <DailyPlaySettingsHeading>What would you like to see on your page</DailyPlaySettingsHeading>
      </div>
      <DailyPlaySettingsContentContainer>
        <DailyPlaySettingContent>
          <DailyPlaySettingContentItemContainer>
            <DailyPlaySettingPlayTypeText main style={{ marginBottom: '5px' }}>
              Daily Play
            </DailyPlaySettingPlayTypeText>
            {playTypes?.map((item, index) => {
              return (
                <DailyPlaySettingPlayTypeContainer
                  key={index}
                  onClick={() => setUserPlayConfig({ ...userPlayConfig, playType: item?.id })}
                >
                  <DisplayFlexSpaceBetween>
                    <DisplayFlexColumn>
                      <DailyPlaySettingPlayTypeText main>
                        {item?.title}
                      </DailyPlaySettingPlayTypeText>
                      <DailyPlaySettingPlayTypeText>
                        {getSamePlayTypeItems(timeSlotsData, item?.id)?.titlesArray?.join(', ')}
                      </DailyPlaySettingPlayTypeText>
                    </DisplayFlexColumn>
                    <EnableDisableDot enabled={userPlayConfig?.playType === item?.id} />
                  </DisplayFlexSpaceBetween>
                </DailyPlaySettingPlayTypeContainer>
              )
            })}
          </DailyPlaySettingContentItemContainer>
        </DailyPlaySettingContent>

        <DailyPlaySettingContent>
          {dailyPlayContentTypesSectionWise?.map((item, index) => {
            return (
              <DailyPlaySettingContentItemContainer key={index}>
                {item?.items?.map((sectionItem) => {
                  return (
                    <DisplayFlexSpaceBetween key={sectionItem?.id}>
                      <DailyPlaySettingsHeading style={{ lineHeight: '2' }}>
                        {sectionItem?.title}
                      </DailyPlaySettingsHeading>
                      <IosSwitch
                        id={sectionItem.id}
                        checked={userPlayConfig?.enabledContentTypes?.includes(sectionItem?.id)}
                        onChange={handleContentTypeClick}
                      />
                    </DisplayFlexSpaceBetween>
                  )
                })}
              </DailyPlaySettingContentItemContainer>
            )
          })}
        </DailyPlaySettingContent>
      </DailyPlaySettingsContentContainer>
    </DailyPlaySettingsContainer>
  )
}
