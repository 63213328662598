import React, { useContext, useEffect } from 'react'
import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from '@mui/material'
import { FCMContext, setNotificationsAreDeniedLocal } from '../../../context/FCMProvider'
import {
  EnableNotificationIcon,
  PushNotificationAction,
  PushNotificationComponentContent,
  PushNotificationHeading,
  StyledPushNotificationDialogActionButton,
  StyledPushNotificationDialogContent,
  StyledPushNotificationDialogText,
  StyledPushNotificationDialogTitle,
} from './styledPushNotificationsComponent'
import notificationEnable from '../../../assets/notification-enable.svg'
import { Close, InfoOutlined } from '@mui/icons-material'

export function PushNotificationComponent({ closeFunction }) {
  const { fcmToken, fcmStatus, fetchFCMToken, fetchingToken } = useContext(FCMContext)
  console.log(fcmStatus)

  useEffect(() => {
    if (fcmStatus === 'denied') {
      setNotificationsAreDeniedLocal(true)
    } else {
      sessionStorage.removeItem('notificationsAreDenied')
    }
  }, [fcmStatus])

  return (
    <>
      <DialogActions>
        <IconButton onClick={closeFunction}>
          <Close />
        </IconButton>
      </DialogActions>
      <StyledPushNotificationDialogContent>
        <EnableNotificationIcon
          src={notificationEnable}
          alt="notification-icon"
        ></EnableNotificationIcon>
        <PushNotificationComponentContent>
          <PushNotificationHeading>
            {fcmStatus === 'default' && (
              <div style={{ padding: '8px 0px' }}>
                <StyledPushNotificationDialogTitle>
                  Enable AUMHUM notifications
                </StyledPushNotificationDialogTitle>
                <StyledPushNotificationDialogText>
                  To access personalized content, you need to enable AUMHUM notifications.
                </StyledPushNotificationDialogText>
              </div>
            )}
            {fcmStatus === 'denied' && (
              <div style={{ padding: '10px 0px 0px 0px' }}>
                <StyledPushNotificationDialogTitle>
                  Notifications are blocked or not supported on your device
                </StyledPushNotificationDialogTitle>
                <StyledPushNotificationDialogText style={{ marginBottom: '15px' }}>
                  To unblock notifications for AUMHUM, follow these steps:
                </StyledPushNotificationDialogText>
                <StyledPushNotificationDialogText>
                  <strong>For Chrome, Brave and Edge: </strong>
                  <span>
                    - Info icon <InfoOutlined fontSize={'small'} /> in the address bar &gt;
                    Notifications
                  </span>
                </StyledPushNotificationDialogText>
                <StyledPushNotificationDialogText>
                  <strong>For Safari:</strong>
                  <span>- Safari Settings &gt; Websites &gt; Notifications &gt; AUMHUM</span>
                </StyledPushNotificationDialogText>
              </div>
            )}
          </PushNotificationHeading>
        </PushNotificationComponentContent>
        {/*Please allow AUMHUM notifications in your browser to use the app.*/}
      </StyledPushNotificationDialogContent>

      {fcmStatus === 'default' && (
        <PushNotificationAction>
          <StyledPushNotificationDialogActionButton onClick={fetchFCMToken}>
            {fetchingToken ? (
              <CircularProgress size={30} sx={{ color: '#e6c9ff' }} />
            ) : (
              'Enable Notifications'
            )}
          </StyledPushNotificationDialogActionButton>
        </PushNotificationAction>
      )}
    </>
  )
}
