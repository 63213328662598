import React from 'react'
import { createContext } from 'react'
import { useFetch } from '../services'

export const ThemeDataContext = createContext()

export const ThemeDataContextProvider = ({ children }) => {
  const {
    loading: themeDataLoading,
    data: themeData,
    error: themeDataError,
  } = useFetch('/api/theme', '', 'web')

  const value = { themeDataLoading, themeData, themeDataError }
  const valueSubstitute = {
    themeC1: '#2f2d3b',
    themeC2: '#424572',
    themeC3: '#5e4d9b',
    themeC4: '#6768ab',
    themeC5: '#cfcad1',
    themeC7: '#999999',
    themeC2p5: '#484977',
  }
  const businessTheme = {
    dark: {
      paywallBackground: '#000',
      paywallTrialHeadingColor: '#FFFFFF',
      paywallTrialHeadingHighlightColor: '#93F856',
      paywallTrialSubheadingColor: '#AFAFAF',
      paywallTrialBackground: '#111111',
      paywallTrialButtonColor: '#7352AC',
      paywallTrialButtonTextColor: '#fff',
      paywallPlansHeadingColor: '#fff',
      paywallPlanCardBackgroundMain: '#4E2D92',
      paywallPlanCardBackgroundSecondary: '#272727',

      bgEventsDropDown: '#37383D',
      bgDashboardGoals: '#2256C8',
      bgSidebar: '#5F2EC7',
      sidebarText: '#fff',
      widgetContainerBackground: '#25262a',
      statTileBackground: '#37383d',
      statTileTextColorHeading: '#fff',
      statTileTextColorSubHeading: '#d9bbfd',
      statCardBackground: '#37383d',
      statHeadingColor: '#a59eac',
      statCardHeadingColor: '#9f68da',
      statCardDataColor: '#bba2d6',
      leaderBoardStripHeaderBackground: '#1e1f23',
      leaderBoardStripBackground: '#37383d',
      leaderBoardUserStripBackground: '#53545a',
      leaderBoardTextColor: '#fff',
      badgeSubtitleColor: '#fff',
      badgeTitleColor: '#fff',
    },
    light: {
      paywallBackground: '#E3EBFF',
      paywallTrialHeadingColor: '#200769',
      paywallTrialHeadingHighlightColor: '#41AB00',
      paywallTrialSubheadingColor: '#6F6F6F',
      paywallTrialBackground: '#5F2EC7',
      paywallTrialButtonColor: '#41AB00',
      paywallTrialButtonTextColor: '#fff',
      paywallPlanCardBackgroundMain: '#7047C5',
      paywallPlanCardBackgroundSecondary: '#200769',
      paywallPlansHeadingColor: '#200769',

      bgEventsDropDown: '#E3EBFF',
      bgDashboardGoals: '#2256C8',
      bgSidebar: '#5F2EC7',
      sidebarText: '#fff',
      widgetContainerBackground: '#E3EBFF',
      statTileBackground: '#9DDFEA',
      statTileTextColorHeading: '#175E67',
      statTileTextColorSubHeading: '#200769',
      statHeadingColor: '#A59EAC',
      statCardBackground: '#9CDFE8',
      statCardHeadingColor: '#45939D',
      statCardDataColor: '#125760',
      leaderBoardStripHeaderBackground: '#E3EBFF',
      leaderBoardStripBackground: '#FFFFFF',
      leaderBoardUserStripBackground: '#5A74B6',
      leaderBoardTextColor: '#1016AE',
      badgeTitleColor: '#8087b9',
      badgeSubtitleColor: '#5d5d5d',
    },
  }

  return (
    <ThemeDataContext.Provider value={{ themeData: valueSubstitute, businessTheme: businessTheme }}>
      {children}
    </ThemeDataContext.Provider>
  )
}
