import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh'
import moment from 'moment'
import { useEffect, useMemo, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import 'react-quill/dist/quill.bubble.css'
import { useLocation } from 'react-router-dom'
import { components } from 'react-select'
import { withAsyncPaginate } from 'react-select-async-paginate'
import AsyncSelect from 'react-select/async'
import { default as Creatable, default as CreatableSelect } from 'react-select/creatable'
import { toast } from 'react-toastify'
import { RectangleRadioButton } from 'src/clinic/components/ClinicManageBookingsPage/ClinicManageBookingComponents'
import { sortArrayMomentBased } from 'src/clinic/components/ClinicManageBookingsPage/ClinicManageBookingsHelper'
import { getCPDataWithoutParams } from 'src/clinic/services'
import { AutocompleteHelperButton, CustomInput } from 'src/consumer/components/helperComponents'
import {
  HE_DRUG_ADVICE_DOSAGE,
  HE_DRUG_ADVICE_DURATION,
  HE_DRUG_ADVICE_FREQUENCY,
  HE_DRUG_ADVICE_NOTE,
  HE_DRUG_ADVICE_TIMING,
  HE_KEY_ADVICE,
  HE_KEY_DIAGNOSIS,
  HE_KEY_DRUGS,
  HE_KEY_HEALTH_COMPLAINTS,
  HE_KEY_INSTRUCTIONS,
  HE_KEY_OBSERVATIONS,
  PRESCRIPTION_LAYOUTS,
} from 'src/consumer/constants'
import JointHomunculusComponent from 'src/emr/components/swollenJointHighlighter/SwollenJointHighlighter'
import {
  addOrUpdateAdvicesInIdb,
  addOrUpdateDiagnosisInIdb,
  addOrUpdateDrugsInIdb,
  addOrUpdateHealthComplaintsInIdb,
  addOrUpdateInstructionsInIdb,
  addOrUpdateInvestigationInIdb,
  addOrUpdateObservationsInIdb,
  getAdvicesByIds,
  getDiagnosisByIds,
  getDrugsByIDs,
  getFunctionForAddOrUpdateHealthEntitySingle,
  getHealthComplaintsByIds,
  getInstructionsByIds,
  getInvestigationByIds as getInvestigationByIdsFromIdb,
  getObservationsByIds,
  searchAdvicesInIdb,
  searchDiagnosisInIdb,
  searchDrugsInIdb,
  searchHealthComplaintsInIdb,
  searchInstructionsInIdb,
  searchInvestigationInIdb,
  searchObservationsInIdb,
} from 'src/emr/database/indexedDbDao'
import { isCommonResponseSuccessful } from 'src/mentor/helpers/utilityFunctions'
import { getMPDataWithoutParams, postMPDataWithoutParams } from 'src/mentor/services'
import { getDrugItemOptionLabel, getDrugItemOptionValue } from './inputComponents/drugAdvice/utils'
import {
  PMPSectionInputBox,
  PMPSectionInputLabel,
  PMPSectionTextAreaBox,
  PMPSingleRowBox,
  PMPSingleRowForDrugs,
} from './PrescriptionMakingPageStyles'
import { DefaultNextVisitRowComponent } from './prescriptionTemplate/body/DefaultBodyComponents'
import { ThreeColumnNextVisitRowComponent } from './prescriptionTemplate/body/ThreeColumnFooterBodyComponents'
import DefaultPrescriptionFooter from './prescriptionTemplate/footer/DefaultPrescriptionFooter'
import ThreeColumnPrescriptionFooter from './prescriptionTemplate/footer/ThreeColumnPrescriptionFooter'
import TwoColumnPrescriptionFooter from './prescriptionTemplate/footer/TwoColumnPrescriptionFooter'
import TwoColumnPrescriptionWithDisclaimerFooter from './prescriptionTemplate/footer/TwoColumnPrescriptionWithDisclaimerFooter'
import DefaultPrescriptionHeader from './prescriptionTemplate/header/DefaultPrescriptionHeader'
import DefaultPatientDetails from './prescriptionTemplate/header/patientDetails/DefaultPatientDetails'
import ThreeColumnPrescriptionFooterPatientDetails from './prescriptionTemplate/header/patientDetails/ThreeColumnPrescriptionFooterPatientDetails'
import TwoColumnPrescriptionHeader from './prescriptionTemplate/header/TwoColumnPrescriptionHeader'
import { isHomunculusValid } from './utils'

export function getDateOfPrescription(prescriptionData, withTime) {
  let format = 'DD/MM/YYYY'
  if (withTime) {
    format = 'DD/MM/YYYY h:mmA'
  }
  if (
    prescriptionData?.prescriptionData?.createdTime ||
    prescriptionData?.prescriptionData?.modifiedTime
  ) {
    return moment
      .utc(
        prescriptionData?.prescriptionData?.createdTime ||
          prescriptionData?.prescriptionData?.modifiedTime,
        'YYYY-MM-DD HH:mm:ss',
      )
      .local()
      .format(format)
  }
  return moment().format(format)
}

const DrugItemsAdviceOption = (props) => {
  const { children, ...rest } = props

  const currentOption = props.data

  return (
    <components.Option {...rest}>
      <div className="d-flex justify-content-start align-items-center gap-4">
        {!currentOption?.__isNew__ ? (
          <>
            <div className="d-flex flex-column gap-2">
              <div>{children}</div>
              <div>{currentOption?.translations?.hi?.title}</div>
            </div>
          </>
        ) : (
          <>
            <span>Create: {currentOption?.value}</span>
          </>
        )}
      </div>
    </components.Option>
  )
}

const DrugItemsAdviceSelectedItemOption = ({ children, ...props }) => {
  const currentOption = props.data

  return (
    <components.SingleValue {...props}>
      <div className="d-flex flex-column gap-2">
        <div>{children}</div>
        <div>{currentOption?.translations?.hi?.title}</div>
      </div>
    </components.SingleValue>
  )
}

export async function putAllDrugsInDB() {
  // if (isDrugDatabaseUpdateNeeded()){
  //   try {
  //     const response = await getCPDataWithoutParams(
  //       '/api/secure/drug/list?search=&clinicId=CLN-ee233e80966946948dea2b518719081b&clinicBrandId=sakra-world-brand&page=1&pageSize=100',
  //     )
  //     const responseData = response?.data
  //     const indexedDB =
  //       window.indexedDB ||
  //       window.mozIndexedDB ||
  //       window.webkitIndexedDB ||
  //       window.msIndexedDB ||
  //       window.shimIndexedDB
  //     const dbRequest = indexedDB.open('amhmDB', 1)
  //     dbRequest.onerror = function (event) {
  //       console.error('IndexedDB error:', event.target.errorCode)
  //     }
  //     dbRequest.onupgradeneeded = function () {
  //       const db = dbRequest.result
  //       if (!db?.objectStoreNames?.contains('drugs')) {
  //         const store = db?.createObjectStore('drugs', { keyPath: 'id' })
  //         store.createIndex('title', ['title'], { unique: false })
  //       }
  //     }
  //     dbRequest.onsuccess = function () {
  //       const db = dbRequest.result
  //       const transaction = db.transaction('drugs', 'readwrite')
  //       const store = transaction.objectStore('drugs')
  //       const clearRequest = store.clear()
  //       clearRequest.onsuccess = function () {
  //         console.log('IDB-PUT: cleared old data')
  //         responseData.forEach((drug) => {
  //           store.put(drug)
  //         })
  //         console.log('IDB-PUT: New Data successfully stored')
  //         sessionStorage.setItem("drugDatabaseUpdated", moment().format())
  //       }
  //       clearRequest.onerror = function (event) {
  //         console.log('IDB-PUT: could not clear old data')
  //       }
  //     }
  //   } catch (error) {
  //     console.error('Putting all drugs in client DB failed:', error)
  //   }
  // } else {
  //   console.log('IDB-PUT: Drugs database update not needed.')
  // }
}

export function readAllDrugDataFromDB() {
  return new Promise((resolve, reject) => {
    const indexedDB =
      window.indexedDB ||
      window.mozIndexedDB ||
      window.webkitIndexedDB ||
      window.msIndexedDB ||
      window.shimIndexedDB

    const dbRequest = indexedDB.open('DrugData', 1)

    dbRequest.onerror = function (event) {
      console.error('IndexedDB error:', event.target.errorCode)
      reject(event.target.errorCode)
    }

    dbRequest.onsuccess = function () {
      const db = dbRequest.result
      const transaction = db.transaction('drugs', 'readonly')
      const store = transaction.objectStore('drugs')

      const getAllRequest = store.getAll()

      getAllRequest.onsuccess = function () {
        const drugs = getAllRequest.result
        resolve(drugs)
      }

      getAllRequest.onerror = function (event) {
        console.error('Error retrieving data from IndexedDB:', event.target.errorCode)
        reject(event.target.errorCode)
      }
    }
  })
}

export const PrescriptionHeader = ({ prescriptionData, leaveSpace }) => {
  const HeaderComponent = useMemo(() => {
    switch (prescriptionData?.layout) {
      case PRESCRIPTION_LAYOUTS.threeColumnFooter:
      case PRESCRIPTION_LAYOUTS.twoColumnFooter:
        return TwoColumnPrescriptionHeader
      default:
        return DefaultPrescriptionHeader
    }
  }, [prescriptionData?.layout])

  const PatientDetailsComponent = useMemo(() => {
    switch (prescriptionData?.layout) {
      case PRESCRIPTION_LAYOUTS.threeColumnFooter:
      case PRESCRIPTION_LAYOUTS.twoColumnFooter:
      case PRESCRIPTION_LAYOUTS.twoColumnWithDisclaimerFooter:
      case PRESCRIPTION_LAYOUTS.largerFontLayout:
        return ThreeColumnPrescriptionFooterPatientDetails
      default:
        return DefaultPatientDetails
    }
  }, [prescriptionData?.layout])

  return (
    <div style={{ background: '#FFF' }}>
      {!Boolean(leaveSpace) && <HeaderComponent prescriptionData={prescriptionData} />}
      {leaveSpace && <div style={{ height: '120px' }}></div>}
      <PatientDetailsComponent prescriptionData={prescriptionData} leaveSpace={leaveSpace} />
    </div>
  )
}

export const PrescriptionDrugAdviceTable = ({
  drugsAdvice,
  prescriptionData,
  prescriptionViewSettings,
}) => {
  const filteredDrugsAdvice = drugsAdvice?.filter((item) => item?.drug?.label)

  const showPrescriptionInHindi = prescriptionViewSettings
    ? prescriptionViewSettings?.preferredPrescriptionLanguages?.includes('hi')
    : prescriptionData?.prescriptionLanguages?.includes('hi')

  if (filteredDrugsAdvice?.length > 0) {
    return (
      <>
        <div style={{ paddingTop: '10px', fontWeight: 600, fontSize: '30px' }}>Rx</div>
        <PMPSingleRowForDrugs reduceLastBox style={{ width: '100%', margin: '0 auto' }}>
          {!showPrescriptionInHindi ? (
            <>
              <PMPSingleRowBox borderRight>Medicine</PMPSingleRowBox>
              <PMPSingleRowBox borderRight>Dose</PMPSingleRowBox>
              <PMPSingleRowBox borderRight>When</PMPSingleRowBox>
              <PMPSingleRowBox borderRight>Frequency</PMPSingleRowBox>
              <PMPSingleRowBox borderRight>Duration</PMPSingleRowBox>
              {/* <PMPSingleRowBox borderRight>Starting</PMPSingleRowBox> */}
              <PMPSingleRowBox borderRight>Notes</PMPSingleRowBox>
            </>
          ) : (
            <>
              <PMPSingleRowBox borderRight>दवाएँ</PMPSingleRowBox>
              <PMPSingleRowBox borderRight>डोज़</PMPSingleRowBox>
              <PMPSingleRowBox borderRight>कब</PMPSingleRowBox>
              <PMPSingleRowBox borderRight>आवृत्ति</PMPSingleRowBox>
              <PMPSingleRowBox borderRight>अवधि</PMPSingleRowBox>
              {/* <PMPSingleRowBox borderRight>Starting</PMPSingleRowBox> */}
              <PMPSingleRowBox borderRight>टिप्पणियाँ</PMPSingleRowBox>
            </>
          )}
        </PMPSingleRowForDrugs>
        {filteredDrugsAdvice?.map((drugAdviceItem, drugAdviceItemIndex) => {
          return (
            <PMPSingleRowForDrugs
              reduceLastBox
              key={drugAdviceItemIndex}
              style={{ width: '100%', margin: '0 auto' }}
            >
              <PMPSingleRowBox whiteBG borderRight>
                {drugAdviceItem?.drug?.label}
              </PMPSingleRowBox>
              <PMPSingleRowBox whiteBG borderRight>
                {drugAdviceItem?.dosage?.label}
              </PMPSingleRowBox>
              <PMPSingleRowBox whiteBG borderRight>
                {drugAdviceItem?.whatTime?.label}
              </PMPSingleRowBox>
              <PMPSingleRowBox whiteBG borderRight>
                {drugAdviceItem?.frequency?.label}
              </PMPSingleRowBox>
              <PMPSingleRowBox whiteBG borderRight>
                {drugAdviceItem?.duration?.label}
              </PMPSingleRowBox>
              <PMPSingleRowBox whiteBG borderRight>
                {drugAdviceItem?.notes?.label}
              </PMPSingleRowBox>
            </PMPSingleRowForDrugs>
          )
        })}
      </>
    )
  } else {
    return null
  }
}

export const PrescriptionBodyFirstSection = ({
  selectedObservations,
  selectedComplaints,
  selectedDiagnosis,
  prescriptionData,
}) => {
  var layoutStyles
  var lenOfData = useMemo(() => {
    var len = 0
    if (Array.isArray(selectedObservations) && selectedObservations?.length > 0) {
      len += 1
    }
    if (Array.isArray(selectedComplaints) && selectedComplaints?.length > 0) {
      len += 1
    }
    if (Array.isArray(selectedDiagnosis) && selectedDiagnosis?.length > 0) {
      len += 1
    }
    return len || 1
  }, [selectedObservations, selectedComplaints, selectedDiagnosis])

  switch (prescriptionData?.layout) {
    case PRESCRIPTION_LAYOUTS.largerFontLayout:
      layoutStyles = {
        display: 'grid',
        gridTemplateColumns: `repeat(${lenOfData}, 1fr)`,
        gap: '10px',
        width: '100%',
      }
      break
    default:
      layoutStyles = {}
      break
  }

  return (
    <div style={layoutStyles}>
      {Array.isArray(selectedObservations) && selectedObservations?.length > 0 && (
        <div>
          <strong>Observations</strong>
          <div>{selectedObservations.map((observation) => observation.label).join(', ')}</div>
        </div>
      )}
      {Array.isArray(selectedComplaints) && selectedComplaints?.length > 0 && (
        <div>
          <strong>Complaints</strong>
          <div>{selectedComplaints.map((complaint) => complaint.label).join(', ')}</div>
        </div>
      )}
      {Array.isArray(selectedDiagnosis) && selectedDiagnosis?.length > 0 && (
        <div>
          <strong>Diagnosis</strong>
          <div>{selectedDiagnosis.map((diagnosisItem) => diagnosisItem.label).join(', ')}</div>
        </div>
      )}
    </div>
  )
}

export const PrescriptionBody = ({
  value,
  setValue,
  placeholder,
  alsoChangePropValue,
  setPropValue,
  selectedTherapies,
  selectedAdvice,
  nextVisitInputType,
  nextVisitInputValue,
  prescriptionData,
  selectedInstructions,
  suggestedBookingTherapy,
  selectedInvestigations,
  jointHomunculusData,
  prescriptionViewSettings,
}) => {
  const showHomunculus = useMemo(() => {
    return isHomunculusValid(jointHomunculusData)
  }, [jointHomunculusData])

  const NextVisitColumn = useMemo(() => {
    switch (prescriptionData?.layout) {
      case PRESCRIPTION_LAYOUTS.threeColumnFooter:
      case PRESCRIPTION_LAYOUTS.largerFontLayout:
      case PRESCRIPTION_LAYOUTS.twoColumnFooter:
        return ThreeColumnNextVisitRowComponent
      default:
        return DefaultNextVisitRowComponent
    }
  }, [prescriptionData?.layout])

  useEffect(() => {
    if (alsoChangePropValue) {
      setPropValue(value)
    }
  }, [value])

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', paddingTop: '20px' }}>
      {Array.isArray(selectedAdvice) && selectedAdvice.length > 0 && (
        <div>
          <strong>Advice</strong>
          <div style={{ whiteSpace: 'pre-wrap' }}>
            {selectedAdvice?.map((item) => item?.label)?.join(', ')}
          </div>
        </div>
      )}
      {Array.isArray(selectedInvestigations) && selectedInvestigations.length > 0 && (
        <div>
          <strong>Advised Investigations</strong>
          <div>{selectedInvestigations?.map((item) => item?.label)?.join(', ')}</div>
        </div>
      )}
      {suggestedBookingTherapy && (
        <div>
          <strong>Therapy Recommended:</strong> {suggestedBookingTherapy ? 'Yes' : 'No'}
        </div>
      )}
      {Array.isArray(selectedTherapies) && selectedTherapies.length > 0 && (
        <div>
          <strong>Suggested Therapies</strong>
          <div>{selectedTherapies?.map((item) => item?.label)?.join(', ')}</div>
        </div>
      )}
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        {showHomunculus && <JointHomunculusComponent editable={false} data={jointHomunculusData} />}
      </div>{' '}
      {Array.isArray(selectedInstructions) && selectedInstructions.length > 0 && (
        <div>
          <strong>Advised Instructions</strong>
          <ul className="d-flex flex-column">
            {selectedInstructions?.map((item, idx) => (
              <li key={idx}>{item?.label}</li>
            ))}
          </ul>
        </div>
      )}
      <NextVisitColumn
        nextVisitInputType={nextVisitInputType}
        nextVisitInputValue={nextVisitInputValue}
        prescriptionData={prescriptionData}
      />
    </div>
  )
}

export const PrescriptionFooter = ({ prescriptionData, leaveSpace }) => {
  const location = useLocation()
  const queryString = location.search
  const urlParams = new URLSearchParams(queryString)
  const showQr = urlParams.get('show-qr') || false

  const FooterComponent = useMemo(() => {
    switch (prescriptionData?.layout) {
      case PRESCRIPTION_LAYOUTS.threeColumnFooter:
        return ThreeColumnPrescriptionFooter
      case PRESCRIPTION_LAYOUTS.twoColumnFooter:
        return TwoColumnPrescriptionFooter
      case PRESCRIPTION_LAYOUTS.twoColumnWithDisclaimerFooter:
        return TwoColumnPrescriptionWithDisclaimerFooter
      default:
        return DefaultPrescriptionFooter
    }
  }, [prescriptionData?.layout])

  if (leaveSpace) {
    return <div style={{ height: '120px' }}></div>
  }

  return <FooterComponent prescriptionData={prescriptionData} noColor={true} hideQr={!showQr} />
}

/////////////////////////////////////////////////////////////////////// NEW prescription drug selection related components:

export const commonStylesPrescriptionSelect = {
  menuList: (provided, state) => ({
    ...provided,
    maxHeight: '200px',
    overflowY: 'scroll',
    scrollbarWidth: 'thin',
    height: '100%',
    '::-webkit-scrollbar': {
      width: '5px',
    },
    '::-webkit-scrollbar-track': {
      background: '#fafafa',
    },
    '::-webkit-scrollbar-thumb': {
      background: '#888',
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
  }),
  menu: (provided, state) => ({
    ...provided,
    borderRadius: 0,
  }),
  control: (provided, state) => ({
    ...provided,
    borderRadius: 0,
    backgroundColor: state.menuIsOpen ? '#fff8dc' : '#FFF',
    border: state.isFocused ? '1px solid green' : provided.border,
    boxShadow: 'none',
    '&:hover': {
      border: '1px solid green',
    },
    height: '100%',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none', // Hide the default arrow separator
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    display: 'none', // Hide the default dropdown arrow
  }),
  singleValue: (provided, state) => ({
    ...provided,
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
  }),
}

export const loadOptionsDrugs = async (
  search,
  loadedOptions,
  { page, clinicId, clinicBrandId, recommendedDrugs = [] },
) => {
  var optionsArray = []

  const filteredDrugsFromDb = await searchDrugsInIdb(search) // Search all drugs from IndexedDB
  const indexedDbOptions = filteredDrugsFromDb.map((drug) => ({
    ...drug,
    value: drug?.title,
    label: drug?.title,
  }))

  optionsArray = [...indexedDbOptions]

  var hasMore = true
  if (indexedDbOptions.length === 0) {
    const response = await getCPDataWithoutParams(
      `/api/secure/drug/list?search=${search}&clinicId=${clinicId}&clinicBrandId=${clinicBrandId}&page=${page}&pageSize=10`,
    )
    if (response.status === 200 && Array.isArray(response.data) && response.data.length > 0) {
      addOrUpdateDrugsInIdb(response.data)
      optionsArray = response?.data?.map((option, index) => {
        const title = `${option?.title || ''} ${
          !!option?.unit && option?.qty ? `(${option?.qty} ${option?.unit})` : ''
        }`
        return {
          ...option,
          value: title,
          label: title,
        }
      })
    } else {
      hasMore = false
    }
  } else {
    hasMore = false
  }

  if (page === 1 && !search) {
    var foundRecommendedDrugs = await getDrugsByIDs(recommendedDrugs)
    if ((foundRecommendedDrugs?.length || 0) < (recommendedDrugs?.length || 0)) {
      const missingDrugIds = recommendedDrugs?.filter((investId) => {
        return !foundRecommendedDrugs?.find((invest) => invest.id === investId)
      })
      const missingResp = await getCPDataWithoutParams(
        `/api/secure/doctor/drug/list?ids=${missingDrugIds.join(',')}`,
      )
      if (
        missingResp.status === 200 &&
        Array.isArray(missingResp.data) &&
        missingResp.data.length > 0
      ) {
        addOrUpdateDrugsInIdb(missingResp.data)
        foundRecommendedDrugs = [...foundRecommendedDrugs, ...missingResp.data]
      }
    }
    optionsArray = [
      ...foundRecommendedDrugs?.map((drug) => ({
        ...drug,
        value: drug?.title,
        label: drug?.title,
      })),
      ...optionsArray,
    ]
  }
  return {
    options: optionsArray.map((drug) => ({
      ...drug,
    })),
    hasMore,
    additional: {
      page: page + 1,
      clinicId,
    },
  }
}

export const DrugSelectMenu = ({
  clinicId,
  clinicBrandId,
  selectedDrug,
  setSelectedDrug,
  recommendedDrugs,
  doctorSpecializations = [],
}) => {
  const CreatableAsyncPaginate = withAsyncPaginate(Creatable)

  return (
    <CreatableAsyncPaginate
      id="drugSelection"
      classNamePrefix="drug_name_selection"
      placeholder=""
      isClearable={true}
      isSearchable={true}
      defaultOptions={false}
      loadOptions={loadOptionsDrugs}
      getOptionValue={(option) => option?.label}
      getOptionLabel={(option) => option?.label}
      value={selectedDrug}
      onChange={(value) => {
        setSelectedDrug(value)
      }}
      debounceTimeout={200}
      additional={{ page: 1, clinicId: clinicId, clinicBrandId: clinicBrandId, recommendedDrugs }}
      styles={commonStylesPrescriptionSelect}
    />
  )
}

export const DosageSelectMenu = ({
  allowEnteringValue,
  options,
  selectedDosage,
  setSelectedDosage,
  prescriptionViewSettings,
}) => {
  const selectOptions = options.map(
    (optn) => {
      return {
        ...optn,
        label: getDrugItemOptionLabel(optn, prescriptionViewSettings),
        value: getDrugItemOptionLabel(optn, prescriptionViewSettings),
      }
    },
    [options],
  )

  return (
    <CreatableSelect
      id="dosageSelection"
      classNamePrefix="drug_dosage_selection"
      placeholder=""
      isClearable={true}
      isSearchable={true}
      options={selectOptions}
      getOptionValue={(option) => option?.label}
      getOptionLabel={(option) => option?.label}
      value={selectedDosage}
      onChange={(value) => {
        allowEnteringValue && setSelectedDosage(value)
      }}
      styles={commonStylesPrescriptionSelect}
    />
  )
}

export const WhatTimeSelectMenu = ({
  allowEnteringValue,
  selectedWhatTime,
  setSelectedWhatTime,
  options,
  prescriptionViewSettings,
}) => {
  const optionsArray = options?.map((option, index) => {
    return {
      ...option,
      value: getDrugItemOptionValue(option, prescriptionViewSettings),
      label: getDrugItemOptionLabel(option, prescriptionViewSettings),
    }
  })

  return (
    <CreatableSelect
      id="whatTimeSelection"
      classNamePrefix="drug_what_time_selection"
      placeholder=""
      isClearable={true}
      isSearchable={true}
      options={optionsArray}
      getOptionValue={(option) => option?.label}
      getOptionLabel={(option) => option?.label}
      value={selectedWhatTime}
      onChange={(value) => {
        allowEnteringValue && setSelectedWhatTime(value)
      }}
      styles={commonStylesPrescriptionSelect}
    />
  )
}

export const FrequencySelectMenu = ({
  allowEnteringValue,
  selectedFrequency,
  setSelectedFrequency,
  options,
  prescriptionViewSettings,
}) => {
  const optionsArray = options?.map((option, index) => {
    return {
      ...option,
      value: getDrugItemOptionValue(option, prescriptionViewSettings),
      label: getDrugItemOptionLabel(option, prescriptionViewSettings),
    }
  })

  return (
    <CreatableSelect
      id="frequencySelection"
      classNamePrefix="drug_frequency_selection"
      placeholder=""
      isClearable={true}
      isSearchable={true}
      options={optionsArray}
      getOptionValue={(option) => option?.label}
      getOptionLabel={(option) => option?.label}
      value={selectedFrequency}
      onChange={(value) => {
        allowEnteringValue && setSelectedFrequency(value)
      }}
      styles={commonStylesPrescriptionSelect}
    />
  )
}

export const DurationSelectMenu = ({
  allowEnteringValue,
  selectedDurationTime,
  setSelectedDurationTime,
  options,
  prescriptionViewSettings,
}) => {
  const optionsArray = options?.map((option, index) => {
    return {
      ...option,
      value: getDrugItemOptionValue(option, prescriptionViewSettings),
      label: getDrugItemOptionLabel(option, prescriptionViewSettings),
    }
  })

  return (
    <CreatableSelect
      id="durationSelection"
      classNamePrefix="drug_duration_selection"
      placeholder=""
      isClearable={true}
      isSearchable={true}
      options={optionsArray}
      getOptionValue={(option) => option?.label}
      getOptionLabel={(option) => option?.label}
      value={selectedDurationTime}
      onChange={(value) => {
        allowEnteringValue && setSelectedDurationTime(value)
      }}
      styles={commonStylesPrescriptionSelect}
    />
  )
}

export const NotesSelectMenu = ({
  allowEnteringValue,
  selectedNote,
  setSelectedNote,
  options,
  prescriptionViewSettings,
}) => {
  const [extraOptions, setExtraOptions] = useState([])

  const optionsArray = options?.map((option, index) => {
    return {
      ...option,
      value: getDrugItemOptionValue(option, prescriptionViewSettings),
      label: getDrugItemOptionLabel(option, prescriptionViewSettings),
    }
  })

  async function checkAndCheckForNewOption(inputValue) {
    console.log({ inputValue })
    if (
      !inputValue ||
      !optionsArray.filter((option) =>
        option?.label.toLowerCase().includes(inputValue?.toLowerCase()),
      ).length
    ) {
      setExtraOptions([])
      return
    }
    setExtraOptions([
      {
        __isNew__: true,
        value: inputValue,
        label: inputValue,
        data: {
          title: inputValue,
          translations: {
            hi: {
              title: inputValue,
            },
          },
        },
      },
      {
        __isNew__: true,
        value: inputValue,
        label: inputValue,
        data: {
          title: inputValue,
          translations: {
            hi: {
              title: inputValue,
            },
          },
        },
      },
    ])
  }

  const customFilter = (option, searchText) => {
    if (option.data.label.toLowerCase().includes(searchText.toLowerCase())) {
      return true
    } else {
      return false
    }
  }

  useEffect(() => {
    console.log({ extraOptions })
  }, [extraOptions])

  return (
    <CreatableSelect
      id="notesSelection"
      classNamePrefix="drug_notes_selection"
      placeholder=""
      isClearable={true}
      isSearchable={true}
      // options={optionsArray}
      options={[...(optionsArray || [])]}
      filterOption={customFilter}
      getOptionValue={(option) => option?.label}
      getOptionLabel={(option) => option?.label}
      // onInputChange={(newValue) => {
      //   checkAndCheckForNewOption(newValue)
      // }}
      value={selectedNote}
      onChange={(value) => {
        allowEnteringValue && setSelectedNote(value)
      }}
      styles={commonStylesPrescriptionSelect}
    />
  )
}

////////////////////////////////////////////////////////////////////////// NEW prescription components

export const PrescriptionTextAreaComponent = ({ inputValue, setInputValue }) => {
  return (
    <PMPSectionTextAreaBox value={inputValue} onChange={(e) => setInputValue(e.target.value)} />
  )
}

export const loadOptionsComplaints = async (
  search,
  loadedOptions,
  { page, clinicId, clinicBrandId, recommendedHealthComplaintIds, prescriptionViewSettings },
) => {
  var optionsArray = []

  const filteredComplaintsFromDb = await searchHealthComplaintsInIdb(search) // Search all complaints from IndexedDB
  const indexedDbOptions = filteredComplaintsFromDb.map((complaint) =>
    getHealthEntityDataWithLabelAndValue(complaint, prescriptionViewSettings),
  )

  optionsArray = [...indexedDbOptions]

  var hasMore = true
  if (indexedDbOptions.length === 0) {
    const response = await getCPDataWithoutParams(
      `/api/secure/doctor/health-complaint/list?search=${search}&clinicId=${clinicId}&clinicBrandId=${clinicBrandId}&page=${page}&pageSize=10`,
    )
    if (response.status === 200 && Array.isArray(response.data) && response.data.length > 0) {
      addOrUpdateHealthComplaintsInIdb(response.data)
      optionsArray = response?.data?.map((option, index) =>
        getHealthEntityDataWithLabelAndValue(option, prescriptionViewSettings),
      )
    } else {
      hasMore = false
    }
  } else {
    hasMore = false
  }
  if (page === 1 && !search) {
    var foundHealthComplaints = await getHealthComplaintsByIds(recommendedHealthComplaintIds)
    if ((foundHealthComplaints?.length || 0) < (recommendedHealthComplaintIds?.length || 0)) {
      const missingComplaintIds = recommendedHealthComplaintIds?.filter((investId) => {
        return !foundHealthComplaints?.find((invest) => invest.id === investId)
      })
      const missingResp = await getCPDataWithoutParams(
        `/api/secure/doctor/health-complaint/list?ids=${missingComplaintIds.join(',')}`,
      )
      if (
        missingResp.status === 200 &&
        Array.isArray(missingResp.data) &&
        missingResp.data.length > 0
      ) {
        addOrUpdateHealthComplaintsInIdb(missingResp.data)
        foundHealthComplaints = [...foundHealthComplaints, ...missingResp.data]
      }
    }
    optionsArray = [
      ...foundHealthComplaints?.map((complaint) =>
        getHealthEntityDataWithLabelAndValue(complaint, prescriptionViewSettings),
      ),
      ...optionsArray,
    ]
  }

  return {
    options: optionsArray,
    hasMore,
    additional: {
      page: page + 1,
      clinicId,
      prescriptionViewSettings,
    },
  }
}

export const loadOptionsDiagnosis = async (
  search,
  loadedOptions,
  { page, clinicId, clinicBrandId, recommendedDiagnosisIds, prescriptionViewSettings },
) => {
  var optionsArray = []

  const filteredDiagnosisFromDb = await searchDiagnosisInIdb(search) // Search all diagnosis from IndexedDB
  const indexedDbOptions = filteredDiagnosisFromDb.map((diagnosis) =>
    getHealthEntityDataWithLabelAndValue(diagnosis, prescriptionViewSettings),
  )

  optionsArray = [...indexedDbOptions]

  var hasMore = true
  if (indexedDbOptions.length === 0) {
    const response = await getCPDataWithoutParams(
      `/api/secure/doctor/diagnosis/list?search=${search}&clinicId=${clinicId}&clinicBrandId=${clinicBrandId}&page=${page}&pageSize=10`,
    )
    if (response.status === 200 && Array.isArray(response.data) && response.data.length > 0) {
      addOrUpdateDiagnosisInIdb(response?.data)
      optionsArray = response?.data?.map((option, index) =>
        getHealthEntityDataWithLabelAndValue(option, prescriptionViewSettings),
      )
    } else {
      hasMore = false
    }
  } else {
    hasMore = false
  }

  if (page === 1 && !search) {
    var foundDiagnosis = await getDiagnosisByIds(recommendedDiagnosisIds)
    if ((foundDiagnosis?.length || 0) < (recommendedDiagnosisIds?.length || 0)) {
      const missingDiagnosisIds = recommendedDiagnosisIds?.filter((investId) => {
        return !foundDiagnosis?.find((invest) => invest.id === investId)
      })
      const missingResp = await getCPDataWithoutParams(
        `/api/secure/doctor/diagnosis/list?ids=${missingDiagnosisIds.join(',')}`,
      )
      if (
        missingResp.status === 200 &&
        Array.isArray(missingResp.data) &&
        missingResp.data.length > 0
      ) {
        addOrUpdateDiagnosisInIdb(missingResp.data)
        foundDiagnosis = [...foundDiagnosis, ...missingResp.data]
      }
    }
    optionsArray = [
      ...foundDiagnosis?.map((diagnosis) =>
        getHealthEntityDataWithLabelAndValue(diagnosis, prescriptionViewSettings),
      ),
      ...optionsArray,
    ]
  }

  return {
    options: optionsArray,
    hasMore,
    additional: {
      page: page + 1,
      clinicId,
      prescriptionViewSettings,
    },
  }
}

export const loadOptionsInvestigations = async (
  search,
  loadedOptions,
  { page, clinicId, clinicBrandId, recommendedInvestigationIds, prescriptionViewSettings },
) => {
  var optionsArray = []

  const filteredInvestigationsFromDb = await searchInvestigationInIdb(search) // Search all complaints from IndexedDB
  const indexedDbOptions = filteredInvestigationsFromDb.map((investigation) =>
    getHealthEntityDataWithLabelAndValue(investigation, prescriptionViewSettings),
  )

  optionsArray = [...indexedDbOptions]

  var hasMore = true
  if (indexedDbOptions.length === 0) {
    const response = await getCPDataWithoutParams(
      `/api/secure/doctor/investigation/list?search=${search}&clinicId=${clinicId}&clinicBrandId=${clinicBrandId}&page=${page}&pageSize=10`,
    )
    if (response.status === 200 && Array.isArray(response.data) && response.data.length > 0) {
      addOrUpdateInvestigationInIdb(response.data)
      optionsArray = response?.data?.map((option, index) =>
        getHealthEntityDataWithLabelAndValue(option, prescriptionViewSettings),
      )
    } else {
      hasMore = false
    }
  } else {
    hasMore = false
  }

  if (page === 1 && !search) {
    var foundRecommendedInvestigations = await getInvestigationByIdsFromIdb(
      recommendedInvestigationIds,
    )
    if (
      (foundRecommendedInvestigations?.length || 0) < (recommendedInvestigationIds?.length || 0)
    ) {
      const missingInvestigationsIds = recommendedInvestigationIds?.filter((investId) => {
        return !foundRecommendedInvestigations?.find((invest) => invest.id === investId)
      })
      const missingResp = await getCPDataWithoutParams(
        `/api/secure/doctor/investigation/list?ids=${missingInvestigationsIds.join(',')}`,
      )
      if (
        missingResp.status === 200 &&
        Array.isArray(missingResp.data) &&
        missingResp.data.length > 0
      ) {
        addOrUpdateInvestigationInIdb(missingResp.data)
        foundRecommendedInvestigations = [...foundRecommendedInvestigations, ...missingResp.data]
      }
    }
    optionsArray = [
      ...foundRecommendedInvestigations?.map((investigation) =>
        getHealthEntityDataWithLabelAndValue(investigation, prescriptionViewSettings),
      ),
      ...optionsArray,
    ]
  }

  return {
    options: optionsArray,
    hasMore,
    additional: {
      page: page + 1,
      clinicId,
      prescriptionViewSettings,
    },
  }
}

export const loadOptionsObservations = async (
  search,
  loadedOptions,
  {
    page,
    clinicId,
    clinicBrandId,
    inDropDown = false,
    prescriptionViewSettings,
    recommendedObservations,
  },
) => {
  var optionsArray = []

  const filteredObservationsFromDb = (await searchObservationsInIdb(search)).slice(0, 500) // Search all observations from IndexedDB
  const indexedDbOptions = filteredObservationsFromDb.map((observation) =>
    getHealthEntityDataWithLabelAndValue(observation, prescriptionViewSettings),
  )

  optionsArray = [...indexedDbOptions]

  var hasMore = true
  if (indexedDbOptions.length === 0) {
    const response = await getCPDataWithoutParams(
      `/api/secure/doctor/health-observation/list?search=${search}&clinicId=${clinicId}&clinicBrandId=${clinicBrandId}&page=${page}&pageSize=10`,
    )
    if (response.status === 200 && Array.isArray(response.data) && response.data.length > 0) {
      addOrUpdateObservationsInIdb(response.data)
      optionsArray = response?.data?.map((option, index) =>
        getHealthEntityDataWithLabelAndValue(option, prescriptionViewSettings),
      )
    } else {
      hasMore = false
    }
  } else {
    hasMore = false
  }

  if (page === 1 && !search) {
    var foundHealthObservations = await getObservationsByIds(recommendedObservations)
    if ((foundHealthObservations?.length || 0) < (recommendedObservations?.length || 0)) {
      const missingObservationIds = recommendedObservations?.filter((investId) => {
        return !foundHealthObservations?.find((invest) => invest.id === investId)
      })
      const missingResp = await getCPDataWithoutParams(
        `/api/secure/doctor/health-observation/list?ids=${missingObservationIds.join(',')}`,
      )
      if (
        missingResp.status === 200 &&
        Array.isArray(missingResp.data) &&
        missingResp.data.length > 0
      ) {
        addOrUpdateObservationsInIdb(missingResp.data)
        foundHealthObservations = [...foundHealthObservations, ...missingResp.data]
      }
    }
    optionsArray = [
      ...foundHealthObservations?.map((adv) =>
        getHealthEntityDataWithLabelAndValue(adv, prescriptionViewSettings),
      ),
      ...optionsArray,
    ]
  }

  return {
    options: optionsArray,
    hasMore,
    additional: {
      page: page + 1,
      clinicId,
      inDropDown,
      prescriptionViewSettings,
    },
  }
}

export const loadOptionsAdvice = async (
  search,
  loadedOptions,
  { page, size = 10, clinicId, clinicBrandId, prescriptionViewSettings, recommendedAdvices },
) => {
  var optionsArray = []

  const filteredAdvicesFromDb = await searchAdvicesInIdb(search) // Search all drugs from IndexedDB
  const indexedDbOptions = filteredAdvicesFromDb.map((advice) =>
    getHealthEntityDataWithLabelAndValue(advice, prescriptionViewSettings),
  )

  optionsArray = [...indexedDbOptions]

  var hasMore = true
  if (indexedDbOptions.length === 0) {
    const response = await getCPDataWithoutParams(
      `/api/secure/doctor/prescription-advice/list?search=${search}&clinicId=${clinicId}&clinicBrandId=${clinicBrandId}&page=${page}&pageSize=${size}`,
    )
    if (response.status === 200 && Array.isArray(response.data) && response.data.length > 0) {
      addOrUpdateAdvicesInIdb(response.data)
      optionsArray = response?.data?.map((option, index) => {
        return getHealthEntityDataWithLabelAndValue(option, prescriptionViewSettings)
      })
    } else {
      hasMore = false
    }
  } else {
    hasMore = false
  }

  if (page === 1 && !search) {
    var foundPrescriptionAdvices = await getAdvicesByIds(recommendedAdvices)
    if ((foundPrescriptionAdvices?.length || 0) < (recommendedAdvices?.length || 0)) {
      const missingAdviceIds = recommendedAdvices?.filter((investId) => {
        return !foundPrescriptionAdvices?.find((invest) => invest.id === investId)
      })
      const missingResp = await getCPDataWithoutParams(
        `/api/secure/doctor/prescription-advice/list?ids=${missingAdviceIds.join(',')}`,
      )
      if (
        missingResp.status === 200 &&
        Array.isArray(missingResp.data) &&
        missingResp.data.length > 0
      ) {
        addOrUpdateAdvicesInIdb(missingResp.data)
        foundPrescriptionAdvices = [...foundPrescriptionAdvices, ...missingResp.data]
      }
    }
    optionsArray = [
      ...foundPrescriptionAdvices?.map((adv) =>
        getHealthEntityDataWithLabelAndValue(adv, prescriptionViewSettings),
      ),
      ...optionsArray,
    ]
  }

  return {
    options: optionsArray,
    hasMore,
    additional: {
      page: page + 1,
      clinicId,
      prescriptionViewSettings,
    },
  }
}

export const loadOptionsInstructions = async (
  search,
  loadedOptions,
  { page, size = 10, clinicId, clinicBrandId, prescriptionViewSettings, recommendedInstructionIds },
) => {
  var optionsArray = []

  const filteredInstructionsFromDb = await searchInstructionsInIdb(search) // Search all instructions from IndexedDB

  const indexedDbOptions = filteredInstructionsFromDb.map((instruction) =>
    getHealthEntityDataWithLabelAndValue(instruction, prescriptionViewSettings),
  )
  optionsArray = [...indexedDbOptions]

  var hasMore = true
  if (indexedDbOptions.length === 0) {
    const response = await getCPDataWithoutParams(
      `/api/secure/doctor/instruction/list?search=${search}&clinicBrandId=${clinicBrandId}&page=${page}&pageSize=${size}`,
    )
    if (response.status === 200 && Array.isArray(response.data) && response.data.length > 0) {
      addOrUpdateInstructionsInIdb(response.data)
      optionsArray = response?.data?.map((option, index) =>
        getHealthEntityDataWithLabelAndValue(option, prescriptionViewSettings),
      )
    } else {
      hasMore = false
    }
  } else {
    hasMore = false
  }

  if (page === 1 && !search) {
    var foundInstructions = await getInstructionsByIds(recommendedInstructionIds)
    if ((foundInstructions?.length || 0) < (recommendedInstructionIds?.length || 0)) {
      const missingInstructionIds = recommendedInstructionIds?.filter((investId) => {
        return !foundInstructions?.find((invest) => invest.id === investId)
      })
      const missingResp = await getCPDataWithoutParams(
        `/api/secure/doctor/instruction/list?ids=${missingInstructionIds.join(',')}`,
      )
      if (
        missingResp.status === 200 &&
        Array.isArray(missingResp.data) &&
        missingResp.data.length > 0
      ) {
        addOrUpdateInstructionsInIdb(missingResp.data)
        foundInstructions = [...foundInstructions, ...missingResp.data]
      }
    }
    optionsArray = [
      ...foundInstructions?.map((adv) =>
        getHealthEntityDataWithLabelAndValue(adv, prescriptionViewSettings),
      ),
      ...optionsArray,
    ]
  }

  return {
    options: optionsArray,
    hasMore,
    additional: {
      page: page + 1,
      clinicId,
      prescriptionViewSettings,
    },
  }
}

const loadOptionsSuggestedTherapies = async (search, loadedOptions, { page, clinicId }) => {
  const response = await getCPDataWithoutParams(
    `/api/secure/technique/paginated/list/searchable?search=${search}&page=${page}&pageSize=10`,
  )
  var hasMore = true
  var optionsArray = []

  if (response.status === 200 && Array.isArray(response.data) && response.data.length > 0) {
    optionsArray = response?.data?.map((option, index) => {
      return {
        ...option,
        value: option?.title,
        label: option?.title,
      }
    })
  } else {
    hasMore = false
  }

  return {
    options: optionsArray,
    hasMore,
    additional: {
      page: page + 1,
      clinicId,
    },
  }
}

const loadOptionsTherapists = async () => {
  const response = await getCPDataWithoutParams(
    `/api/external/mentor/1on1/list?categories=clinical_psychologist&page=1&pageSize=100`,
  )
  var optionsArray = []

  if (response.status === 200 && Array.isArray(response.data) && response.data.length > 0) {
    optionsArray = response?.data?.map((option, index) => {
      return {
        ...option,
        value: option?.id,
        label: option?.name,
      }
    })
  }
  return optionsArray
}

function getHealthEntityDataWithLabelAndValue(entity, prescriptionViewSettings) {
  // if (!!prescriptionViewSettings) {
  //   console.log(prescriptionViewSettings?.preferredPrescriptionLanguages)
  // }
  // else{
  //   console.log("No prescriptionViewSettings")
  // }
  if (prescriptionViewSettings?.preferredPrescriptionLanguages?.includes('hi')) {
    return {
      ...(entity || {}),
      value: entity?.title,
      label: `${entity?.title}${
        entity?.translations?.hi?.title ? ` (${entity?.translations?.hi?.title})` : ''
      }`,
    }
  } else {
    return {
      ...(entity || {}),
      value: entity?.title,
      label: entity?.title,
    }
  }
}

// export const InstructionsMenu = ({
//   clinicId,
//   clinicBrandId,
//   selectedInstructions,
//   setSelectedInstructions,
// }) => {
//   // const CreatableAsyncPaginate = withAsyncPaginate(Creatable)
//   // const onCreateOption = useCallback(async (inputValue) => {
//   //   const instructionToInsert = {
//   //     apiParam: 'instruction',
//   //     title: inputValue,
//   //     label: inputValue,
//   //     value: inputValue,
//   //     __isNew: true,
//   //   }
//   //   setSelectedInstructions((prev) => [...(prev || []), instructionToInsert])
//   // }, [])

//   return (
//     <div style={{ width: '100%' }}>
//       <InstructionsSelect
//         id="instructionsSelection"
//         classNamePrefix="instructions_selection"
//         noOptionsMessage={() => 'Type to add new a instruction'}
//         maxMenuHeight={300}
//         placeholder=""
//         menuIsOpen
//         isMulti={true}
//         closeMenuOnSelect={false}
//         debounceTimeout={500}
//         loadOptions={loadOptionsInstructions}
//         additional={{ page: 1, clinicId: clinicId, clinicBrandId: clinicBrandId }}
//         styles={commonStylesPrescriptionSelect}
//         isClearable={true}
//         isSearchable={true}
//         defaultOptions={false}
//         // onCreateOption={onCreateOption}
//         getOptionValue={(option) => option?.value}
//         getOptionLabel={(option) => option?.label}
//         value={selectedInstructions}
//         onChange={(value) => {
//           setSelectedInstructions(value)
//         }}
//       />
//     </div>
//   )
// }

export const PrescriptionNextVisitComponent = ({
  nextVisitInputType,
  setNextVisitInputType,
  nextVisitInputValue,
  setNextVisitInputValue,
}) => {
  let formattedNextDate

  return (
    <div style={{ display: 'flex', gap: '20px' }}>
      <PMPSectionInputLabel>Next Visit</PMPSectionInputLabel>
      <div style={{ width: '40px' }}>
        <PMPSectionInputBox
          type="number"
          value={nextVisitInputValue}
          onChange={(e) => {
            const value = e.target.value
            if (value >= 0) {
              setNextVisitInputValue(value)
            }
          }}
        />
      </div>
      <div style={{ width: '200px' }}>
        <RectangleRadioButton
          disabled={false}
          options={[
            { label: 'Days', id: 'days' },
            { label: 'Weeks', id: 'weeks' },
            { label: 'Months', id: 'months' },
          ]}
          selectedId={nextVisitInputType}
          selectionFunction={(id) => setNextVisitInputType(id)}
        />
      </div>
      {nextVisitInputValue && nextVisitInputType && (
        <div>
          ({calculateNextVisitDate(nextVisitInputValue, nextVisitInputType, 'DD/MM/YYYY, dddd')})
        </div>
      )}
    </div>
  )
}

export const SuggestedTherapySelectMenu = ({
  clinicId,
  selectedTherapies,
  setSelectedTherapies,
  isMulti = true,
}) => {
  const CreatableAsyncPaginate = withAsyncPaginate(Creatable)

  return (
    <div style={{ width: '100%' }}>
      <CreatableAsyncPaginate
        id="suggestedTherapiesSelection"
        placeholder=""
        isMulti={isMulti}
        isClearable={true}
        isSearchable={true}
        defaultOptions={false}
        loadOptions={loadOptionsSuggestedTherapies}
        getOptionValue={(option) => option?.value}
        getOptionLabel={(option) => option?.label}
        value={selectedTherapies}
        onChange={(value) => {
          setSelectedTherapies(value)
        }}
        debounceTimeout={500}
        additional={{ page: 1, clinicId: clinicId }}
        styles={commonStylesPrescriptionSelect}
      />
    </div>
  )
}

export const TherapistSelectMenu = ({ selectedTherapist, setSelectedTherapist }) => {
  return (
    <div style={{ width: '100%' }}>
      <AsyncSelect
        id="therapistSelection"
        defaultOptions={true}
        placeholder=""
        isClearable={true}
        isSearchable={true}
        loadOptions={loadOptionsTherapists}
        styles={commonStylesPrescriptionSelect}
        getOptionValue={(option) => option?.value}
        getOptionLabel={(option) => option?.label}
        value={selectedTherapist}
        onChange={(value) => {
          setSelectedTherapist(value)
        }}
      />
    </div>
  )
}

///// helper functions:

// drugs related

export function handleDrugAdviceChange(toChange, indexToChange, newValue, setDrugsAdvice) {
  if (toChange === 'drug') {
    setDrugsAdvice((prev) => {
      const prevArray = [...prev]
      prevArray[indexToChange].drug = newValue
      return prevArray
    })
  } else if (toChange === 'dosage') {
    setDrugsAdvice((prev) => {
      const prevArray = [...prev]
      prevArray[indexToChange].dosage = newValue
      return prevArray
    })
  } else if (toChange === 'whatTime') {
    setDrugsAdvice((prev) => {
      const prevArray = [...prev]
      prevArray[indexToChange].whatTime = newValue
      return prevArray
    })
  } else if (toChange === 'frequency') {
    setDrugsAdvice((prev) => {
      const prevArray = [...prev]
      prevArray[indexToChange].frequency = newValue
      return prevArray
    })
  } else if (toChange === 'duration') {
    setDrugsAdvice((prev) => {
      const prevArray = [...prev]
      prevArray[indexToChange].duration = newValue
      return prevArray
    })
  } else if (toChange === 'notes') {
    setDrugsAdvice((prev) => {
      const prevArray = [...prev]
      prevArray[indexToChange].notes = newValue
      return prevArray
    })
  }
}

export function autoSuggestDrugAdviceUsingDrug(indexToChange, newValue, setDrugsAdvice) {
  setDrugsAdvice((prev) => {
    const newArray = [...prev]
    const newObject = newArray?.[indexToChange]
    if (
      Boolean(newObject?.dosage) ||
      Boolean(newObject?.duration) ||
      Boolean(newObject?.notes) ||
      Boolean(newObject?.frequency) ||
      Boolean(newObject?.whatTime)
    ) {
    } else {
      newObject.dosage = newValue?.dosage
        ? { value: newValue?.dosage, label: newValue?.dosage }
        : null
      newObject.duration = newValue?.timing
        ? { value: newValue?.timing, label: newValue?.timing }
        : null
      newObject.notes = newValue?.note ? { value: newValue?.note, label: newValue?.note } : null
      newObject.frequency = newValue?.frequency
        ? { value: newValue?.frequency, label: newValue?.frequency }
        : null
      newObject.whatTime = newValue?.whatTime
        ? { value: newValue?.whatTime, label: newValue?.whatTime }
        : null
      newArray[indexToChange] = newObject
    }
    return newArray
  })
}

export function nullAllValuesOfDrugAdvice(indexToChange, setDrugsAdvice) {
  setDrugsAdvice((prev) => {
    const prevArray = [...prev]
    prevArray[indexToChange] = {
      drug: null,
      dosage: null,
      whatTime: null,
      frequency: null,
      duration: null,
      notes: null,
    }
    return prevArray
  })
}

export function handleDeleteDrugAdviceItem(deletedItemIndex, setDrugsAdvice) {
  setDrugsAdvice((prev) => {
    const prevArray = [...prev]
    prevArray.splice(deletedItemIndex, 1)
    return prevArray
  })
}

export function handleAddDrugAdviceItem(setDrugsAdvice, drugItem) {
  setDrugsAdvice((prev) => {
    const prevArray = [...prev]
    if (drugItem) {
      prevArray.push({
        drug: drugItem,
        dosage: { label: drugItem?.dosage, value: drugItem?.dosage },
        whatTime: { label: drugItem?.whatTime, value: drugItem?.whatTime },
        frequency: { label: drugItem?.frequency, value: drugItem?.frequency },
        duration: { label: drugItem?.timing, value: drugItem?.timing },
        notes: { label: drugItem?.note, value: drugItem?.note },
      })
    } else {
      prevArray.push({
        drug: null,
        dosage: null,
        whatTime: null,
        frequency: null,
        duration: null,
        notes: null,
      })
    }
    return prevArray
  })
}

export function getDrugsListArrayForSubmission(
  drugsAdvice,
  clinicId,
  clinicBrandId,
  newlySavedItems,
) {
  const toReturn = []
  if (Array.isArray(drugsAdvice) && drugsAdvice.length > 0) {
    drugsAdvice?.forEach((drugAdviceItem) => {
      if (drugAdviceItem?.drug?.label) {
        toReturn.push({
          ...drugAdviceItem?.drug,
          apiParam: 'drug-info',
          clinicId: clinicId,
          clinicBrandId: clinicBrandId,
          id:
            drugAdviceItem?.drug?.id ??
            getIdForHealthEntity(newlySavedItems, HE_KEY_DRUGS, drugAdviceItem?.drug?.label),
          name: drugAdviceItem?.drug?.label ?? null,
          dosage: drugAdviceItem?.dosage?.label ?? null,
          whatTime: drugAdviceItem?.whatTime?.label ?? null,
          frequency: drugAdviceItem?.frequency?.label ?? null,
          timing: drugAdviceItem?.duration?.label ?? null,
          note: drugAdviceItem?.notes?.label ?? null,

          drugAdviceDosage: drugAdviceItem?.dosage?.label
            ? {
                apiParam: HE_DRUG_ADVICE_DOSAGE,
                ...drugAdviceItem?.dosage,
                title: drugAdviceItem?.dosage?.label,
              }
            : null,
          drugAdviceNote: drugAdviceItem?.notes?.label
            ? {
                apiParam: HE_DRUG_ADVICE_NOTE,
                ...drugAdviceItem?.notes,
                title: drugAdviceItem?.notes?.label,
              }
            : null,
          drugAdviceFrequency: drugAdviceItem?.frequency?.label
            ? {
                apiParam: HE_DRUG_ADVICE_FREQUENCY,
                ...drugAdviceItem?.frequency,
                title: drugAdviceItem?.frequency?.label,
              }
            : null,
          drugAdviceWhatTime: drugAdviceItem?.whatTime?.label
            ? {
                apiParam: HE_DRUG_ADVICE_TIMING,
                ...drugAdviceItem?.whatTime,
                title: drugAdviceItem?.whatTime?.label,
              }
            : null,
          drugAdviceDuration: drugAdviceItem?.duration?.label
            ? {
                apiParam: HE_DRUG_ADVICE_DURATION,
                ...drugAdviceItem?.duration,
                title: drugAdviceItem?.duration?.label,
              }
            : null,
        })
      }
    })
  }
  return toReturn
}

export async function saveNewDrugs(drugsAdvice, clinicId, clinicBrandId) {
  const toSave = []
  const toReturn = []
  if (Array.isArray(drugsAdvice) && drugsAdvice.length > 0) {
    drugsAdvice?.forEach((drugAdviceItem) => {
      if (drugAdviceItem?.drug?.label && drugAdviceItem?.drug?.__isNew__) {
        toSave.push({
          apiParam: 'drug-info',
          clinicId: clinicId,
          clinicBrandId: clinicBrandId,
          name: drugAdviceItem?.drug?.label ?? null,
          dosage: drugAdviceItem?.dosage?.label ?? null,
          whatTime: drugAdviceItem?.whatTime?.label ?? null,
          frequency: drugAdviceItem?.frequency?.label ?? null,
          timing: drugAdviceItem?.duration?.label ?? null,
          note: drugAdviceItem?.notes?.label ?? null,
          drugAdviceDosage: drugAdviceItem?.dosage?.label ? drugAdviceItem?.dosage : null,
          drugAdviceNote: drugAdviceItem?.notes?.label ? drugAdviceItem?.notes : null,
          drugAdviceFrequency: drugAdviceItem?.frequency?.label ? drugAdviceItem?.frequency : null,
          drugAdviceWhatTime: drugAdviceItem?.whatTime?.label ? drugAdviceItem?.whatTime : null,
          drugAdviceDuration: drugAdviceItem?.duration?.label ? drugAdviceItem?.duration : null,
        })
      }
    })
  }
  const promises = toSave.map(async (item) => {
    const response = await postMPDataWithoutParams(
      `/api/secure/drug/save?clinicId=${clinicId}&clinicBrandId=${clinicBrandId}`,
      item,
    )
    if (
      response?.status === 200 &&
      isCommonResponseSuccessful(response?.data?.code) &&
      response?.data?.data?.drugInfo?.id &&
      response?.data?.data?.drugInfo?.title
    ) {
      addOrUpdateDrugsInIdb([response?.data?.data?.drugInfo])
      toReturn.push(response?.data?.data?.drugInfo)
    }
  })

  await Promise.all(promises)
  return toReturn
}

export async function saveNewHealthComplaints(healthComplaints = [], clinicId, clinicBrandId) {
  const toSave = []
  if (Array.isArray(healthComplaints) && healthComplaints.length > 0) {
    healthComplaints?.forEach((healthComplaintItem) => {
      if (healthComplaintItem?.value && healthComplaintItem?.__isNew__) {
        toSave.push({
          apiParam: 'health-complaint',
          clinicId: clinicId,
          clinicBrandId: clinicBrandId,
          title: healthComplaintItem?.value ?? null,
          translations: healthComplaintItem?.translations,
        })
      }
    })
  }
  return await Promise.all(
    toSave.map(async (item) => {
      const response = await postMPDataWithoutParams(
        `/api/secure/doctor/health-complaint/save?clinicId=${clinicId}&clinicBrandId=${clinicBrandId}`,
        item,
      )
      if (
        response?.status === 200 &&
        isCommonResponseSuccessful(response?.data?.code) &&
        response?.data?.data?.['health-complaint']?.id &&
        response?.data?.data?.['health-complaint']?.title
      ) {
        addOrUpdateHealthComplaintsInIdb([response?.data?.data?.['health-complaint']])
        return response?.data?.data?.['health-complaint']
      }
    }),
  )
}

export async function saveNewHealthEntityList(
  healthEntityObjects = [],
  healthEntityType,
  clinicId,
  clinicBrandId,
) {
  const toSave = []
  if (!healthEntityType) {
    throw new Error(`healthEntityType is required: saving data -> ${healthEntityObjects}`)
  }

  if (Array.isArray(healthEntityObjects) && healthEntityObjects.length > 0) {
    healthEntityObjects?.forEach((heItem) => {
      if (heItem?.label && heItem?.__isNew__) {
        toSave.push({
          apiParam: healthEntityType,
          clinicId: clinicId,
          clinicBrandId: clinicBrandId,
          title: heItem?.value ?? null,
          translations: heItem?.translations,
        })
      }
    })
  }
  return await Promise.all(
    toSave.map(async (item) => {
      try {
        const response = await postMPDataWithoutParams(
          `/api/secure/doctor/${healthEntityType}/save?clinicId=${clinicId}&clinicBrandId=${clinicBrandId}`,
          item,
        )
        if (
          response?.status === 200 &&
          isCommonResponseSuccessful(response?.data?.code) &&
          response?.data?.data?.[healthEntityType]?.id &&
          response?.data?.data?.[healthEntityType]?.title
        ) {
          getFunctionForAddOrUpdateHealthEntitySingle(
            healthEntityType,
            response?.data?.data?.[healthEntityType],
          )
          return response?.data?.data?.[healthEntityType]
        }
      } catch (error) {
        console.error(`Error saving ${healthEntityType}:`, error)
      }
    }),
  )
}

export async function saveNewDiagnosis(diagnosis = [], clinicId, clinicBrandId) {
  const toSave = []
  if (Array.isArray(diagnosis) && diagnosis.length > 0) {
    diagnosis?.forEach((diagnosisItem) => {
      if (diagnosisItem?.label && diagnosisItem?.__isNew__) {
        toSave.push({
          apiParam: 'diagnosis',
          clinicId: clinicId,
          clinicBrandId: clinicBrandId,
          title: diagnosisItem?.value ?? null,
          translations: diagnosisItem?.translations,
        })
      }
    })
  }
  return await Promise.all(
    toSave.map(async (item) => {
      const response = await postMPDataWithoutParams(
        `/api/secure/doctor/diagnosis/save?clinicId=${clinicId}&clinicBrandId=${clinicBrandId}`,
        item,
      )
      if (
        response?.status === 200 &&
        isCommonResponseSuccessful(response?.data?.code) &&
        response?.data?.data?.diagnosis?.id &&
        response?.data?.data?.diagnosis?.title
      ) {
        addOrUpdateDiagnosisInIdb([response?.data?.data?.diagnosis])
        return response?.data?.data?.diagnosis
      }
    }),
  )
}

export async function saveNewObservations(observationsArray = [], clinicId, clinicBrandId) {
  const toSave = []
  if (Array.isArray(observationsArray) && observationsArray.length > 0) {
    observationsArray?.forEach((observationItem) => {
      if (observationItem?.label && observationItem?.__isNew__) {
        toSave.push({
          apiParam: 'health-observation',
          clinicId: clinicId,
          clinicBrandId: clinicBrandId,
          title: observationItem?.value ?? null,
          translations: observationItem?.translations,
        })
      }
    })
  }
  return await Promise.all(
    toSave.map(async (item) => {
      const response = await postMPDataWithoutParams(
        `/api/secure/doctor/health-observation/save?clinicId=${clinicId}&clinicBrandId=${clinicBrandId}`,
        item,
      )
      if (
        response?.status === 200 &&
        isCommonResponseSuccessful(response?.data?.code) &&
        response?.data?.data?.['health-observation']?.id &&
        response?.data?.data?.['health-observation']?.title
      ) {
        addOrUpdateObservationsInIdb([response?.data?.data?.['health-observation']])
        return response?.data?.data?.['health-observation']
      }
    }),
  )
}

export async function saveNewPrescriptionAdvice(
  prescriptionAdviceArray = [],
  clinicId,
  clinicBrandId,
) {
  const toSave = []
  if (Array.isArray(prescriptionAdviceArray) && prescriptionAdviceArray.length > 0) {
    prescriptionAdviceArray?.forEach((adviceItem) => {
      if (adviceItem?.label && adviceItem?.__isNew__) {
        toSave.push({
          apiParam: 'prescription-advice',
          clinicId: clinicId,
          clinicBrandId: clinicBrandId,
          title: adviceItem?.value ?? null,
          translations: adviceItem?.translations,
        })
      }
    })
  }
  return await Promise.all(
    toSave.map(async (item) => {
      const response = await postMPDataWithoutParams(
        `/api/secure/doctor/prescription-advice/save?clinicId=${clinicId}&clinicBrandId=${clinicBrandId}`,
        item,
      )
      if (
        response?.status === 200 &&
        isCommonResponseSuccessful(response?.data?.code) &&
        response?.data?.data?.['prescription-advice']?.id &&
        response?.data?.data?.['prescription-advice']?.title
      ) {
        addOrUpdateAdvicesInIdb([response?.data?.data?.['prescription-advice']])
        return response?.data?.data?.['prescription-advice']
      }
    }),
  )
}

export async function saveNewInstructions(instructionsArray = [], clinicId, clinicBrandId) {
  const toSave = []
  if (Array.isArray(instructionsArray) && instructionsArray.length > 0) {
    instructionsArray?.forEach((instructionItem) => {
      if (instructionItem?.value && instructionItem?.__isNew__) {
        toSave.push({
          apiParam: 'instruction',
          clinicId: clinicId,
          clinicBrandId: clinicBrandId,
          title: instructionItem?.value ?? null,
          translations: instructionItem?.translations,
        })
      }
    })
  }
  return Promise.all(
    toSave.map(async (item) => {
      const response = await postMPDataWithoutParams(
        `/api/secure/doctor/instruction/save?clinicId=${clinicId}&clinicBrandId=${clinicBrandId}`,
        item,
      )
      if (
        response?.status === 200 &&
        isCommonResponseSuccessful(response?.data?.code) &&
        response?.data?.data?.['instruction']?.id &&
        response?.data?.data?.['instruction']?.title
      ) {
        addOrUpdateInstructionsInIdb([response?.data?.data?.['instruction']])
        return response?.data?.data?.['instruction']
      }
    }),
  )
}

export async function saveNewInvestigation(testName, clinicId, clinicBrandId) {
  const newItem = {
    apiParam: 'investigation',
    clinicId: clinicId,
    clinicBrandId: clinicBrandId,
    title: testName,
  }
  const response = await postMPDataWithoutParams(
    `/api/secure/doctor/investigation/save?clinicId=${clinicId}&clinicBrandId=${clinicBrandId}`,
    newItem,
  )
  if (response.status === 200) {
    toast.success('New Investigation Added')
  } else {
    toast.error('Failed to add')
  }
}

export const getInitialDataForSettingExistingDrugAdvice = (existingData) => {
  const toReturn = []
  if (Array.isArray(existingData) && existingData.length > 0) {
    existingData?.forEach((item) => {
      const dosageObj = getInitialDataForSettingSingleExistingHealthEntity(
        item?.drugAdviceDosage,
        item?.dosage,
      )

      const whatTimeObj = getInitialDataForSettingSingleExistingHealthEntity(
        item?.drugAdviceWhatTime,
        item?.whatTime,
      )
      const frequencyObj = getInitialDataForSettingSingleExistingHealthEntity(
        item?.drugAdviceFrequency,
        item?.frequency,
      )
      const durationObj = getInitialDataForSettingSingleExistingHealthEntity(
        item?.drugAdviceDuration,
        item?.timing,
      )
      const notesObj = getInitialDataForSettingSingleExistingHealthEntity(
        item?.drugAdviceNote,
        item?.note,
      )

      toReturn.push({
        drug: item?.title ? { ...item, label: item?.title, value: item?.title } : null,
        dosage: dosageObj,
        whatTime: whatTimeObj,
        frequency: frequencyObj,
        duration: durationObj,
        notes: notesObj,
        // whatTime: item?.whatTime ? { label: item?.whatTime, value: item?.whatTime } : null,
        // frequency: item?.frequency ? { label: item?.frequency, value: item?.frequency } : null,
        // duration: item?.timing ? { label: item?.timing, value: item?.timing } : null,
        // notes: item?.note ? { label: item?.note, value: item?.note } : null,
      })
    })
  }
  return toReturn
}

export function getHealthEntityArrayForSubmission(
  healthEntityArray,
  healthEntityType,
  clinicId,
  newlySavedItems = [],
  returnAsStringArrays = false,
) {
  const toReturn = []
  if (Array.isArray(healthEntityArray) && healthEntityArray.length > 0) {
    healthEntityArray?.forEach((healthEntityItem) => {
      if (healthEntityItem?.label) {
        const entityObj = {
          ...healthEntityItem,
          id:
            healthEntityItem?.id ??
            getIdForHealthEntity(newlySavedItems, healthEntityType, healthEntityItem?.value),
          apiParam: healthEntityType,
          clinicId: clinicId,
          title: healthEntityItem?.label,
        }
        toReturn.push(entityObj)
      }
    })
  }
  if (returnAsStringArrays) {
    return toReturn.map((item) => item?.title)
  }
  return toReturn
}
// complaints array related:

export function getComplaintsArrayForSubmission(complaintsArray, clinicId, newlySavedItems) {
  const toReturn = []
  if (Array.isArray(complaintsArray) && complaintsArray.length > 0) {
    complaintsArray?.forEach((complaintItem) => {
      if (complaintItem?.label) {
        toReturn.push({
          ...complaintItem,
          id:
            complaintItem?.id ??
            getIdForHealthEntity(newlySavedItems, HE_KEY_HEALTH_COMPLAINTS, complaintItem?.value),
          apiParam: 'health-complaint',
          clinicId: clinicId,
          title: complaintItem?.label,
        })
      }
    })
  }
  return toReturn
}

export const getInitialDataForSettingExistingSelectedComplaints = (complaintsFromServer) => {
  const toReturn = []
  if (Array.isArray(complaintsFromServer) && complaintsFromServer.length > 0) {
    complaintsFromServer?.forEach((item) => {
      toReturn.push({ ...item, label: item?.title, value: item?.title })
    })
  }
  return toReturn
}

// diagnosis array related:

export function getDiagnosisArrayForSubmission(diagnosisArray, clinicId, newlySavedItems) {
  const toReturn = []
  if (Array.isArray(diagnosisArray) && diagnosisArray.length > 0) {
    diagnosisArray?.forEach((diagnosisItem) => {
      if (diagnosisItem?.label) {
        toReturn.push({
          ...diagnosisItem,
          id:
            diagnosisItem?.id ??
            getIdForHealthEntity(newlySavedItems, HE_KEY_DIAGNOSIS, diagnosisItem?.value),
          apiParam: 'diagnosis',
          clinicId: clinicId,
          title: diagnosisItem?.label,
        })
      }
    })
  }
  return toReturn
}

export const getInitialDataForSettingExistingSelectedDiagnosis = (diagnosisFromServer) => {
  const toReturn = []
  if (Array.isArray(diagnosisFromServer) && diagnosisFromServer.length > 0) {
    diagnosisFromServer?.forEach((item) => {
      toReturn.push({ ...item, label: item?.title, value: item?.title })
    })
  }
  return toReturn
}

// investigations array related:

export function getInvestigationsArrayForSubmission(investigationsArray, clinicId) {
  const toReturn = []
  if (Array.isArray(investigationsArray) && investigationsArray.length > 0) {
    investigationsArray?.forEach((investigationItem) => {
      if (investigationItem?.label) {
        toReturn.push({
          ...investigationItem,
          apiParam: 'investigation',
          clinicId: clinicId,
          title: investigationItem?.label,
        })
      }
    })
  }
  return toReturn
}

export const getInitialDataForSettingExistingSelectedInvestigations = (
  investigationsFromServer,
) => {
  const toReturn = []
  if (Array.isArray(investigationsFromServer) && investigationsFromServer.length > 0) {
    investigationsFromServer?.forEach((item) => {
      toReturn.push({ ...item, label: item?.title, value: item?.title })
    })
  }
  return toReturn
}

// therapies needed array related:

export function getSelectedTherapiesArrayForSubmission(selectedTherapies) {
  const toReturn = []
  if (Array.isArray(selectedTherapies) && selectedTherapies.length > 0) {
    selectedTherapies?.forEach((selectedTherapy) => {
      toReturn.push(selectedTherapy?.label)
    })
  }
  return toReturn
}

export const getInitialDataForSettingExistingSelectedTherapies = (therapiesFromServer) => {
  const toReturn = []
  if (Array.isArray(therapiesFromServer) && therapiesFromServer.length > 0) {
    therapiesFromServer?.forEach((item) => {
      toReturn.push({ label: item, value: item })
    })
  }
  return toReturn
}

export const getInitialDataForSettingSelectedTherapist = async (therapist) => {
  const response = await getCPDataWithoutParams(
    `/api/external/mentor/1on1/list?categories=clinical_psychologist&page=1&pageSize=100`,
  )
  if (response.status === 200) {
    const selectedTherapist = response.data.find(
      (therapistFromList) => therapistFromList?.id === therapist?.id,
    )
    if (selectedTherapist?.name) {
      return { value: selectedTherapist?.id, label: selectedTherapist?.name }
    }
    return null
  }
}

// observations array related

export const getInitialDataForSettingExistingSelectedObservations = (observations) => {
  const toReturn = []
  if (Array.isArray(observations) && observations.length > 0) {
    observations?.forEach((item) => {
      toReturn.push({ label: item, value: item })
    })
  }
  return toReturn
}

export const getObservationsArrayForSubmission = (observationsArray, clinicId, newlySavedItems) => {
  const toReturn = []
  if (Array.isArray(observationsArray) && observationsArray.length > 0) {
    observationsArray?.forEach((observationItem) => {
      if (observationItem?.label) {
        toReturn.push({
          ...observationItem,
          apiParam: 'health-observation',
          id:
            observationItem?.id ??
            getIdForHealthEntity(newlySavedItems, HE_KEY_OBSERVATIONS, observationItem?.value),
          clinicId: clinicId,
          title: observationItem?.label,
        })
      }
    })
  }
  return toReturn?.map((observation) => observation?.label)
}

// advice array related

export const getInitialDataForSettingExistingSelectedAdvice = (advices) => {
  const toReturn = []
  if (Array.isArray(advices) && advices.length > 0) {
    advices?.forEach((item) => {
      toReturn.push({ label: item, value: item })
    })
  }
  return toReturn
}

export const getAdviceArrayForSubmission = (adviceArray, clinicId, newlySavedItems) => {
  const toReturn = []
  if (Array.isArray(adviceArray) && adviceArray.length > 0) {
    adviceArray?.forEach((adviceItem) => {
      if (adviceItem?.label) {
        toReturn.push({
          ...adviceItem,
          apiParam: 'prescription-advice',
          clinicId: clinicId,
          id:
            adviceItem?.id ??
            getIdForHealthEntity(newlySavedItems, HE_KEY_ADVICE, adviceItem?.value),
          title: adviceItem?.label,
        })
      }
    })
  }
  return toReturn?.map((observation) => observation?.label)
}

// instructions array related:

function getInitialDataForHealthEntity(healthEntityArray, hasFullObjects = false) {
  const toReturn = []
  if (Array.isArray(healthEntityArray) && healthEntityArray.length > 0) {
    healthEntityArray?.forEach((item) => {
      toReturn.push(getInitialDataForSingleHealthEntity(item, hasFullObjects))
    })
  }
  return toReturn
}

function getInitialDataForSingleHealthEntity(healthEntity, hasFullObject = false) {
  if (hasFullObject) {
    return { ...healthEntity, label: healthEntity?.title, value: healthEntity?.title }
  }
  return { label: healthEntity, value: healthEntity }
}

export function getInitialDataForSettingSingleExistingHealthEntity(
  healthEntity,
  stringHealthEntity,
  prescriptionViewSettings,
) {
  if (healthEntity) {
    return getInitialDataForSingleHealthEntity(healthEntity, true, prescriptionViewSettings)
  } else if (stringHealthEntity) {
    return getInitialDataForSingleHealthEntity(stringHealthEntity, prescriptionViewSettings)
  }
  return null
}

export function getInitialDataForSettingExistingHealthEntity(
  fullObjectHealthEntityArray,
  stringListHealthEntityArray = [],
) {
  let toReturn = []
  if (!!fullObjectHealthEntityArray && fullObjectHealthEntityArray.length > 0) {
    toReturn = getInitialDataForHealthEntity(fullObjectHealthEntityArray, true)
  } else {
    if (!!stringListHealthEntityArray && stringListHealthEntityArray.length > 0) {
      toReturn = getInitialDataForHealthEntity(stringListHealthEntityArray)
    }
  }
  return toReturn
}

export function getInitialDataForSettingExistingSelectedInstructions(instructions) {
  const toReturn = []
  if (Array.isArray(instructions) && instructions.length > 0) {
    instructions?.forEach((item) => {
      toReturn.push({ label: item, value: item })
    })
  }
  return toReturn
}

export function getInstructionArrayForSubmission(instructionsArray, clinicId, newlySavedItems) {
  const toReturn = []
  if (Array.isArray(instructionsArray) && instructionsArray.length > 0) {
    instructionsArray?.forEach((instructionItem) => {
      if (instructionItem?.label) {
        toReturn.push({
          ...instructionItem,
          apiParam: 'instruction',
          clinicId: clinicId,
          id:
            instructionItem?.id ??
            getIdForHealthEntity(newlySavedItems, HE_KEY_INSTRUCTIONS, instructionItem?.value),
          title: instructionItem?.label,
        })
      }
    })
  }
  return toReturn?.map((instruction) => instruction?.label)
}

// next date related

export const calculateNextVisitDate = (nextVisitInputValue, nextVisitInputType, format) => {
  const currentDate = moment() // Get the current date

  // Map the input type to the corresponding moment method
  const methodMap = {
    days: 'add',
    months: 'add',
    weeks: 'add',
  }

  // Calculate the next visit date based on input type and value
  if (methodMap[nextVisitInputType]) {
    currentDate[methodMap[nextVisitInputType]](nextVisitInputValue, nextVisitInputType)
  } else {
    console.error('Invalid input type')
  }

  if (format) {
    return currentDate.format(format)
  }
  // Return the formatted date (you can adjust the format based on your needs)
  return currentDate.format('DD/MM/YYYY')
}

export const getNextVisitDateForSubmission = (
  nextVisitInputValue,
  nextVisitInputType,
  nextVisitCalendarInput,
) => {
  if (nextVisitInputValue && nextVisitInputType) {
    const currentDate = moment() // Get the current date

    // Map the input type to the corresponding moment method
    const methodMap = {
      days: 'add',
      months: 'add',
      weeks: 'add',
    }

    // Calculate the next visit date based on input type and value
    if (methodMap[nextVisitInputType]) {
      currentDate[methodMap[nextVisitInputType]](nextVisitInputValue, nextVisitInputType)
    } else {
      console.error('Invalid input type')
    }
    return currentDate?.format('YYYY-MM-DDTHH:mm:ssZ')
  }
}

export const getInitialDataForSettingDaysOfNextVisit = (nextDate, createdTime) => {
  if (nextDate) {
    if (createdTime) {
      return moment?.(nextDate)?.diff(moment(createdTime, 'YYYY-MM-DD HH:mm:ss'), 'days')
    } else {
      return moment?.(nextDate)?.diff(moment(), 'days')
    }
  }
  return null
}

// latestvitalid in prescriptionData

// next visit
// threapy chosen
// therpast chosen from list
// therpast chosen manually
// new complaints fire api, id in complaints
// new diagnosis fire api, id in diagnosis

// decide what to do with save, print and save as it is taking a lot of time
// something to do with all these fields : newly created drug is not showing up
// if "genericName" coming, then show

// find solution 1: rerendering and slowing down fix - typing
// find solution 2:

function isDrugDatabaseUpdateNeeded() {
  const dataLastUpdated = sessionStorage.getItem('drugDatabaseUpdated')
  if (dataLastUpdated) {
    const momentObj = moment(dataLastUpdated)
    var isSameDate = momentObj.isSame(moment(), 'day')
    return !isSameDate
  }
  return true
}

export const AutoFillFromLastPrescriptionComponent = ({
  patientId,
  thisBookedEventId,
  clickFunction,
}) => {
  const [pastPrescriptionsLoading, setPastPrescriptionsLoading] = useState(false)
  const [lastPrescriptionData, setLastPrescriptionData] = useState(null)

  async function setupThings() {
    setPastPrescriptionsLoading(true)
    const response = await getMPDataWithoutParams(
      `/api/secure/doctor/prescription/patient/list?patientId=${patientId}`,
    )
    setPastPrescriptionsLoading(false)
    if (response?.status === 200 && isCommonResponseSuccessful(response?.data?.code)) {
      const prescriptionDataList = response?.data?.data?.prescriptionDataList || []
      sortArrayMomentBased(prescriptionDataList, 'modifiedTime', false)
      const resultArray = prescriptionDataList?.map(
        (pastPrecriptionsDataOnly, pastPrecriptionsDataOnlyIndex) => ({
          ...(response?.data?.data?.prescriptionTemplateList?.[0] || {}),
          prescriptionData: pastPrecriptionsDataOnly,
        }),
      )
      const filteredResult = resultArray?.filter(
        (item) => item?.prescriptionData?.bookedEventId !== thisBookedEventId,
      )?.[0]
      setLastPrescriptionData(filteredResult)
    }
  }

  useEffect(() => {
    setupThings()
  }, [])

  function handleOnClick() {
    clickFunction(lastPrescriptionData)
  }

  return (
    <>
      {pastPrescriptionsLoading ? (
        <div style={{ margin: '8px 0' }}>
          <Spinner animation="border" variant="dark" size="sm" />
          <span>{' Checking if past prescriptions are available '}</span>
        </div>
      ) : (
        <div style={{ margin: '32px 0 16px' }}>
          {lastPrescriptionData ? (
            <>
              <AutocompleteHelperButton
                text={'Autofill from last Prescription'}
                Icon={AutoFixHighIcon}
                iconProps={{ fontSize: '8px' }}
                clickFunction={handleOnClick}
              />
              <div style={{ fontSize: '12px', marginLeft: '12px' }}>
                from{' '}
                {moment
                  .utc(lastPrescriptionData?.createdTime || lastPrescriptionData?.modifiedTime)
                  ?.local()
                  ?.format('DD/MM/YYYY h:mmA')}
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      )}
    </>
  )
}

export const AddNewTestComponent = ({ closeFunc, clinicId, clinicBrandId }) => {
  const [nameOfTest, setNameOfTest] = useState('')
  const [loading, setLoading] = useState(false)

  async function saveNewTestAndClose(testName) {
    setLoading(true)
    if (nameOfTest) {
      await saveNewInvestigation(testName, clinicId, clinicBrandId)
      if (closeFunc) {
        closeFunc()
      }
    }
    setLoading(false)
  }

  return (
    <div
      style={{ padding: '8px', width: '256px', border: '1px solid #d3d3d3', borderRadius: '4px' }}
    >
      <div style={{ marginBottom: '24px' }}>
        <b>Add Test</b>
      </div>
      <CustomInput
        type={'text'}
        value={nameOfTest}
        onChange={(e) => setNameOfTest(e.target.value)}
      />
      <button
        style={{
          marginTop: '16px',
          background: `${nameOfTest ? '#000' : '#fff'}`,
          borderRadius: '4px',
          border: `${nameOfTest ? '1px solid #000' : '1px solid #d3d3d3'}`,
          color: `${nameOfTest ? '#fff' : '#000'}`,
        }}
        disabled={!nameOfTest}
        onClick={() => saveNewTestAndClose(nameOfTest)}
      >
        Add {loading && <Spinner animation="border" variant="light" size="sm" />}
      </button>
    </div>
  )
}

const buildQueryParams = (params) => {
  const query = Object.entries(params)
    .filter(([_, value]) => value !== undefined && value !== null)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join('&')

  return query ? `?${query}` : ''
}

function getIdForHealthEntity(map, key, title) {
  if (map && map[key] && Array.isArray(map[key]) && map[key].length > 0) {
    const found = map[key].find((item) => item?.title === title)
    if (found?.id) {
      return found?.id
    }
  }
  return null
}

{
  /* 
const data = [
    drug,
    {
      apiParam: 'drug-info',
      clinicId: 1,
      clinicBrandId: 1,
      id: 0,
      name: 'drug1',
      dosage: null,
      whatTime: null,
      frequency: null,
      timing: null,
      note: null,
    },
    {
      apiParam: 'drug-info',
      clinicId: 1,
      clinicBrandId: 1,
      id: 0,
      name: 'drug2',
      dosage: null,
      whatTime: null,
      frequency: null,
      timing: null,
      note: null,
    },
  ] */
}
