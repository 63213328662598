export const getTileCardDetails = (cardData) => {
    const tileTitle = cardData?.tabAndTile?.tileTitle || cardData?.tabAndTile?.title || '';
    const tilePrimaryColor = cardData?.tabAndTile?.defaultPrimaryThemeColor || "#4e1a3d";
    const tileSecondaryColor = cardData?.tabAndTile?.defaultSecondaryThemeColor || "#4e1a3d";
    const tileId = cardData?.tabAndTile?.id;
    const policyId = cardData?.tabAndTile?.policyId;
    const description = cardData?.tabAndTile?.description;
    const image = cardData?.tabAndTile?.image;
    const colors = cardData?.tabAndTile?.colors || {};
    return { tileTitle, tilePrimaryColor, tileSecondaryColor, tileId, policyId, description, image, colors };
}

export const getTitleParts = (title) => {
    const titleWordsList = title.trim().split(" ");
    let titleFirstPart = "", titleSecondPart = "";
    titleSecondPart = titleWordsList[titleWordsList.length - 1];
    titleWordsList.pop();
    titleFirstPart = titleWordsList.join(" ");
    return { titleFirstPart, titleSecondPart };
}

export const getTextParts = (text) => {
    const textWordsList = text.trim().split(" ");
    let textFirstPart = "", textSecondPart = "";
    const textSecondPartWordsList = [];

    if (textWordsList.length < 6) {
        textSecondPart = textWordsList[textWordsList.length - 1];
        textWordsList.pop();
        textFirstPart = textWordsList.join(" ");
    } else {
        textSecondPartWordsList.push(textWordsList[textWordsList.length - 2])
        textSecondPartWordsList.push(textWordsList[textWordsList.length - 1])
        textWordsList.pop();
        textWordsList.pop();
        textFirstPart = textWordsList.join(" ");
        textSecondPart = textSecondPartWordsList.join(" ");
    }

    return { textFirstPart, textSecondPart };
}

export const getColorStyle = (tilePrimaryColor, tileSecondaryColor, colors, fallbackReturnType) => {


    if (typeof colors === "object" && Object.keys(colors)?.length) {

        if (colors?.type === "solid") {
            return (`${colors?.colorIds?.[0]?.hexCode}`)
        }

        let colorPartOfStyle = ""
        colors?.colorIds?.forEach((color) => {
            colorPartOfStyle += `, ${color?.hexCode} ${color?.position}`
        })

        return (`${colors?.type}(${colors?.degrees}deg ${colorPartOfStyle})`)

    } else {
        if (fallbackReturnType === "lg") {
            return (`linear-gradient(120.13deg, ${tilePrimaryColor} 3.17%, ${tileSecondaryColor} 105.79%)`)
        } else if (fallbackReturnType === "solid") {
            return (`${tilePrimaryColor}`)
        }
    }

}