import styled from 'styled-components'

export const StyledTileContainer = styled.div`
  ${(props) => !props?.isOnBackground && `padding: 20px 0 0 0`};
  font-family: 'Outfit';
`

export const StyledTileHeader = styled.div`
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  padding: 0 20px;
  @media (min-width: 768px) {
    padding: 0 35px;
  }
  @media (min-width: 1024px) {
    padding: 0 50px;
  }
`

export const StyledTileTitle = styled.div`
  background: rgba(0, 0, 0, 0.36);
  backdrop-filter: blur(26px);
  border-radius: 47px;
  width: fit-content;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${(props) => (props.nopointer ? 'default' : 'pointer')};

  font-weight: 700;
  font-size: 22px;
  padding: 2px 10px;
  @media (min-width: 768px) {
    font-size: 28px;
    padding: 3px 15px;
  }
  @media (min-width: 1024px) {
    font-size: 32px;
    padding: 5px 20px;
  }
`

export const StyledTileMore = styled.div`
  background: rgba(0, 0, 0, 0.36);
  backdrop-filter: blur(26px);
  border-radius: 47px;
  width: fit-content;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  font-weight: 700;
  font-size: 22px;
  padding: 2px 10px;
  @media (min-width: 768px) {
    font-size: 32px;
    padding: 3px 15px;
  }
  @media (min-width: 1024px) {
    font-size: 42px;
    padding: 5px 20px;
  }
`
