import {
  IDB_ADVICE_STORENAME,
  IDB_DIAGNOSIS_STORENAME,
  IDB_DRUGS_STORENAME,
  IDB_HEALTH_COMPLAINTS_STORENAME,
  IDB_INSTRUCTIONS_STORENAME,
  IDB_INVESTIGATION_STORENAME,
  IDB_OBSERVATIONS_STORENAME,
} from 'src/consumer/constants'
import { createERMDatabase } from './indexedDb'

async function getDB() {
  const db = await createERMDatabase()
  return db
}

export function getFunctionForAddOrUpdateHealthEntitySingle(healthEntity, data) {
  return getFunctionForAddOrUpdateHealthEntityMulitple(healthEntity, [data])
}

export function getFunctionForAddOrUpdateHealthEntityMulitple(healthEntity, data) {
  switch (healthEntity) {
    case 'complaints':
      return addOrUpdateHealthComplaintsInIdb(data)
    case 'diagnosis':
      return addOrUpdateDiagnosisInIdb(data)
    case 'observations':
      return addOrUpdateObservationsInIdb(data)
    case 'advices':
      return addOrUpdateAdvicesInIdb(data)
    case 'instructions':
      return addOrUpdateInstructionsInIdb(data)
    case 'investigation':
      return addOrUpdateInvestigationInIdb(data)
    default:
      // return addOrUpdateHealthEntitiesInIdbBasedOnStoreName(data, healthEntity)
      return null
  }
}

// IDB_HEALTH_COMPLAINTS_STORENAME:

export async function addOrUpdateHealthComplaintsInIdb(complaints) {
  const db = await getDB()
  const tx = db.transaction(IDB_HEALTH_COMPLAINTS_STORENAME, 'readwrite')
  const store = tx.objectStore(IDB_HEALTH_COMPLAINTS_STORENAME)

  for (const complaint of complaints) {
    if (complaint?.title) {
      await store.put({ ...complaint, lowerCaseIndexField: complaint?.title?.toLowerCase() }) // `put` will add or update the record
    }
  }

  await tx.done
}

export async function getAllHealthComplaintsFromIdb() {
  const db = await getDB()
  const tx = db.transaction(IDB_HEALTH_COMPLAINTS_STORENAME, 'readonly')
  const store = tx.objectStore(IDB_HEALTH_COMPLAINTS_STORENAME)
  const allComplaints = await store.getAll()
  await tx.done
  return allComplaints
}

export async function searchHealthComplaintsInIdb(searchTerm) {
  if (!searchTerm) {
    return await getAllHealthComplaintsFromIdb()
  }
  return await searchItemsInIdb(searchTerm, IDB_HEALTH_COMPLAINTS_STORENAME, 'titleIDX')
}

export async function getHealthComplaintsByIds(healthComplaintIds) {
  if (!Array.isArray(healthComplaintIds) || healthComplaintIds.length === 0) {
    return []
  }

  return await getItemsByIds(healthComplaintIds, IDB_HEALTH_COMPLAINTS_STORENAME)
}

// IDB_DIAGNOSIS_STORENAME:

export async function addOrUpdateDiagnosisInIdb(diagnosisList) {
  const db = await getDB()
  const tx = db.transaction(IDB_DIAGNOSIS_STORENAME, 'readwrite')
  const store = tx.objectStore(IDB_DIAGNOSIS_STORENAME)

  for (const diagnosis of diagnosisList) {
    if (diagnosis?.title) {
      await store.put({ ...diagnosis, lowerCaseIndexField: diagnosis?.title?.toLowerCase() }) // `put` will add or update the record
    }
  }

  await tx.done
}

export async function getAllDiagnosisFromIdb() {
  const db = await getDB()
  const tx = db.transaction(IDB_DIAGNOSIS_STORENAME, 'readonly')
  const store = tx.objectStore(IDB_DIAGNOSIS_STORENAME)
  const allDiagnosis = await store.getAll()
  await tx.done
  return allDiagnosis
}

export async function searchDiagnosisInIdb(searchTerm) {
  if (!searchTerm) {
    return await getAllDiagnosisFromIdb()
  }
  return await searchItemsInIdb(searchTerm, IDB_DIAGNOSIS_STORENAME, 'titleIDX')
}

export async function getDiagnosisByIds(diagnosisIds) {
  if (!Array.isArray(diagnosisIds) || diagnosisIds.length === 0) {
    return []
  }

  return await getItemsByIds(diagnosisIds, IDB_DIAGNOSIS_STORENAME)
}

// IDB_OBSERVATIONS_STORENAME:

export async function addOrUpdateObservationsInIdb(observationsList) {
  const db = await getDB()
  const tx = db.transaction(IDB_OBSERVATIONS_STORENAME, 'readwrite')
  const store = tx.objectStore(IDB_OBSERVATIONS_STORENAME)

  for (const observation of observationsList) {
    if (observation?.title) {
      await store.put({ ...observation, lowerCaseIndexField: observation?.title?.toLowerCase() }) // `put` will add or update the record
    }
  }

  await tx.done
}

export async function getAllObservationsInIdb() {
  const db = await getDB()
  const tx = db.transaction(IDB_OBSERVATIONS_STORENAME, 'readonly')
  const store = tx.objectStore(IDB_OBSERVATIONS_STORENAME)
  const allInstructions = await store.getAll()
  await tx.done
  return allInstructions
}

export async function searchObservationsInIdb(searchTerm) {
  if (!searchTerm) {
    return await getAllObservationsInIdb()
  }
  return await searchItemsInIdb(searchTerm, IDB_OBSERVATIONS_STORENAME, 'titleIDX')
}

export async function getObservationsByIds(observationsIds) {
  if (!Array.isArray(observationsIds) || observationsIds.length === 0) {
    return []
  }

  return await getItemsByIds(observationsIds, IDB_OBSERVATIONS_STORENAME)
}

// IDB_ADVICE_STORENAME:

export async function addOrUpdateAdvicesInIdb(advicesList) {
  const db = await getDB()
  const tx = db.transaction(IDB_ADVICE_STORENAME, 'readwrite')
  const store = tx.objectStore(IDB_ADVICE_STORENAME)

  for (const advice of advicesList) {
    if (advice?.title) {
      await store.put({ ...advice, lowerCaseIndexField: advice?.title?.toLowerCase() }) // `put` will add or update the record
    }
  }

  await tx.done
}

export async function getAllAdvicesInIdb() {
  const db = await getDB()
  const tx = db.transaction(IDB_ADVICE_STORENAME, 'readonly')
  const store = tx.objectStore(IDB_ADVICE_STORENAME)
  const allInstructions = await store.getAll()
  await tx.done
  return allInstructions
}

export async function searchAdvicesInIdb(searchTerm) {
  if (!searchTerm) {
    return await getAllAdvicesInIdb()
  }
  return await searchItemsInIdb(searchTerm, IDB_ADVICE_STORENAME, 'titleIDX')
}

export async function getAdvicesByIds(advicesByIds) {
  if (!Array.isArray(advicesByIds) || advicesByIds.length === 0) {
    return []
  }

  return await getItemsByIds(advicesByIds, IDB_ADVICE_STORENAME)
}

// IDB_INSTRUCTIONS_STORENAME:

export async function addOrUpdateInstructionsInIdb(instructionsList) {
  const db = await getDB()
  const tx = db.transaction(IDB_INSTRUCTIONS_STORENAME, 'readwrite')
  const store = tx.objectStore(IDB_INSTRUCTIONS_STORENAME)

  for (const instruction of instructionsList) {
    if (instruction?.title) {
      await store.put({ ...instruction, lowerCaseIndexField: instruction?.title?.toLowerCase() }) // `put` will add or update the record
    }
  }

  await tx.done
}

export async function getAllInstructionsInIdb() {
  const db = await getDB()
  const tx = db.transaction(IDB_INSTRUCTIONS_STORENAME, 'readonly')
  const store = tx.objectStore(IDB_INSTRUCTIONS_STORENAME)
  const allInstructions = await store.getAll()
  await tx.done
  return allInstructions
}

export async function searchInstructionsInIdb(searchTerm) {
  if (!searchTerm) {
    return await getAllInstructionsInIdb()
  }
  return await searchItemsInIdb(searchTerm, IDB_INSTRUCTIONS_STORENAME, 'titleIDX')
}

export async function getInstructionsByIds(instructionsByIds) {
  if (!Array.isArray(instructionsByIds) || instructionsByIds.length === 0) {
    return []
  }

  return await getItemsByIds(instructionsByIds, IDB_INSTRUCTIONS_STORENAME)
}

// IDB_INVESTIGATION_STORENAME:

export async function addOrUpdateInvestigationInIdb(investigationList) {
  const db = await getDB()
  const tx = db.transaction(IDB_INVESTIGATION_STORENAME, 'readwrite')
  const store = tx.objectStore(IDB_INVESTIGATION_STORENAME)

  for (const investigation of investigationList) {
    if (investigation?.title) {
      await store.put({
        ...investigation,
        lowerCaseIndexField: investigation?.title?.toLowerCase(),
      }) // `put` will add or update the record
    }
  }

  await tx.done
}

export async function getAllInvestigationInIdb() {
  const db = await getDB()
  const tx = db.transaction(IDB_INVESTIGATION_STORENAME, 'readonly')
  const store = tx.objectStore(IDB_INVESTIGATION_STORENAME)
  const allInvestigation = await store.getAll()
  await tx.done
  return allInvestigation
}

export async function searchInvestigationInIdb(searchTerm) {
  if (!searchTerm) {
    return await getAllInvestigationInIdb()
  }
  return await searchItemsInIdb(searchTerm, IDB_INVESTIGATION_STORENAME, 'titleIDX')
}

export async function getInvestigationByIds(investigationByIds) {
  if (!Array.isArray(investigationByIds) || investigationByIds.length === 0) {
    return []
  }

  return await getItemsByIds(investigationByIds, IDB_INVESTIGATION_STORENAME)
}

// IDB_DRUGS_STORENAME:

export async function addOrUpdateDrugsInIdb(drugsList) {
  const db = await getDB()
  const tx = db.transaction(IDB_DRUGS_STORENAME, 'readwrite')
  const store = tx.objectStore(IDB_DRUGS_STORENAME)

  for (const drug of drugsList) {
    if (drug?.name) {
      const record = {
        ...drug,
        lowerCaseIndexField: drug.name.toLowerCase(),
      }
      await store.put(record) // `put` will add or update the record
    }
  }

  await tx.done
}

export async function getAllDrugsFromIdb() {
  const db = await getDB()
  const tx = db.transaction(IDB_DRUGS_STORENAME, 'readonly')
  const store = tx.objectStore(IDB_DRUGS_STORENAME)
  const allDiagnosis = await store.getAll()
  await tx.done
  return allDiagnosis
}

export async function searchDrugsInIdb(searchTerm) {
  if (!searchTerm) {
    return await getAllDrugsFromIdb()
  }
  return await searchItemsInIdb(searchTerm, IDB_DRUGS_STORENAME, 'titleIDX')
}

export async function findDrugsByRelatedName(relatedName) {
  if (!relatedName || relatedName.trim() === '') {
    return []
  }

  const db = await getDB()
  const tx = db.transaction(IDB_DRUGS_STORENAME, 'readonly')
  const store = tx.objectStore(IDB_DRUGS_STORENAME)

  const matchedDrugs = []

  // Use a cursor to iterate through all drugs
  let cursor = await store.openCursor()

  while (cursor) {
    // Check if the relatedName matches the input
    if (cursor.value?.relatedName === relatedName) {
      matchedDrugs.push(cursor.value)
    }
    cursor = await cursor.continue() // Move to the next record
  }

  await tx.done
  return matchedDrugs
}

export async function getDrugsByIDs(drugIds) {
  if (!Array.isArray(drugIds) || drugIds.length === 0) {
    return []
  }

  return await getItemsByIds(drugIds, IDB_DRUGS_STORENAME)
}

//IDB_DRUGS_DOSAGE_STORENAME:

export async function addOrUpdateHealthEntitiesInIdbBasedOnStoreName(heList, storeName) {
  const db = await getDB()
  const tx = db.transaction(storeName, 'readwrite')
  const store = tx.objectStore(storeName)

  for (const healthEntity of heList) {
    if (healthEntity?.title) {
      await store.put({ ...healthEntity, lowerCaseIndexField: healthEntity?.title?.toLowerCase() }) // `put` will add or update the record
    }
  }

  await tx.done
}

export async function getAllHealthEntityItemsBasedOnStoreNameFromIdb(storeName) {
  const db = await getDB()
  const tx = db.transaction(storeName, 'readonly')
  const store = tx.objectStore(storeName)
  const allDosage = await store.getAll()
  await tx.done
  return allDosage
}

export async function searchAllHealthEntityItemsBasedOnStoreNameInIdb(searchTerm, storeName) {
  if (!searchTerm) {
    return await getAllHealthEntityItemsBasedOnStoreNameFromIdb(storeName)
  }
  return await searchItemsInIdb(searchTerm, storeName, 'titleIDX')
}

export async function getHealthEntityItemsByIdsBasedOnStoreName(heIds, storeName) {
  if (!Array.isArray(heIds) || heIds.length === 0) {
    return []
  }

  return await getItemsByIds(heIds, storeName)
}

//
//
//

export async function searchItemsInIdb(searchTerm, storeName, indexName) {
  if (!searchTerm || searchTerm.trim() === '') {
    return []
  }
  const db = await getDB()
  const tx = db.transaction(storeName, 'readonly')

  const index = tx.store.index(indexName)
  const searchLowercase = searchTerm.toLowerCase()
  const range = IDBKeyRange.bound(searchLowercase, searchLowercase + '\uffff')
  let cursor = await index.openCursor(range)

  const searchResults = []

  while (cursor) {
    searchResults.push(cursor.value)
    cursor = await cursor.continue()
  }

  return searchResults
}
export async function searchItemsInIdbById(id, storeName) {
  if (!id) {
    return null
  }

  const db = await getDB()
  const tx = db.transaction(storeName, 'readonly')
  const store = tx.store

  // Get the item directly by the primary key (id)
  const result = await store.get(id)

  return result ? result : null
}

export async function getItemsByIds(ids, storeName) {
  if (!Array.isArray(ids) || ids.length === 0) {
    return []
  }

  // Use `Promise.all` to fetch all items by their IDs in parallel
  const results = await Promise.all(ids.map((id) => searchItemsInIdbById(id, storeName)))

  // Filter out null values for items that weren't found
  return results.filter((item) => item !== null)
}
