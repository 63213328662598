import { getCategoryListMap } from 'src/consumer/helpers/courseCardHelper'
import { getMPDataWithParams, putMPDataWithParams } from 'src/mentor/services'

export async function handleAccept(
  activityPlan,
  userProfileData,
  togglePrewall,
  afterStartPlanFunction,
) {
  const subscriptionPlanPriority = [
    'subscription_free',
    'subscription_normal',
    'subscription_premium',
  ]

  let activityPlanSubscriptionPlanResponse
  // if (!!activityPlan?.pricePlan) {
  //   activityPlanSubscriptionPlanResponse = await getMPDataWithParams('/api/subscription-plan', {
  //     id: activityPlan?.pricePlan,
  //   })
  // }
  // const userSubPlanPriority = subscriptionPlanPriority.indexOf(
  //   userProfileData?.subscriptionPlanType,
  // )
  // const activityPlanSubPlanPriority = subscriptionPlanPriority.indexOf(
  //   activityPlan?.subscriptionType,
  // )

  // if (userSubPlanPriority < activityPlanSubPlanPriority) {
  //   togglePrewall(activityPlanSubscriptionPlanResponse?.data)
  //   return
  // }
  await startPlan(activityPlan, afterStartPlanFunction)
}

async function startPlan(activityPlan, afterStartPlanFunction) {
  const response = await putMPDataWithParams('/api/secure/v3/activity-plan/start', undefined, {
    planId: activityPlan?.id,
  })
  if (response.status === 200) {
    await afterStartPlanFunction()
  }
}

export function isUserAllowedToAccessContent(userSubscriptionType, contentSubscriptionType) {
  if (contentSubscriptionType) {
    const subscriptionPlanPriority = [
      'subscription_free',
      'subscription_normal',
      'subscription_premium',
    ]
    if (
      subscriptionPlanPriority.indexOf(userSubscriptionType) <
      subscriptionPlanPriority.indexOf(contentSubscriptionType)
    ) {
      return false
    }
    return true
  } else {
    return true
  }
}
