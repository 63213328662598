import styled from 'styled-components'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'

export const StyledBrowseByCategoryDrawer = styled(Drawer)`
  display: flex;
  justify-content: center;
  // backdrop-filter: blur(1px);
  background-color: rgba(106, 109, 210, 0.2);
  .MuiPaper-root {
    height: 100vh;
    width: 100%;
    left: auto;
    right: auto;
    background: ${(props) => props?.color};
    padding: 55px 25px 0px;
    overflow: hidden;
    @media (min-width: 480px) {
      padding: 30px 55px 0px;
    }
  }
`

export const StyledBackButton = styled(IconButton)`
  height: 30px;
  width: 30px;
  color: #ffffff !important;
  position: absolute;
  right: 20px;
  top: 20px;
`

export const StyledCategoryList = styled.div`
  font-family: Outfit;
  font-style: normal;
  font-size: 20px;
  line-height: 25px;
  color: #b6b6b6;
  padding-right: 20px;
  display: none;
  @media (orientation: landscape) {
    display: block;
  }
  @media (orientation: portrait) and (min-width: 769px) {
    display: block;
  }
`

export const StyledContentParent = styled.div`
  display: flex;
  margin: 30px auto 0;
  width: 100%;
`

export const StyledCategoryHeaderLandscape = styled.div`
  font-family: Outfit;
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  color: #ffffff;
  width: 100%;
  text-align: left;
  display: none;
  @media (orientation: landscape) {
    display: flex;
  }
  @media (orientation: portrait) and (min-width: 769px) {
    display: flex;
  }
`

export const StyledCategoryHeaderPortrait = styled.div`
  font-family: Outfit;
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  color: #ffffff;
  justify-content: center;
  width: 100%;
  display: none;
  padding-top: 20px;
  @media (orientation: portrait) and (max-width: 768px) {
    display: flex;
  }
`

export const StyledCategoryContent = styled.div`
  display: flex;
  overflow: auto;
  flex-direction: column;

  max-width: 1264px;
  width: 100%;
  margin: 0 auto;
  /* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`

export const StyledCategory = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  font-weight: ${(props) => (props.isSelected ? '700' : '400')};
  color: ${(props) => (props.isSelected ? '#FFFFFF' : '#B6B6B6')};
  min-width: 240px;
  &:hover {
    cursor: pointer;
  }
`

export const StyledContentList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  gap: 15px;
`

export const SelectedCategoryIcon = styled.img`
  height: 14px;
  margin-right: 12px;
`

export const StyledContentBackground = styled.div`
  background-image: linear-gradient(180deg, rgba(43, 43, 43, 0) 36.99%, #2b2b2b 110.28%),
    url(${(props) => props.imgURL});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100%;
  width: 100%;
  position: relative;
`

export const StyledPlayButton = styled.div`
  opacity: 0;
  transition: opacity 0.5s ease-out;
  display: flex;
  width: 150px;
  height: 40px;
  margin-top: 12px;
  background: #ffffff;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  font-family: 'heroregular';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.01em;
  color: #000000;
  cursor: pointer;
`

export const StyledCardInfo = styled.div`
  transition: transform 0.5s ease-out;
  transform: translateY(55px);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;

  font-family: 'heroregular';
  font-style: normal;
  font-weight: 400;
  color: #ffffff;

  margin: 20px 16px;
`

export const StyledContentCard = styled.div`
  flex: 0 0 90%;
  height: 208.5px;
  border-radius: 15px;
  position: relative;
  overflow: hidden;

  &:hover ${StyledContentBackground} {
    transform: scale(1.05);
    transition: transform 0.5s ease-in-out;
  }
  &:hover ${StyledPlayButton} {
    opacity: 1;
  }
  &:hover ${StyledCardInfo} {
    transform: translateY(0);
  }

  @media (min-width: 768px) {
    flex: 0 0 235.65px;
    height: 408.5px;
  }
`

export const StyledCourseTitle = styled.div`
  font-weight: 600;
  font-size: 28px;
  line-height: 35px;
  text-align: center;
  @media (max-width: 1296px) {
    font-size: 14px;
    line-height: 15px;
  }
`

export const StyledCourseCategory = styled.div`
  background: rgba(0, 0, 0, 0.34);
  backdrop-filter: blur(8.5px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 20px;
  font-size: 12px;
  line-height: 15px;
  padding: 5px 12px;
  text-align: center;
  @media (max-width: 1296px) {
    font-size: 9px;
    line-height: 12px;
    padding: 5px 9px;
  }
`

export const StyledCardInfoSeparator = styled.div`
  background: #ffffff;
  height: 4px;
  width: 20px;
  margin: 10px 0;
  @media (max-width: 520px) {
    margin: 5px 0;
  }
`

export const EmptyPageScreenContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
`

export const EmptyPageScreenText = styled.div`
  font-family: Outfit;
  font-size: ${(props) => (props?.heading ? '21px' : '16px')};
  font-weight: ${(props) => (props?.heading ? '700' : '500')};
  color: ${(props) => (props?.heading ? '#FFF' : '#929cb8')};
  text-align: center;
  @media (min-width: 768px) {
    font-size: ${(props) => (props?.heading ? '31px' : '24px')};
    font-weight: ${(props) => (props?.heading ? '700' : '500')};
    color: ${(props) => (props?.heading ? '#FFF' : '#929cb8')};
  }
`

export const EmptyPageImagesContainer = styled.div`
  display: flex;
  align-items: center;
`

export const EmptyPageImageMain = styled.img`
  height: 150px;
  width: 100px;
  border-radius: 10px;
  z-index: 2;
  @media (min-width: 768px) {
    height: 300px;
    width: 200px;
    border-radius: 20px;
  }
`

export const EmptyPageImage = styled.img`
  height: 125px;
  width: 75px;
  border-radius: 10px;
  position: relative;
  z-index: 1;
  ${(props) => props?.left && `left: 30px;`};
  ${(props) => props?.right && `right: 30px;`};
  @media (min-width: 768px) {
    height: 250px;
    width: 150px;
    border-radius: 20px;
  }
`

export const StyledSelectMenu = styled.select`
  height: 52.71px;
  width: 95%;
  background: #0000005c;
  border-radius: 12px;
  font-family: Outfit;
  font-size: 20px;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  padding: 0 10px;
  color: #fff;
  // -moz-appearance: none;
  // -webkit-appearance: none;

  option {
    color: #fff;
    width: auto;
    background: #000;
    font-family: Outfit;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
  }
`
