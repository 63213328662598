import React from 'react'
import PdfReportQuestionAnswer from './PdfReportQuestionAnswer'

export default function PdfReportSectionHandler({ section }) {
  return (
    <div>
      <div style={{ fontWeight: '600'}}>{section?.title}</div>
      <div>
        {section?.questions?.map((question) => (
          <PdfReportQuestionAnswer key={question?.id} question={question} />
        ))}
      </div>
    </div>
  )
}
