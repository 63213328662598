import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'
import { defaultBgImgId, supportedDailyPlayItems } from '../constants'
import { appVersion, platformWeb } from '../services'
import { getCategoryListMap } from './courseCardHelper'

export const itemDataExtractor = (item, setupData) => {
  let itemData = {}
  itemData.itemType = item?.type
  itemData.dfid = item?.dfid
  itemData.completed = item?.completed || false
  itemData.iconId = item?.coloredIconId || item?.iconId || ''
  itemData.coins = item?.coins || 0
  itemData.participantPics = item?.participantPics || []
  itemData.participants = item?.participants || ''
  itemData.altText = item?.altText || ''
  let categoryList = getCategoryListMap(setupData)

  if (item?.type === 'course') {
    itemData.imgId =
      item?.onlineCourse?.episode?.coverIcon || item?.onlineCourse?.episode?.coverImage || ''
    itemData.imgHasText = item?.onlineCourse?.episode?.coverIconHasText || false
    itemData.durationDetail = item?.onlineCourse?.episode?.duration
    itemData.cardData = {
      course: {
        id: item?.onlineCourse?.course?.id || '',
        title: item?.onlineCourse?.episode?.title || '',
        longDesc: item?.onlineCourse?.episode?.longDesc,
        courseType: item?.onlineCourse?.episode?.sessionType,
        categoryId: [item?.onlineCourse?.episode?.category?.[0]],
        altTextObject: categoryList
          ? categoryList?.get(item?.onlineCourse?.episode?.category?.[0])?.dailyPlayAltText
          : {},
        courseCategory: categoryList
          ? categoryList?.get(item?.onlineCourse?.episode?.category?.[0])?.singleTitle ||
            categoryList?.get(item?.onlineCourse?.episode?.category?.[0])?.name
          : '',
        sessions: [
          {
            ...item?.onlineCourse?.episode,
          },
        ],
      },
      teacher: {
        firstName: item?.onlineCourse?.teachers?.[0]?.firstName || '',
        profilePicId: item?.onlineCourse?.teachers?.[0]?.profilePicId || '',
      },
    }
  }
  if (item?.type === 'quote') {
    itemData.id = item?.quote?.id || ''
    itemData.imgId = item?.quote?.imageId || ''
    itemData.author = item?.quote?.author || ''
    itemData.category = item?.quote?.category || ''
    itemData.msg = item?.quote?.msg || ''
    itemData.durationDetail = '2 mins'
    itemData.customJournalId = item?.quote?.customJournalId || ''
    itemData.enableJournal = item?.quote?.enableJournal || false
    itemData.journalId = item?.quote?.journalId || ''
    itemData.altTextObject = categoryList ? categoryList?.get('myQuotes')?.dailyPlayAltText : {}
    itemData.category = categoryList
      ? categoryList?.get('myQuotes')?.singleTitle || categoryList?.get('myQuotes')?.name
      : ''
  }
  if (item?.type === 'journal') {
    itemData.journalData = item?.journal || ''
    itemData.title = item?.journal?.title || ''
    itemData.durationDetail = item?.journal?.journalQusAns?.length
    itemData.imgId = item?.journal?.coverIcon || item?.journal?.coverImage || defaultBgImgId
    itemData.altTextObject = categoryList ? categoryList?.get('myJournal')?.dailyPlayAltText : {}
    itemData.category = categoryList
      ? categoryList?.get('myJournal')?.singleTitle || categoryList?.get('myJournal')?.name
      : ''
  }
  if (item?.type === 'breathe') {
    itemData.imgId = item?.breathe?.coverIcon || item?.breathe?.coverImage || ''
    itemData.altTextObject = categoryList ? categoryList?.get('myBreathe')?.dailyPlayAltText : {}
    itemData.category = categoryList
      ? categoryList?.get('myBreathe')?.singleTitle || categoryList?.get('myBreathe')?.name
      : ''
    itemData.title = item?.breathe?.name || ''
    itemData.durationDetail = item?.breathe?.cycles
  }
  if (item?.type === 'practice') {
    itemData.meditation = item?.meditation
    itemData.imgId = item?.meditation?.coverIcon || item?.meditation?.coverImage || ''
    itemData.altTextObject = categoryList ? categoryList?.get('myPractices')?.dailyPlayAltText : {}
    itemData.category = categoryList
      ? categoryList?.get('myPractices')?.singleTitle || categoryList?.get('myPractices')?.name
      : ''
    itemData.title = item?.meditation?.title || ''
    if (item?.meditation?.durationType === 'fixed') {
      itemData.durationDetail = item?.meditation?.duration
    } else if (item?.meditation?.durationType === 'range') {
      itemData.durationDetail = [item?.meditation?.minDuration, item?.meditation?.maxDuration]
    } else {
      itemData.durationDetail = '∞'
    }
  }
  if (item?.type === 'mental-state-type') {
    itemData.itemId = item?.mentalStateType?.id
    itemData.question = item?.mentalStateType?.question
    itemData.title = item?.mentalStateType?.title
    itemData.qaTexts = item?.mentalStateType?.dailyPlayAltText
    itemData.options = item?.mentalStateType?.options
    itemData.completedValue = item?.completedValue
  }
  if (item?.type === 'assessment') {
    itemData.assessmentData = item?.assessment || ''
    itemData.title = item?.assessment?.title || ''
    itemData.durationDetail = item?.assessment?.journalQusAns?.length
    itemData.imgId = item?.assessment?.coverIcon || item?.assessment?.coverImage || defaultBgImgId
    itemData.altTextObject = categoryList ? categoryList?.get('assessment')?.dailyPlayAltText : {}
    itemData.category = categoryList
      ? categoryList?.get('assessment')?.singleTitle || categoryList?.get('assessment')?.name
      : ''
  }
  if (item?.type === 'article') {
    itemData.imgId = item?.article?.coverIcon || item?.article?.coverImage || ''
    itemData.title = item?.assessment?.title || ''
    itemData.altTextObject = categoryList ? categoryList?.get('article')?.dailyPlayAltText : {}
    itemData.category = categoryList
      ? categoryList?.get('article')?.singleTitle || categoryList?.get('article')?.name
      : ''
    itemData.articleData = item?.article
  }
  return itemData
}

export const getBodyForSavingJournal = (
  id,
  quesAnsArray,
  type,
  startingTime,
  duration,
  jetID = '',
) => {
  return {
    clientCreatedTime: startingTime?.utc()?.format('YYYY-MM-DD HH:mm:ss'),
    clientSessionDuration: duration,
    clientSessionId: uuidv4(),
    countryCode: localStorage.getItem('countryCode'),
    createdTime: startingTime?.utc()?.format('YYYY-MM-DD HH:mm:ss'),
    id: jetID,
    journalId: id,
    journalQusAns: quesAnsArray,
    journalType: type,
    languageCode: sessionStorage.getItem('languageCode'),
    modifiedTime: startingTime?.utc()?.format('YYYY-MM-DD HH:mm:ss'),
    userDevice: {
      appPackage: 'com.aumhum.web',
      appVersion: appVersion,
      browserName: '',
      browserType: '',
      browserVersion: '',
      createdTime: startingTime?.utc()?.format('YYYY-MM-DD HH:mm:ss'),
      deviceId: '',
      deviceType: '',
      imei: '',
      manufacturer: '',
      modelNumber: '',
      modifiedTime: startingTime?.utc()?.format('YYYY-MM-DD HH:mm:ss'),
      osName: '',
      osVersion: '',
      platform: platformWeb,
      userAgent: '',
      userId: '',
    },
  }
}

export const prepareDataForEditingJournal = (data) => {
  return {
    journalQusAns: data?.journalQusAns,
    jetID: data?.id,
    journalType: data?.journalType,
    id: data?.journal?.id,
    subscription: { id: data?.journal?.subscription?.id },
  }
}

export const dataForBlankJournal = {
  journalQusAns: [
    {
      answere: '',
      hints: [],
      question: '',
      qusId: '',
      subtext: '',
    },
  ],
  journalType: 'regular',
  id: 'default',
  subscription: { id: 'subscription_free' },
}

export function scoreCalculator(scoreMap, items) {
  let currentScore = 0
  let maxScore = 0
  let dataAboutItem

  items?.forEach((item) => {
    maxScore += item?.coins

    dataAboutItem = scoreMap?.get(item?.dfid) || []

    dataAboutItem?.forEach((itemData) => {
      if (item?.itemId === itemData?.contentId || item?.quote?.journalId === itemData?.contentId) {
        if (itemData?.completed === true) {
          currentScore += item?.coins
        }
      }
    })
  })
  return { currentScore, maxScore }
}

export function tasksCalculator(scoreMap, items) {
  let completedTasks = 0
  let totalTasks = items?.length
  let dataAboutItem

  items?.forEach((item) => {
    dataAboutItem = scoreMap?.get(item?.dfid) || []

    dataAboutItem?.forEach((itemData) => {
      if (item?.itemId === itemData?.contentId || item?.quote?.journalId === itemData?.contentId) {
        if (itemData?.completed === true) {
          completedTasks += 1
        }
      }
    })
  })
  return { completedTasks, totalTasks }
}

export function markCompleteIfPlayed(scoreMap, items) {
  const markedArray = []

  items?.forEach((item) => {
    const dataAboutItem = scoreMap?.get(item?.dfid) || []

    const completed = dataAboutItem.some((itemData) => {
      if (item?.type === 'quote') {
        if (item?.quote?.journalId === itemData?.contentId) {
          return itemData?.completed
        }
      }
      if (item?.itemId === itemData?.contentId) {
        return itemData?.completed
      }
    })

    if (item?.type === 'mental-state-type') {
      dataAboutItem?.forEach((itemData) => {
        if (itemData?.contentType === 'mental-state-type') {
          if (itemData?.contentId === item?.itemId) {
            item.completedValue = itemData?.completedValue
          }
        }
      })
    }

    if (completed) {
      item.completed = true
    } else {
      item.completed = false
    }
    markedArray.push(item)
  })

  return markedArray
}

export function progressOfActiveDayProvider(progressData, chosenDay) {
  let activeDayMoment = moment().add(parseInt(chosenDay), 'day')
  let progressOfActiveDay = []

  progressData?.forEach((item) => {
    let dateMoment = moment(item?.date)

    if (dateMoment.isSame(activeDayMoment, 'day')) {
      progressOfActiveDay.push(item)
    }
  })

  return progressOfActiveDay
}

export const giveCompletionNumbers = (daysData, chosenDay, completionData) => {
  let totalDaySupportedItems = 0
  daysData[chosenDay]?.forEach((item, index) => {
    if (supportedDailyPlayItems.includes(item?.dailyFeature?.item?.heroItem?.type)) {
      totalDaySupportedItems += 1
    }
    item?.dailyFeature?.item?.otherItems?.forEach((otherItem) => {
      if (supportedDailyPlayItems.includes(otherItem?.type)) {
        totalDaySupportedItems += 1
      }
    })
  })

  let totalTasksCompleted = 0
  progressOfActiveDayProvider(completionData, chosenDay)?.forEach((feature) => {
    feature?.progress?.forEach((item) => {
      if (item?.completed) {
        totalTasksCompleted += 1
      }
    })
  })

  if (totalDaySupportedItems === 0) {
    totalDaySupportedItems = 1
  }

  return {
    totalTasksCompleted: totalTasksCompleted,
    totalDaySupportedItems: totalDaySupportedItems,
  }
}

export const getDurationText = (data) => {
  let duration = ''
  if (data?.itemType === 'course') {
    duration = `${parseInt(data?.durationDetail / 60000)} mins`
  } else if (data?.itemType === 'episode') {
    duration = `${parseInt(data?.durationDetail / 60000)} mins`
  } else if (data?.itemType === 'journal') {
    duration = `${data?.durationDetail} questions`
  } else if (data?.itemType === 'quote') {
    duration = data?.durationDetail
  } else if (data?.itemType === 'practice') {
    if (Array.isArray(data?.durationDetail)) {
      duration = `${parseInt(data?.durationDetail?.[0] / 60000)} - ${parseInt(
        data?.durationDetail?.[1] / 60000,
      )} mins`
    } else {
      duration = `${parseInt(data?.durationDetail / 60000)} mins`
    }
  } else if (data?.itemType === 'breathe') {
    duration = `${data?.durationDetail} cycles`
  }

  return duration
}

export const getCateogryTitleText = (data) => {
  let title
  if (data?.altText) {
    title = data?.altText
  } else if (data?.itemType === 'course') {
    title = data?.cardData?.course?.courseCategory
  } else if (data?.itemType === 'journal') {
    if (data?.category) {
      title = data?.category
    } else {
      title = 'Guided Journal'
    }
  } else if (data?.itemType === 'quote') {
    if (data?.category) {
      title = data?.category
    } else {
      title = 'Reflection'
    }
  } else if (data?.itemType === 'practice') {
    if (data?.category) {
      title = data?.category
    } else {
      title = 'Practice'
    }
  } else if (data?.itemType === 'breathe') {
    if (data?.category) {
      title = data?.category
    } else {
      title = 'Breathe'
    }
  }

  return title
}
