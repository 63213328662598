import { ButtonBase } from '@mui/material'
import styled from 'styled-components'
import { styled as muiStyled } from '@mui/material'
import { allAvailableEventTypes } from 'src/consumer/constants'

export const MySessionsBackground = styled.div`
  background: #ffffff;
  width: 100%;
  min-height: 100vh;
  font-family: Outfit;
`

export const SecondaryContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  @media (min-width: 800px) {
    flex-direction: row;
  }
`

export const SecondarySisterContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 50px 10px;
  position: relative;
  @media (min-width: 800px) {
    padding: 50px;
  }
`

// top index component :

export const TopIndexComponent = styled.div`
  background: #7a52b5;
  width: 100%;
  height: ${(props) => (props?.coverFullHeight ? '100vh' : 'auto')};
  flex-shrink: 0;
  padding: 0px 27px 23px;
  transition: all 0.3s ease-in-out;
  overflow: auto;
  @media (min-width: 800px) {
    display: none;
  }
`

export const TopIndexTitle = styled.div`
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`

export const TopIndexItem = styled.div`
  border-radius: 7px;
  background: #fff;
  padding: 10px 18px;
`

// side index components :

export const IndexContainer = styled.div`
  background: #7a52b5;
  width: 400px;
  height: 100vh;
  flex-shrink: 0;
  padding: 100px 0 0px 49px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  @media (max-width: 800px) {
    display: none;
  }
`

export const BackButton = styled.button`
  position: absolute;
  top: 20px;
  left: 20px;
  display: flex;
  align-items: center;
  gap: 5px;
  color: ${(props) => (props?.dark ? '#787ce4' : '#FFFFFF')};
`

export function getIndexItemContainerBackground(eventType, roleInEvent) {
  if (roleInEvent === 'user') {
    if (allAvailableEventTypes?.consultation === eventType) {
      return '#10217d'
    } else {
      return '#a179dc'
    }
  } else {
    if (roleInEvent === 'panelist') {
      return '#ccac2b'
    } else if (roleInEvent === 'mentor') {
      return '#ff8a38'
    } else {
      return '#a179dc'
    }
  }
}

export const IndexItemContainer = styled.div`
  flex-shrink: 0;
  min-height: 97px;
  width: ${(props) => (props?.selected ? '100%' : `calc(100% - 49px)`)};
  border-radius: ${(props) => (props?.selected ? '7px 0 0 7px' : '7px')};
  background: ${(props) =>
    props?.selected ? '#FFF' : getIndexItemContainerBackground(props?.eventType, props?.role)};
  color: ${(props) => (props?.selected ? '#000' : '#fff')};
  cursor: pointer;
  padding: 19px;
  padding-right: ${(props) => props?.selected && '68px'};
  color: ${(props) => (props?.selected ? '#000' : '#FFF')};
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
`

export const BookedSlotsContainer = muiStyled('div')(
  ({ theme }) => `
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 100%;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;
    @media(min-width: 800px) {
        overflow-y: auto;
    }
    ::-webkit-scrollbar { 
      display: none;  /* Safari and Chrome */
  }
`,
)

export function getEndsStartsTextColor(eventType) {
  switch (eventType) {
    case allAvailableEventTypes?.consultation:
      return '#89dee2'
    default:
      return '#E3D0FF'
  }
}

export function getTertiaryColor(eventType) {
  switch (eventType) {
    case allAvailableEventTypes?.consultation:
      return '#537ad3'
    default:
      return '#7215d5'
  }
}

export const StartsInTextOne = styled.div`
  color: ${(props) => (props?.selected ? '#858585' : getEndsStartsTextColor(props.eventType))};
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
`

export const StartsInTextTwo = styled.div`
  color: ${(props) => (props?.selected ? '#7351AB' : '#FFFFFF')};
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  margin: 3px 0;
`

export const DetailsButton = styled.button`
  color: #000;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  gap: 2px;
  padding: 4px 9px;
  border-radius: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  display: ${(props) => props?.selected && 'none'};
  @media (min-width: 800px) {
    padding: 7px 11px;
  }
`

export const BookNewSessionButton = muiStyled(ButtonBase)`
    background: linear-gradient(155deg, #6C89D2 0%, #a674da 100%);
    border-radius: 25px;
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 14px 27px;
    width: calc(100% - 49px);
    margin-top: 20px;
`

export const SessionTypeTime = styled.div`
  background: #ffffff;
  border-radius: 23px;
  padding: 2.9px;
  width: calc(100% - 49px);
  margin-top: 10px;
  display: flex;
  height: 35px;
`

export const SessionTypeTimeOption = styled.button`
  color: ${(props) => (props?.selected ? '#FFF' : '#000')};
  background: ${(props) => (props?.selected ? '#fa821f' : '#FFF')};
  height: 100%;
  width: 100%;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  border-radius: 23px;
`

// details part components :

export const DetailsOfSessionContainer = styled.div`
  width: 100%;
  max-width: 1512px;
  padding: 15px 0px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  @media (min-width: 800px) {
    padding: 100px 46px;
  }
  @media (min-width: 1300px) {
    flex-direction: row;
    justify-content: space-between;
    gap: 50px;
  }
`

export const BookedEventsShortDescription = muiStyled('div')(
  ({ theme }) => `
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    gap:10px;
`,
)

// ## (subsection) text part

export const TextPartDetails = styled.div``

export const HeadlineOfSession = styled.div`
  color: #373737;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 4px;
  @media (min-width: 800px) {
    color: #373737;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: left;
    margin-bottom: 6px;
  }
`

export const SubheadingOfSession = styled.div`
  color: #555;
  text-align: left;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 12px;
  margin-bottom: 12px;
  @media (min-width: 800px) {
    font-size: 20px;
    margin-top: 22px;
    margin-bottom: 22px;
  }
`

export const DescriptionOfSession = styled.div`
  color: #6a6a6a;
  text-align: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 200;
  text-align: center;
  margin-bottom: 17px;
  @media (min-width: 800px) {
    font-size: 19px;
    text-align: left;
    margin-bottom: 30px;
  }
`

export const MeetingDetails = styled.div`
  color: #4d4d4d;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  display: flex;
  flex-direction: column;
  gap: 17px;
  text-align: center;
  display: flex;
  @media (min-width: 800px) {
    flex-direction: row;
    gap: 26px;
  }
`

export const MeetingButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 10px;
  @media (min-width: 800px) {
    gap: 15px;
  }
`

export const JoinMeetingButton = styled.div`
  color: #fff;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  background: linear-gradient(123.83deg, #feae36 12.4%, #fd7600 70.06%);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4.5px;
  border-radius: 12px;
  padding: 8.5px 31px;
  cursor: ${props => props?.active ? 'pointer' : 'default'};
  @media (min-width: 800px) {
    font-size: 16px;
    gap: 6.5px;
    padding: 8.5px 35px;
  }
`

export const MeetingPlatformImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 21px;
  height: 21px;
  flex-shrink: 0;
  background: #fff;
  border-radius: 50%;
  @media (min-width: 800px) {
    width: 32px;
    height: 32px;
  }
`

export const CopyButton = styled.button`
  color: #4f23d9;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-radius: 12px;
  border: 1.7px solid #4f23d9;
  @media (min-width: 800px) {
    padding: 8.5px 35px;
  }
  &:hover {
    background-color: #f2f8fc;
  }
`

// ## (subsection) mentor part

export const MentorPartDetails = styled.div`
  flex-shrink: 0;
`

export const YourMentorText = styled.div`
  color: #4d4d4d;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  cursor: default;
  margin-bottom: 11px;
  @media (min-width: 800px) {
    font-size: 16px;
    margin-bottom: 25px;
  }
  @media (min-width: 1300px) {
    text-align: center;
  }
`

export const MentorPictureAndName = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  gap: 17px;
  @media (min-width: 1300px) {
    flex-direction: column;
    justify-content: center;
  }
`

export const MentorImage = styled.img`
  width: 78px;
  height: 78px;
  border-radius: 14px;
  box-shadow: 3px 4px 14px 0px rgba(0, 0, 0, 0.25);
  flex-shrink: 0;
  object-fit: cover;
  @media (min-width: 1300px) {
    width: 200px;
    height: 200px;
  }
`

export const NameAndSubtext = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 1300px) {
    align-items: center;
  }
`

export const MentorName = styled.div`
  color: ${(props) => (props?.secondary ? '#7a5697' : '#2E0060')};
  font-size: ${(props) => (props?.secondary ? '14px' : '18px')};
  font-style: normal;
  font-weight: ${(props) => (props?.secondary ? '300' : '800')};
  line-height: normal;
  cursor: default;
  @media (min-width: 1300px) {
    font-size: ${(props) => (props?.secondary ? '14px' : '20px')};
  }
`

export const PictureAndName = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 17px;
  @media (min-width: 1300px) {
    flex-direction: column;
    justify-content: center;
  }
`

export const AboutIcon = styled.img`
  width: 43px;
  height: 43px;
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.18);
  border-radius: 50%;
  background-size: cover;
`

export const DetailsIconBackground = styled.div`
  width: 40px;
  height: 40px;
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.18);
  flex-shrink: 0;
  border-radius: 50%;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (min-width: 500px) and (max-width: 800px) {
    display: none;
  }
  @media (min-width: 1000px) {
    display: none;
  }
`

export const DetailsButtonContainer = styled.div`
  height: 40px;
  padding: 0 22px;
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.18);
  gap: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  border-radius: 27px;
  cursor: pointer;
  @media (max-width: 500px) {
    display: none;
  }
  @media (min-width: 800px) and (max-width: 1000px) {
    display: none;
  }
`
