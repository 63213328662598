import styled from 'styled-components'
import { Drawer } from '@mui/material'
import Modal from 'styled-react-modal'

// parent:

export const LeaderboardPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  min-height: ${(props) => (props?.isMobile ? `calc(100vh - 40px)` : '100vh')};
  background: #1e1f23;
`

export const LeaderboardPrimaryHeader = styled.div`
  background: #393e53;
  color: #fff;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 55px;
  padding: 0 30px;
  z-index: 2;
  font-size: 16px;
  border-width: 0;
  position: sticky;
  top: ${(props) => (props?.isMobile ? '40px' : '0px')};
`

export const LeaderboardProfileImage = styled.img`
  width: 45px;
  height: 45px;
  flex-shrink: 0;
  border-radius: 9px;
`

export const LeaderboardSecondaryHeader = styled.div`
  background: #393e53;
  color: #fff;
  width: 100%;
  display: flex;
  align-items: center;
  height: 55px;
  gap: 5px;
  border-width: 0;
  overflow: auto;
  padding: 0 12px;
  position: sticky;
  z-index: 2;
  top: ${(props) => (props?.isMobile ? '95px' : '55px')};
  @media (min-width: 800px) {
    padding: 0 42px;
    gap: 22px;
  }
  @media (min-width: 1300px) {
    padding: 0 72px;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`

export const LeaderboardSecondaryHeaderItems = styled.div`
  height: 100%;
  background: ${(props) => (props?.isActive ? '#1e1f23' : '#4d536e')};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #fff;
  flex-shrink: 0;
  font-family: Outfit;
  border-radius: 17px 17px 0px 0px;
  font-size: 15px;
  gap: 5px;
  font-weight: ${(props) => (props?.isActive ? '700' : '500')};
  width: 152px;
  @media (min-width: 800px) {
    width: 200px;
    font-size: 18px;
  }
  @media (min-width: 1300px) {
    width: 290px;
  }
`

export const LeaderboardBody = styled.div`
  width: 100%;
  flex-grow: 1;
  position: relative;
  top: ${(props) => (props?.isMobile ? '40px' : '0px')};
`

export const LeaderboardSectionIcon = styled.img`
  height: 20px;
  margin-right: 5px;
`

// leaderboard section styles:

export const LeaderboardSectionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #1e1f23;
  @media (min-width: 800px) {
    flex-direction: row;
  }
`

// highlighted leaders

export const HighlightedLeadersContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  @media (min-width: 800px) {
    width: 50%;
  }
`

export const ComparisonModeSelectionContainer = styled.div`
  background: #15161a;
  display: flex;
  justify-content: space-around;
  border-radius: 10px;
  flex-shrink: 0;
  padding: 7px;
  margin: 25px auto;
  height: 45.155px;
`

export const ComparisonModeSelectionItem = styled.div`
  background-color: ${(props) => props?.selected && '#37383d'};
  display: flex;
  gap: 3px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 10px;
  cursor: pointer;
  color: #fff;
  font-family: Outfit;
  font-style: normal;
  line-height: normal;
  font-weight: 700;
  font-size: 16px;
  padding: 7px 30px;
  @media (min-width: 1300px) {
    padding: 9px 48px;
  }
`

export const LeaderPicturesContainer = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  margin: 70px 0;
  @media (min-width: 1300px) {
    margin: 120px 0;
  }
`

export const LeaderboardPictureSilver = styled.div`
  width: 89px;
  height: 89px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 2px;
  border: 3px solid #c0c0c0;
  border-radius: 50%;
  background: url(${(props) => props?.background}) no-repeat center center;
  background-size: cover;
  position: relative;
  top: 60px;
  left: 10px;
  @media (min-width: 1300px) {
    width: 150px;
    height: 150px;
    top: 100px;
    left: 20px;
  }
`

export const LeaderboardPictureGold = styled.div`
  width: 119px;
  height: 119px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 2px;
  border: 3px solid #ebff00;
  background: url(${(props) => props?.background}) no-repeat center center;
  border-radius: 50%;
  background-size: cover;
  @media (min-width: 1300px) {
    width: 200px;
    height: 200px;
  }
`

export const LeaderboardPictureBronze = styled.div`
  width: 89px;
  height: 89px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 2px;
  border: 3px solid #cd7f32;
  border-radius: 50%;
  background: url(${(props) => props?.background}) no-repeat center center;
  background-size: cover;
  position: relative;
  top: 60px;
  right: 10px;
  @media (min-width: 1300px) {
    width: 150px;
    height: 150px;
    top: 100px;
    right: 20px;
  }
`

export const RankNumberText = styled.div`
  position: relative;
  bottom: 60px;
  color: #fff;
  font-size: 17px;
  font-family: Outfit;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  @media (min-width: 1300px) {
    font-size: 30px;
    font-weight: 600;
    bottom: 90px;
  }
`

export const CrownPicture = styled.img`
  position: relative;
  bottom: 60px;
  width: 25px;
  height: 25px;
  @media (min-width: 1300px) {
    bottom: 90px;
    width: 43px;
    height: 43px;
  }
`

export const LeadersCrownAlt = styled.div`
  width: 12px;
  height: 12px;
  position: relative;
  bottom: 60px;
  flex-shrink: 0;
  border-radius: 12px;
  filter: blur(2px);
  background: ${(props) => (props?.type === 'silver' ? '#D2D2D2' : '#C17200')};
  @media (min-width: 1300px) {
    width: 20px;
    height: 20px;
    bottom: 90px;
    border-radius: 20px;
  }
`

export const LeadersName = styled.div`
  color: #fff;
  font-family: Outfit;
  font-style: normal;
  line-height: normal;
  position: relative;

  font-size: 12px;
  font-weight: 300;
  top: ${(props) => (props?.secondary ? '50px' : '70px')};

  @media (min-width: 1300px) {
    font-size: 18px;
    font-weight: 500;
    top: ${(props) => (props?.secondary ? '90px' : '120px')};
  }
`

export const ScoreNumbers = styled.div`
  color: #fff;
  position: relative;
  top: ${(props) => (props?.secondary ? '55px' : '80px')};
  border-radius: 31px;
  background: #54555a;
  font-family: Outfit;
  font-style: normal;
  line-height: normal;
  gap: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 16px;
  font-weight: 500;
  font-size: 16px;
  @media (min-width: 1300px) {
    font-weight: 700;
    font-size: 18px;
    padding: 6px 21px;
    top: ${(props) => (props?.secondary ? '95px' : '125px')};
  }
`

// userstrips

export const UserScoreStripsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 50px 0;
  font-family: Outfit;
  margin: 0 auto;
  @media (min-width: 800px) {
    width: 50%;
    padding: 50px 0;
    margin: 0 auto;
  }
`

export const UserScoreStrip = styled.div`
  height: 54px;
  flex-shrink: 0;
  background: ${(props) => (props?.heading ? '#1e1f23' : '#37383d')};
  ${(props) => props?.isMe && `background: #53545a;`};
  display: grid;
  grid-template-columns: 1.5fr 3fr 1.5fr; /* Four columns with different sizes */
  align-items: center;
  color: #fff;
  font-family: Outfit;
  line-height: normal;
  font-weight: 500;
  border-radius: 16px;
  margin: 0 auto;
  padding: 0 16px;
  font-size: 14px;
  width: 90%;
`

export const CriteriaChangerButton = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
  justify-content: center;
  font-family: Outfit;
  background: #000000;
  padding: 8px 5px;
  border-radius: 7px;
  cursor: pointer;
`

export const CriteriaChangeOption = styled.div`
  min-width: 165px;
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: center;
  font-family: Outfit;
  padding: 8px 5px;
  border-radius: 7px;
  cursor: pointer;
  color: ${(props) => (props?.selected ? '#10CC00' : '#FFF')};
  font-weight: 500;
  font-size: 14px;
  &:hover {
    color: #10cc00;
  }
`

// for your stats section

export const YourStatsSectionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #1e1f23;
  padding: 28px 20px;
  gap: 29px;
  @media (min-width: 1150px) {
    flex-direction: row;
    padding: 35px 40px;
  }
  @media (min-width: 1500px) {
    padding: 61px 111px;
  }
`
// for boxes

export const YourStatsBoxesContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 16px;
  @media (min-width: 1150px) {
    flex-grow: 1;
  }
`

export const YourStatsBoxesContainerColumn = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props?.left ? 'flex-end' : 'flex-start')};
  gap: 15px;
`

export const YourStatsSmallBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 157px;
  height: 134px;
  box-shadow: 7px 9px 22px 0px rgba(0, 0, 0, 0.51);
  flex-shrink: 0;
  border-radius: 21px;
  padding: 16px 18px;
  position: relative;
  overflow: hidden;
  background: #37383d;
  @media (min-width: 600px) {
    width: 249px;
    height: 199px;
    padding: 23px 26px;
  }
  @media (min-width: 1500px) {
    width: 334px;
    height: 268px;
    padding: 33px 28px;
  }
`

export const YourStatsBigBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 157px;
  height: 200px;
  flex-shrink: 0;
  border-radius: 21px;
  padding: 16px 18px;
  position: relative;
  overflow: hidden;
  box-shadow: 7px 9px 22px 0px rgba(0, 0, 0, 0.51);
  background: #37383d;
  @media (min-width: 600px) {
    width: 249px;
    height: 298px;
    padding: 23px 26px;
  }
  @media (min-width: 1500px) {
    width: 334px;
    height: 400px;
    padding: 33px 28px;
  }
`

export const GreenEffect = styled.div`
  position: absolute;
  top: 20px;
  right: 30px;
  width: 73.678px;
  height: 73.678px;
  background: #76ff36;
  filter: blur(50.5px);
  @media (min-width: 800px) {
    width: 147px;
    height: 147px;
    filter: blur(103px);
  }
`

export const PurpleEffect = styled.div`
  position: absolute;
  bottom: 10px;
  right: 20px;
  width: 53.678px;
  height: 53.678px;
  background: #b336ff;
  filter: blur(50.5px);
  @media (min-width: 800px) {
    width: 108px;
    height: 108px;
    filter: blur(75.5px);
  }
`

export const OrangeEffect = styled.div`
  position: absolute;
  bottom: 30px;
  left: 20px;
  width: 73.678px;
  height: 73.678px;
  background: #ffac36;
  filter: blur(50.5px);
  @media (min-width: 800px) {
    width: 147px;
    height: 147px;
    filter: blur(103px);
  }
`

export const BlueEffect = styled.div`
  position: absolute;
  top: 20px;
  left: 35px;
  width: 53.678px;
  height: 53.678px;
  background: #36cfff;
  filter: blur(50.5px);
  @media (min-width: 800px) {
    width: 108px;
    height: 108px;
    filter: blur(75.5px);
  }
`

export const PinkEffect = styled.div`
  position: absolute;
  top: 25px;
  left: 25px;
  width: 53.678px;
  height: 53.678px;
  background: #ffc0cb;
  filter: blur(40.5px);
  @media (min-width: 800px) {
    width: 108px;
    height: 108px;
    filter: blur(75.5px);
  }
`

export const TextRowOne = styled.div`
  color: #d9bbfd;
  font-family: Outfit;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 17px;
  padding-right: 10px;
  @media (min-width: 600px) {
    font-size: 14px;
  }
  @media (min-width: 1500px) {
    font-size: 22px;
    font-weight: 400;
  }
`

export const TextRowLast = styled.div`
  color: #fff;
  font-family: Outfit;
  font-style: normal;
  font-weight: 700;
  text-align: left;
  font-size: 42px;
  line-height: 42px;
  @media (min-width: 600px) {
    font-size: 52px;
    line-height: 52px;
  }
  @media (min-width: 1500px) {
    font-size: 77px;
    line-height: 77px;
  }
`

export const IconImageStats = styled.img`
  width: 21.556px;
  height: 20.478px;
  flex-shrink: 0;
  @media (min-width: 600px) {
    width: 32.148px;
    height: 30.539px;
  }
  @media (min-width: 1500px) {
    width: 43.175px;
    height: 41.014px;
  }
`

// for tables

export const YourStatsTablesContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 13px;
  @media (min-width: 800px) {
    flex-grow: 1;
    gap: 21px;
  }
`

export const YourStatsTableItemHeading = styled.div`
  color: #a59eac;
  font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 1.4px;
  text-align: center;
  text-transform: uppercase;
  margin-top: 10px;
  @media (min-width: 800px) {
    font-size: 20px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 1.4px;
  }
`

export const YourStatsTableItemContainer = styled.div`
  width: 100%;
  background: #37383d;
  flex-grow: 0;
  border-radius: 21px;
  padding: 18px 19px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  @media (min-width: 800px) {
    padding: 26px 33px;
    gap: 21px;
  }
`

export const DisplayFlexSpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const YourStatsTableItemKey = styled.div`
  color: #9f68da;
  font-family: Outfit;
  font-size: 14px;
  line-height: 17px;
  @media (min-width: 800px) {
    font-size: 18px;
  }
`

export const YourStatsTableItemValue = styled.div`
  color: #bba2d6;
  font-family: Outfit;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 17px;
  @media (min-width: 800px) {
    font-size: 19px;
  }
`

// for 'Your Badges' section

export const YourBadgesSectionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #1e1f23;
  padding: 28px 0;
  gap: 29px;
`

export const AllEarnedRewardsContainer = styled.div`
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 25px;
  border-radius: 24px;
  background: #37383d;
  margin: 0 auto;
  padding: 19px 27px;
  @media (min-width: 500px) {
    gap: 55px;
  }
  @media (min-width: 800px) {
    padding: 25px 50px;
    gap: 60px;
  }
  @media (min-width: 1500px) {
    padding: 30px 94px;
    gap: 75px;
  }
`

export const AwardCircleGeneric = styled.div`
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-shrink: 0;
  background: ${(props) => props?.colorString};
  width: 109px;
  height: 109px;
  margin-bottom: 13.79px;
  @media (min-width: 800px) {
    width: 129px;
    height: 129px;
    margin-bottom: 17px;
  }
  @media (min-width: 1500px) {
    width: 166px;
    height: 166px;
    margin-bottom: 21px;
  }
`

export const GlowyEffectOfCircle = styled.div`
  width: 95.134px;
  height: 95.134px;
  border-radius: 50%;
  flex-shrink: 0;
  position: absolute;
  bottom: -12px;
  background: ${(props) => props?.colorString};
  filter: blur(16.5px);
  @media (min-width: 800px) {
    width: 112px;
    height: 112px;
    bottom: -18px;
  }
  @media (min-width: 1500px) {
    width: 145.012px;
    height: 145.012px;
  }
`

export const DiscUnderCircle = styled.img`
  width: 121.91px;
  flex-shrink: 0;
  @media (min-width: 800px) {
    width: 155.825px;
  }
  @media (min-width: 1500px) {
    width: 185.825px;
  }
`

export const ActiveAwardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  cursor: pointer;
`

export const AwardTitleGeneric = styled.div`
  color: #fff;
  text-align: center;
  font-family: Outfit;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 1.05px;
  margin-bottom: 6px;
  max-width: 121.91px;
  ${(props) => props?.moreMargin && `margin-top: 8px`};
  @media (min-width: 800px) {
    max-width: 155px;
    ${(props) => props?.moreMargin && `margin-top: 12px`};
  }
  @media (min-width: 1500px) {
    font-size: 20px;
    line-height: 21px;
    max-width: 185px;
    ${(props) => props?.moreMargin && `margin-top: 20px`};
  }
`

export const AwardSubtitleGeneric = styled.div`
  color: #bdbdbd;
  text-align: center;
  font-family: Outfit;
  font-size: 10px;
  font-style: normal;
  font-weight: 300;
  line-height: 17px;
  letter-spacing: 0.7px;
  margin-bottom: 8px;
  max-width: 121.91px;
  @media (min-width: 800px) {
    max-width: 155px;
  }
  @media (min-width: 1500px) {
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.98px;
    margin-bottom: 12px;
    max-width: 185px;
  }
`

export const AwardsRow = styled.div`
  display: flex;
  gap: 30px;
  overflow: auto;
  padding: 0 25px;

  @media (min-width: 800px) {
    gap: 45px;
    padding: 0 35px;
  }
  @media (min-width: 1500px) {
    gap: 70px;
    padding: 0 55px;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`

export const EarnFirstRewardContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const EarnFirstRewardPictureHeading = styled.span`
  color: #fff;
  text-align: center;
  cursor: default;
  font-family: Outfit;
  font-size: ${(props) => (props?.big ? '42px' : '18px')};
  font-style: normal;
  font-weight: ${(props) => (props?.big ? '700' : '400')};
  line-height: ${(props) => (props?.big ? '45px' : '24px')};
  ${(props) => props?.big && 'letter-spacing: 2.94px;'};
`

export const EarnFirstRewardPicture = styled.img`
  width: 146px;
  margin: 50px 0 27px 0;
`

export const EarnFirstRewardFooterHeading = styled.div`
  color: ${(props) => (props?.first ? '#D4D4D4' : '#FFF')};
  font-family: Outfit;
  font-style: normal;
  font-size: ${(props) => (props?.first ? '18px' : '12px')};
  font-weight: ${(props) => (props?.first ? '300' : '600')};
  margin-bottom: ${(props) => (!props?.first ? '15px' : '5px')};
  line-height: 17px;
  cursor: pointer;
  @media (min-width: 800px) {
    font-size: ${(props) => (props?.first ? '23px' : '16px')};
    line-height: 17px;
  }
`

export const ContextBoxForBadge = styled.div`
  position: absolute;
  min-width: 27.068px;
  height: 18.537px;
  flex-shrink: 0;
  cursor: default;
  border-radius: 4px;
  background: #fff;
  bottom: 10px;
  right: 0px;
  color: #53545f;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Outfit;
  font-size: 9px;
  font-style: normal;
  font-weight: 500;
  padding: 0 2px;
  @media (min-width: 800px) {
    min-width: 34.051px;
    font-size: 11px;
    height: 25.617px;
    font-weight: 600;
  }
  @media (min-width: 1500px) {
    min-width: 42.051px;
    font-size: 13px;
    height: 29.617px;
    font-weight: 600;
  }
`

// detail of badge

export const BadgeInformationDrawer = styled(Drawer)`
  display: flex;
  justify-content: center;
  // backdrop-filter: blur(1px);
  background-color: rgba(106, 109, 210, 0.2);
  .MuiPaper-root {
    height: 70vh;
    width: 100%;
    left: auto;
    right: auto;
    background: #ffffff;
    background-size: 100% 100%;
    border-radius: 25px 25px 0px 0px;
    //   overflow: hidden;
    //   overflow-y: auto; /* Hide scrollbar for Chrome, Safari and Opera */
    //   ::-webkit-scrollbar {
    //     display: none;
    //   }
  }
`

export const BadgeDetailContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`

export const AwardContainerBox = styled.div`
  width: 100%;
  height: 50%;
  background: ${(props) => props?.background};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const DetailContainerBox = styled.div`
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 41px;
  color: #ff839b;
  font-family: Outfit;
  font-style: normal;
  text-align: center;
  font-size: 16px;
  font-weight: 300;
  line-height: 22px;
  cursor: default;
  @media (min-width: 800px) {
    font-size: 19px;
    line-height: 24px;
    padding: 44px;
  }
`

export const DetailCloseButton = styled.button`
  color: #44008e;
  text-align: center;
  flex-shrink: 0;
  flex-grow: 0;
  font-family: Outfit;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 2.1px;
  text-decoration: underline;
  padding: 8.7px 24px;
  border-radius: 35px;
  background: #ead7ff;
  max-width: 102px;
`

export const StyledBadgeInformationModal = Modal.styled`
  width: 300px;
  height: 459px; 
  opacity: ${(props) => props.opacity};
  transition : all 0.3s ease-in-out;
  border-radius: 25px;
  position: relative;
  overflow: hidden;
  background: #FFF;
  border-radius: 52px;
  @media(min-width: 1500px) {
    width: 350px;
    height: 530px; 
  }
`
