import CloseIcon from '@mui/icons-material/Close'
import { styled as muiStyled, TextareaAutosize } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import moment from 'moment'
import { useState } from 'react'
import Select from 'react-select'
import {
  DISPLAY_NAMES_OF_WEEKDAYS,
  NEW_MENTOR_SCHEDULE,
  RENDERING_ORDER_OF_DAYS,
  timezones_list,
} from 'src/consumer/constants'
import { GenericDisplayFlex, GenericDisplayFlexColumn } from 'src/mentor/layout/Styles'
import styled from 'styled-components'
import Modal, { BaseModalBackground, ModalProvider } from 'styled-react-modal'
import { v4 as uuidv4 } from 'uuid'
import { StyledSelectTZCO } from '../MentorCalendarPage/MentorCalendarPageStyles'
import { addSlotTimeToData } from './Components'

export const AvailabilityPageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  font-family: Outfit;
`

export const PageHeadlineContainer = styled.div`
  margin-bottom: 32px;
  padding: 20px 0;
  box-shadow: 0 20px 20px -20px rgb(0 0 0/15%);
`

export const PageHeadline = styled.div`
  max-width: 1020px;
  margin: 0 auto;
  font-size: 22px;
  font-weight: 400;
  color: #000;
  padding: 0 20px;
`

export const PageContentOneContainer = styled.div`
  width: 100%;
  max-width: 1020px;
  margin: 0 auto;
  padding: 0 20px;
  padding-bottom: 16px;
`

export const PageDescriptionText = styled.p`
  font-size: 16px;
  color: #1a1a1a;
  margin-bottom: 24px;
`

export const ScheduleSelectHeading = styled.h2`
  margin: 0 0 8px;
  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
`

export const ScheduleEventsClearButton = styled(ScheduleSelectHeading)`
  margin: 8px 0;
  font-weight: 400;
  font-size: 12px;
  color: red;
  cursor: pointer;
`

export const ScheduleSelectHorizonalContainer = styled.div`
  display: flex;
  gap: 12px;
  overflow: auto;
  /* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`

export const ScheduleButton = styled.button`
  border-radius: 6px;
  border: 1px solid #999999;
  flex-shrink: 0;
  padding: 8px 16px;
  box-shadow: ${(props) => props?.selected && '0 1px 5px rgb(0 74 116/15%)'};
  background: ${(props) => props?.selected && '#FFFFFF'};
  font-size: 13px;
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: ${(props) => (props?.selected ? '700' : '400')};
  @media (min-width: 768px) {
    font-size: 16px;
  }
`

export const PageMainContentContainer = styled.div`
  width: calc(100% - 40px);
  max-width: 980px;
  border: 1px solid #cccccc;
  border-radius: 8px;
  margin: 0 auto 40px;
  box-shadow: 0 1px 5px rgb(0 74 116/15%);
  background: #fff;
`

export const PageMainContentPartOne = styled.div`
  display: grid;
  grid-template-columns: 4fr 1fr;
  padding: 20px 20px 0;
  @media (min-width: 768px) {
    padding: 24px 24px 0;
  }
`

export const ScheduleName = styled.div`
  color: ${(props) => (props?.main ? '#000' : '#767676')};
  font-weight: ${(props) => (props?.main ? '700' : '500')};
  font-size: ${(props) => (props?.main ? '20px' : '14px')};
  display: flex;
  align-items: center;
`

export const CopyEventLinkButton = styled.button`
  color: ${(props) => (props?.invert ? '#FFF' : '#864ef5')};
  background: ${(props) => (props?.invert ? '#864ef5' : '#FFF')};
  font-weight: 500;
  border: 1px solid #864ef5;
  border-radius: 15px;
  font-size: 14px;
  padding: 2px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:active {
    color: ${(props) => (props?.invert ? '#864ef5' : '#FFF')};
    background: ${(props) => (props?.invert ? '#FFF' : '#864ef5')};
  }
`

export const PageMainContentPartTwo = styled.div`
  border-bottom: 1px solid #cccccc;
  padding: 20px;
  @media (min-width: 768px) {
    padding: 24px;
  }
`

export const EventAndTimeZoneContainer = styled.div`
  display: flex;
  gap: 18px;
  flex-direction: column;
  @media (min-width: 768px) {
    flex-direction: row;
  }
`

export const PageMainContentPartThree = styled.div`
  display: flex;
  padding: 16px 20px 0;
  overflow: auto;
  /* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  @media (min-width: 768px) {
    padding: 20px 24px 0;
  }
`

export const UnderlineActiveButtons = styled.button`
  margin-right: 16px;
  font-size: 16px;
  font-weight: 500;
  line-weight: 24px;
  padding: 7px 0 14px;
  color: ${(props) => (props?.active ? '#000' : '#767676')};
  border-bottom: ${(props) => (props?.active ? '3px solid blue' : '3px solid transparent')};
`

export const PageMainContentPartFour = styled.div``

export const DateOverrideCalAndTimeParent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  border-top: 1px solid #cccccc;
  padding: 25px 0;
  @media (min-width: 1080px) {
    flex-direction: row;
    justify-content: center;
    align-items: unset;
    padding: 50px 0;
  }
`

export const OverrideCalendarContainer = styled.div`
  width: 100%;
  max-width: 350px;
  flex-shrink: 0;
`

export const WeekItemContainer = styled.div`
  font-size: 14px;
  font-weight: 700;
  color: #000;
  padding: 20px;
  border-top: 1px solid #cccccc;
  @media (min-width: 768px) {
    padding: 24px;
  }
`

export const GenericMentorPortalModal = Modal.styled`
  opacity: ${(props) => props.opacity};
  transition : all 0.3s ease-in-out;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 15px rgba(0,0,0,.1);
  padding: 24px;
  max-height: 80vh;
  overflow:auto;
  ${(props) => props?.isAtBottom && 'margin-top: auto;'};
      :: -webkit-scrollbar {
    width: 5px;
    }
    :: -webkit-scrollbar-track {
    background: #F1F1F1;
    border-radius: 5px;
    }
    :: -webkit-scrollbar-thumb {
    background: #415dfc;
    border-radius: 5px;
    }
    scrollbar-width: thin;
    scrollbar-color: #415dfc #ddd;
`

export const WhiteFilterBackground = styled(BaseModalBackground)`
  opacity: ${(props) => props.opacity};
  background-color: #afafaf99;
  transition: all 0.3s ease-in-out;
  z-index: 1000;
`

export const GenericModalHeadline = styled.div`
  font-size: 24px;
  line-height: 1.4;
  font-weight: 700;
  color: #000000;
`

export const GenericModalHeadlineLevelTwo = styled.div`
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  color: #000000;
  margin-bottom: 8px;
`

export const StyledInputBoxModal = styled.input`
  color: #000;
  font-size: 16px;
  border: 1px solid #b2b2b2;
  border-radius: 8px;
  padding: 10px 14px;
  width: 300px;
  @media (min-width: 768px) {
    width: 400px;
  }
`

export const StyledTextAreaBoxModal = styled(TextareaAutosize)`
  color: #000;
  font-size: 16px;
  border: 1px solid #b2b2b2;
  border-radius: 8px;
  padding: 10px 14px;
  width: 300px;
  outline: none;
  resize: none;
  @media (min-width: 768px) {
    width: 400px;
  }
`

export const StyledInputSelect = styled.select`
  color: #000;
  font-size: 16px;
  border: 1px solid #b2b2b2;
  border-radius: 8px;
`

export const ModalActionButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  ${(props) => !props?.success && 'border: 1px solid #1a1a1a;'};
  border-radius: 40px;
  color: ${(props) => (props?.success ? '#FFF' : '#1a1a1a')};
  background: ${(props) => (props?.success ? '#415dfc' : '#FFF')};
  font-weight: ${(props) => (props?.success ? '700' : '400')};
  font-size: 14px;
  line-height: 20px;
  width: 100%;
`

export const ModalActionButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  @media (min-width: 768px) {
    flex-direction: row;
  }
`

export const ModalDaysContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  width: 100%;
  border: 1px solid #c1c1c1;
  border-radius: 8px;
  padding: 15px 0;
  color: #000;
`

export const StyledEventTextArea = muiStyled('textarea')(
  ({ theme }) => `
  width: 100%;
  border: 1px solid #0000003d;
  padding: 7px;
  border-radius: 7px;
  outline: none;
  `,
)
export const StyledEventInputContainer = muiStyled('div')(
  ({ theme }) => `
    width: 100%;
    border: 1px solid #0000003d;
    padding: 7px;
    border-radius: 7px;
    display:flex;
    `,
)

export const StyledEventReactSelect = muiStyled(Select)`
  text-align: start;
  > .react-select__control{
    background:transparent;
    border: 1px solid #0000003d;
    padding: 0px;
      border-radius: 7px;
  };
  > .react-select__control .react-select__value-container .react-select__multi-value{
    background-color: rgb(200 200 200);
    margin: 0px 10px 0px 0px;
    border-radius: 3px;
  };
`
export const StyledEventReactSelectViewCountry = muiStyled(Select)`
  text-align: start;
  width: 170px;

  > .react-select__control{
    background:#ebebeb;
    border:none;
    box-shadow:none;
    padding: 0px;
      border-radius: 7px;
  };
  > .react-select__control .react-select__value-container .react-select__multi-value{
    background-color: rgb(200 200 200);
    margin: 0px 10px 0px 0px;
    border-radius: 3px;
  };
  > .react-select__control .react-select__value-container .react-select__placeholder{
    color: black;
    text-align: center;
  };
  > .react-select__control .react-select__indicators{
      display: none;
  };
`

// small components, helpers and functions

export const getSlotTimeOptions = () => {
  const options = []
  const startMoment = moment()?.startOf('day')
  const endMoment = moment()?.endOf('day')
  while (startMoment?.isBefore(endMoment)) {
    const timeValue = startMoment?.format('HH:mm:ss')
    const displayText = startMoment?.format('h:mmA')

    options?.push(
      <option key={timeValue} value={timeValue}>
        {displayText}
      </option>,
    )

    startMoment?.add(15, 'minutes')
  }
  return options
}

export const getSlotTimeOptionsBetweenTimes = (data) => {
  console.log(data)
  const options = []
  const startMoment = moment().startOf('day')
  const endMoment = moment().endOf('day')

  while (startMoment.isBefore(endMoment)) {
    const timeValue = startMoment.format('HH:mm:ss')
    const displayText = startMoment.format('h:mmA')

    // Check if the current time is between any of the provided slots
    const isBetweenSlots = data.some((slot) => {
      const slotStartTime = moment(slot.startTime, 'HH:mm:ss')
      const slotEndTime = moment(slot.endTime, 'HH:mm:ss')
      return startMoment.isBetween(slotStartTime, slotEndTime, null, '[]')
    })

    if (isBetweenSlots) {
      options.push(
        <option key={timeValue} value={timeValue}>
          {displayText}
        </option>,
      )
    }

    startMoment.add(15, 'minutes')
  }

  return options
}

export const getTimeZoneOptionsWithValueAsTimezoneOffset = () => {
  return timezones_list?.map((item, index) => {
    return (
      <option key={index} value={encodeURIComponent(item?.gmtvalue)}>
        {item?.name}
      </option>
    )
  })
}

export const getTimeZoneOptionsWithValueAsTimezoneId = () => {
  return timezones_list?.map((item, index) => {
    return (
      <option key={index} value={item?.timezone}>
        {item?.name}
      </option>
    )
  })
}

export const PageHeadlineComponent = ({ heading, children }) => {
  return (
    <PageHeadlineContainer>
      <PageHeadline>{heading}</PageHeadline>
      {children}
    </PageHeadlineContainer>
  )
}

export const GenericMentorPortalModalComponent = (props) => {
  const [opacity, setOpacity] = useState(0)
  const { newScheduleModalOpen, setNewScheduleModalOpen, isAtBottom, setEventFormModalData } = props

  function toggleModal(e) {
    setOpacity(0)
    setNewScheduleModalOpen(false)
  }

  function afterOpen() {
    setTimeout(() => {
      setOpacity(1)
    }, 100)
  }

  function beforeClose() {
    return new Promise((resolve) => {
      setOpacity(0)
      setTimeout(resolve, 300)
      if (setEventFormModalData) {
        setEventFormModalData(null)
      }
    })
  }

  return (
    <ModalProvider backgroundComponent={WhiteFilterBackground}>
      <GenericMentorPortalModal
        isOpen={newScheduleModalOpen}
        afterOpen={afterOpen}
        beforeClose={beforeClose}
        onEscapeKeydown={toggleModal}
        opacity={opacity}
        backgroundProps={{ opacity }}
        isAtBottom={isAtBottom}
      >
        {props?.children}
      </GenericMentorPortalModal>
    </ModalProvider>
  )
}

export const NewSchedulePageComponent = (props) => {
  const offset = encodeURIComponent(moment().format('Z'))

  const { handleScheduleCreation, setNewScheduleModalOpen, windowWidth } = props
  const [basicScheduleObject, setBasicScheduleObject] = useState({
    ...NEW_MENTOR_SCHEDULE,
    timeZone: offset,
  })

  const handleCheckboxChange = (event, item) => {
    const { checked } = event.target

    setBasicScheduleObject((prevObject) => {
      const updatedObject = { ...prevObject }
      updatedObject.weeklyHourMap[item].available = checked
      return updatedObject
    })
  }

  const handleValidation = (basicScheduleObject) => {
    if (
      !basicScheduleObject?.title ||
      basicScheduleObject?.startTime >= basicScheduleObject?.endTime
    ) {
      return false
    }
    return true
  }

  return (
    <GenericDisplayFlexColumn style={{ gap: '24px' }}>
      <GenericDisplayFlex sb>
        <GenericModalHeadline>New schedule</GenericModalHeadline>
        <CloseIcon
          sx={{ fontSize: 24, color: 'black', cursor: 'pointer' }}
          onClick={() => setNewScheduleModalOpen(false)}
        />
      </GenericDisplayFlex>

      <div>
        <GenericModalHeadlineLevelTwo>
          Schedule name (Only visible to you)
        </GenericModalHeadlineLevelTwo>
        <StyledInputBoxModal
          value={basicScheduleObject?.title}
          onChange={(e) =>
            setBasicScheduleObject((prev) => ({
              ...prev,
              title: e.target.value,
            }))
          }
        />
      </div>

      <div>
        <GenericModalHeadlineLevelTwo>Available hours</GenericModalHeadlineLevelTwo>
        <GenericDisplayFlex sb style={{ width: '100%', gap: '5px' }}>
          <StyledSelectTZCO
            value={basicScheduleObject?.startTime}
            onChange={(e) =>
              setBasicScheduleObject((prev) => ({
                ...prev,
                startTime: e.target.value,
              }))
            }
          >
            {getSlotTimeOptions()}
          </StyledSelectTZCO>
          <span>to</span>
          <StyledSelectTZCO
            value={basicScheduleObject?.endTime}
            onChange={(e) =>
              setBasicScheduleObject((prev) => ({
                ...prev,
                endTime: e.target.value,
              }))
            }
          >
            {getSlotTimeOptions()}
          </StyledSelectTZCO>
        </GenericDisplayFlex>
        {basicScheduleObject?.startTime >= basicScheduleObject?.endTime && (
          <GenericModalHeadlineLevelTwo style={{ color: 'red' }}>
            End time must be greater than Start time
          </GenericModalHeadlineLevelTwo>
        )}
      </div>

      <div>
        <GenericModalHeadlineLevelTwo>Time Zone</GenericModalHeadlineLevelTwo>
        <StyledSelectTZCO
          value={basicScheduleObject?.timeZone}
          onChange={(e) =>
            setBasicScheduleObject((prev) => ({
              ...prev,
              timeZone: e.target.value,
            }))
          }
        >
          {getTimeZoneOptionsWithValueAsTimezoneOffset()}
        </StyledSelectTZCO>
      </div>

      <div>
        <GenericModalHeadlineLevelTwo>Available days</GenericModalHeadlineLevelTwo>
        <ModalDaysContainer>
          {RENDERING_ORDER_OF_DAYS?.map((item, idx) => {
            return (
              <GenericDisplayFlexColumn aic key={idx}>
                <Checkbox
                  size="small"
                  sx={{
                    padding: 0,
                    '&.Mui-checked': {
                      color: 'blue',
                    },
                  }}
                  checked={basicScheduleObject?.weeklyHourMap?.[item]?.available}
                  onChange={(event) => handleCheckboxChange(event, item)}
                />
                <span>
                  {windowWidth > 768
                    ? DISPLAY_NAMES_OF_WEEKDAYS?.[item]?.longName
                    : DISPLAY_NAMES_OF_WEEKDAYS?.[item]?.shortName}
                </span>
              </GenericDisplayFlexColumn>
            )
          })}
        </ModalDaysContainer>
      </div>

      <ModalActionButtonsContainer>
        <ModalActionButton onClick={() => setNewScheduleModalOpen(false)}>Cancel</ModalActionButton>
        <ModalActionButton
          success={handleValidation(basicScheduleObject)}
          onClick={() => {
            if (handleValidation(basicScheduleObject)) {
              handleScheduleCreation(addSlotTimeToData(basicScheduleObject))
            }
          }}
        >
          Create
        </ModalActionButton>
      </ModalActionButtonsContainer>
    </GenericDisplayFlexColumn>
  )
}

export const getUniqueIdFromTitle = (title) => {
  let title_id = title?.toLowerCase()?.replaceAll(' ', '_')
  const uniqueId = uuidv4()

  return title_id + '_' + uniqueId
}
