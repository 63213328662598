import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  sidebarShow: true,
  asideShow: false,
  sidebarUnfoldable: true,
  theme: 'default',
}

const layoutSlice = createSlice({
  name: 'businessLayoutStore',
  initialState: initialState,
  reducers: {
    setSidebarShow(state, action) {
      return { ...state, sidebarShow: action.payload }
    },
    setAsideShow(state, action) {
      return { ...state, asideShow: action.payload }
    },
    setSidebarUnfoldable(state, action) {
      return { ...state, sidebarUnfoldable: action.payload }
    },
    setTheme(state, action) {
      return { ...state, theme: action.payload }
    },
  },
})

export const { setSidebarShow, setAsideShow, setSidebarUnfoldable, setTheme } = layoutSlice.actions
export const businessLayoutReducer = layoutSlice.reducer

function getLayoutStore(state) {
  return state[layoutSlice.name]
}

export const selectSidebarShow = (state) => {
  return getLayoutStore(state).sidebarShow
}
export const selectAsideShow = (state) => {
  return getLayoutStore(state).asideShow
}
export const selectSidebarUnfoldable = (state) => {
  return getLayoutStore(state).sidebarUnfoldable
}
export const selectTheme = (state) => {
  return getLayoutStore(state).theme
}
