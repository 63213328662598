import { createContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getCPDataWithoutParams } from 'src/clinic/services'
import { getData } from 'src/consumer/services/profileService'
import { getLegalPolicies } from 'src/mentor/components/LegalTerms/legal_utils'
import { getAllRolesInBoolean } from 'src/mentor/layout/utils'

export const ClinicDataContext = createContext(null)

export function ClinicDataProvider({
  chosenRoleFlow,
  ownerData,
  assistantData,
  refreshOwnerAssociatedClinicsData,
  refreshOwnerAssociatedClinicBrandsData,
  refreshOwnerAssociatedClinicChambersData,
  children,
}) {
  // const navigate = useNavigate()
  // const [profileData, setProfileData] = useState()
  // const [assistantProfileAssociations, setAssistantProfileAssociations] = useState([])
  // const [assistantProfileData, setAssistantProfileData] = useState([])
  // const [clinicChambersData, setClinicChambersData] = useState([])
  // const [allLegalPolicies, setAllLegalPolicies] = useState([])
  // const [userProfile, setUserProfile] = useState()
  // const [profileContextLoading, setProfileContextLoading] = useState(false)
  // const [associatedClinicBrands, setAssociatedClinicBrands] = useState([])
  // const [associatedClinicBrandsLoading, setAssociatedClinicBrandsLoading] = useState(false)

  // async function getProfileData() {
  //   const resp = await getData('/api/secure/cp/clinic-profile')
  //   if (resp?.status === 200) {
  //     // const notOnboarded = localStorage.getItem('onboardingClinicUser')
  //     // console.log(resp.data?.[0])
  //     const responseArray = resp?.data || []
  //     if (responseArray?.length > 0) {
  //       responseArray.forEach((item, index) => {
  //         responseArray[index] = { ...item, onboarded: Boolean(true) }
  //       })
  //     }
  //     setProfileData(responseArray)
  //   }
  // }

  // async function getAssociatedClinicBrands() {
  //   setAssociatedClinicBrandsLoading(true)
  //   const resp = await getData('/api/secure/cp/clinic-brand')
  //   if (resp?.status === 200) {
  //     const responseArray = resp?.data || []
  //     if (responseArray?.length > 0) {
  //       // responseArray.forEach((item, index) => {
  //       //   responseArray[index] = { ...item, onboarded: Boolean(true) }
  //       // })
  //     }
  //     setAssociatedClinicBrands(responseArray)
  //   }
  //   setAssociatedClinicBrandsLoading(false)
  // }

  // async function getAssistantAssociatedClinicProfiles() {
  //   const isClinicAssistant = getAllRolesInBoolean().isClinicAssistant
  //   const isDoctor = getAllRolesInBoolean().isDoctor

  //   if (isClinicAssistant) {
  //     const resp = await getData('/api/secure/clinic-assistant/associated/clinic-profile/list')
  //     if (resp?.status === 200) {
  //       setAssistantProfileAssociations(resp?.data)
  //       if (Array.isArray(resp?.data) && resp?.data?.length > 0) {
  //         await getAndSetClinicChambersData(resp?.data)
  //         await getAndSetAssistantProfileData(resp?.data)
  //       }
  //     }
  //   } else if (isDoctor) {
  //     const resp = await getData('/api/secure/teacher/clinic-profile')
  //     if (resp?.status === 200) {
  //       setAssistantProfileAssociations(resp?.data)
  //     }
  //   }
  // }

  // async function getAndSetClinicChambersData(clinicArray) {
  //   clinicArray.forEach(async (clinicItem) => {
  //     const resp = await getCPDataWithoutParams(
  //       `/api/secure/clinic-assistant/associated/clinic-chamber/list?clinicId=${clinicItem?.id}`,
  //     )
  //     if (resp?.status === 200) {
  //       setClinicChambersData((prev) => [...prev, ...resp?.data])
  //     }
  //   })
  // }

  // async function getAndSetAssistantProfileData(clinicArray) {
  //   clinicArray.forEach(async (clinicItem) => {
  //     const response = await getCPDataWithoutParams(
  //       `/api/secure/clinic-assistant/profile?clinicId=${clinicItem?.id}`,
  //     )
  //     if (response.status === 200) {
  //       setAssistantProfileData((prev) => [...prev, response?.data?.data?.assistant])
  //     }
  //   })
  // }

  // async function getAndSetLegalPolicies() {
  //   const allLegalPolicies = await getLegalPolicies()
  //   if (allLegalPolicies) {
  //     setAllLegalPolicies(allLegalPolicies)
  //   }
  // }

  // const fetchProfileData = async () => {
  //   try {
  //     const resp = await getData('/api/secure/user/profile')
  //     if (resp?.status === 200) {
  //       setUserProfile(resp.data)
  //       localStorage.setItem('authorities', JSON.stringify(resp?.data?.authorities))
  //     }
  //   } catch (error) {
  //     // Handle any errors from the data fetching here
  //   }
  // }

  // async function Setup() {
  //   await fetchProfileData()
  //   const availableRoles = getAllRolesInBoolean()
  //   setProfileContextLoading(true)
  //   const promises = []
  //   if (!availableRoles.isClinicAssistant && !availableRoles.isDoctor) {
  //     promises.push(getProfileData(), getAssociatedClinicBrands())
  //   } else {
  //     promises.push(getAssistantAssociatedClinicProfiles())
  //   }
  //   // promises.push(getAndSetLegalPolicies())
  //   await Promise.all(promises)
  //   setProfileContextLoading(false)
  // }

  // function checkOnboarded() {
  //   if (profileData) {
  //     if (!profileData?.[0]?.onboarded) navigate('/clinic-home/onboarding')
  //   }
  // }

  // useEffect(() => {
  //   Setup()
  // }, [])

  // useEffect(() => {
  //   checkOnboarded()
  // }, [profileData])

  return (
    <ClinicDataContext.Provider
      value={{
        chosenRoleFlow,
        ownerData,
        assistantData,
        refreshOwnerAssociatedClinicsData,
        refreshOwnerAssociatedClinicBrandsData,
        refreshOwnerAssociatedClinicChambersData,
        // userProfile: userProfile,
        // assistantProfileAssociations,
        // associatedClinicBrands,
        // associatedClinicBrandsLoading,
        // fetchAssociatedClinicBrands: getAssociatedClinicBrands,
        // profileData: profileData,
        // profileSetup: Setup,
        // refreshProfileData: getProfileData,
        // profileContextLoading,
        // allLegalPolicies,
        // refreshLegalPolicies: getAndSetLegalPolicies,
        // clinicChambersData,
        // assistantProfileData,
      }}
    >
      {children}
    </ClinicDataContext.Provider>
  )
}
