export const profileOptionsList = [
  {
    displayName: 'Your Profile',
    id: 'your_profile',
    icon: 'your_profile_icon',
  },
  {
    displayName: 'Your Credit Vouchers and Purchases',
    id: 'your_credits_and_purchases',
    icon: 'your_credits_and_purchases',
  },
  // {
  //     displayName: 'Workplace Wellness',
  //     id: 'workplace_wellness',
  //     icon: 'workplace_icon'
  // },

  // take icons from flaticon

  {
    displayName: 'Plan Preferences',
    id: 'plan_preferences',
    icon: 'plan_icon',
  },
  // {
  //     displayName: 'Settings',
  //     id: 'settings',
  //     icon: 'settings_icon'
  // },
  {
    displayName: 'Goals',
    id: 'goals',
    icon: 'goals_icon',
  },
  {
    displayName: 'Daily Checkin Preferences',
    id: 'daily_checkin_preferences',
    icon: 'daily_checkin_icon',
  },
  {
    displayName: 'Manage your Data',
    id: 'manage_data_and_account',
    icon: 'manage_data_and_account',
  },
  {
    displayName: 'About',
    id: 'about',
    icon: 'about_icon',
  },
  {
    displayName: 'Logout',
    id: 'logout',
    icon: 'logout_icon',
  },
]

export const getAge = (dob) => {
  let today = new Date()
  let birthDate = new Date(dob)
  let age = today.getFullYear() - birthDate.getFullYear()
  let m = today.getMonth() - birthDate.getMonth()
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--
  }
  return age
}

export const getDOB = (age) => {
  return new Date(new Date().setFullYear(new Date().getFullYear() - age))
    .toISOString()
    .split('T')[0]
}

export const getDOBWithTime = (age) => {
  const dateString = new Date(new Date().setFullYear(new Date().getFullYear() - age))
    .toISOString()
    .split('T')[0]
  return `${dateString} 00:00:00`
}

export const getAgeFromDobWithTime = (dob) => {
  if (dob) {
    let today = new Date()
    let birthDate = new Date(dob?.substring(0, 10))
    let age = today.getFullYear() - birthDate.getFullYear()
    let m = today.getMonth() - birthDate.getMonth()
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--
    }
    return age
  } else {
    return ''
  }
}

export const getProfileDetails = (profileData) => {
  const name = profileData?.firstName || ''
  const age = profileData?.dob ? getAge(profileData?.dob) : ''
  const gender = profileData?.gender || ''
  const contentPreferences = profileData?.personalize?.studentPersonalize?.persona?.category || []
  const contentLanguages = profileData?.personalize?.studentPersonalize?.contentLanguages || []
  const focusAreas = profileData?.personalize?.studentPersonalize?.persona?.focusAreas || []
  const bodyTopics = profileData?.personalize?.studentPersonalize?.persona?.bodyTopics || []
  const mindTopics = profileData?.personalize?.studentPersonalize?.persona?.mindTopics || []
  const soulTopics = profileData?.personalize?.studentPersonalize?.persona?.soulTopics || []
  return {
    name,
    age,
    gender,
    contentPreferences,
    contentLanguages,
    focusAreas,
    bodyTopics,
    mindTopics,
    soulTopics,
  }
}

export const getPreferenceValueById = (setupData, id) => {
  const index = setupData?.metaCategories?.findIndex((preference) => preference.id === id)
  return index !== -1 ? setupData?.metaCategories[index]?.name : ''
}

export const getLanguageValueById = (setupData, id) => {
  const index = setupData?.contentLanguages?.findIndex((language) => language.id === id)
  return index !== -1 ? setupData?.contentLanguages[index].language : ''
}

export const getProfilePayload = (profile, profileState) => {
  return {
    ...profile,
    firstName: profileState.name.value,
    gender: profileState.gender.value,
    dob: getDOB(profileState.age.value),
    personalize: {
      ...profile?.personalize,
      studentPersonalize: {
        ...profile?.personalize?.studentPersonalize,
        contentLanguages: profileState?.contentLanguages?.value,
        persona: {
          ...profile?.personalize?.studentPersonalize?.persona,
          category: profileState?.contentPreferences?.value,
        },
      },
    },
  }
}

export const getProfilePayloadForGoals = (
  profile,
  focusAreas,
  bodyTopics,
  mindTopics,
  soulTopics,
  isFocusAreaEnabled,
) => {
  return isFocusAreaEnabled
    ? {
        ...profile,
        personalize: {
          ...profile?.personalize,
          studentPersonalize: {
            ...profile?.personalize?.studentPersonalize,
            persona: {
              ...profile?.personalize?.studentPersonalize?.persona,
              focusAreas: focusAreas,
            },
          },
        },
      }
    : {
        ...profile,
        personalize: {
          ...profile?.personalize,
          studentPersonalize: {
            ...profile?.personalize?.studentPersonalize,
            persona: {
              ...profile?.personalize?.studentPersonalize?.persona,
              bodyTopics: bodyTopics,
              mindTopics: mindTopics,
              soulTopics: soulTopics,
            },
          },
        },
      }
}

export const genderOptions = ['Male', 'Female', 'nonBinary']

export const getLanguageDrawerItems = (setupData) => {
  const languageItems = setupData?.contentLanguages?.map((language) => {
    return {
      id: language.id,
      title: language.language,
      subtitle: language.localLanguageName || language.language,
      icon: language.coverIcon,
    }
  })
  return languageItems
}

export const getPreferencesDrawerItems = (setupData) => {
  const categoryItems = (setupData?.categoryPolicy?.category || [])?.map((category) => {
    const fullObj = setupData?.metaCategories?.find((item) => item?.id === category)
    if (fullObj) {
      return {
        id: fullObj?.id,
        title: fullObj?.name,
        subtitle: '',
        icon: fullObj?.coverIcon,
      }
    }
  })

  console.log(categoryItems)

  return categoryItems
}

export const getFocusAreaOptions = (setupData) => {
  const categoryItems = setupData?.focusAreaPolicy?.focusArea?.map((category) => {
    return {
      id: category.id,
      title: category.name,
      subtitle: '',
      icon: category.coverIcon,
      type: 'focusAreas',
    }
  })
  return categoryItems
}

export const getFocusOptions = (setupData) => {
  const mindAndBody = setupData?.metaFocuses?.filter(
    (option) => option?.categories?.[0] === 'mind' || option?.categories?.[0] === 'body',
  )
  const mindAndBodyItems = mindAndBody?.map((category) => {
    return {
      id: category?.genreId,
      title: category?.title,
      subtitle: '',
      icon: category?.coverIcon,
      type: category?.categories?.[0],
    }
  })
  const beingAndBeyond = setupData?.metaFocuses?.filter(
    (option) => option?.categories?.[0] === 'soul',
  )
  const beingAndBeyondItems = beingAndBeyond?.map((category) => {
    return {
      id: category?.genreId,
      title: category?.title,
      subtitle: '',
      icon: category?.coverIcon,
      type: category?.categories?.[0],
    }
  })
  return { mindAndBodyItems, beingAndBeyondItems }
}

export const getFlagEmoji = (countryCode) => {
  const codePoints = countryCode
    .toUpperCase()
    .split('')
    .map((char) => 127397 + char.charCodeAt())
  return String.fromCodePoint(...codePoints)
}

export const handleOptionClick = (selectedItems, item) => {
  if (selectedItems.includes(item?.id)) {
    const index = selectedItems.indexOf(item?.id)
    if (index > -1) {
      selectedItems.splice(index, 1)
    }
  } else {
    selectedItems.push(item?.id)
  }
  return selectedItems
}
