import { Box, LinearProgress } from '@mui/material'
import styled from 'styled-components'
import React from 'react'

export const ProfileLeftPannelContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #ffffff;
  font-family: Outfit;
  font-style: normal;
  padding: 21px;
  @media (min-width: 1280px) {
    padding: 27px;
  }
`

export const BackHomeText = styled.div`
  width: 100%;
  color: #fff;
  font-size: 16px;
  line-height: 40px;
  font-family: Outfit;
  display: flex;
  align-items: center;
  gap: 3px;
  cursor: pointer;
  z-index: 4;
  @media (orientation: portrait) {
    position: fixed;
    top: ${(props) => (props?.isMobile ? '40px' : '0px')};
    padding: 0 25px;
    background: #18191c;
    justify-content: space-between;
  }
`

export const LogoutForMobile = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  @media (orientation: landscape) {
    display: none;
  }
`

export const DisplayFlex = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
`

export const ProfilePicture = styled.div`
  flex: 0 0 82px;
  width: 82px;
  border-radius: 50%;
  background-image: url(${(props) => props.imgURL});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  filter: drop-shadow(-16px 7px 17px rgba(0, 0, 0, 0.39))
    drop-shadow(10px 16px 32px rgba(0, 0, 0, 0.47));
  position: relative;
  border: 1px solid #000;
  margin-top: 29px;
  z-index: 1;
  @media (min-width: 1280px) {
    flex: 0 0 127px;
    width: 127px;
    margin-top: 40px;
  }
`

export const ProfileIntitial = styled.div`
  flex: 0 0 82px;
  width: 82px;
  border-radius: 50%;
  filter: drop-shadow(-16px 7px 17px rgba(0, 0, 0, 0.39))
    drop-shadow(10px 16px 32px rgba(0, 0, 0, 0.47));
  background: linear-gradient(116.72deg, #cc2b5e 6.79%, #753a88 108.41%);
  font-size: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  border: 1px solid #000;
  margin-top: 29px;
  z-index: 1;
  @media (min-width: 1280px) {
    flex: 0 0 127px;
    width: 127px;
    margin-top: 40px;
    font-size: 80px;
  }
`

export const CameraIcon = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;
  height: 30px;
  width: 30px;
  cursor: pointer;
  border-radius: 50%;
  z-index: 2;
`

export const MembershipStatusBox = styled.div`
  width: 100%;
  padding: 16px;
  border-radius: 10px;
  background: linear-gradient(138deg, #0e0e0e 0%, #303030 100%);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  @media (min-width: 1280px) {
    margin: 17px 0;
    padding: 22px;
  }
`

export const MembershipText = styled.div`
  display: flex;
  flex-direction: column;
  color: #d6d6d6;
  font-size: 12px;
  font-family: heroregular;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`

export const ProfileMembership = styled.div`
  color: #f3ff6e;
  font-size: 22px;
  font-family: Outfit;
  font-style: normal;
  font-weight: 700;
  text-align: left;
  line-height: normal;
  @media (min-width: 1280px) {
    font-size: 29px;
  }
`

export const ProfileUpgradeButton = styled.button`
  border-radius: 46px;
  background: linear-gradient(180deg, #61f6ff 0%, #1e7fcb 100%);
  font-weight: 700;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1f1f1f;
  padding: 5px 16px;
  @media (min-width: 1280px) {
    padding: 8px 22px;
  }
`

export const IndeterminateLoading = () => {
  return (
    <Box sx={{ width: '50px', position: 'absolute', bottom: '0', right: '-50px' }}>
      <LinearProgress />
    </Box>
  )
}

export const ProfileName = styled.div`
  color: #fff;
  text-align: center;
  font-size: 23px;
  font-family: Outfit;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 14px 0;
  @media (min-width: 1280px) {
    font-size: 28px;
    margin: 15px 0;
  }
`

export const ProfileId = styled.div`
  color: #a8a8a8;
  text-align: center;
  font-size: 14px;
  font-family: heroregular;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  @media (min-width: 1280px) {
    color: #a8a8a8;
    text-align: center;
    font-size: 16px;
    font-family: heroregular;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`

export const ProfileOptionsList = styled.div`
  margin-top: 24px;
  width: 100%;
`

export const ProfileOption = styled.div`
  align-items: center;
  display: flex;
  min-height: 43px;
  padding: 5px;
  width: 100%;
  flex-shrink: 0;
  margin-bottom: 10px;
  padding-left: 20px;
  font-family: Outfit;
  font-style: normal;
  font-weight: ${(props) => (props.isSelected ? '700' : '300')};
  font-size: 17px;
  line-height: 26px;
  color: ${(props) => (props?.optionId === 'logout' ? '#FAA' : '#FFF')};
  background: ${(props) => (props.isSelected ? '#4D4D4D' : 'none')};
  border-radius: 34px;
  box-shadow: ${(props) => (props.isSelected ? '7px 6px 13px rgba(0, 0, 0, 0.69)' : 'none')};
  &:hover {
    cursor: pointer;
  }
`

export const ProfileOptionIcon = styled.img`
  height: 32px;
  margin-right: 12px;
`
