import styled from 'styled-components'
import Modal, { BaseModalBackground } from 'styled-react-modal'
import ruledLineSingle from 'src/assets/ruled_line_single.png'
import arrowButtonRight from 'src/assets/arrow_button_right.png'
import { TextareaAutosize } from '@mui/material'
import InfiniteScroll from 'react-infinite-scroll-component'

const JournalColors = {
  ModalBackgroundColor: '#FFFFFF',
  IndexBackgroundColor: '#F3E9FF',
  ReflectionBackgroundColor: '#FFF0BA',
  JournalBackgroundColor: '#C3E8FF',
  GuidedJournalBackgroundColor: '#fef4ec',
}

export const StyledJournalModal = Modal.styled`
  width: 95vw;
  height: 95vh;
  max-width: 1920px;
  max-height: 1080px;
  opacity: ${(props) => props.opacity};
  transition : all 0.3s ease-in-out;
  border-radius: 15px;
  position: relative;
  overflow: hidden;
`

export const FadingBackground = styled(BaseModalBackground)`
  opacity: ${(props) => props.opacity};
  transition: all 0.3s ease-in-out;
  z-index: 1000;
`

export const ModalCloseButton = styled.img`
  height: 21px;
  width: 21px;
  position: absolute;
  right: 45px;
  top: 45px;
  cursor: pointer;
  z-index: 10;
  @media (max-width: 720px) {
    height: 15px;
    width: 15px;
    right: 25px;
    top: 25px;
  }
`

export const StyledHeading = styled.div`
  font-family: Outfit;
  font-size: ${(props) => (props?.bold ? '30px' : '27px')};
  font-weight: ${(props) => (props?.bold ? '700' : '500')};
  color: #290055;
`

export const BlankModalHeading = styled(StyledHeading)``

export const BrowseModalHeading = styled(StyledHeading)`
  font-weight: 700;
  font-size: 45px;
`

export const ScreenBackground = styled.div`
  width: 100%;
  font-family: Outfit;
  height: 100%;
  background: ${(props) =>
    props?.newEntry
      ? JournalColors?.GuidedJournalBackgroundColor
      : JournalColors?.ModalBackgroundColor};
  padding: 25px;
  position: relative;
`

export const CentralImage = styled.img`
  width: 228.5px;
  @media (min-width: 720px) {
    width: 300px;
  }
  @media (min-width: 1500px) {
    width: 450px;
  }
`

export const CentralText = styled.div`
  font-family: Outfit;
  text-align: center;
  font-size: ${(props) => (props?.main ? '31px' : '15px')};
  font-weight: ${(props) => (props?.main ? '700' : '400')};
  color: ${(props) => (props?.main ? '#6641A3' : '#9271C9')};
  @media (min-width: 720px) {
    font-size: ${(props) => (props?.main ? '36px' : '18px')};
  }
  @media (min-width: 1500px) {
    font-size: ${(props) => (props?.main ? '41px' : '22px')};
  }
`

export const StartJournalingButton = styled.button`
  border-radius: 56px;
  background-color: #dfc5ff;
  font-family: Outfit;
  font-size: 22px;
  font-weight: 600;
  color: #6912d1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 13px;
  gap: 16px;
  padding: 10px 15px;
`

// styles specific to user journals page :

export const IndexContainer = styled.div`
  height: 90%;
  width: min(610px, 90%);
  background: ${JournalColors?.IndexBackgroundColor};
  border-radius: 15px 55px 55px 15px;
  padding: 35px 55px;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  @media (max-width: 1296px) {
    width: min(400px, 90%);
    padding: 35px 20px;
  }
`

export const JournalButton = styled.img`
  height: ${(props) => (props?.small ? '25px' : '48.88px')};
  width: ${(props) => (props?.small ? '25px' : '48.88px')};
  transform: ${(props) => props?.rotate && 'rotate(180deg)'};
  cursor: pointer;
  margin: ${(props) => (props?.small ? '0 6px' : '0 12px')};
`

export const IndexButton = styled.img`
  height: ${(props) => (props?.caret ? '13px' : '34.26px')};
  width: ${(props) => (props?.caret ? '20px' : '34.26px')};
  cursor: pointer;
  transform: ${(props) => props?.up && props?.caret && 'rotate(180deg)'};
`

export const EntriesContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  position: relative;

  /* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`

export const IndexJournalEntryContainer = styled.div`
  flex: 0 0 443px;
  height: ${(props) => (props?.showResponse ? '272px' : '182px')};
  border-radius: 14px;
  background: ${(props) => (props?.reflection ? '#FFF0BA' : '#C3E8FF')};
  ${(props) => props?.assessment && 'background: #9f68da;'};
  border: ${(props) => props?.active && '2px solid #A067E6'};
  ${(props) => props?.assessment && props?.active && 'border: 2px solid #00ff00;'};
  padding: 18px;
  position: relative;
  cursor: default;
  overflow: hidden;
  margin: auto 0;
  transition: height 0.5s ease-in-out;
  @media (max-width: 1296px) {
    flex: 0 0 300px;
  }
  @media (max-width: 500px) {
    flex: 0 0 275px;
  }
`

export const ShowAnswerIndexContainer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: calc(100% - 108px);
  overflow: auto;
  ::-webkit-scrollbar {
    display: none;
  }
`

export const StyledConnectingLine = styled.div`
  height: 100%;
  width: 1.5px;
  border-left: 1.5px dashed #d3aeff;
`

export const LineAndItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex: ${(props) => (props?.showResponse ? '0 0 303px' : '0 0 213px')};
  padding-left: 8px;
  transition: flex 0.5s ease-in-out;
`

export const JournalDayContainer = styled.div`
  font-family: Outfit;
  font-size: 23px;
  font-weight: 600;
  line-height: 25px;
  color: #210055;
  display: flex;
  align-items: center;
  gap: 10px;
`

export const JournalEntryTitle = styled.div`
  font-family: 'Outfit';
  font-style: normal;
  font-weight: ${(props) => (props?.main ? '700' : '600')};
  font-size: ${(props) => (props?.main ? '27px' : '20px')};
  line-height: ${(props) => (props?.main ? '27px' : '25px')};
  color: ${(props) => (props?.main ? '#290055' : '#3F3F3F')};
  ${(props) => props?.assessment && 'color: #FFF'};
  height: ${(props) => !props?.main && '75px'};
  width: ${(props) => (props?.main ? '432px' : '250px')};
  margin-bottom: ${(props) => (props?.main ? '30px' : '15px')};
  margin-top: ${(props) => props?.main && '30px'};
  @media (max-width: 1296px) {
    font-size: ${(props) => (props?.main ? '27px' : '15px')};
    width: ${(props) => (props?.main ? '432px' : '100px')};
  }
`

export const JournalEntryTime = styled.div`
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: #666666;
  ${(props) => props?.assessment && 'color: #c7c5ca;'};
  position: ${(props) => props?.positioned && 'absolute'};
  top: 18px;
  right: 18px;
  @media (max-width: 1296px) {
    font-size: 10px;
  }
`

export const JournalIndexEntryLine = styled.div`
  height: 1px;
  width: 241.92px;
  border-radius: 3px;
  background: #9c9c9c;
  margin-top: 15px;
  @media (max-width: 1296px) {
    width: 141.92px;
  }
`

export const CornerBlob = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;
`

export const AnswerOverviewContainerParent = styled.div`
  height: 90%;
  width: calc(100% - 610px);
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 59px;
  @media (max-width: 780px) {
    display: none;
  }
  @media (max-width: 1296px) {
    width: calc(100% - 400px);
  }
`

export const AnswerOverviewContainer = styled.div`
  width: 90%;
  height: 90%;
`

export const AnswerOverviewColoredContainer = styled.div`
  background: ${(props) => (props?.reflection ? '#FFF0BA' : '#C3E8FF')};
  border-radius: 31px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 45px 25px 25px 60px;
  overflow-y: auto;
  position: relative;

  ::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
  // Hide scrollbar for IE, Edge and Firefox
`

export const AssessmentResultOverviewColoredContainer = styled(AnswerOverviewColoredContainer)`
  background: #9f68da;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
`

export const ChangeButtonsContainer = styled.div`
  width: calc(100% - 118px);
  position: absolute;
  bottom: 0;
  margin: 0 auto;
  display: flex;
  justify-content: ${(props) => (props?.right ? 'right' : 'space-between')};
  font-family: Outfit;
  font-size: 15px;
  font-weight: 400;
  color: #7e7e7e;
`

export const UserJournalTextArea = styled(TextareaAutosize)`
  width: 100%;
  padding: 0 20px 0 20px;
  border: none;
  outline: none;
  resize: none;
  font-family: heroregular;
  font-size: 15px;
  cursor: default;
  font-weight: ${(props) => (props?.light ? '400' : '700')};
  background: url(${ruledLineSingle});
  background-repeat: repeat;
  background-attachment: local;
  line-height: 42px;
  letter-spacing: 1px;
`

export const ContainerTopRight = styled.div`
  position: absolute;
  top: ${(props) => (props?.onSmallBox ? '45px' : '59px')};
  right: ${(props) => (props?.onSmallBox ? '12px' : '59px')};
  display: flex;
  justify-content: right;
`

export const DarkOverlayBehindDrawer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
`

export const RecommendationDrawer = styled.div`
  height: ${(props) => (props?.recommendationDrawerOpen ? '50%' : '9%')};
  width: 100%;
  background-color: ${(props) => (props?.recommendationDrawerOpen ? '#F3E9FF' : '#7E5FB1')};
  border-radius: 15px 15px 0px 0px;
  position: absolute;
  bottom: 0;
  left: 0;
  transition: all 1s ease-in-out;
  display: flex;
  justify-content: center;
  cursor: pointer;
`

export const RecommendsClosedTitle = styled.div`
  font-family: Outfit;
  font-size: ${(props) => (props?.main ? '26px' : '16px')};
  font-weight: ${(props) => (props?.main ? '700' : '400')};
  color: ${(props) => (props?.main ? '#FFF' : '#D1D1D1')};
  margin: 0 10px;

  @media (max-width: 768px) {
    ${(props) => !props?.main && `display: none;`}
    font-size: ${(props) => props?.main && '20px'}
  }
`

export const GreenDotImage = styled.img`
  position: relative;
  bottom: 5px;
`

export const RecommendationClosedParentContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px 0 100px;
  @media (max-width: 768px) {
    padding: 0 20px;
  }
`

export const RecommendationOpenParentContainer = styled.div`
  width: 75%;
  max-width: 754.9px;
  padding: 20px 20px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  ::-webkit-scrollbar {
    display: none;
  }
`

export const RecommendationText = styled.div`
  width: 100%;
  margin-top: 40px;
  margin-bottom: 24px;
  font-family: Outfit;
  font-size: 21px;
  font-weight: 400;
  color: #5700b5;
`

export const RecommendedItemContainer = styled.div`
  background: #e6d1ff;
  border-radius: 9px;
  width: 100%;
  padding: 11px 17px;
  display: flex;
  align-items: center;
  gap: 15px;
  flex-wrap: wrap;
  font-family: Outfit;
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
  color: #45008f;
  cursor: pointer;
`

export const RecommendedItemImage = styled.div`
  height: 82px;
  width: 111.38px;
  border-radius: 10px;
  background: url(${(props) => props?.imgUrl});
  background-size: cover;
  filter: hue-rotate(330deg);
  background-size: cover;
`

// styles specific to browse journal page :

export const JournalDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  ${(props) => props?.hiddenBelow && `transform: translateY(60px)`};
  transition: transform 0.5s ease-out;
`

export const JournalItemBackgroundImage = styled.img`
  height: 100%;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
  object-position: center;
`

export const JournalItemBackgroundOverlay = styled.div`
  height: 100%;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
  object-position: center;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 100%);
`

export const JournalItemContainer = styled.div`
  flex: 0 0 235.65px;
  height: 408.5px;
  border-radius: 15px;
  background: #ead8ff;
  position: relative;
  cursor: pointer;
  overflow: hidden;

  &:hover {
    ${JournalDetailsContainer} {
      transform: translateY(-20px);
    }

    ${JournalItemBackgroundImage} {
      transform: scale(1.05);
      transition: transform 0.5s ease-in-out;
    }
  }
`

export const AssessmentDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  transform: translateY(50px);
  transition: transform 0.5s ease-out;
  gap: 15px;
`

export const AssessmentItemContainer = styled.button`
  width: 200px;
  height: 200px;
  position: relative;
  overflow: hidden;
  border-radius: 20px;
  @media (min-width: 768px) {
    width: 275px;
    height: 275px;
  }
  &:hover {
    ${JournalItemBackgroundImage} {
      transform: scale(1.05);
      transition: transform 0.5s ease-in-out;
    }
    ${AssessmentDetailsContainer} {
      transform: translateY(-20px);
    }
  }
`

export const BrowseInternalContainer = styled.div`
  overflow-y: scroll;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 26.77px;
  margin: 65px auto 0 auto;
  height: calc(100% - 120px);
  width: 1050px;
  padding-bottom: 100px;

  > .infinite-scroll-component__outerdiv {
    width: 100%;
  }

  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1);
    border-radius: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background: #5720a3;
    border-radius: 5px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #5720a3a1;
  }

  @media (max-width: 1280px) {
    width: 800px;
  }

  @media (max-width: 800px) {
    width: 520px;
  }

  @media (max-width: 600px) {
    width: 300px;
  }
`

export const StyledInfiniteScroll = styled(InfiniteScroll)`
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
  gap: 26.77px;
  width: 100%;
  ${(props) => props?.justifyCenter && 'justify-content: center;'};
`

export const JournalTitle = styled.div`
  font-family: Outfit;
  font-size: 26px;
  font-weight: 600;
  line-height: 33px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 20px;
  min-height: 66px;
  @media (max-width: 1296px) {
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
    min-height: 36px;
  }
`

export const AssessmentTitle = styled.div`
  color: #fff;
  width: 100%;
  text-transform: capitalize;
  font-size: 22px;
  font-weight: 300;
  line-height: 25px;
  padding: 0 10px;
  @media (min-width: 768px) {
    font-size: 28px;
    line-height: 31px;
    font-weight: 300;
  }
`

export const AssessmentCardButton = styled.button`
  color: #fff;
  display: flex;
  font-size: 18px;
  font-weight: 300;
  line-height: normal;
  border-radius: 10px;
  background: #ffaf22;
  padding: 10px 25px;
`

export const JournalCategory = styled.div`
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Outfit;
  font-size: 12px;
  font-weight: 400;
  background: rgba(0, 0, 0, 0.34);
  backdrop-filter: blur(8.5px);
  margin-bottom: 9.71px;
  color: #ffffff;
  padding: 5px 12px;
  @media (max-width: 520px) {
    font-size: 9px;
    padding: 3px 9px;
  }
`

export const StyledSeparatorJournal = styled.div`
  height: 3.2109375px;
  width: 63.6884765625px;
  border-radius: 4px;
  background: #ff5252;
  margin-bottom: 7.15px;
  @media (max-width: 520px) {
    margin-bottom: 4.15px;
  }
`

export const StyledLine = styled.div`
  height: 1.64px;
  width: 80%;
  border-radius: 3px;
  background: #ffffff;
  margin-top: 12px;
  @media (max-width: 520px) {
    margin-top: 12px;
  }
`

export const StyledUnderline = styled.div`
  height: 2.29px;
  width: 70%;
  border-radius: 7px;
  background: #8d8d8d;
`

export const BackText = styled.div`
  font-family: Outfit;
  font-size: 18px;
  font-weight: 600;
  color: #8d8d8d;
`

export const BackTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 40px;
  right: 100px;
  cursor: pointer;
  @media (max-width: 768px) {
    top: 100px;
  }
`

export const BlankJournalTextContainer = styled.div`
  font-family: Outfit;
  font-size: 26px;
  font-weight: 600;
  line-height: 33px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #b291da;
  margin-top: 20px;
`

export const ToggleButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  margin: 20px 0;
  gap: 20px;
  align-items: center;
  justify-content: center;
`

export const ToggleButton = styled.button`
  color: ${(props) => (props?.selected ? '#FFF' : '#6F6F6F')};
  font-size: 14px;
  font-weight: 400;
  padding: 9px 30px;
  flex-shrink: 0;
  border-radius: 24px;
  background: ${(props) => (props?.selected ? '#7927DB' : '#D9D9D9')};
  @media (min-width: 768px) {
    font-size: 18px;
    padding: 13px 60px;
  }
`

// styles specific to browse new journal entry page :

export const NewEntryNav = styled.div`
  width: 100%;
  height: 55px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const PrePostMsgContainer = styled.div`
  width: 100%;
  height: calc(100% - 143px);
  position: relative;
  font-family: Outfit;
  font-size: 30px;
  font-weight: 500;
  line-height: 40px;
  text-align: center;
  color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const QuestionAnsContainer = styled.div`
  width: calc(100% - 90px);
  height: calc(100% - 120px);
  position: absolute;
  bottom: 0;
`

export const AllTextContainer = styled.div`
  width: 100%;
  position: absolute;
  bottom: 75%;
`

export const QuestionContainer = styled.div`
  width: 100%;
  font-family: Outfit;
  font-size: 30px;
  font-weight: 500;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: default;
  color: #000000;
  margin-bottom: 10px;
  @media (max-width: 720px) {
    line-height: 30px;
    font-size: 20px;
  }
`

export const SubtextContainer = styled.div`
  width: 100%;
  font-family: Outfit;
  font-size: 20px;
  font-weight: 300;
  line-height: 30px;
  text-align: center;
  color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: default;
  margin-bottom: 10px;
  @media (max-width: 720px) {
    line-height: 20px;
    font-size: 14px;
  }
`

export const HintsContainer = styled.div`
  width: 100%;
  font-family: Outfit;
  font-size: 20px;
  font-weight: 300;
  line-height: 30px;
  text-align: center;
  color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: default;
  gap: 10px;
  @media (max-width: 720px) {
    line-height: 20px;
    font-size: 15px;
  }
`

export const HintTextContainer = styled.div`
  padding: 2.8px 25px;
  background: #434e643d;
  border-radius: 26px;
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;
  font-size: 14px;
`

export const AnswerContainer = styled.div`
  width: 100%;
  height: 70%;
  text-align: center;
  position: absolute;
  bottom: 0;
  color: #000000;
  background-color: #ffffff;
  border-radius: 20px 20px 0 0;
  border: 1px solid #000000;
`

export const AnswerTextArea = styled.textarea`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 100px);
  padding: 0 20px 0 20px;
  border: none;
  outline: none;
  resize: none;
  font-family: heroregular;
  font-size: 15px;
  cursor: default;
  font-weight: 600;
  background: url(${ruledLineSingle});
  background-repeat: repeat;
  background-attachment: local;
  line-height: 42px;
  letter-spacing: 1px;

  :: -webkit-scrollbar {
    width: 5px;
  }
  :: -webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 5px;
  }
  :: -webkit-scrollbar-thumb {
    background: #a8a8a8;
    border-radius: 5px;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
  // Hide scrollbar for IE, Edge and Firefox
`

export const NewEntryNavButton = styled.img`
  height: 21px;
  width: 21px;
  cursor: pointer;
  @media (max-width: 720px) {
    height: 15px;
    width: 15px;
  }
`

export const NewEntryBackContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  visibility: ${(props) => props?.hide && 'hidden'};
  cursor: pointer;
`

export const SubmitButton = styled.button`
  min-height: 60.2px;
  min-width: 260.53px;
  padding: 0px 10px;
  border-radius: 35px;
  background: #a55cf3;
  font-family: Outfit;
  font-size: 27px;
  font-weight: 400;
  line-height: 34px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px auto 0px auto;
  cursor: pointer;
`

export const SubmitArrow = styled.button`
  height: 67.2px;
  width: 67.2px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #a55cf3;
  background-image: url(${arrowButtonRight});
  background-repeat: no-repeat;
  background-position: 70% 50%;
  background-size: 75%;
  position: absolute;
  right: 20px;
  top: 10px;

  &:disabled {
    background-color: #434e643d;
  }
`

export const ProgressBarContainer = styled.div`
  width: 50%;
  height: 26.64px;
  border-radius: 9px;
`

export const ProgressBarFilled = styled.div`
  background-color: #434e64;
  text-align: right;
  padding-right: 10px;
  font-size: 20px;
`

export const StyledGenericModal = Modal.styled`
opacity: ${(props) => props.opacity};
transition: all 0.3s ease -in -out;
position: relative;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
background-color: #F8D7DA;
color: #721C24;
padding: 20px;
border-radius: 5px;

  h2 {
  margin-bottom: 10px;
}

  button {
  margin: 5px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
}

#yes-btn {
  background-color: #28A745;
  color: #FFFFFF;
}
#no-btn {
  background-color: #DC3545;
  color: #FFFFFF;
}
`

// for assessment result overview

export const MyAssessmentHeader = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #fff;
`

export const MyAssessmentNameInHeader = styled.div`
  color: #fff;
  font-family: Outfit;
  font-size: 27px;
  font-style: normal;
  font-weight: 700;
  line-height: 35px;
`

export const MyAssessmentUserInputQA = styled.div`
  color: #fff;
  font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 19px;
  ${(props) =>
    props?.answer &&
    `color: #E5E5E5;
font-family: Outfit;
font-size: 16px;
font-style: normal;
font-weight: 300;
line-height: 19px;`}
`

export const YourInputsText = styled.div`
  color: #fff;
  width: 100%;
  text-align: left;
  font-family: Outfit;
  font-size: 21px;
  font-style: normal;
  font-weight: 700;
  line-height: 19px; /* 90.476% */
`

export const MyAssessmentChartAndDetailsContainer = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;
  align-items: center;
  @media (min-width: 1080px) {
    flex-direction: row;
  }
`

export const MyAssessmentSeeMoreText = styled.span`
  color: #0029ff;
  max-width: 400px;
  text-align: center;
  cursor: pointer;
  font-size: 14px;
  line-height: 17px;
  margin: 10px 0;
  font-weight: 300;
  text-decoration: underline;
`
