import styled from "styled-components";
import Modal, { BaseModalBackground } from "styled-react-modal";
import ruledLineSingle from "src/assets/ruled_line_single.png"

const QuoteColors = {
  QuoteModalBackgroundColor: '#F3E9FF',
  QuoteBackgroundColor: '#FFFFFF',
  QuoteTextColor: '#7351AB',
  QuestionModalBackgroundColor: '#FFF0BA'
}

export const StyledQuoteModal = Modal.styled`
  width: 90vw;
  height: 90vh;
  background-color: ${QuoteColors?.QuoteModalBackgroundColor};
  opacity: ${(props) => props.opacity};
  transition : all 0.3s ease-in-out;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`

export const StyledQuestionModal = Modal.styled`
  width: 782px;
  height: 80vh;
  opacity: ${(props) => props.opacity};
  transition : all 0.3s ease-in-out;
  position: relative;
  display: flex;
  justify-content: center;
  @media (max-width: 1080px) {
    width: 650px
  }
`

export const FadingBackground = styled(BaseModalBackground)`
  opacity: ${(props) => props.opacity};
  transition: all 0.3s ease-in-out;
  z-index: 1000;
`

export const QuoteBackground = styled.div`
  height: 100%;
  width: 610.95px;
  border-radius: 25px;
  position: relative;
  font-family: 'Outfit';
  color: ${QuoteColors?.QuoteTextColor};
  font-size: 35px;
  line-height:40px;
  font-weight: 700;
  background-color: ${QuoteColors?.QuoteBackgroundColor};
  padding: 43px 62px 0px 62px;
  @media (max-width: 720px){
    width: 350px;
    padding: 20px 32px 0px 32px;
    font-size: 15px;
    line-height:20px;
  }
`

export const ModalCloseButton = styled.img`
  height: 21px;
  width: 21px;
  position: absolute;
  right: 45px;
  top: 45px;
  cursor: pointer;
  @media (max-width: 720px){
    height: 15px;
    width: 15px;
    right: 25px;
    top: 25px;
  }
`

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
`

export const QuoteTextArea = styled.div`
  min-height: 50%;
  padding: 17px 0px 0px 0px; 
  display: flex;
  align-items: center;
  @media (max-width: 720px){
    min-height: 40%;
  }
`

export const AuthorNameText = styled.div`
  font-family: Outfit;
  font-size: 22px;
  font-weight: 500;
  line-height: 16px;
  color: #FF8080;
  @media (max-width: 720px){
    font-size: 16px;
  }
`

export const FavoriteIcon = styled.img`
  height: 64.19px;
  width: 62.51px;
  cursor: pointer;
`

export const VisibleDrawerBackground = styled.div`
  background-color: #FFF0BA;
  width: 90%;
  max-width: 845.57px;
  height: 185px;
  box-shadow: -4px -1px 26px rgba(0, 0, 0, 0.29);
  border-radius: 20px 20px 0px 0px;
  position: absolute;
  bottom: 0px;
  display: flex;
  justify-content: center;
  padding-top: 30px;
  overflow: hidden;
  cursor: pointer;
`

export const DrawerItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 66%;
`

export const DrawerDate = styled.div`
  font-family: Outfit;
  font-size: 16px;
  font-weight: 300;
  color: #FFAF22;
`

export const DrawerQuestion = styled.div`
  width: 100%;
  font-family: Outfit;
  font-size: 23px;
  line-height: 25px;
  font-weight: 500;
  color: #45008F;
  @media (max-width: 720px){
    font-size: 16px;
  }
`

export const DrawerRuledLines = styled.img`
  width: 100%;
  margin-top: 15px;
`

export const ModalInsideContainer = styled.div`
  height: calc(100% - 86.45px);
  width: 100%;
  border-radius: 31px;
  background-color: ${QuoteColors?.QuestionModalBackgroundColor};
  position: absolute;
  top: 0px;
  padding: 65px 70px 10px 70px;
  @media (max-width: 1080px) {
    padding: 15px 20px 5px 20px;
  }
`

export const ReflectionSubmitButton = styled.button`
  height: 61.740234375px;
  width: 331.654296875px;
  border-radius: 37px;
  font-family: Outfit;
  font-size: 25px;
  font-weight: 600;
  background: linear-gradient(93.16deg, #B795E7 9.87%, #6B4D9B 95.29%);
  color: #FFFFFF;
  position: absolute;
  bottom: 0px;
  
  &:disabled {
    background: #DEDEDE;
    color: #7C7C7C;
  }
`

export const ReflectionTimeAndDate = styled.div`
  font-family: Outfit;
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  color: #666666;
`

export const ReflectiveQuestionHeading = styled.div`
  font-family: Outfit;
  font-size: 27px;
  font-weight: 700;
  line-height: 35px;
  color: #290055;
`

export const RuledTextArea = styled.textarea`
  margin-top: 40px;
  width: 100%;
  height: 70%;
  border: none;
  outline: none;
  resize: none;
  font-family: heroregular;
  font-size: 15px;
  cursor: default;
  font-weight: 700;
  background: url(${ruledLineSingle});
  background-repeat: repeat;
  background-attachment: local;
  line-height: 42px;
  letter-spacing: 1px;
  color: #727172;

  @media (max-width: 1080px) {
    margin-top: 20px;
  }

  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    background: #FFE072;
    border-radius: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background: #5720A3;
    border-radius: 5px;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
  // Hide scrollbar for IE, Edge and Firefox

`