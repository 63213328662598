import { Add, Delete } from '@mui/icons-material'
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from '@mui/material'
import { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { CustomInput, CustomSelect, selectTypes } from 'src/consumer/components/helperComponents'
import { currency_list } from 'src/consumer/constants'
import { UnsecureSetupContext } from 'src/consumer/context/UnsecureSetupContext'
import { checkAndGetTargetValue, genericHandleChange } from 'src/consumer/helpers/utilFunctions'
import { MentorProfileContext } from 'src/mentor/context/MentorProfileContext'
import { sleep } from 'src/mentor/helpers/utilityFunctions'
import { postMPDataWithParams } from 'src/mentor/services'
import {
  StyledEventInputContainer,
  StyledEventReactSelect,
} from '../../MentorAvailabilityPage/MentorAvailabilityPageStyles'
import { SavingCircularProgress } from '../../MentorProfilePage/MentorProfilePageStyles'
import {
  createSubmittablePricingMatrix,
  filterOtherSelectedCountries,
  getUsablePricingMatrix,
  validatePricingMatrixToSave,
} from '../MentorEventsHelpers'
import {
  AddCustomPriceButton,
  AddCustomPriceSubHeading,
  AddPricingFab,
  CancelAddCustomPrice,
  CancelConfirmationButton,
  ChangesWillNotBeSavedButton,
  CountryPricingContainer,
  DeletePriceButton,
  SavePlanButton,
} from '../MentorEventsPageStyles'
import BundlePackCustomPriceComponent from './bundle/BundlePackCustomPriceComponent'

export default function BaseAddCustomPriceComponent({
  selectedPlan,
  setSelectedPlan,
  handleChangePlan: changePlan,
  closeFunction,
  refreshOneOnOnePlans,
  type = 'default',
  allOneOnOnePlans,
}) {
  const { unSecureSetupData } = useContext(UnsecureSetupContext)
  const { mentorProfileData } = useContext(MentorProfileContext)
  const [pricingMatrix, setPricingMatrix] = useState()
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [confirmationType, setConfirmationType] = useState('')
  const planIsValid = useMemo(() => {
    console.log({ pricingMatrix, planIsValid: validatePricingMatrixToSave(pricingMatrix) })
    return validatePricingMatrixToSave(pricingMatrix)
  }, [pricingMatrix])
  const dialogContentRef = useRef(null)
  const [saving, setSaving] = useState(false)
  const [changesMade, setChangesMade] = useState(false)
  const [justRendered, setJustRenderded] = useState(true)
  const [subscriptionPlanToUse, setSubscriptionPlanToUse] = useState()

  function handleChange(e, idx) {
    const { id } = e.target
    console.log({ id, idx })
    setChangesMade(true)
    setPricingMatrix((prev) => {
      const newMatrix = [...prev]?.map((item, index) => {
        return index === idx ? { ...item, [id]: checkAndGetTargetValue(e) } : item
      })
      return newMatrix
    })
  }

  function handleChangeSubscriptionPlanToUse(eve) {
    genericHandleChange(eve, setSubscriptionPlanToUse)
  }

  function handleAddToPricingMatrix() {
    setPricingMatrix((prev) => {
      const foundCountryFromMatrix = prev?.find(
        (prv) => prv?.countryId === mentorProfileData?.location?.countryCode,
      )
      const foundCountry = unSecureSetupData?.metaCountries?.find(
        (ctry) => ctry?.id === mentorProfileData?.location?.countryCode,
      )
      const newMatrix = [
        ...prev,
        {
          countryId: !!foundCountryFromMatrix ? '' : mentorProfileData?.location?.countryCode,
          currencyId: !!foundCountryFromMatrix ? '' : foundCountry?.currency,
        },
      ]
      return newMatrix
    })
  }

  function handleDelete(idx) {
    setPricingMatrix((prev) => {
      const newMatrix = [...prev].filter((item, index) => index !== idx)
      return newMatrix
    })
  }

  function handleCloseButton() {
    if (changesMade) {
      setShowConfirmation(true)
      setConfirmationType('close')
    } else {
      closeFunction()
    }
  }
  function handleSaveButton() {
    setShowConfirmation(true)
    setConfirmationType('save')
  }

  async function SavePlan() {
    setSaving(true)

    const pricingMatrixToSave = createSubmittablePricingMatrix(pricingMatrix)
    console.log(pricingMatrixToSave)
    const response = await postMPDataWithParams(
      `/api/secure/teacher/1on1-custom-plan`,
      pricingMatrixToSave,
      { planId: subscriptionPlanToUse ? subscriptionPlanToUse?.id : null },
    )
    if (response.status === 200) {
      await refreshOneOnOnePlans()
      setShowConfirmation(false)
      changePlan({ target: { value: response.data?.data?.subscriptionPlan?.id } })
      closeFunction()
    }
    setSaving(false)
  }

  async function Setup() {
    console.log({ selectedPlan })
    const mentorCountry = unSecureSetupData?.metaCountries?.find(
      (ctry) => ctry?.id === mentorProfileData?.location?.countryCode,
    )
    var pricingMatrixToSet = [{ countryId: 'row', currencyId: mentorCountry?.currency }]
    if (selectedPlan) {
      pricingMatrixToSet = getUsablePricingMatrix(selectedPlan?.pricingMatrix)
    }
    setPricingMatrix(pricingMatrixToSet)
    setSubscriptionPlanToUse(selectedPlan)
    await sleep(400)
    setJustRenderded(false)
  }

  function showModalTitle() {
    if (type === 'bundle') {
      return !!subscriptionPlanToUse ? 'Edit Bundle' : 'Add Bundle'
    }
    return !!subscriptionPlanToUse ? 'Edit Plan Prices' : 'Add Custom Price'
  }

  function showModalSubtitle() {
    if (type === 'bundle') {
      return 'Create a bundle and add prices in different countries.'
    }
    return 'Add prices for your plan in different countries.'
  }

  useEffect(() => {
    Setup()
  }, [selectedPlan])

  return (
    <>
      <DialogTitle>
        <span>{showModalTitle()}</span>
        <AddCustomPriceSubHeading>{showModalSubtitle()}</AddCustomPriceSubHeading>
      </DialogTitle>
      <DialogContent
        sx={{
          fontFamily: 'Outfit',
          gap: '10px',
          display: 'flex',
          flexDirection: 'column',
          paddingTop: '20px !important',
        }}
        ref={justRendered ? null : dialogContentRef}
      >
        <CustomInput
          label="Subscription Title"
          value={subscriptionPlanToUse?.title}
          onChange={handleChangeSubscriptionPlanToUse}
          invalid={!subscriptionPlanToUse?.title}
          required
        />
        {type === 'bundle' && (
          <Grid item xs={12}>
            <BundlePackCustomPriceComponent
              bundle={subscriptionPlanToUse?.bundle || []}
              setSelectedPlan={setSubscriptionPlanToUse}
              allOneOnOnePlans={allOneOnOnePlans}
            />
          </Grid>
        )}
        {pricingMatrix?.map((pricingData, idx) => (
          <ChangeCountryPricing
            key={`${pricingData?.countryId}-${pricingData?.currencyId}-${idx}`}
            handleChange={(e) => handleChange(e, idx)}
            handleDelete={() => handleDelete(idx)}
            priceObject={pricingData}
            country={pricingData?.countryId}
            pricingMatrix={pricingMatrix}
            dialogContentRef={dialogContentRef}
          />
        ))}
        <div
          style={{
            position: 'sticky',
            bottom: '10px',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <AddPricingFab onClick={handleAddToPricingMatrix}>
            <Add />
          </AddPricingFab>
        </div>
      </DialogContent>
      <DialogActions>
        <CancelAddCustomPrice onClick={handleCloseButton}>Cancel</CancelAddCustomPrice>
        <AddCustomPriceButton disabled={!planIsValid} onClick={handleSaveButton}>
          {!!subscriptionPlanToUse ? 'Save Pricing Details' : 'Add Custom Price'}
        </AddCustomPriceButton>
      </DialogActions>
      <Dialog open={showConfirmation} onClose={() => setShowConfirmation(false)}>
        <DialogTitle>
          {confirmationType === 'save'
            ? 'Do you want to save this One on One plan?'
            : 'You will lose all your changes do you want to continue?'}
        </DialogTitle>
        <DialogActions>
          <CancelConfirmationButton disabled={saving} onClick={() => setShowConfirmation(false)}>
            Cancel
          </CancelConfirmationButton>
          {confirmationType === 'save' ? (
            <Box sx={{ m: 1, position: 'relative' }}>
              {' '}
              <SavePlanButton
                disabled={saving}
                onClick={() => {
                  if (planIsValid) {
                    SavePlan()
                  }
                }}
              >
                {saving && (
                  <SavingCircularProgress
                    size={24}
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      marginTop: '-12px',
                      marginLeft: '-12px',
                    }}
                  />
                )}
                Save
              </SavePlanButton>
            </Box>
          ) : (
            <ChangesWillNotBeSavedButton
              onClick={() => {
                setShowConfirmation(false)
                closeFunction()
              }}
            >
              Continue
            </ChangesWillNotBeSavedButton>
          )}
        </DialogActions>
      </Dialog>
    </>
  )
}

function ChangeCountryPricing({
  priceObject,
  handleChange,
  pricingMatrix,
  handleDelete,
  dialogContentRef,
}) {
  const { unSecureSetupData } = useContext(UnsecureSetupContext)
  const isRestOfWorld = priceObject?.countryId === 'row'
  const [showConfirmation, setShowConfirmation] = useState(false)

  function changeCountry(selectValue) {
    const countryCode = selectValue?.id
    const currencyCode = selectValue?.currency
    console.log({ selectValue })
    handleChange({ target: { value: countryCode, id: 'countryId' } })
    handleChange({ target: { value: currencyCode, id: 'currencyId' } })
  }

  function deletePrice() {
    setShowConfirmation(false)
    handleDelete()
  }

  useEffect(() => {
    const dialogContent = dialogContentRef?.current
    console.log(dialogContent?.scrollHeight)
    if (dialogContent) {
      dialogContent.scrollTo({
        top: dialogContent.scrollHeight,
        behavior: 'smooth',
      })
    }
  }, [])

  return (
    <div
      style={{
        border: '1px solid #00000033',
        borderRadius: '7px',
        padding: '20px',
        boxShadow: isRestOfWorld ? '0px 0px 5px #900aff' : 'unset',
        position: 'relative',
      }}
    >
      <CountryPricingContainer container spacing={2}>
        <Grid item xs={12} md={6}>
          <CustomSelect
            id="countryId"
            value={priceObject?.countryId}
            options={filterOtherSelectedCountries(
              unSecureSetupData?.metaCountries.map((country) =>
                country?.id === 'row'
                  ? { ...country, name: 'Global (Rest of the world)' }
                  : country,
              ),
              pricingMatrix,
              priceObject?.countryId,
            )}
            customComponent={StyledEventReactSelect}
            getOptionValue={(option) => option?.id}
            getOptionLabel={(option) => option.name}
            selectType={selectTypes.string}
            menuPlacement="auto"
            label="Country"
            isDisabled={isRestOfWorld}
            onChange={changeCountry}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            menuPortalTarget={document.body}
            invalid={priceObject?.countryId}
            required
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomSelect
            id="currencyId"
            value={priceObject?.currencyId}
            options={currency_list?.map((currency) => ({
              ...currency,
              name: `(${currency.symbol}) ${currency.name}`,
            }))}
            customComponent={StyledEventReactSelect}
            getOptionValue={(option) => option?.code}
            getOptionLabel={(option) => option.name}
            selectType={selectTypes.string}
            menuPlacement="auto"
            label="Currency"
            onChangeEvent={handleChange}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            menuPortalTarget={document.body}
            invalid={!priceObject?.currencyId}
            required
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <CustomInput
            id="price"
            value={priceObject?.price}
            label="Price"
            required
            inputAndMessageContainer={StyledEventInputContainer}
            type="number"
            invalid={!priceObject?.price}
            onChange={(e) => {
              const newValue = Math.max(0, e.target.value) // Ensure value is not below 0
              handleChange({
                target: {
                  value: e.target.value === '' ? '' : newValue,
                  id: 'price',
                  type: 'number',
                },
              })
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <CustomInput
            id="discountedPrice"
            value={priceObject?.discountedPrice}
            label="Discounted Price"
            onFocus={(e) => {
              if (e.target.value === '' || e.target.value === null || e.target.value === undefined)
                handleChange({
                  target: { value: priceObject?.price, id: 'discountedPrice', type: 'number' },
                })
            }}
            inputAndMessageContainer={StyledEventInputContainer}
            placeholder={priceObject?.price}
            type="number"
            onChange={(e) => {
              const newValue = Math.max(0, e.target.value) // Ensure value is not below 0
              handleChange({
                target: {
                  value: e.target.value === '' ? '' : newValue,
                  id: 'discountedPrice',
                  type: 'number',
                },
              })
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <CustomInput
            id="introductoryPrice"
            value={priceObject?.introductoryPrice}
            label="Introductory Price"
            inputAndMessageContainer={StyledEventInputContainer}
            placeholder={priceObject?.price}
            onFocus={(e) => {
              if (e.target.value === '' || e.target.value === null || e.target.value === undefined)
                handleChange({
                  target: { value: priceObject?.price, id: 'introductoryPrice', type: 'number' },
                })
            }}
            type="number"
            onChange={(e) => {
              const newValue = Math.max(0, e.target.value) // Ensure value is not below 0
              handleChange({
                target: {
                  value: e.target.value === '' ? '' : newValue,
                  id: 'introductoryPrice',
                  type: 'number',
                },
              })
            }}
          />
        </Grid>
      </CountryPricingContainer>
      {!isRestOfWorld && (
        <IconButton
          color="error"
          onClick={() => setShowConfirmation(true)}
          sx={{ position: 'absolute', top: '5px', right: '5px' }}
        >
          <Delete />
        </IconButton>
      )}
      <Dialog open={showConfirmation} onClose={() => setShowConfirmation(false)}>
        <DialogTitle>
          Do you want to remove the pricing for{' '}
          {
            unSecureSetupData?.metaCountries?.find((count) => count?.id === priceObject?.countryId)
              ?.name
          }
        </DialogTitle>
        <DialogActions>
          <CancelConfirmationButton onClick={() => setShowConfirmation(false)}>
            Cancel
          </CancelConfirmationButton>
          <DeletePriceButton onClick={deletePrice}>Delete</DeletePriceButton>
        </DialogActions>
      </Dialog>
    </div>
  )
}
