import styled from 'styled-components'

export const ProfileContainer = styled.div`
  background: #faf5ff;
  max-width: 400px;
  width: 100%;
  margin: 50px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
`

export const ProfileCardComponent = styled.div`
  padding: 0.5rem;
  margin: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
`
