import { Add, CheckCircle } from '@mui/icons-material'
import {
  Avatar,
  ButtonBase,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputBase,
  Paper,
  styled as muiStyled,
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import PhoneInput from 'react-phone-input-2'
import styled from 'styled-components'

export const MentorPageContainer = styled.form`
  font-family: 'Outfit' !important;
  position: relative;
  padding: 25px 25px;
  max-width: 1000px;
  flex-grow: 1;
  margin: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const ProfileTabContainer = styled.div`
  display: flex;
  position: relative;
  overflow-x: auto;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;

  ::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
`

export const ArrowButton = muiStyled(IconButton)`
  position: absolute;
`

export const ProfileTabButtonContainer = muiStyled('div')`
  position: sticky;
  display:flex;
  justify-content: space-between;
  padding-top: 15px;
  padding-bottom: 15px;
  top: 0px;
  background: #fafafa;
  z-index:2;
`

function getTabButtonColor(selected, invalid) {
  if (invalid) {
    return 'red'
  }
  if (selected) {
    return '#A864FF'
  } else {
    return 'transparent'
  }
}

export const ProfileTabButton = muiStyled(ButtonBase)(
  ({ theme, selected, showBorder, invalid = false }) => `
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border:${showBorder ? ' 1px solid #0000003b' : 'unset'};
  border-radius: 38px;
  flex-shrink: 0;
  padding: 8px 23px;
  background: ${getTabButtonColor(selected, invalid)};
  color : ${selected ? '#fff' : '#000'} !important;
  transition: background 150ms ease-in-out;
  :hover {
    background: ${selected ? '#b880ff' : '#d6d6d6'};
  }
`,
)

export const ProfileComponentsContainer = muiStyled(Grid)(
  ({ theme }) => `
  padding: 7px 0px;
`,
)
export const SectionSubHeading = muiStyled('div')`
  font-size: 16px;
  font-weight: 300;
`
export const WelcomeDialogPaper = muiStyled(Paper)`
  padding: 20px;
  text-align: center;
  font-family: 'Outfit' !important;
  width: 550px;
  height: 460px;
`
export const WelcomeDialogContent = muiStyled(DialogContent)``
export const WelcomeDialogTitle = muiStyled(DialogTitle)(
  ({ theme }) => `
font-family: 'Outfit';
font-weight: 600;
font-size: 23px;
@media(min-width: 768px){
  font-size: 26px;
}
`,
)
export const WelcomeDialogActions = muiStyled(DialogActions)(
  ({ theme }) => `
font-family: 'Outfit';
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 10px;
font-size: 15px;
@media(min-width: 768px){
  font-size: 18px;
}
`,
)
export const WelcomeDialogButtonPrimary = muiStyled(ButtonBase)(
  ({ theme }) => `
  border-radius: 43px;
  padding: 12px 85px;
  background-color: #A864FF;
  color: #fff;
`,
)
export const WelcomeDialogButtonSecondary = muiStyled(ButtonBase)(
  ({ theme }) => `
  border-radius: 20px;
  padding: 5px 10px;
  font-weight: 300;
  font-size: 15px;
`,
)
export const WelcomeDialogHeroButtonsContainer = muiStyled('div')(
  ({ theme }) => `
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px;
  gap: 20px;
`,
)
export const WelcomeDialogHeroButtons = muiStyled('div')(
  ({ theme }) => `
  position: relative;
  height: 100px;
  width: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  gap: 10px;
  @media(min-width: 768px){
    height: 150px;
    width: 130px;
    font-size: 15px;
}

`,
)
export const WelcomeDialogHeroButtonsCheck = muiStyled(CheckCircle)`
  position: absolute;
  top: 0px;
  right: 0px;
  color: green;
`
export const WelcomeDialogGetStartedIcon = styled.img`
  height: 70px;
`
export const MentorProfileButton = muiStyled(Avatar)(
  ({ theme, img }) => `
  height: 100%;
  width: 100%;
`,
)

export const MentorProfileButtonContainer = styled.div`
  height: 150px;
  width: 150px;
  border-radius: 40px;
  overflow: hidden;
  @media (min-width: 768px) {
    height: 200px;
    width: 200px;
  }
`

export const AvatarActionIcon = muiStyled(Avatar)`
  background: ${({ theme, imgIsThere }) => (imgIsThere ? theme.palette.warning.light : '#a864ff')};
  border: 2px solid #fafafa;
  width: 50px;
  height: 50px;
`
export const StrongHeading = styled.div`
  font-size: 20px;
  font-weight: 600;
`
export const InLineLighterHeading = styled.span`
  font-size: 18px;
  font-weight: 600;
`
export const StyledSlugInput = styled.input`
  outline: none;
  width: 100%;
  background: transparent;
`
export const StyledSlugInputBox = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  outline: none;
  padding: 10px;
  background: #ebebeb;
  border-radius: 7px;
  width: 100%;
`
export const StyledSlugPrefix = styled.div`
  color: #868686;
  font-size: 16px;
`
export const SectionHeading = styled.div`
  font-size: 32px;
  margin-top: 15px;
  font-weight: 600;
`
export const SaveMentorButton = muiStyled(ButtonBase)`
  padding: 8px 48px;
  flex-shrink: 0;
  color: #fafafa;
  background: ${({ theme, disabled }) => (disabled ? '#D9D9D9' : '#03D63E')};
  border-radius: 40px;
  transition: background 300ms linear;
`
export const MentorProfileActions = muiStyled('div')`
  width: 100%;
  display: flex;
  padding: 25px 0px 10px 0px;
  justify-content: space-between;
  align-items:center;
  @media( min-width: 768px ){
    padding: 40px 0px 10px 0px;
  }
`
export const SavingCircularProgress = muiStyled(CircularProgress)`
  color: #03d63e;
`

export const AgreeAgreementButton = muiStyled(ButtonBase)(
  ({ theme }) => `
    padding: 10px 20px;
    background: 'grey';
`,
)

export const CancelAgreementButton = muiStyled(ButtonBase)(
  ({ theme }) => `
    padding: 10px 20px;
    background: 'grey';
`,
)

export const AgreementDialogContent = muiStyled(DialogContent)(
  ({ theme }) => `
padding: 0 0 10px 0;
`,
)
export const AgreementDialogActions = muiStyled(DialogActions)(
  ({ theme }) => `
`,
)

export const AgreementsDialogPaper = muiStyled(Paper)`
  padding: 20px;
  font-family: 'Outfit' !important;
  width: 90vw;
  max-width: 90vw !important;
  height: 90vh;
`

export const StartWorkingDateSelector = muiStyled(DatePicker)`
> .MuiFormControl-root{
  border-color: green !important;
}
`
export const StartWorkingInputField = muiStyled(InputBase)`
background: #ebebebeb;
    border-radius: 7px;
    padding: 8px 11px;
`
export const MentorWorkExperienceInputContainer = muiStyled('div')`
  display: flex;
  flex-direction: column;
  gap: 7px;
`

export const UploadCertificateDialogPaper = muiStyled(Paper)`
  width:100%;
  height: 570px;
  max-width: 500px !important;
  padding: 15px 10px;
  border-radius: 20px;
  font-family:'Outfit';
`

export const CloseCertificateDialogButton = muiStyled(ButtonBase)`
// border: 1px solid black;
border-radius:30px;
  padding: 5px 15px;
`
export const SaveCertificateDialogButton = muiStyled(ButtonBase)`
  padding: 5px 32px;
  color: #fafafa;
  background: ${({ theme, disabled }) => (disabled ? '#D9D9D9' : '#03D63E')};
  border-radius: 40px;
  transition: background 300ms linear;
`

export const CertificationDialogActions = muiStyled(DialogActions)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
`
export const CertificationDialogContent = muiStyled(DialogContent)`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 25px;
  width: 100%;
`

export const CertificationDialogTitle = muiStyled(DialogTitle)`
  font-size: 25px;
  font-weight: 600;
  width: 100%;
  font-family: "Outfit";
`

export const CertificationHeaderContainer = muiStyled('div')`
  display:flex;
  justify-content: flex-start;
  align-items: center;
  gap:30px;
  @media(min-width:768px){
    gap: 100px;
  }
`

export const WorkExperienceDropDownsContainer = muiStyled('div')`
  display: flex;
  gap: 10px;
`

export const CertificateAddIcon = muiStyled(Add)`
    border-radius: 30px;
    background: #A864FF;
    color: #fafafa;
    font-size: 18px;
`

export const AddCertificationButton = muiStyled(ButtonBase)`
    display: flex;
    justify-content: flex-start;
    padding: 5px 10px;
    align-items: center;
    gap: 10px;
    border-radius:7px;
    max-width: 300px;
    flex-shrink: 0;
    color: #A864FF;
    transition: background 150ms ease-in-out;
    padding: 5px 10px;
    font-weight: 500;
    font-size: 16px;
`

export const MentorProfilePageSkipButton = muiStyled(ButtonBase)(
  ({ theme, disabled, backgroundColorOverride = '#9d9d9d' }) => `
display: flex;
justify-content: center;
gap: 7px;
    padding: 8px 20px;
    border-radius: 50px;
    background: ${disabled ? '#dddddd' : backgroundColorOverride};
    color: #fafafa;
`,
)

export const NoCertificationsFoundContainer = muiStyled('div')`
display: flex;
width: 100%;
justify-content: center;
align-items: center;
  padding: 30px 0px;
`
export const AddACertificateContainer = muiStyled('div')`
  padding: 20px 0px 0px 22px;
`
export const DownloadCertificateTag = muiStyled('a')`
transition:all 100ms cubic-bezier(0, 0, 0.27, 0.93);
  color: #374253;
  &:hover {
    color: #2d7cc5;
  }
  
`

export const EditCertificateButton = muiStyled(ButtonBase)(
  ({ theme, disabled }) => `
  border-radius:30px;
  display: flex;
  justify-content: center;
  gap: 5px;
  padding:7px;
  color: #e7b300;
`,
)

export const CertificationFormTableItem = muiStyled('div')(
  ({ theme, odd }) => `
display:flex;
justify-content: flex-start;
align-items: center;
gap: 10px;
padding: 5px 10px;
border-radius: 7px;
background: #f0f0f0;
`,
)

export const StyledMentorPhoneNumberInput = muiStyled(PhoneInput)(
  ({ theme }) => `
  font-family: Outfit !important;
  width: 100%;
  > .special-label {
   display: none;
  }
  > .flag-dropdown {
    font-family: Outfit !important;
  }
  > .form-control { 
    border: none !important;
    background: transparent !important;
    width: 100% !important;
    padding: 10px 14px 10px 14px !important;
    border-radius: 9px !important;
    &:focus {
        box-shadow: unset !important;
    }
  }
`,
)

export const StyledClinicBrandPhoneNumberInput = muiStyled(PhoneInput)(
  ({ theme }) => `
  font-family: Outfit !important;
  width: 100%;
  > .special-label {
   display: none;
  }
  > .flag-dropdown {
    font-family: Outfit !important;
  }
  > .form-control { 
    border: none !important;
    background: transparent !important;
    width: 100% !important;
    padding: 0px !important;
    border-radius: 9px !important;
    &:focus {
        box-shadow: unset !important;
    }
  }
`,
)
