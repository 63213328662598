import axios from 'axios'
import { apiURL } from 'src/consumer/services'

export async function SignIn(email, password) {
  const countryCode = localStorage.getItem('countryCode')
  const languageCode = sessionStorage.getItem('languageCode')
  try {
    const res = await axios.get(
      `${apiURL}/api/secure/user/login?languageCode=${languageCode}&countryCode=${countryCode}`,
      {
        auth: {
          username: email,
          password: password,
        },
      },
    )
    return res
  } catch (err) {
    console.log(err)
    return err
  }
}
