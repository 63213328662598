import React, { useState } from 'react'
import logo from 'src/assets/logo_brand.png'

const AuthorityCheck = () => {
  const [isLoading, setLoading] = useState()
  return (
    <>
      <div
        className="w-full h-screen flex flex-col justify-between"
        style={{ background: '#E5E5E5' }}
      >
        <div className="mx-auto flex flex-col gap-4 p-6 w-full items-center text-center">
          <img className="h-16 rounded-md" src={logo} />
          <div className="w-full flex flex-col text-center gap-4">
            <h1 className="text-5xl font-black text-black">Where Do you want to go?</h1>
            <h4 className="w-1/2 mx-auto">Select your Role</h4>
          </div>
          <div className="bg-white h-20 w-80">
            <p className="mx-auto w-max mt-4 text-black text-xl font-black">User/Consumer</p>
          </div>
          <div className="bg-white h-20 w-80">
            <p className="mx-auto w-max mt-4 text-black text-xl font-black">Business User</p>
          </div>{' '}
          <div className="bg-white h-20 w-80">
            <p className="mx-auto w-max mt-4 text-black text-xl font-black">Admin</p>
          </div>
        </div>
        <div className="w-full h-max">
          <img className="w-full h-32" src="footerImage.png"></img>
        </div>
      </div>
    </>
  )
}

export default AuthorityCheck
