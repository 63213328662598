export const MUIThemeConfig = {
  typography: {
    fontFamily: `"Outfit"`,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
      xxl: 1400,
    },
    palette: {
      primary: {
        default: '#3f51b5',
        main: '#3f51b5',
        light: 'rgb(50, 31, 219)',
        dark: 'rgb(133, 125, 204)',
      },
      common: {
        black: '#000',
      },
      disabled: {
        default: '#f50057',
        main: '#f50057',
        light: 'rgb(247, 51, 120)',
        dark: 'rgb(171, 0, 60)',
      },
    },
  },
  transitions: {
    easing: {
      quickInOut: 'cubic-bezier(0.1, 1.01, 0.62, 1)',
      // quickInOut: 'cubic-bezier(0.1, 0.92, 0.54, 0.99)',
    },
  },
}
