import { useParams } from 'react-router-dom'
import { PublicAppFooter } from '../PublicAppFooter'
import PublicAppHeader from '../PublicAppHeader'
import { useEffect } from 'react'
import BoardMemberProfile from '../MentalHealthMedicalBoardComponents/boardMember/BoardMemberProfile'

export default function MedicalBoardMemberPage() {
  const { id } = useParams()
  useEffect(() => {
    console.log({ id })
  }, [id])
  return (
    <>
      <PublicAppHeader config={{ showLinks: true }} />
      <BoardMemberProfile id={id} />
      <PublicAppFooter />
    </>
  )
}
