import { TextareaAutosize } from '@mui/base'
import styled from 'styled-components'

export const ChatExternalContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  padding-bottom: 48px;
  border: 1px solid #c6c6c6;
`

export const ChatHeader = styled.div`
  background: #1e323b;
  width: 100%;
  padding: 8px 8px;
  color: #fff;
  display: flex;
  align-items: center;
  gap: 4px;
`

export const ChatContainer = styled.div`
  width: 100%;
  flex-grow: 1;
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;
  background: #f5f8fb;
  border-radius: 0px 0px 0 0;
  padding: 8px;
  /* Define a custom property for scrollbar colors */
  --thumb-color: #cecece; /* Color of the thumb */
  --background-color: #fff; /* Background color */

  /* Style for WebKit browsers (Chrome, Safari) */
  &::-webkit-scrollbar {
    width: 8px; /* Width of the scrollbar */
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--thumb-color); /* Color of the thumb */
    border-radius: 4px; /* Optional: Rounded corners for the thumb */
  }

  /* Style for Firefox */
  /* Thin scrollbar width */
  scrollbar-width: thin;

  /* Color of the thumb */
  scrollbar-color: var(--thumb-color) var(--background-color);

  /* Style for Microsoft Edge and IE */
  /* Thin scrollbar width */
  &::-ms-scrollbar {
    width: 8px; /* Width of the scrollbar */
  }

  /* Color of the thumb */
  &::-ms-scrollbar-thumb {
    background-color: var(--thumb-color); /* Color of the thumb */
    border-radius: 4px; /* Optional: Rounded corners for the thumb */
  }
`

export const ChatMessageThisUser = styled.div`
  background: #cbc5fb;
  color: #000;
  padding: 4px 4px 16px;
  position: relative;
  word-wrap: break-word;
  margin-left: auto;
  border-radius: 4px;
  text-align: right;
  margin-bottom: 8px;
  width: fit-content;
  max-width: 70%;
  min-width: 70px;
  font-weight: 100;
`

export const ChatMesageOtherUser = styled.div`
  background: #ebebeb;
  color: #000;
  padding: 4px 4px 16px;
  position: relative;
  word-wrap: break-word;
  margin-right: auto;
  border-radius: 4px;
  text-align: left;
  margin-bottom: 8px;
  width: fit-content;
  max-width: 70%;
  min-width: 70px;
  font-weight: 100;
`

export const ChatMessageInputContainer = styled.div`
  width: 100%;
  min-height: 48px;
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  border-top: 1px solid #c6c6c6;
`

export const ChatMessageInputSameSizeDiv = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`

export const ChatMessageInput = styled(TextareaAutosize)`
  width: 100%;
  height: 100%;
  padding: 0px 72px 0px 16px;
  resize: none;
  border: none;
  outline: none;
  &:focus,
  &:active {
    outline: none;
  }
`

export const ConversationListItemContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 12px 8px;
  alignitems: center;
  gap: 4px;
  cursor: pointer;
  transition: all 0.2s ease;
  &:hover {
    background: #e8e8e8;
  }
`

export const ChatAvatarContainer = styled.div`
  width: ${(props) => (props?.small ? '25px' : '40px')};
  height: ${(props) => (props?.small ? '25px' : '40px')};
  flex-shrink: 0;
  border-radius: 50%;
  font-size: ${(props) => (props?.small ? '8px' : '16px')};
  background: ${(props) => (props.backgroundImage ? `url(${props.backgroundImage})` : '#fff')};
  background-size: cover;
  background-position: center;
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgb(182, 194, 200);
  position: relative;
`
