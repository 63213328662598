import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Popover } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { mediaURL } from "src/consumer/services";
import { isValidHttpUrl } from "src/mentor/helpers/utilityFunctions";
import { ModalProvider } from "styled-react-modal";
import { DisplayFlex } from "../ConsumerProfilePage/ProfileLeftPannelStyle";
import { getFormattedCount } from "../DailyPlay/DailyPlayComponents";
import { IconImage } from "../DailyPlay/DailyPlayStyles";
import { FadingBackground } from "../GenericInformationModal/GenericInformationModalStyles";
import {
    ActiveAwardContainer, AwardCircleGeneric, AwardContainerBox,
    AwardSubtitleGeneric, AwardTitleGeneric, BadgeDetailContainer, BlueEffect,
    ComparisonModeSelectionContainer, ComparisonModeSelectionItem, ContextBoxForBadge,
    CriteriaChangeOption,
    CriteriaChangerButton,
    CrownPicture, DetailCloseButton, DetailContainerBox, DiscUnderCircle, DisplayFlexSpaceBetween,
    EarnFirstRewardContainer, EarnFirstRewardFooterHeading, EarnFirstRewardPicture, EarnFirstRewardPictureHeading,
    GlowyEffectOfCircle, GreenEffect, HighlightedLeadersContainer, IconImageStats, LeaderPicturesContainer,
    LeaderboardPictureBronze, LeaderboardPictureGold, LeaderboardPictureSilver, LeadersCrownAlt, LeadersName, OrangeEffect,
    PinkEffect, PurpleEffect, RankNumberText, ScoreNumbers,
    StyledBadgeInformationModal, TextRowLast, TextRowOne, UserScoreStrip,
    UserScoreStripsContainer, YourStatsBigBox, YourStatsBoxesContainer, YourStatsBoxesContainerColumn,
    YourStatsSmallBox, YourStatsTableItemContainer, YourStatsTableItemHeading, YourStatsTableItemKey,
    YourStatsTableItemValue, YourStatsTablesContainer
} from "./LeaderboardPageStyles";


export const findIconImage = (primaryCriteria) => {
    if (primaryCriteria === "points") {
        return (require("src/assets/unlimited_star.png"))
    } else if (primaryCriteria === "coins") {
        return (require("src/assets/gem.png"))
    } else if (primaryCriteria === "words") {
        return (require("src/assets/letter_w.png"))
    }
}

export const findProfileImage = (leaderData) => {
    
    if (isValidHttpUrl(leaderData?.profilePicId)) {
        return leaderData?.profilePicId;
    } else if (leaderData?.profilePicId) {
        return `${mediaURL}${leaderData?.profilePicId}`;
    } else if (leaderData?.avatarImageId) {
        return `${mediaURL}${leaderData?.avatarImageId}`;
    } else if (leaderData?.location?.countryCode) {
        return `https://flagcdn.com/h240/${leaderData?.location?.countryCode}.png`
    } else {
        return require("src/assets/profile_icon.png");
    }
};

export const HighlightedLeaders = (props) => {

    const { nationalLeadersData, nationalRankData, globalLeadersData, globalRankData, comparisonMode, setComparisonMode, primaryCriteria, setPrimaryCriteria, profileData, unSecureSetupData, statFields } = props;

    const countryCode = localStorage?.getItem('countryCode')?.toLowerCase();
    let countryName;

    unSecureSetupData?.metaCountries?.forEach((item) => {
        if (item?.id === countryCode) {
            countryName = item.name;
        }
    })

    return (
        <HighlightedLeadersContainer>

            <ComparisonModeSelectionContainer>
                <ComparisonModeSelectionItem selected={comparisonMode === "global"} onClick={() => setComparisonMode("global")}> Worldwide</ComparisonModeSelectionItem>
                <ComparisonModeSelectionItem selected={comparisonMode === "national"} onClick={() => setComparisonMode("national")}><IconImage w={20} h={13} src={`https://flagcdn.com/h240/${countryCode}.png`} />{countryName}</ComparisonModeSelectionItem>
                {/* <ComparisonModeSelectionItem selected={comparisonMode === "institution"} onClick={() => setComparisonMode("institution")}>Institution</ComparisonModeSelectionItem> */}
            </ComparisonModeSelectionContainer>

            {(comparisonMode === "national") &&
                <LeaderPicturesContainer>
                    <LeaderboardPictureSilver
                        background={findProfileImage(nationalLeadersData?.[1])}
                    ><RankNumberText>2</RankNumberText><LeadersCrownAlt type="silver" /><LeadersName secondary>{nationalLeadersData?.[1]?.firstName}</LeadersName><ScoreNumbers secondary><IconImage h={20} src={findIconImage(primaryCriteria)} />{getFormattedCount(nationalLeadersData?.[1]?.[primaryCriteria])}</ScoreNumbers>
                    </LeaderboardPictureSilver>
                    <LeaderboardPictureGold
                        background={findProfileImage(nationalLeadersData?.[0])}
                    ><RankNumberText>1</RankNumberText><CrownPicture src={require("src/assets/crown.png")} /><LeadersName>{nationalLeadersData?.[0]?.firstName}</LeadersName><ScoreNumbers><IconImage h={20} src={findIconImage(primaryCriteria)} />{getFormattedCount(nationalLeadersData?.[0]?.[primaryCriteria])}</ScoreNumbers>
                    </LeaderboardPictureGold>
                    <LeaderboardPictureBronze
                        background={findProfileImage(nationalLeadersData?.[2])}
                    ><RankNumberText>3</RankNumberText><LeadersCrownAlt type="bronze" /><LeadersName secondary>{nationalLeadersData?.[2]?.firstName}</LeadersName><ScoreNumbers secondary><IconImage h={20} src={findIconImage(primaryCriteria)} />{getFormattedCount(nationalLeadersData?.[2]?.[primaryCriteria])}</ScoreNumbers>
                    </LeaderboardPictureBronze>
                </LeaderPicturesContainer>
            }

            {(comparisonMode === "global") &&
                <LeaderPicturesContainer>
                    <LeaderboardPictureSilver
                        background={findProfileImage(globalLeadersData?.[1])}
                    ><RankNumberText>2</RankNumberText><LeadersCrownAlt type="silver" /><LeadersName secondary>{globalLeadersData?.[1]?.firstName}</LeadersName><ScoreNumbers secondary><IconImage h={20} src={findIconImage(primaryCriteria)} />{getFormattedCount(globalLeadersData?.[1]?.[primaryCriteria])}</ScoreNumbers>
                    </LeaderboardPictureSilver>
                    <LeaderboardPictureGold
                        background={findProfileImage(globalLeadersData?.[0])}
                    ><RankNumberText>1</RankNumberText><CrownPicture src={require("src/assets/crown.png")} /><LeadersName>{globalLeadersData?.[0]?.firstName}</LeadersName><ScoreNumbers><IconImage h={20} src={findIconImage(primaryCriteria)} />{getFormattedCount(globalLeadersData?.[0]?.[primaryCriteria])}</ScoreNumbers>
                    </LeaderboardPictureGold>
                    <LeaderboardPictureBronze
                        background={findProfileImage(globalLeadersData?.[2])}
                    ><RankNumberText>3</RankNumberText><LeadersCrownAlt type="bronze" /><LeadersName secondary>{globalLeadersData?.[2]?.firstName}</LeadersName><ScoreNumbers secondary><IconImage h={20} src={findIconImage(primaryCriteria)} />{getFormattedCount(globalLeadersData?.[2]?.[primaryCriteria])}</ScoreNumbers>
                    </LeaderboardPictureBronze>
                </LeaderPicturesContainer>
            }

        </HighlightedLeadersContainer >
    )

}

export const UsersList = (props) => {

    const { nationalLeadersData, nationalRankData, globalLeadersData, globalRankData, comparisonMode, setComparisonMode, primaryCriteria, setPrimaryCriteria, profileData, unSecureSetupData, statFields } = props;
    const [anchorElPrimaryCriteria, setAnchorElPrimaryCriteria] = useState();
    const primaryCriteriasOpen = Boolean(anchorElPrimaryCriteria);

    const handlePrimaryCriteriasPopoverClosed = () => {
        setAnchorElPrimaryCriteria(null);
    };

    if (comparisonMode === "national") {

        return (
            <UserScoreStripsContainer>
                <UserScoreStrip heading>
                    <div>Rank</div>
                    <DisplayFlex>Profile Name</DisplayFlex>

                    {/* <DisplayFlex><IconImage h={12} src={findIconImage(primaryCriteria)} />{`${primaryCriteria.charAt(0).toUpperCase() + primaryCriteria.slice(1)} `}</DisplayFlex> */}
                    <CriteriaChangerButton onClick={(event) => setAnchorElPrimaryCriteria(event.currentTarget)}>
                        <IconImage h={12} src={findIconImage(primaryCriteria)} />{`${(statFields?.find(item => item?.id === primaryCriteria))?.name}`} <ExpandMoreIcon sx={{ fontSize: 20, color: 'white' }} />
                    </CriteriaChangerButton>
                    <Popover
                        id="booked-slots-popover"
                        open={primaryCriteriasOpen}
                        anchorEl={anchorElPrimaryCriteria}
                        onClose={handlePrimaryCriteriasPopoverClosed}
                        PaperProps={{ sx: { mt: 1, background: '#000000' } }}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >
                        <div style={{ background: '#000000' }}>
                            {statFields?.map((item, index) => {
                                return (
                                    <CriteriaChangeOption key={index} selected={item?.id === primaryCriteria} onClick={() => setPrimaryCriteria(item?.id)}>
                                        <IconImage h={12} src={findIconImage(item?.id)} />{item?.name}
                                    </CriteriaChangeOption>
                                )
                            })}
                        </div>
                    </Popover>
                </UserScoreStrip>

                {
                    nationalLeadersData && nationalLeadersData?.map((item, index) => {
                        return (
                            <UserScoreStrip key={index} isMe={parseInt(nationalRankData?.rank) === index + 1}>
                                <div>{`#${index + 1} `}</div>
                                <DisplayFlex>
                                    <IconImage
                                        h={35}
                                        w={35}
                                        b={35}
                                        src={findProfileImage(item)}
                                        style={{ objectFit: 'cover' }}
                                    />
                                    {item?.firstName}
                                </DisplayFlex>
                                <DisplayFlex style={{ justifyContent: 'center' }}>
                                    <IconImage h={12} src={findIconImage(primaryCriteria)} />
                                    {getFormattedCount(item?.[primaryCriteria])}
                                </DisplayFlex>
                            </UserScoreStrip>
                        )
                    })
                }

                {(nationalRankData === "ineligible" || nationalRankData?.rank > nationalLeadersData?.length) && <UserScoreStrip isMe>
                    <div>{(nationalRankData === "ineligible") ? 'Unranked' : `#${nationalRankData?.rank}`}</div>
                    <DisplayFlex>
                        <IconImage
                            h={35}
                            w={35}
                            b={35}
                            src={findProfileImage(profileData)}
                            style={{ objectFit: 'cover' }}
                        />
                        {profileData?.firstName}
                    </DisplayFlex>
                    <DisplayFlex style={{ justifyContent: 'center' }}>
                        <IconImage h={12} src={findIconImage(primaryCriteria)} />
                        {(nationalRankData === "ineligible") ? '0' : profileData?.[primaryCriteria]}
                    </DisplayFlex>
                </UserScoreStrip>}

            </UserScoreStripsContainer >
        )

    } else if (comparisonMode === "global") {

        return (
            <UserScoreStripsContainer>
                <UserScoreStrip heading>

                    <div>Rank</div>

                    <DisplayFlex>Profile Name</DisplayFlex>

                    {/* <DisplayFlex><IconImage h={12} src={findIconImage(primaryCriteria)} />{`${primaryCriteria.charAt(0).toUpperCase() + primaryCriteria.slice(1)} `}</DisplayFlex> */}
                    <CriteriaChangerButton onClick={(event) => setAnchorElPrimaryCriteria(event.currentTarget)}>
                        <IconImage h={12} src={findIconImage(primaryCriteria)} />{`${(statFields?.find(item => item?.id === primaryCriteria))?.name}`} <ExpandMoreIcon sx={{ fontSize: 20, color: 'white' }} />
                    </CriteriaChangerButton>
                    <Popover
                        id="booked-slots-popover"
                        open={primaryCriteriasOpen}
                        anchorEl={anchorElPrimaryCriteria}
                        onClose={handlePrimaryCriteriasPopoverClosed}
                        PaperProps={{ sx: { mt: 1, background: '#000000' } }}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >
                        <div style={{ background: '#000000' }}>
                            {statFields?.map((item, index) => {
                                return (
                                    <CriteriaChangeOption key={index} selected={item?.id === primaryCriteria} onClick={() => setPrimaryCriteria(item?.id)}>
                                        <IconImage h={12} src={findIconImage(item?.id)} />{item?.name}
                                    </CriteriaChangeOption>
                                )
                            })}
                        </div>
                    </Popover>

                </UserScoreStrip>
                {globalLeadersData && globalLeadersData?.map((item, index) => {
                    return (
                        <UserScoreStrip key={index} isMe={parseInt(globalRankData?.rank) === index + 1}>
                            <div>{`#${index + 1} `}</div>
                            <DisplayFlex>
                                <IconImage
                                    h={35}
                                    w={35}
                                    b={35}
                                    src={findProfileImage(item)}
                                    style={{ objectFit: 'cover' }}
                                />
                                {item?.firstName}
                            </DisplayFlex>
                            <DisplayFlex style={{ justifyContent: 'center' }}>
                                <IconImage
                                    h={12}
                                    src={findIconImage(primaryCriteria)}
                                />
                                {getFormattedCount(item?.[primaryCriteria])}
                            </DisplayFlex>
                        </UserScoreStrip>
                    )
                })}
                {(globalRankData === "ineligible" || globalRankData?.rank > globalLeadersData?.length) && <UserScoreStrip isMe>
                    <div>{(globalRankData === "ineligible") ? 'Unranked' : `#${globalRankData?.rank}`}</div>
                    <DisplayFlex>
                        <IconImage
                            h={35}
                            w={35}
                            b={35}
                            src={findProfileImage(profileData)}
                            style={{ objectFit: 'cover' }}
                        />
                        {profileData?.firstName}
                    </DisplayFlex>
                    <DisplayFlex style={{ justifyContent: 'center' }}>
                        <IconImage h={12} src={findIconImage(primaryCriteria)} />
                        {(globalRankData === "ineligible") ? '0' : profileData?.[primaryCriteria]}
                    </DisplayFlex>
                </UserScoreStrip>}

            </UserScoreStripsContainer>
        )
    }

}

///////////////

export const StatBoxItemSmall = (props) => {

    const { data } = props;
    const response = Math.floor(Math.random() * 3) + 1;

    return (
        <YourStatsSmallBox data={data}>
            {response === 1 && <PurpleEffect />}
            {response === 2 && <BlueEffect />}
            {response === 3 && <PinkEffect />}
            <DisplayFlexSpaceBetween>
                <TextRowOne>{data?.title}</TextRowOne>
                <IconImageStats src={require("src/assets/brainIcon.png")} />
            </DisplayFlexSpaceBetween>
            <TextRowLast>{getValueText(data?.value, data?.valueType)}</TextRowLast>
        </YourStatsSmallBox>
    )
}

export const StatBoxItemBig = (props) => {

    const { data } = props;
    const response = Math.floor(Math.random() * 3) + 1;

    return (
        <YourStatsBigBox data={data}>
            {response === 1 && <PinkEffect />}
            {response === 2 && <GreenEffect />}
            {response === 3 && <OrangeEffect />}
            <DisplayFlexSpaceBetween>
                <TextRowOne>{data?.title}</TextRowOne>
                <IconImageStats src={data?.iconId ? `${mediaURL}${data?.iconId}` : require("src/assets/brainIcon.png")} />
            </DisplayFlexSpaceBetween>
            <TextRowLast>{getValueText(data?.value, data?.valueType)}</TextRowLast>
        </YourStatsBigBox>
    )
}

export const StatBoxItem = (props) => {
    const { column, position, data } = props;

    if (position > 1) {
        return (
            <StatBoxItemSmall data={data} />
        )
    }

    if (column === "left") {
        if (position % 2 === 0) {
            return (
                <StatBoxItemSmall data={data} />
            )
        } else {
            return (
                <StatBoxItemBig data={data} />
            )
        }
    } else {
        if (position % 2 === 0) {
            return (
                <StatBoxItemBig data={data} />
            )
        } else {
            return (
                <StatBoxItemSmall data={data} />
            )
        }
    }
}

export const StatBoxes = (props) => {

    const { data } = props;

    const dataForStatBoxes = [];

    data?.forEach((item) => {
        if (item?.sectionId === "highlight") {
            dataForStatBoxes?.push(item)
        }
    })


    const halfIndex = Math.ceil(dataForStatBoxes?.length / 2);
    const columnOneData = dataForStatBoxes?.slice(0, halfIndex);
    const columnTwoData = dataForStatBoxes?.slice(halfIndex);

    return (
        <YourStatsBoxesContainer>

            <YourStatsBoxesContainerColumn left>

                {columnOneData?.map((item, index) => {
                    return (
                        <StatBoxItem column="left" key={index} position={index} data={item} />
                    )
                })}

            </YourStatsBoxesContainerColumn>

            <YourStatsBoxesContainerColumn>
                {columnTwoData?.map((item, index) => {
                    return (
                        <StatBoxItem column="right" key={index} position={index} data={item} />
                    )
                })}
            </YourStatsBoxesContainerColumn>

        </YourStatsBoxesContainer>
    )
}

// table related

export const formatTime = (seconds, withoutSeconds = false) => {

    const numberOfSecondsInDay = 24 * 60 * 60;
    const numberOfSecondsInHour = 60 * 60;
    const numberOfSecondsInMinute = 60;

    let remainingSeconds = seconds;
    let formattedTime = "";

    const numberOfDays = Math.floor(remainingSeconds / numberOfSecondsInDay);
    remainingSeconds = remainingSeconds % (numberOfSecondsInDay);

    const numberOfHours = Math.floor(remainingSeconds / numberOfSecondsInHour);
    remainingSeconds = remainingSeconds % (numberOfSecondsInHour);

    const numberOfMinutes = Math.floor(remainingSeconds / numberOfSecondsInMinute);
    remainingSeconds = remainingSeconds % (numberOfSecondsInMinute);

    const numberOfSeconds = Math.floor(remainingSeconds);

    let count = 0;

    if (count < 2) {
        if (numberOfDays === 1) {
            formattedTime += numberOfDays + " Day ";
            count += 1;
        } else if (numberOfDays > 1) {
            formattedTime += numberOfDays + " Days ";
            count += 1;
        }
    }
    if (count < 2) {
        if (numberOfHours === 1) {
            formattedTime += numberOfHours + " hour ";
            count += 1;
        } else if (numberOfHours > 1) {
            formattedTime += numberOfHours + " hours ";
            count += 1;
        }
    }
    if (count < 2) {
        if (numberOfMinutes === 1) {
            formattedTime += numberOfMinutes + " minute ";
            count += 1;
        } else if (numberOfMinutes > 1) {
            formattedTime += numberOfMinutes + " minutes ";
            count += 1;
        }
    }

    if (withoutSeconds) {
        return formattedTime;
    }

    if (count < 2) {
        if (numberOfSeconds === 1) {
            formattedTime += numberOfSeconds + " second ";
            count += 1;
        } else if (numberOfSeconds > 1) {
            formattedTime += numberOfSeconds + " seconds ";
            count += 1;
        }
    }

    return formattedTime
}

export const getValueText = (value, type) => {
    if (type === "number") {
        return getFormattedCount(value);
    } else if (type === "days") {
        if (value === 1) {
            return `${value} day`
        } else {
            return `${value} days`
        }
    } else if (type === "words") {
        if (value === 1) {
            return `${value} word`
        } else {
            return `${value} words`
        }
    } else if (type === "seconds") {
        return formatTime(value)
    } else {
        return value;
    }
}

export const YourStatsTableItem = (props) => {

    const { data } = props;

    return (

        <>
            <YourStatsTableItemHeading>{data?.items?.[0]?.sectionTitle}</YourStatsTableItemHeading>

            <YourStatsTableItemContainer>

                {data?.items?.map((item, index) => {
                    return (
                        <DisplayFlexSpaceBetween key={index}>
                            <YourStatsTableItemKey>{item?.title}</YourStatsTableItemKey>
                            <YourStatsTableItemValue>{getValueText(item?.value, item?.valueType)}</YourStatsTableItemValue>
                        </DisplayFlexSpaceBetween>
                    )
                })
                }

            </YourStatsTableItemContainer>

        </>
    )

}

export const StatTables = (props) => {

    const { data } = props;

    const transformedArray = data?.reduce((result, obj) => {
        const sectionId = obj?.sectionId;
        const existingSection = result?.find(section => section.sectionId === sectionId);

        if (existingSection) {
            existingSection?.items?.push(obj);
        } else {
            result?.push({ sectionId: sectionId, items: [{ ...obj }] });
        }

        return result;
    }, []);

    const finalArray = transformedArray?.map(section => {
        return {
            sectionId: section?.sectionId,
            items: section?.items
        };
    });

    return (
        <YourStatsTablesContainer>
            {finalArray?.map((item, index) => {
                if (item?.sectionId === "highlight") {
                    return null;
                }
                return (
                    <YourStatsTableItem data={item} key={index} />
                )
            })}

        </YourStatsTablesContainer>
    )

}

// your badges related

export const HighlightedBadgeComponent = (props) => {

    const { title, category, colorString, icon, discImage, description, setIsBadgeDetailOpen, data } = props;

    return (
        <ActiveAwardContainer onClick={() => setIsBadgeDetailOpen({ state: true, data: data })}>
            <AwardTitleGeneric>
                {title?.toUpperCase()}
            </AwardTitleGeneric>
            {category && <AwardSubtitleGeneric>
                {category?.toUpperCase()}
            </AwardSubtitleGeneric>}
            <AwardCircleGeneric colorString={colorString} >
                <GlowyEffectOfCircle colorString={colorString} />
                <img src={icon} style={{ width: '50%', zIndex: '1' }} />
            </AwardCircleGeneric>
            <DiscUnderCircle src={discImage} />
        </ActiveAwardContainer>
    )

}

export const ActiveBadgeComponentInStrip = (props) => {

    const { title, subtitle, currentCount, maxCount, earned, icon, colorString, discIcon } = props;

    return (
        <ActiveAwardContainer>
            <AwardCircleGeneric colorString={colorString} >
                {earned && <GlowyEffectOfCircle colorString={colorString} />}
                <img src={icon} style={{ width: '50%', zIndex: '1' }} />
                <ContextBoxForBadge>
                    {(parseInt(currentCount) < parseInt(maxCount)) ? `${currentCount}/${maxCount}` : <img style={{ width: '50%' }} src={require("src/assets/green_tick.png")} />}
                </ContextBoxForBadge>
            </AwardCircleGeneric>
            <DiscUnderCircle src={discIcon} />
            <AwardTitleGeneric moreMargin>
                {title?.toUpperCase()}
            </AwardTitleGeneric>
            <AwardSubtitleGeneric>
                {subtitle}
            </AwardSubtitleGeneric>
        </ActiveAwardContainer>
    )

}

//

export const EarnYourFirstBadgeComponent = (props) => {

    const navigate = useNavigate();

    return (
        <EarnFirstRewardContainer>
            <DisplayFlex style={{ gap: '11px' }}>
                <EarnFirstRewardPictureHeading big>0</EarnFirstRewardPictureHeading>
                <EarnFirstRewardPictureHeading>Badges</EarnFirstRewardPictureHeading>
            </DisplayFlex>
            <EarnFirstRewardPicture src={require("src/assets/no_reward_image.png")} />
            <EarnFirstRewardFooterHeading first onClick={() => navigate('/')}>Earn your First Badge</EarnFirstRewardFooterHeading>
            {/* <EarnFirstRewardFooterHeading>How to?</EarnFirstRewardFooterHeading> */}
        </EarnFirstRewardContainer>
    )
}

export const BadgeDetailComponent = (props) => {

    const { isBadgeDetailOpen, setIsBadgeDetailOpen } = props;
    const colorString = "linear-gradient(145deg, #3AD7FA 0%, #5174FC 100%)"
    const icon = require("src/assets/astronaut_image.png");
    const discImage = require("src/assets/blue_disc_image.png");

    return (
        <BadgeDetailContainer>

            <AwardContainerBox background="linear-gradient(142deg, #FFDD9F 0%, #FF759A 100%)">
                <AwardTitleGeneric>
                    {isBadgeDetailOpen?.data?.title}
                </AwardTitleGeneric>
                <AwardCircleGeneric colorString={colorString} >
                    <GlowyEffectOfCircle colorString={colorString} />
                    <img src={icon} style={{ width: '50%', zIndex: '1' }} />
                </AwardCircleGeneric>
                <DiscUnderCircle src={discImage} />
            </AwardContainerBox>

            <DetailContainerBox>
                <div>{isBadgeDetailOpen?.data?.criteria || isBadgeDetailOpen?.data?.description}</div>
                <DetailCloseButton onClick={() => setIsBadgeDetailOpen({ state: false, data: {} })}>CLOSE</DetailCloseButton>
            </DetailContainerBox>


        </BadgeDetailContainer>
    )

}


export default function BadgeModalComponent(props) {

    const { isBadgeDetailOpen } = props;

    const [opacity, setOpacity] = useState(0);

    function toggleModal(e) {
        setOpacity(0);
    }
    function afterOpen() {
        setTimeout(() => {
            setOpacity(1);
        }, 100);
    }
    function beforeClose() {
        return new Promise((resolve) => {
            setOpacity(0);
            setTimeout(resolve, 300);
        });
    }

    return (
        <ModalProvider backgroundComponent={FadingBackground}>
            <StyledBadgeInformationModal
                isOpen={isBadgeDetailOpen?.state}
                afterOpen={afterOpen}
                beforeClose={beforeClose}
                onEscapeKeydown={toggleModal}
                opacity={opacity}
                backgroundProps={{ opacity }}
            >

                {props.children}

            </StyledBadgeInformationModal>
        </ModalProvider >
    )

}