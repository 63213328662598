import moment from 'moment'
import { useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { GenericDisplayFlexColumn } from 'src/mentor/layout/Styles'
import { NextFewSlotsContainer, NextFewSlotsItem, SelectDateHeading } from '../EventBookingStyles'

export default function NextAvailableSlots({
  availableTimeSlots,
  loading,
  userProfileData,
  eventBookingPageStrings,
  dateUnavailableChecker,
  onClick,
  onClickSeeMore,
  handleMonthChange,
}) {
  const [timeSlotsToUse, setTimeSlotsToUse] = useState([])

  useEffect(() => {
    var timeSlotsToSet = []
    availableTimeSlots?.forEach((tSlot) => {
      if (!dateUnavailableChecker({ date: tSlot.date, view: 'month' })) {
        ///meaning there is a slot in this date
        timeSlotsToSet = [...timeSlotsToSet, ...(tSlot.availableTimeSlots || [])]
      }
    })
    setTimeSlotsToUse(timeSlotsToSet)
    console.log({ availableTimeSlots })
  }, [availableTimeSlots])

  useEffect(() => {
    handleMonthChange('drillDown', new Date(), new Date(), 'month')
  }, [])

  return (
    <GenericDisplayFlexColumn>
      <SelectDateHeading isNotInARow={true}>
        Next Available Slots
        {loading && (
          <Spinner
            animation="border"
            variant="dark"
            size="sm"
            style={{ margin: '0 auto', marginLeft: '5px' }}
          />
        )}
      </SelectDateHeading>
      {!loading && (
        <>
          <NextFewSlotsContainer>
            {timeSlotsToUse.slice(0, 6).map((timeSlot, index) => {
              return (
                <NextFewSlotsItem key={index} onClick={() => onClick(timeSlot)}>
                  <span className="main-heading">
                    {moment(timeSlot.startTime).format('DD MMM')}
                  </span>
                  <span className="secondary-heading">
                    {moment(timeSlot.startTime).format('hh:mm a')}
                  </span>
                </NextFewSlotsItem>
              )
            })}
          </NextFewSlotsContainer>
          <div className="w-100">
            <div
              className="text-center mt-4"
              style={{ cursor: 'pointer' }}
              onClick={onClickSeeMore}
            >
              More Slots
            </div>
          </div>
        </>
      )}
    </GenericDisplayFlexColumn>
  )
}
