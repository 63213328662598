import {
  getAuth,
  signInWithPopup,
  OAuthProvider,
  GoogleAuthProvider,
  FacebookAuthProvider,
} from 'firebase/auth'
import { verifyLogin } from './index'
import { toast } from 'react-toastify'

export const handleGoogleLogin = async (
  handlePostLogin,
  setErrorText,
  doBasicOnboardingInside,
  setBasicOnboardingFlowVisible,
  setProfileDataForBasicOnboarding,
) => {
  const provider = new GoogleAuthProvider()
  const auth = getAuth()
  signInWithPopup(auth, provider)
    .then(async (result) => {
      // This gives you a Google Access Token. You can use it to access the Google API.
      //const credential = GoogleAuthProvider.credentialFromResult(result);
      const user = result?.user
      handlePostSocialLogin(
        handlePostLogin,
        user?.accessToken,
        setErrorText,
        doBasicOnboardingInside,
        setBasicOnboardingFlowVisible,
        setProfileDataForBasicOnboarding,
      )
    })
    .catch((error) => {
      const errorMessage = error.message
      console.log(errorMessage)
      handlePostSocialLogin(handlePostLogin)
    })
}

export const handleFacebookLogin = async (
  handlePostLogin,
  setErrorText,
  doBasicOnboardingInside,
  setBasicOnboardingFlowVisible,
  setProfileDataForBasicOnboarding,
) => {
  const provider = new FacebookAuthProvider()
  const auth = getAuth()
  signInWithPopup(auth, provider)
    .then((result) => {
      // The signed-in user info.
      const user = result.user

      // This gives you a Facebook Access Token. You can use it to access the Facebook API.
      const credential = FacebookAuthProvider.credentialFromResult(result)
      const accessToken = credential.accessToken

      console.log(result)

      handlePostSocialLogin(
        handlePostLogin,
        user?.accessToken,
        setErrorText,
        doBasicOnboardingInside,
        setBasicOnboardingFlowVisible,
        setProfileDataForBasicOnboarding,
      )

      // IdP data available using getAdditionalUserInfo(result)
      // ...
    })
    .catch((error) => {
      // Handle Errors Here.
      const errorMessage = error.message
      console.log(errorMessage)
      handlePostSocialLogin(handlePostLogin)
    })
}

export const handleAppleLogin = async (
  handlePostLogin,
  setErrorText,
  doBasicOnboardingInside,
  setBasicOnboardingFlowVisible,
  setProfileDataForBasicOnboarding,
) => {
  const provider = new OAuthProvider('apple.com')
  const auth = getAuth()
  signInWithPopup(auth, provider)
    .then((result) => {
      // The signed-in user info.
      const user = result?.user
      // Apple credential
      // const credential = OAuthProvider.credentialFromResult(result);
      handlePostSocialLogin(
        handlePostLogin,
        user?.accessToken,
        setErrorText,
        doBasicOnboardingInside,
        setBasicOnboardingFlowVisible,
        setProfileDataForBasicOnboarding,
      )
    })
    .catch((error) => {
      // Handle Errors Here.
      const errorMessage = error.message
      console.log(errorMessage)
      handlePostSocialLogin(handlePostLogin)
    })
}

export const handlePostSocialLogin = async (
  handlePostLogin,
  accessToken,
  setErrorText,
  doBasicOnboardingInside,
  setBasicOnboardingFlowVisible,
  setProfileDataForBasicOnboarding,
) => {
  if (!accessToken) {
    console.log('Error Signing In')
    toast.error('Invalid Social Login Attempt!')
    return
  }
  const response = await verifyLogin(accessToken)
  handlePostLogin(
    response,
    setErrorText,
    doBasicOnboardingInside,
    setBasicOnboardingFlowVisible,
    setProfileDataForBasicOnboarding,
  )
}

export const logoutHandler = () => {
  let lanCode = sessionStorage.getItem('languageCode')
  sessionStorage.clear()
  localStorage.removeItem('token')
  localStorage.removeItem('authorities')
  localStorage.removeItem('FCMToken')
  localStorage.removeItem('submittedFCMTokenForThisUser')
  sessionStorage.setItem(
    'languageCode',
    lanCode ? lanCode : navigator?.language || navigator?.userLanguage.split('-')[0],
  )
  window.dispatchEvent(new Event('storage'))
}
