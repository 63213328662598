import { Accordion, AccordionSummary, styled as muiStyled } from '@mui/material'

export const FAQAccordion = muiStyled(Accordion)(
  ({ theme, fullWidth }) => `
          width: 100%;
          marginLeft: auto;
          marginRight: auto;
          border-radius: 7px !important;
          border: 1px solid #dbdbdb;
          box-shadow: 0px 0px 7px 0px #8a8a8a2e;
          transition: background 250ms ${theme.transitions.easing.quickInOut};
          &:hover {
            background: #f5f5f5;
          }
          &:before {
            display: none;
          }
          
  `,
)

export const FAQAccordionSummary = muiStyled(AccordionSummary)(
  ({ theme, fullWidth, isExpandingLocked }) => `
    font-weight: 600;
  `,
)
