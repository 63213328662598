import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  oneOnOneMentorsData: null,
  categoriesList: null,
  tagsList: null,
}

const genericDataSlice = createSlice({
  name: 'genericDataStore',
  initialState: initialState,
  reducers: {
    setOneOnOneMentorsData(state, action) {
      return { ...state, oneOnOneMentorsData: action.payload }
    },
    setCategoriesList(state, action) {
      console.log({ action })

      return { ...state, categoriesList: action.payload }
    },
    setTagsList(state, action) {
      return { ...state, tagsList: action.payload }
    },
  },
})

export const { setOneOnOneMentorsData, setCategoriesList, setTagsList } = genericDataSlice.actions
export const genericDataReducer = genericDataSlice.reducer

function getGenericDataStore(state) {
  return state.genericDataStore
}

export const selectOneOnOneMentorsData = (state) => {
  return getGenericDataStore(state).oneOnOneMentorsData
}
export const selectCategoriesList = (state) => {
  return getGenericDataStore(state).categoriesList
}
export const selectTagsList = (state) => {
  return getGenericDataStore(state).tagsList
}
