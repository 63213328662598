import axios from 'axios'
import { apiURL, commonHeadersWeb } from 'src/consumer/services'

export const getFaq = async (searchString, params = {}) => {
  try {
    const response = await axios.get(`${apiURL}/api/faq/response?categoryIds=${searchString}`, {
      headers: {
        ...commonHeadersWeb,
        'ef-user-country-code': localStorage.getItem('countryCode'),
        'ef-app-language': sessionStorage.getItem('languageCode'),
      },
      params,
    })
    return response
  } catch (error) {
    console.log(error)
    return error
  }
}

export async function getFaqScopeCategories(scopeIds = [], params = {}) {
  const scopeIdsString = scopeIds.join(',')
  // const scopeIdsString = scopeIds?.length === 1 ? `${scopeIds?.[0]},` : scopeIds.join(',')

  try {
    const response = await axios.get(
      `${apiURL}/api/faq-scope/response?faqScopeIds=${scopeIdsString}`,
      {
        headers: {
          ...commonHeadersWeb,
          'ef-user-country-code': localStorage.getItem('countryCode'),
          'ef-app-language': sessionStorage.getItem('languageCode'),
        },
        params,
      },
    )
    return response
  } catch (error) {
    console.log(error)
    return error
  }
}
