import React, { useContext, useState } from 'react'
import {
  FAQDrawer,
  SPLPCardActionsNextAvailableSlot,
  SPLPCardButton,
  SPLPCardButtonsContainer,
  SPLPCardExpertiseSection,
  SPLPCardName,
  SPLPCardParent,
  SPLPCardParentActionsContainer,
  SPLPCardParentPersonalDetails,
  SPLPCardParentProfessionalDetails,
  SPLPCardParentRecommendedTag,
  SPLPCardPersonalDetailsContent,
  SPLPCardPicture,
  SPLPCardProviderParent,
  SPLPDetailProfessionalInPersonal,
  SPLPDetailText,
  SPLPNextAvailableSlotTime,
  SPLPScrollableExpertise,
  SPLPScrollableItem,
  SPLPSkeletonsContainer,
  SPLPTLFButton,
  SPLPTLFParentButtonContainer,
  SPLPTopLevelFilterParent,
  SPLPTopLevelFilterParentActionText,
  SPLPTopLevelFilterParentTextHeading,
  SPLPTopLevelFilterParentTextHeadingH1,
} from './ServiceProvidersListingComponentsStyles'
import { useScreenSize } from 'src/customHooks/customHooks'
import useInfiniteScroll from 'react-infinite-scroll-hook'
import { useEffect } from 'react'
import { getPaginatedDataWithAPIString } from 'src/consumer/services/featureServices'
import Skeleton from '@mui/material/Skeleton'
import { getImageLink } from 'src/mentor/helpers/utilityFunctions'
import EventIcon from '@mui/icons-material/Event'
import { UnsecureSetupContext } from 'src/consumer/context/UnsecureSetupContext'
import { getDisplayEventTypesOfMentor } from '../Professionals List Page'
import {
  APP_CONSTANTS,
  FAQ_CATEGORIES,
  currency_list,
  platformOptions,
} from 'src/consumer/constants'
import {
  calculateYearsAndMonthsSince,
  createMomentDate,
} from 'src/mentor/components/MentorProfilePage/components/MentoringDetailsSection'
import FaqSection from '../PrewallAndPaywall/FaqSection'
import CloseIcon from '@mui/icons-material/Close'
import { FAQ_LAYOUTS } from '../PrewallAndPaywall/faqComponents/utils'

// SPLP = Service Provider Listing Page

// 1. top level filter component to change calls APIs addresses
export const SPLPTopLevelFilterComponent = ({
  selectedTabId,
  setSelectedTabId,
  options,
  primaryHeading,
  actionText,
  actionTextAnchor,
  actionTextFaqDomain,
  onSelectedTabChange,
}) => {
  const [faqOpen, setFaqOpen] = useState(false)

  return (
    <SPLPTopLevelFilterParent>
      <SPLPTopLevelFilterParentTextHeadingH1 primary>
        {primaryHeading}
      </SPLPTopLevelFilterParentTextHeadingH1>
      <SPLPTLFParentButtonContainer number={options?.length}>
        {options.map((tabItem, tabItemIndex) => (
          <SPLPTLFButton
            key={tabItem.id}
            onClick={() => {
              if (onSelectedTabChange) {
                onSelectedTabChange(tabItem.id)
                setSelectedTabId(tabItem.id)
              } else {
                setSelectedTabId(tabItem.id)
              }
            }}
            selected={selectedTabId === tabItem.id}
            isLastItem={tabItemIndex === options?.length - 1}
          >
            {tabItem.label}
          </SPLPTLFButton>
        ))}
      </SPLPTLFParentButtonContainer>
      <SPLPTopLevelFilterParentTextHeading>
        {options?.find((item) => item?.id === selectedTabId)?.helpText}
      </SPLPTopLevelFilterParentTextHeading>
      <SPLPTopLevelFilterParentActionText>
        <div
          onClick={() => {
            if (actionTextAnchor === 'faq-modal-open') {
              setFaqOpen(true)
            } else {
              return null
            }
          }}
        >
          {actionText}
        </div>
        <AppFAQModalComponent
          // categories={[actionTextFaqDomain]}
          categories={[FAQ_CATEGORIES.therapist_booking_page_faqs]}
          open={faqOpen}
          onClose={() => setFaqOpen(false)}
        />
      </SPLPTopLevelFilterParentActionText>
    </SPLPTopLevelFilterParent>
  )
}

// 2. card provider which calls APIs itself level filtering component along with placeholder
export const SPLPCardsProviderComponent = ({
  apiCall,
  twoColumnDesign = true,
  plansData,
  fromBusiness,
  showPriceFromPlansData = false,
}) => {
  const [dataLoading, setDataLoading] = useState(false)
  const [itemsList, setItemsList] = useState()
  const [pageNumber, setPageNumber] = useState(1)
  const [hasNextPage, setHasNextPage] = useState(true)

  async function pageSetup() {
    setDataLoading(true)
    setItemsList([])
    const resp = await getPaginatedDataWithAPIString(apiCall, true)
    if (resp?.status === 200) {
      setItemsList(resp?.data)
    }
    setDataLoading(false)
  }

  async function getNextMentorPage() {
    const nextPageNumber = pageNumber + 1
    setDataLoading(true)
    const resp = await getPaginatedDataWithAPIString(apiCall, true, nextPageNumber)
    setDataLoading(false)

    if (resp?.status === 200) {
      if (resp?.data && resp?.data?.length > 0) {
        setPageNumber(nextPageNumber)
        setItemsList((prev) => [...prev, ...resp?.data])
      } else {
        // If there's no more data to load, set hasNextPage to false
        setHasNextPage(false)
      }
    } else {
      setHasNextPage(false)
    }
  }

  const [sentryRef] = useInfiniteScroll({
    hasNextPage,
    onLoadMore: getNextMentorPage,
    rootMargin: '0px 0px 20px 0px',
  })

  useEffect(() => {
    pageSetup()
  }, [apiCall])

  return (
    <>
      <SPLPCardProviderParent twoColumnDesign={twoColumnDesign}>
        {itemsList?.map((item, index) => (
          <SPLPCardComponent
            item={item}
            key={index}
            twoColumnDesign={twoColumnDesign}
            plansData={plansData}
            showPriceFromPlansData={showPriceFromPlansData}
            fromBusiness={fromBusiness}
          />
        ))}
        {dataLoading && <SPLPListingSkeleton twoColumnDesign={twoColumnDesign} />}
      </SPLPCardProviderParent>
    </>
  )
}

export const SPLPCardComponent = ({ item, twoColumnDesign, plansData, showPriceFromPlansData, fromBusiness }) => {
  const { unSecureSetupData } = useContext(UnsecureSetupContext)
  const languagesArrayData = getArrayOfLanguagesOfMentor(item, unSecureSetupData)

  const displayEventTypes = getDisplayEventTypesOfMentor(item)
  let arrayOfPlatforms = []
  const offlineEventLocation = displayEventTypes?.find(
    (item) => item?.meetingSrc === 'offline',
  )?.location

  displayEventTypes?.forEach((item) => {
    arrayOfPlatforms.push(item?.meetingSrc)
  })
  arrayOfPlatforms = arrayOfPlatforms.filter(
    (item, index) => arrayOfPlatforms.indexOf(item) === index,
  )

  const yearsOfExperience = getYearsOfExperienceFromProfile(item)

  const startingPriceObject = getSessionsStartingPrice(
    getDisplayEventTypesOfMentor(item),
    plansData,
  )

  const discoveryCallEvent = displayEventTypes?.find((item) => item?.free)

  console.log(displayEventTypes)

  return (
    <SPLPCardParent twoColumnDesign={twoColumnDesign}>
      {item?.favorite && (
        <SPLPCardParentRecommendedTag>
          <svg
            stroke="currentColor"
            fill="currentColor"
            strokeWidth="0"
            viewBox="0 0 576 512"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z"></path>
          </svg>
          RECOMMENDED
        </SPLPCardParentRecommendedTag>
      )}

      <SPLPCardParentPersonalDetails>
        <SPLPCardPicture twoColumnDesign={twoColumnDesign} src={getImageLink(item?.profilePicId)} alt='mentorProfilePic' />

        <SPLPCardPersonalDetailsContent>
          <SPLPCardName>{item?.name}</SPLPCardName>
          <SPLPDetailText>
            {yearsOfExperience >= 2 && `${yearsOfExperience}+ years of experience`}
          </SPLPDetailText>
          <SPLPDetailText>
            {showPriceFromPlansData && startingPriceObject?.price > 0 && !Boolean(fromBusiness) && (
              <>
                Starts{' '}
                <b>{` @ ${
                  currency_list?.find((item) => item.code === startingPriceObject?.currency)?.symbol
                }${startingPriceObject?.price} for ${startingPriceObject?.duration} mins`}</b>
              </>
            )}
          </SPLPDetailText>
          <SPLPDetailProfessionalInPersonal>
            <ProfessionalDetailsComponent
              arrayOfPlatforms={arrayOfPlatforms}
              languagesArrayData={languagesArrayData}
              focusAreas={item?.personalize?.focusAreas}
              offlineEventLocation={offlineEventLocation}
            />
          </SPLPDetailProfessionalInPersonal>
        </SPLPCardPersonalDetailsContent>
      </SPLPCardParentPersonalDetails>

      <SPLPCardParentProfessionalDetails>
        <ProfessionalDetailsComponent
          arrayOfPlatforms={arrayOfPlatforms}
          languagesArrayData={languagesArrayData}
          offlineEventLocation={offlineEventLocation}
        />
        {displayEventTypes.length > 0 && <NextAvailableComponent singleRow />}
      </SPLPCardParentProfessionalDetails>

      {displayEventTypes.length > 0 && (
        <SPLPCardParentActionsContainer>
          {/* {displayEventTypes.length > 0 && <NextAvailableComponent />} */}
          <SPLPCardButtonsContainer>
            <SPLPCardButton to={`/mentor/${item?.slug}/events`}>Explore Sessions</SPLPCardButton>
            <SPLPCardButton
              to={`/mentor/${item?.slug}/events/${
                displayEventTypes?.find((item) => !item?.free)?.uid
              }`}
              desiredClick
            >
              Book
            </SPLPCardButton>
          </SPLPCardButtonsContainer>
          {discoveryCallEvent && (
            <SPLPCardButton
              takeFullWidthOnSmallScreen={true}
              to={`/mentor/${item?.slug}/events/${discoveryCallEvent?.uid}`}
            >
              Free Discovery Call
            </SPLPCardButton>
          )}
        </SPLPCardParentActionsContainer>
      )}
    </SPLPCardParent>
  )
}

const NextAvailableComponent = ({ singleRow }) => {
  return (
    <></>
    // <SPLPCardActionsNextAvailableSlot singleRow={singleRow}>
    //   <div>Next Available Slot:</div>
    //   <div style={{ display: 'flex', alignItems: 'center', gap: '3px' }}>
    //     <EventIcon sx={{ fontSize: 14, m: 0, p: 0, minWidth: 0 }} />
    //     <SPLPNextAvailableSlotTime>11 Dec 2023</SPLPNextAvailableSlotTime>
    //   </div>
    // </SPLPCardActionsNextAvailableSlot>
  )
}

const ProfessionalDetailsComponent = ({
  arrayOfPlatforms,
  languagesArrayData,
  focusAreas,
  offlineEventLocation,
}) => {
  return (
    <>
      {Array.isArray(focusAreas) && focusAreas.length > 0 && (
        <SPLPCardExpertiseSection>
          Expertise:
          <SPLPScrollableExpertise>
            {focusAreas.map((item, index) => (
              <SPLPScrollableItem key={index}>{item}</SPLPScrollableItem>
            ))}
          </SPLPScrollableExpertise>
        </SPLPCardExpertiseSection>
      )}
      <SPLPDetailText>
        {languagesArrayData?.length > 0 && 'Speaks: '}
        {languagesArrayData?.join(', ')}
      </SPLPDetailText>
      {arrayOfPlatforms?.length > 0 && (
        <SPLPDetailText>
          Session Mode:{' '}
          <b>
            {arrayOfPlatforms
              ?.map((item) => platformOptions.find((platform) => platform.id === item)?.name)
              ?.join(', ')}
            {offlineEventLocation?.city && ` (${offlineEventLocation?.city})`}
          </b>
        </SPLPDetailText>
      )}
    </>
  )
}

const SPLPListingSkeleton = ({ twoColumnDesign }) => {
  return (
    <SPLPSkeletonsContainer>
      <Skeleton variant="rounded" width={120} height={120} animation="pulse" />
      <Skeleton animation="pulse" />
      <Skeleton animation="pulse" />
      <Skeleton animation="pulse" />
      <Skeleton animation="pulse" />
    </SPLPSkeletonsContainer>
  )
}

function getSessionsStartingPrice(eventTypes, plansData) {
  let sessionStartingPrice = { currency: 'inr', price: 0, duration: 0 }
  if (Array.isArray(eventTypes)) {
    eventTypes?.forEach((eventType) => {
      if (!eventType.free && eventType?.subscriptionPlanId) {
        const thisPlanData = plansData?.find((plan) => plan?.id === eventType?.subscriptionPlanId)
        if (thisPlanData?.finalPrice) {
          if (sessionStartingPrice?.price === 0) {
            sessionStartingPrice = {
              currency: thisPlanData?.currency,
              price: thisPlanData?.finalPrice,
              duration: eventType?.durationInMins,
            }
          } else if (thisPlanData?.finalPrice < sessionStartingPrice?.price) {
            sessionStartingPrice = {
              currency: thisPlanData?.currency,
              price: thisPlanData?.finalPrice,
              duration: eventType?.durationInMins,
            }
          }
        }
      }
    })
    return sessionStartingPrice
  } else {
    return null
  }
}

export const AppFAQModalComponent = ({ categories, open, onClose }) => {
  return (
    <FAQDrawer
      className="drawer"
      anchor="bottom"
      open={open}
      transitionDuration={{ enter: 600, exit: 300 }}
    >
      <CloseIcon
        onClick={onClose}
        style={{ cursor: 'pointer', position: 'absolute', top: '20px', right: '20px' }}
      />
      <FaqSection categoryIds={categories} fullWidth={true} layoutType={FAQ_LAYOUTS.TWO_COLUMN_SIDEBAR} />
    </FAQDrawer>
  )
}

export function getYearsOfExperienceFromProfile(profileData) {
  let yearsOfExperience = 0
  if (
    profileData?.personalize?.experienceStartYear &&
    profileData?.personalize?.experienceStartYear
  ) {
    yearsOfExperience = calculateYearsAndMonthsSince(
      createMomentDate(
        profileData?.personalize?.experienceStartMonth,
        profileData?.personalize?.experienceStartYear,
      ),
    ).years
  }
  return yearsOfExperience
}

export function getArrayOfLanguagesOfMentor(profile, unSecureSetupData) {
  return profile?.languages?.map((item) => {
    return unSecureSetupData?.appLanguages?.find((language) => language?.id === item)?.language
  })
}
