import React, { useState, useEffect, useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import {
  PAFALink,
  PAFBrandLogo,
  PAFClickableText,
  PAFCompanyNameSpan,
  PAFHeading,
  PAFLevelFiveContainer,
  PAFLevelFourContainer,
  PAFLevelOneContainer,
  PAFLevelThreeContainer,
  PAFLevelTwoContainer,
  PAFSectionOne,
  PAFSectionThree,
  PAFSectionThree1,
  PAFSectionTwo,
  PAFSectionTwo1,
  PAFSectionTwo2,
  PAFSectionTwo3,
  PAFSectionTwoItemsContainer,
} from './PublicAppFooterStyles'
import { APP_CONSTANTS, APP_FOOTER_DATA } from 'src/consumer/constants'
import InstagramIcon from '@mui/icons-material/Instagram'
import XIcon from '@mui/icons-material/X'
import LinkedInIcon from '@mui/icons-material/LinkedIn'

export const PublicAppFooter = () => {
  const navigate = useNavigate()

  return (
    <PAFLevelOneContainer>
      <PAFLevelTwoContainer>
        <PAFLevelThreeContainer>
          <PAFLevelFourContainer>
            <PAFLevelFiveContainer>
              <PAFSectionOne>
                <PAFBrandLogo
                  onClick={() => navigate('/')}
                  src={require('src/assets/logo_brand.png')} alt='aumhumLogo'
                />
              </PAFSectionOne>
              <PAFSectionTwo>
                <PAFSectionTwo1>
                  <PAFHeading>{APP_FOOTER_DATA.sectionOne.heading}</PAFHeading>
                  <PAFSectionTwoItemsContainer>
                    {APP_FOOTER_DATA.sectionOne.links.map((item, index) => {
                      if (item.type === 'relative') {
                        return (
                          <PAFClickableText key={index} to={item.link}>
                            {item.name}
                          </PAFClickableText>
                        )
                      } else {
                        return (
                          <PAFALink key={index} href={item.link}>
                            {item.name}
                          </PAFALink>
                        )
                      }
                    })}
                  </PAFSectionTwoItemsContainer>
                </PAFSectionTwo1>
                <PAFSectionTwo2>
                  <PAFHeading>{APP_FOOTER_DATA.sectionTwo.heading}</PAFHeading>
                  <PAFSectionTwoItemsContainer>
                    {APP_FOOTER_DATA.sectionTwo.links.map((item, index) => {
                      if (item.type === 'relative') {
                        return (
                          <PAFClickableText key={index} to={item.link}>
                            {item.name}
                          </PAFClickableText>
                        )
                      } else {
                        return (
                          <PAFALink key={index} href={item.link}>
                            {item.name}
                          </PAFALink>
                        )
                      }
                    })}
                  </PAFSectionTwoItemsContainer>
                </PAFSectionTwo2>
                <PAFSectionTwo3>
                  <PAFHeading>{APP_FOOTER_DATA.sectionThree.heading}</PAFHeading>
                  <PAFSectionTwoItemsContainer>
                    {APP_FOOTER_DATA.sectionThree.links.map((item, index) => {
                      if (item.type === 'relative') {
                        return (
                          <PAFClickableText key={index} to={item.link}>
                            {item.name}
                          </PAFClickableText>
                        )
                      } else {
                        return (
                          <PAFALink key={index} href={item.link}>
                            {item.name}
                          </PAFALink>
                        )
                      }
                    })}
                  </PAFSectionTwoItemsContainer>
                </PAFSectionTwo3>
              </PAFSectionTwo>
              <PAFSectionThree>
                <PAFSectionThree1>
                  <PAFBrandLogo
                    onClick={() => navigate('/')}
                    src={require('src/assets/logo_brand.png')}alt='aumhumLogo'
                  />
                </PAFSectionThree1>

                {/* Company Name, Terms, Conditions */}
                <p style={{ display: 'flex', flexWrap: 'wrap', gap: '0.75rem' }}>
                  <PAFCompanyNameSpan>© 2024 AUMHUM Pvt Ltd.</PAFCompanyNameSpan>
                  <a
                    style={{ textDecoration: 'none', color: 'inherit' }}
                    href={APP_CONSTANTS.APP_TERMS_URL}
                  >
                    Terms
                  </a>
                  <a
                    style={{ textDecoration: 'none', color: 'inherit' }}
                    href={APP_CONSTANTS.APP_PRIVACY_POLICY_URL}
                  >
                    Privacy
                  </a>
                </p>

                {/* Social Media */}
                <p style={{ display: 'flex', gap: '1rem' }}>
                  <a
                    href="https://www.instagram.com/aumhum.app/"
                    style={{ textDecoration: 'none', color: 'inherit' }}
                  >
                    <InstagramIcon
                      sx={{ '&:hover': { color: '#f14e46' } }}
                      style={{ cursor: 'pointer' }}
                    />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/aumhum"
                    style={{ textDecoration: 'none', color: 'inherit' }}
                  >
                    <LinkedInIcon
                      sx={{ '&:hover': { color: '#1558d6' } }}
                      style={{ cursor: 'pointer' }}
                    />
                  </a>
                  <a
                    href="https://x.com/aumhums"
                    style={{ textDecoration: 'none', color: 'inherit' }}
                  >
                    <XIcon sx={{ '&:hover': { color: '#000' } }} style={{ cursor: 'pointer' }} />
                  </a>
                </p>
              </PAFSectionThree>
            </PAFLevelFiveContainer>
          </PAFLevelFourContainer>
        </PAFLevelThreeContainer>
      </PAFLevelTwoContainer>
    </PAFLevelOneContainer>
  )
}
