import { ButtonBase, FormControlLabel, IconButton, styled as muiStyled } from '@mui/material'

export const PrescriptionTableViewInputFormControlLabel = muiStyled(FormControlLabel)(
  ({ theme }) => `
    .MuiTypography-root{
    font-weight: 600;
    }
`,
)

function getBackgroundColor({ theme, selected, highlighted }) {
  if (highlighted) {
    return '#003a89'
  }
  if (selected) {
    return '#246797'
  }
  return '#d7d7d7'
}

export const PrescriptionPillViewInputPillContainer = muiStyled(ButtonBase)(
  ({ theme, selected, highlighted }) => `
    display: flex;
    gap: 2px;
    text-align: left;
    font-weight: ${selected ? '600' : '400'};
    background-color: ${getBackgroundColor({ theme, selected, highlighted })};
    color: ${selected ? 'white' : 'black'};
    align-items: center;
    padding: 7px 30px;
    border-radius: 66px;
    gap: 18px;
`,
)

export const PrescriptionRemoveIconButton = muiStyled(IconButton)(
  ({ theme }) => `
    color: #ff9797;
    border: 2px solid;
    padding: 0px;
    &:hover {
      background-color: ${theme.palette.action.hover};
    }
`,
)

export const PrescriptionPillViewInputNewPillContainer = muiStyled(ButtonBase)(
  ({ theme }) => `
    display: flex;
    gap: 2px;
    font-weight: 600;
    color: #246797;
    border: 1px solid #246797;
    align-items: center;
    padding: 7px 30px;
    border-radius: 66px;
    gap: 18px;
`,
)

export const DoconContextualForm = muiStyled('div')(
  ({ theme, side, open, slideTransitionTiming = 200 }) => `
    z-index: 2;
    position:absolute;
    background: white;
    top: 0px;
    height:100%;
    width: ${open ? '50%' : '0px'};
    ${side === 'left' ? 'left:0px' : 'right: 0px'};
    transition: width ${slideTransitionTiming}ms ${theme.transitions.easing.quickInOut};
    // transform: ${open ? 'translateX(0)' : `translateX(${side === 'left' ? '-100%' : '100%'})`};
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    display: flex;
    flex-direction: column;
  `,
)

export const TableHeadingContainer = muiStyled('div')(
  ({ theme }) => `
  position: sticky;
  top: 129px;
    z-index: 1;
    background: white;
    padding: 10px 10px;
    box-shadow: 0 3px 17px -7px #000;
    border-radius: 10px;
    margin-bottom: 20px;
`,
)
