import React from 'react'

export default function PdfReportQuestionAnswer({ question }) {
  return (
    <div>
      <div style={{ fontWeight: '500' }}>{question?.questionText}</div>
      <div style={{ whiteSpace: 'pre-wrap' }}>{question?.answer?.response}</div>
      <div style={{ whiteSpace: 'pre-wrap' }}>{question?.answer?.additionalResponse}</div>
    </div>
  )
}
