import axios from 'axios'
import { apiURL, commonHeadersWeb, platformWeb } from './index.js'

export async function getData(url) {
  const token = localStorage.getItem('token')
  const countryCode = localStorage.getItem('countryCode')
  const languageCode = sessionStorage.getItem('languageCode')
  try {
    const res = await axios.get(
      `${apiURL}${url}?languageCode=${languageCode}&platform=${platformWeb}&countryCode=${countryCode}`,
      {
        headers: {
          ...(token && { Authorization: `Bearer ${token}` }),
          ...commonHeadersWeb,
          'ef-user-country-code': localStorage.getItem('countryCode'),
          'ef-app-language': sessionStorage.getItem('languageCode'),
        },
      },
    )
    return res
  } catch (err) {
    console.log(err)
    return err
  }
}

export async function getDataWithParams(url, apiParams) {
  const token = localStorage.getItem('token')
  const countryCode = localStorage.getItem('countryCode')
  const languageCode = sessionStorage.getItem('languageCode')
  try {
    const res = await axios.get(`${apiURL}${url}`, {
      headers: {
        ...(token && { Authorization: `Bearer ${token}` }),
        ...commonHeadersWeb,
        'ef-user-country-code': localStorage.getItem('countryCode'),
        'ef-app-language': sessionStorage.getItem('languageCode'),
      },
      params: {
        languageCode: languageCode,
        platform: platformWeb,
        countryCode: countryCode,
        ...apiParams,
      },
    })
    return res
  } catch (err) {
    console.log(err)
    return err
  }
}

export async function postData(url, data) {
  const token = localStorage.getItem('token')
  const countryCode = localStorage.getItem('countryCode')
  const languageCode = sessionStorage.getItem('languageCode')
  try {
    // const os = `${platform.os.family}`.toLowerCase()
    const res = await axios.post(
      `${apiURL}${url}?languageCode=${languageCode}&platform=${platformWeb}&countryCode=${countryCode}`,
      data,
      {
        headers: {
          ...(token && { Authorization: `Bearer ${token}` }),
          ...commonHeadersWeb,
          'ef-user-country-code': localStorage.getItem('countryCode'),
          'ef-app-language': sessionStorage.getItem('languageCode'),
        },
      },
    )
    return res
  } catch (err) {
    console.log(err)
    return err
  }
}

export async function postDataWithParams(url, data, params) {
  const token = localStorage.getItem('token')
  const countryCode = localStorage.getItem('countryCode')
  const languageCode = sessionStorage.getItem('languageCode')
  try {
    // const os = `${platform.os.family}`.toLowerCase()
    const res = await axios.post(`${apiURL}${url}`, data, {
      headers: {
        ...(token && { Authorization: `Bearer ${token}` }),
        ...commonHeadersWeb,
        'ef-user-country-code': localStorage.getItem('countryCode'),
        'ef-app-language': sessionStorage.getItem('languageCode'),
      },
      params: {
        languageCode: languageCode,
        platform: platformWeb,
        countryCode: countryCode,
        ...params,
      },
    })
    return res
  } catch (err) {
    console.log(err)
    return err
  }
}

export async function putData(url, data, customParams) {
  const token = localStorage.getItem('token')
  const countryCode = localStorage.getItem('countryCode')
  const languageCode = sessionStorage.getItem('languageCode')
  try {
    // const os = `${platform.os.family}`.toLowerCase()
    const res = await axios.put(
      `${apiURL}${url}?languageCode=${languageCode}&platform=${platformWeb}&countryCode=${countryCode}${customParams}`,
      data,
      {
        headers: {
          ...(token && { Authorization: `Bearer ${token}` }),
          ...commonHeadersWeb,
          'ef-user-country-code': localStorage.getItem('countryCode'),
          'ef-app-language': sessionStorage.getItem('languageCode'),
        },
      },
    )
    return res
  } catch (err) {
    console.log(err)
    return err
  }
}
