import moment from 'moment'
import { WEBSITE_VIEW_TYPES } from 'src/consumer/constants'
import MyLiveSessionsPage, { BookANewSessionPage } from '.'

export const bookedPageViewTypes = {
  booked_sessions: 'booked-sessions',
  new: 'new',
}

export const LiveSessionPageViews = {
  [bookedPageViewTypes.booked_sessions]: MyLiveSessionsPage,
  [bookedPageViewTypes.new]: BookANewSessionPage,
}

export const pageViewUrlTypesValidator = {
  [bookedPageViewTypes?.booked_sessions]: bookedPageViewTypes?.booked_sessions,
  [bookedPageViewTypes?.new]: bookedPageViewTypes?.new,
}

export const prefixedLinkForNavigates = {
  [WEBSITE_VIEW_TYPES.default]: `/my-live-sessions`,
  [WEBSITE_VIEW_TYPES.webview]: `/webview/my-live-sessions`,
}

export const checkIfEventUpcoming = (item, currentTime) =>
  moment?.utc(item?.endTime)?.local()?.isAfter(moment?.(currentTime))

export const canEventBeJoined = (item, currentTime) =>
  moment?.utc(item?.startTime)?.diff(moment?.(currentTime), 'seconds') < 1800

export const checkIfAllowedToJoinAllDay = (item) => {
  const eventStartMoment = moment(item?.startTime)
  if (
    (item?.meetingSrc === 'offline' || item?.clinicId) &&
    eventStartMoment.isSame(moment(), 'day') &&
    !item?.markedCompleted
  ) {
    return true
  }
  return false
}

export function getMyRoleForThisEvent(bookedEventData, userProfileData) {
  let toReturn
  if (bookedEventData?.userId === userProfileData?.userId) {
    toReturn = 'user'
  } else if (bookedEventData?.mentorId === userProfileData?.teacher?.id) {
    toReturn = 'mentor'
  } else if (bookedEventData?.panelIds?.includes(userProfileData?.teacher?.id)) {
    toReturn = 'panelist'
  }
  return toReturn
}

export function deviceIdToSinkId(deviceId) {
  if (deviceId === 'default') return ''
  if (deviceId === 'silent') return { type: 'none' }
  return deviceId
}
