import { ExpandMore } from '@mui/icons-material'
import { Box, ButtonBase, Chip, Fab, Grid, Paper, styled as muiStyled } from '@mui/material'

export function getPreviewReorderCardColor(
  theme,
  selected,
  disabled,
  invalid,
  defaultColor,
  overrideColor,
) {
  if (overrideColor) {
    return overrideColor
  }
  if (theme.palette.mode === 'dark') {
    if (selected) {
      if (invalid) {
        return 'rgb(194 1 1)'
      }
      if (disabled) {
        return '#393a43'
      } else {
        return defaultColor || 'rgb(163 127 234 / 17%)'
      }
    } else {
      if (invalid) {
        return 'rgb(105 17 17)'
      }
      if (disabled) {
        return '#232329'
      }
      return defaultColor || 'rgba(var(--cui-body-bg-rgb))'
    }
  } else {
    if (selected) {
      if (invalid) {
        return 'rgb(255 128 128)'
      }
      return disabled ? '#141414' : '#483866'
    } else {
      if (invalid) {
        return 'rgb(255 188 188)'
      }
      return disabled ? '#141414' : '#483866'
    }
  }
}

export function hoverPreviewOrderCardColor(theme, selected, disabled, invalid) {
  if (!selected) {
    if (theme.palette.mode === 'dark') {
      if (invalid) {
        return theme.palette.error.light
      }
      return 'rgb(163 127 234 / 17%)'
    } else {
      if (invalid) {
        return theme.palette.error.dark
      }
      if (disabled) {
        return '#373737'
      } else {
        return '#3d2e58'
      }
    }
  } else {
    return ''
  }
}

export const StyledMainHeadingCustomPlan = muiStyled('div')`
    font-size: 35px;
    font-weight: 600;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
`
export const StyledMainSubHeadingCustomPlan = muiStyled('div')`
    font-size: 18px;
    font-weight: 400;
`
export const StyledCustomPlanFormGridContainer = muiStyled(Grid)`
 padding: 24px 0px;
 margin-top: 0px;
`
export const PhaseDataContainer = muiStyled('div')(
  ({ theme, isDragging }) => `
  padding-top: 10px;
  transition: all 150ms ease-in-out;
`,
)
export const PhaseTitle = muiStyled('div')`
    font-size: 24px;
    font-weight: 600;
`
export const PhaseSectionTitle = muiStyled('div')`
    font-size: 35px;
    font-weight: 600;
`
export const PhaseSectionSubTitle = muiStyled('div')`
    font-size: 15px;
    font-weight: 400;
`
export const PhaseNumberContainer = muiStyled(Chip)`
font-size: 20px;
font-weight:400;
`
export const PhaseTitleContainer = muiStyled('div')`
display: flex;
justify-content: start;
align-items: center;
gap: 15px;
`

export const PhaseDayRangeSubtitle = muiStyled('div')(
  ({ theme }) => `
    display: flex;
    justify-content: flex-start;
    align-items:center;
    gap:12px;
    font-size: 14px;
    color: rgb(0 0 0 / 48%);
    background:#001cff4f;
    border-radius: 50px;
    padding: 5px 10px;
    color: ${theme.palette.text.secondary};
    > strong{
        font-weight: 600;
    }
`,
)

export const StyledContentEditPaper = muiStyled(Paper)`
overflow-y:hidden;
  font-family: Outfit;
    padding: 15px;
    max-width: 768px !important;
    width: 100%;
    border-radius: 7px;
    height: 100%;
    margin: 0px !important;
`

export const CustomPlanSectionContainer = muiStyled('div')`
padding: 10px 0px`

export const PlanPhaseTabsContainer = muiStyled('div')`
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 15px 0px;
    overflow-x: auto;
`
export const PlanPhaseTab = muiStyled(ButtonBase)(
  ({ theme, selected }) => `
padding: 7px 20px;
border-radius: 50px;
color: ${selected ? '#fff' : theme.palette.mode === 'dark' ? '#fff' : '#000'};
background: ${selected ? theme.palette.primary.main : 'transparent'};
transition: all 150ms linear;
flex-shrink: 0;
`,
)

export const PhaseContentReorderPaper = muiStyled(Paper)`
    width: 90vw;
    max-width: 100vw !important;
    height: 90vh;
    padding: 10px;
    border-radius: 10px;

`
export const CollapseIcon = muiStyled(ExpandMore)(
  ({ theme, collapsed, time = '0.4s' }) => `
    transform: ${collapsed ? 'rotate(0deg)' : 'rotate(180deg)'};
        transition: all ${time} cubic-bezier(0.42, 0.03, 0.16, 0.94);
`,
)

export const ActivityContainer = muiStyled('div')(
  ({ theme, main }) => `
    background: ${main ? '#ffffff' : 'transparent'};
    border-radius: 7px;
`,
)

export const PlannedActivitiesTabsContainer = muiStyled('div')(
  ({ theme }) => `
    background: ${theme.palette.mode === 'dark' ? '#000' : '#fff'};
    border-radius: 7px;
    padding: 7px;
    // max-height: 1010px;
    // overflow-y: auto;
    width: 100%;

`,
)

export const PlannedActivityTab = muiStyled('div')(
  ({ theme, selected }) => `
    width: 100%;
    background: ${selected ? theme.palette.primary.main : '#ebebeb'};
    padding: 10px 25px;
    border-radius: 7px;
    color: ${selected ? '#fff' : theme.palette.mode === 'dark' ? '#fff' : '#000'};
`,
)

export const DayWisePlannedActivitySummary = muiStyled('div')(
  ({ theme, selected, disabled, invalid, isDragging }) => `
    background: ${getPreviewReorderCardColor(
      theme,
      selected,
      disabled,
      invalid,
      isDragging ? 'green' : undefined,
    )};
    padding: 0px 5px 0px 0px;
    width: 100%;
    display: flex;
    justify-content:flex-start;
    align-items: stretch;
    gap:15px;
    text-align: start;
    cursor: pointer;
    transition: all 150ms linear;
    border-bottom: 1px solid  #0000004a;
    &:hover {
      ${
        !selected &&
        `background: ${hoverPreviewOrderCardColor(theme, selected, disabled, invalid)};`
      }
    }
  `,
)

export const PhaseLegendDiv = muiStyled('div')(
  ({ theme, legendColor }) => `
  position: relative;
    ::before {
      position: absolute;
      content: '';
      height: 5px;
      bottom: -2px;
      background: ${legendColor};
      width: 100%;
    }
`,
)

export const PhaseLegendVerticalDiv = muiStyled('div')(
  ({ theme, legendColor }) => `
  height: inherit;
  width: 7px;
  background: ${legendColor};
  flex-shrink:0;
`,
)

export const PhaseLegendsContainer = muiStyled('div')(
  ({ theme }) => `
position: sticky;
top: 70px;
background:#fafafa;
z-index: 2;
`,
)

export const ActivityPlanListingContainer = muiStyled('div')(
  ({ theme }) => `
  max-width: 1200px;
  padding: 5px 15px 30px 15px;
  margin: 0 auto;
  width: 100%;
  position: relative;
`,
)

export const StyledAPTabContainer = muiStyled(Box)`
  display: flex;
  position: relative;
  justify-content: flex-start;
  overflow-x: auto;
  align-items: center;
  gap: 20px;

  ::-webkit-scrollbar {
    display: none;
  }
`

function getSelectedTabTextColor(theme, selected, invalid) {
  if (theme?.palette?.mode === 'dark') {
    return '#fff'
  }
  if (selected) {
    return '#fff'
  }
  return '#000'
}
function getTabHoverBackgroundFunction(theme, selected, invalid) {
  if (theme.palette.mode === 'dark') {
    if (invalid) {
      return theme.palette.error.light
    }
    if (selected) {
      return '#554277'
    }
    return '#242424'
  } else {
    if (invalid) {
      return theme.palette.error.light
    }
    if (selected) {
      return '#554277'
    } else {
      return '#d6d6d6'
    }
  }
}
function getTabButtonColor(theme, selected, invalid) {
  if (invalid) {
    return theme.palette.error.main
  }
  if (selected) {
    return '#A864FF'
  } else {
    return 'transparent'
  }
}

export const StyledAPTabButton = muiStyled(ButtonBase)(
  ({ theme, selected, showBorder, invalid = false }) => `
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border:${showBorder ? ' 1px solid #0000003b' : 'unset'};
  border-radius: 38px;
  flex-shrink: 0;
  padding: 8px 23px;
  background: ${getTabButtonColor(theme, selected, invalid)};
  color : ${getSelectedTabTextColor(theme, selected, invalid)} !important;
  transition: background 150ms ease-in-out;
  :hover {
    background: ${getTabHoverBackgroundFunction(theme, selected, invalid)};
  }
`,
)

export const StyledCancelEditFAB = muiStyled(Fab)(
  ({ theme }) => `
  background: #7f7f7f;
  &:hover{
    background: #676565;
  }
`,
)

export const StyledActivitySelectorContainer = muiStyled('div')(
  ({ theme }) => `
  width: 100%;
  .__async-paginate-container {
    width: 100%;
  }
`,
)

export const StyledSubscriptionDropDownContainer = muiStyled('div')(
  ({ theme }) => `
  width: 100%;
  .react-select__menu {
    z-index: 3;
  }
`,
)

export const CreationDialogStyledComponent = muiStyled(Paper)(
  ({ theme }) => `
  width: 100%;
`,
)

export const TrackUploadDialogPaper = muiStyled(Paper)(
  ({ theme }) => `
margin: 0!important;
`,
)

export const ActivitySectionContainer = muiStyled('div')(
  ({ theme }) => `
  padding: 7px 25px 25px;
  width: 100%;
  box-shadow: 0px 0px 10px -2px #0000003b;
  border-radius: 7px;
`,
)
