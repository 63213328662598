import styled from "styled-components";


export const FeatureCardContainer = styled.div`
    background: #fff;
    width: 100%;
    padding: 8px;
    border-radius: 16px;
    border: ${props => props?.withBorderAndShadow && '1px solid #cecece'};
    box-shadow: ${props => props?.withBorderAndShadow && 'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px'};
    margin: 10px 0;
`

export const FeatureCardImage = styled.img`
    width: 75px;
    height: 75px;
    border-radius: 16px;
`

export const FeatureCardTitle = styled.div`
    font-weight: 500;
`

export const FeatureCardDescription = styled.div`
    font-weight: 300;
`

export const FeatureCardActionButton = styled.button`
    border-radius: 8px;
    padding: 8px 16px;
    background: #7154B0;
    color: #fff;
    font-weight: 500;
    margin-top: 16px;
    width: 100%;
`