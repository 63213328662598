import {
  ButtonBase,
  Chip,
  DialogContent,
  List,
  ListItem,
  ListItemAvatar,
  Paper,
  styled as muiStyled,
} from '@mui/material'
import GreenFilledTick from 'src/assets/green_filled_tick.png'
import styled from 'styled-components'

export const ActivityPlanBackground = styled.div`
  background: #fff;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  font-family: Outfit;
  display: flex;
  flex-direction: column;
  position: relative;
  padding-bottom: 100px;
  ${
    '' /* @media (min-width: 980px) {
        display: grid;
        grid-template-columns: 40% 60%;
    } */
  }
`

export const ActivityPlanPrimaryWidgetItemsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  @media (min-width: 1080px) {
    margin-bottom: 100px;
  }
`
export const ActivityPlanDescriptionContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`
export const ActivityPlanSubtitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  @media (min-width: 480px) {
    font-size: 20px;
  }
`

// header

export const ActivityPlanHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: fixed;
  top: 0;
  background: #fff;
  box-shadow: 0px 5px 25px 0px #00000040;
  height: 64px;
  padding: 0 20px;
  z-index: 1000;
`

export const APPBackButtonContainer = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 8px;
  background: #fff;
  flex-shrink: 0;
  border: 1px solid #d3d8de;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  &:hover {
    background: #f2f2f2;
  }
`

export const APHeaderTitle = styled.div`
  font-size: 18px;
  font-weight: 700;
  color: #000000;
  @media (min-width: 980px) {
    font-size: 20px;
  }
`

// left wala section

export const ActivityPlanPhasesSection = styled.div`
  margin-top: 64px;
  background: #fff;
  overflow-x: scroll;
  height: 136px;
  flex-shrink: 0;
  display: flex;
  gap: 31px;
  padding-left: 31px;
  padding-right: 31px;
  padding-top: 20px;
  /* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  @media (min-width: 980px) {
    padding-right: 0;
    padding-left: 20px;
    padding-top: 62px;
    padding-bottom: 62px;
    flex-direction: column;
    align-items: flex-end;
    gap: 31px;
    height: calc(100% - 64px);
    overflow-x: unset;
    overflow-y: scroll;
  }
`

export const APPhaseItemContainer = styled.div`
  width: 100%;
  padding: 32px;
  background: ${(props) => (props?.active ? '#224053' : '#E3E3E3')};
  border-radius: 20px 0 0 20px;
  cursor: pointer;
  ${(props) => !props?.active && 'margin-right: 44px;'};
  ${(props) => !props?.active && 'width: calc(100% - 44px);'};
  ${(props) => !props?.active && 'border-radius: 20px;'};
  ${(props) => props?.active && 'padding: 32px 76px 32px 32px;'};
`

export const APPhaseItemTitleAndProgress = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 1280px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`

export const APPhaseItemTitle = styled.div`
  font-size: 22px;
  font-weight: 700;
  color: ${(props) => (props?.active ? '#FFF' : '#5e5e5e')};
`

export const APPhaseItemInfoText = styled.div`
  font-size: 14px;
  font-weight: 300;
  color: ${(props) => (props?.active ? '#ededed' : '#5e5e5e')};
`

export const APPhaseItemCompletedText = styled.div`
  font-size: 14px;
  font-weight: 500;
  width: 130px;
  color: ${(props) => (props?.active ? '#F4CE8A' : '#BF7B00')};
  text-align: left;
  @media (min-width: 1280px) {
    margin-left: 15px;
    text-align: center;
  }
`

export const APPhaseProgressContainer = styled.div`
  border-radius: 15px;
  background: #3e6379;
  height: 8.7px;
  width: 130px;
  flex-shrink: 0;
  overflow: hidden;
  position: relative;
  margin: 15px 0 15px 0;
  @media (min-width: 1280px) {
    margin: 0 0 5px 15px;
  }
`

export const APPhaseProgressFilled = styled.div`
  position: absolute;
  background: #44ff33;
  top: 0;
  left: 0;
  height: 100%;
  width: ${(props) => `${props?.progress}%`};
  transition: all 0.4s ease-in-out;
`

export const APPhaseDescription = styled.div`
  display: flex;
  gap: 20px;
  align-items: flex-start;
  font-size: 14px;
  color: ${(props) => (props?.active ? '#DADADA' : '#7A7A7A')};
  margin-top: 20px;
`

export const APPhaseImage = styled.img`
  width: 60px;
  object-fit: contain;
`

export const APMobilePhaseItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  min-width: 200px;
  padding: 20px;
  flex-shrink: 0;
  background: ${(props) => (props?.active ? '#224053' : '#E3E3E3')};
  border-radius: 20px 20px 0 0;
  cursor: pointer;
`

export const APMobilePhaseItemTitle = styled.div`
  font-size: 18px;
  font-weight: 500;
  color: ${(props) => (props?.active ? '#FFF' : '#5e5e5e')};
`

export const APMobilePhaseDescription = styled.div`
  display: flex;
  gap: 20px;
  align-items: flex-start;
  font-size: 16px;
  color: #dadada;
  margin: 20px 0;
`

// right wala section

export const ActivityPlanPhaseDetailSection = styled.div`
  flex-grow: 1;
  background: #224053;
  padding: 40px 20px;
  @media (min-width: 768px) {
    padding: 40px 45px;
    padding: 54px;
  }
  @media (min-width: 980px) {
    padding: 54px;
  }
`

export const ActivityPlanPhaseDayItemContainer = styled.div`
  width: 100%;
  gap: 50px;
  display: flex;
`

export const APPDayProgressConnectorContainer = styled.div`
  ${'' /* height: 215px; */}
  width: 45px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`

export const APPDayHeading = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  align-items: center;
  background: #fff;
  border-radius: 50px;
  height: 60px;
  width: 60px;
  @media (min-width: 768px) {
    font-size: 18px;
    height: 70px;
    width: 70px;
  }
`

export const APDayHeadingDateAndParticipants = styled.div`
  display: flex;
  align-items: center;
  justify-content: right;
  font-size: 16px;
  font-weight: 400;
  color: #fff;
`

export const APDayFlagContainer = styled.div`
  background: #fff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const APDayThread = styled.div`
  width: 5px;
  height: 100%;
  border-right: 2px ${(props) => (props?.hide ? 'solid' : 'dashed')}
    ${(props) => (props?.hide ? 'transparent' : '#7899AC')};
`

export const APDayCompletionTick = styled.div`
  width: 29px;
  height: 29px;
  flex-shrink: 0;
  border-radius: 50%;
  background: ${(props) => (props?.complete ? `url(${GreenFilledTick})` : '#e6e6e6')};
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
`

export const APPDayItemEventsContainer = styled.div`
  padding: 30px 0;
  display: flex;
  flex-direction: column;
  gap: 18px;
  @media (min-width: 980px) {
    gap: 24px;
    padding: 40px 0;
  }
`

export const APDayItemEventParent = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 4fr;
    grid-template-rows: 1fr;
    align-items: center;
  }
`

export const APDayItemEventTimeContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #5486a3;
  color: #fff;
  border-radius: 10px 10px 0 0;
  margin: 0 10px;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  padding: 10px 10px;
  gap: 20px;
  ${
    '' /* @media (min-width: 768px) {
    height: 85%;
    font-size: 20px;
    justify-content: space-between;
    border-radius: 10px 0 0 10px;   
  } */
  }
  @media (min-width: 768px) {
    margin: 0;
    height: 85%;
    font-size: 20px;
    justify-content: center;
    border-radius: 10px 0 0 10px;
  }
`

export const APDayItemEventDetailsTitle = styled.div`
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  @media (min-width: 980px) {
    font-size: 16px;
  }
`

export const APDayItemEventDetailsSubtitle = styled.div`
  color: #fff;
  font-weight: 400;
  font-size: 12px;
  @media (min-width: 980px) {
    font-size: 14px;
  }
`

export const APDayItemEventDetailsContainer = styled.div`
  background: #94c6e3;
  border-radius: 10px;
  padding: 15px;
  min-height: 100px;
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  @media (min-width: 768px) {
    padding: 25px;
  }
  ${
    '' /* &:hover {
    background: #fff;
    ${APDayItemEventDetailsTitle} {
      color: #68a3c5;
    }
    ${APDayItemEventDetailsSubtitle} {
      color: #68a3c5;
    }
  } */
  }
`

export const APDayItemEventPicture = styled.img`
  object-fit: cover;
  border-radius: 5px;
  right: 2.5px;
  width: 45px;
  height: 45px;
  @media (min-width: 768px) {
    width: 70px;
    height: 70px;
  }
  ${
    '' /* @media (min-width: 980px) {
    width: 70px;
    height: 70px;
    margin: auto;
  } */
  }
`
export const APJoinNowButton = muiStyled(ButtonBase)(
  ({ theme }) => `
  background: #00a9be;
  color: #fff;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 240px;
  height: 54px;
  font-size: 24px;
  font-weight: 600;
  box-shadow: 0 0 10px #00000045;
`,
)

export const APJoinNowButtonContainer = muiStyled('div')(
  ({ theme }) => `
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 45px 0px;
  z-index: 2;
`,
)

export const APGoToUserActivityDialogPaper = muiStyled(Paper)(
  ({ theme }) => `
  padding: 20px;
  border-radius: 20px;
  margin: 8px !important;
`,
)

export const APGoToUserActivityDialogContent = muiStyled(DialogContent)(
  ({ theme }) => `
  font-family: Outfit !important;
display: flex;
flex-direction: column;
justify-content: center;
gap: 40px;
align-items: center
`,
)

export const APGoToUserActivityDialogHeading = muiStyled('div')(
  ({ theme }) => `
  font-size: 20px;
  font-weight: 600;
  text-align:center;
`,
)
export const APGoToUserActivityDialogSubHeading = muiStyled('div')`
font-size: 16px;
text-align: center;
`

export const PlanStartedSuccessIcon = muiStyled('img')(
  ({ theme }) => `
height: 100px;
width: 100px;
`,
)

export const APGoToUserActivityDialogGoBackButton = muiStyled(ButtonBase)`
background: transparent;
border: 1px solid black;
padding: 10px 26px;
color: black;
border-radius: 40px;
`
export const APGoToUserActivityDialogProceedButton = muiStyled(ButtonBase)`
background: #00a9be;
padding: 10px 58px;
color: white;
border-radius: 40px;
`
export const APCategoryCountChipsContainer = muiStyled('div')(
  ({ theme }) => `
display: flex;
gap: 10px;
flex-wrap: wrap;
justify-content: center;
`,
)

export const APCategoryCountContainer = muiStyled(List)(
  ({ theme }) => `
display: flex;
gap: 10px;
flex-wrap: wrap;
text-align: start;
width: 100%;
justify-content: center;
`,
)

export const APCategoryCountListItem = muiStyled(ListItem)(
  ({ theme }) => `
  max-width: unset;
  @media (min-width: 768px) {
max-width: 250px;
}
`,
)

export const APCategoryCountAvatarContainer = muiStyled(ListItemAvatar)(
  ({ theme }) => `
  min-width:40px;
`,
)

export const APCategoryChipDataContainer = muiStyled('div')(
  ({ theme }) =>
    `
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
`,
)

export const APCategoryChipIcon = muiStyled('img')(
  ({ theme }) => `
  width: 20px;
  height: 20px;
  object-fit: cover;
`,
)

export const APDescriptionContainer = muiStyled('div')(
  ({ theme }) => `
    width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 768px;
  text-align: center;
  margin: 0 auto;
  // font-size: 14px;
  align-items: center;
  // @media(min-width: 768px) {
  //   font-size: 16px;
  // }
`,
)

export const APSectionHeading = styled.div`
  text-align: center;
  font-weight: 700;
  font-size: 37px;
  color: #000;
  font-family: Outfit;
  margin: 10px auto 35px auto;
`
export const APSectionContainer = muiStyled('div')(
  ({ theme, noSideMargins }) => `
margin: ${noSideMargins ? '40px 0px 0px 0px' : '40px 25px 0px 25px'};
`,
)

export const APTagChips = muiStyled(Chip)(
  ({ theme }) => `
    background-color: #5486a3; 
    color: #fff;
`,
)

export const ProceedToActivityPlanButtonsContainer = muiStyled('div')(
  ({ theme }) => `
  display:flex;
  justify-content: center;
  align-items: center;
  gap:4px;
  flex-direction: column-reverse;
  @media (min-width: 768px) {
    gap: 16px;
    flex-direction: row;
  }
`,
)
