import { getImageLink } from 'src/mentor/helpers/utilityFunctions'
import { ConversationListHeaderComponent, ConversationListItemComponent } from './chatComponents'
import { giveUnreadCount } from './helperFunctions'
import { useEffect, useState } from 'react'
import { isSameUserId } from '../FloatingIntercom/helperFunctions'

export default function ConversationListChatUI({
  listItems,
  closeFunction,
  choseClickedPersonFunc,
  userPresenceList,
  arraysOfMessages,
  updateUnreadGlobalCountFunc,
}) {
  const [allUsersUnreadCount, setAllUsersUnreadCount] = useState({})

  useEffect(() => {
    const lastMessageSeenOfOtherUsers =
      JSON.parse(localStorage.getItem('lastMessageSeenOfOtherUsers')) || {}
    const tempObj = {}
    Object.keys(arraysOfMessages)?.forEach((user) => {
      tempObj[user] = giveUnreadCount(
        (arraysOfMessages?.[user] || [])?.filter((message) => isSameUserId(message?.from, user)),
        lastMessageSeenOfOtherUsers?.[user],
      )
    })

    setAllUsersUnreadCount(tempObj)
    let sum = Object.values(tempObj).reduce((accumulator, currentValue) => {
      return accumulator + currentValue
    }, 0)
    updateUnreadGlobalCountFunc && updateUnreadGlobalCountFunc(sum)
  }, [arraysOfMessages])

  return (
    <div style={{ height: '100%' }}>
      <ConversationListHeaderComponent closeFunction={closeFunction} />
      <div style={{height: 'calc(100% - 56px)', overflowY: 'scroll'}}>
        {listItems?.length === 0 && 'No Contacts here'}
        {listItems?.map((item, index) => {
          return (
            <ConversationListItemComponent
              key={index}
              clickFunction={(data) => choseClickedPersonFunc(data)}
              chatId={item?.userId}
              name={item?.name}
              imageLink={item?.profilePicId ? getImageLink(item?.profilePicId) : undefined}
              showPresence={true}
              presence={
                userPresenceList?.find((presenceListItem) =>
                  isSameUserId(presenceListItem?.userId, item?.userId),
                )?.online || false
              }
              relativeRole={item?.relativeRole}
              lastMessage={getLastMessageOfThisUser(arraysOfMessages?.[item?.userId])}
              unreadCount={allUsersUnreadCount?.[item?.userId] || 0}
            >
              {item?.name || item?.userId}
            </ConversationListItemComponent>
          )
        })}
      </div>
    </div>
  )
}

function getLastMessageOfThisUser(array) {
  const msgItem = array?.[array?.length - 1]
  if (msgItem?.type === 'txt') {
    return msgItem?.msg || ''
  } else if (msgItem?.type === 'custom') {
    return 'Feature Item'
  } else {
    return ''
  }
}
