export function getSectionsAndQuestionsBasedOnFilledAnswers({ sections, answers }) {
  const validAnswers = answers?.filter((answer) => !!answer.response)
  const sectionsWithValidQuestions = sections?.map((section) => {
    const validQuestions = section?.questions?.filter((question) =>
      validAnswers?.some((answer) => answer?.questionId === question.id),
    )
    return {
      ...section,
      questions: validQuestions,
    }
  })
  return sectionsWithValidQuestions
    ?.filter((section) => section?.questions?.length > 0)
    ?.map((section) => {
      return {
        ...section,
        questions: section?.questions?.map((question) => {
          const answer = validAnswers?.find((answer) => answer?.questionId === question?.id)
          return {
            ...question,
            answer,
          }
        }),
      }
    })
}
