import styled from 'styled-components'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { styled as muiStyled } from '@mui/system'
import { Link } from 'react-router-dom'

export const PAHFinalParent = styled.header`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
`

export const PAHContainer = styled.div`
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 0;
  align-items: center;
  background: #fff;
  font-family: Outfit;
  height: 64px;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 10px 20px -20px;
  padding: 0 12px;
`

export const PAHHeightCompensation = styled.div`
  width: 100%;
  height: 64px;
  flex-shrink: 0;
`
export const PAHLOGOLink = styled(Link)`
  text-decoration: none;
  display: flex;
`

export const PAHLogo = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 12px;
  &:hover {
    cursor: pointer;
  }
`

export const PAHItemsContainerRight = styled.div`
  display: flex;
  position: absolute;
  right: 12px;
  align-items: center;
  justify-content: right;
  height: 100%;
  gap: 12px;
`

// links and buttons:

export const PAHActionButton = styled.button`
  background: #705dbb;
  font-weight: 500;
  text-decoration: none;
  color: #fff;
  transition: all 0.2s ease-in-out;
  &:hover {
    background: #514387;
    color: #fff;
  }
  font-size: 16px;
  padding: 8px 12px;
  border-radius: 10px;
`

export const PAHSecondaryActionButton = styled.button`
  font-size: 12px;
  padding: 4px 6px;
  border-radius: 6px;
  text-decoration: none;
  background: #e0e0e1;
  font-weight: 500;
  color: #000;
  transition: all 0.2s ease-in-out;
  &:hover {
    background: #adadae;
    color: #000;
  }
  @media (min-width: 991px) {
    font-size: 16px;
    padding: 8px 12px;
    border-radius: 10px;
  }
`

export const PAHLinkInHeader = styled(Link)`
  text-decoration: none;
  padding: 5px 10px;
  border-radius: 10px;
  color: #27325a;
  cursor: pointer;
  &:hover {
    color: #27325a;
    background: #f4f6fa;
  }
  ${(props) => props?.hideOnSmall && 'display: none;'};
  @media (min-width: 991px) {
    display: unset;
  }
`

export const PAHTextInHeader = styled.div`
  text-decoration: none;
  padding: 5px 10px;
  border-radius: 10px;
  color: #27325a;
  cursor: pointer;
  &:hover {
    color: #27325a;
    background: #f4f6fa;
  }
  ${(props) => props?.hideOnSmall && 'display: none;'};
  @media (min-width: 991px) {
    display: unset;
  }
`

// overlays and menus:

export const PAHOverlayMenuContainer = styled.div`
  width: 100%;
  position: absolute;
  top: 64px;
  left: 0;
  right: 0;
  transition: ${(props) =>
    props?.fullHeight > 1000 ? 'all 0.5s ease-in-out' : 'all 0.4s ease-in-out'};
  height: ${(props) => (props?.fullHeightMenuOpen ? `${props?.fullHeightValue - 64}px` : '0')};
  background: #fafafa;
  overflow: hidden;
  z-index: 99;
`

export const PAHOverlayMenu = styled.div`
  padding: 30px 16px;
  height: calc(100vh - 64px);
  overflow: scroll;
  @media (min-width: 991px) {
    display: none;
  }
`

export const PAHCaretDownDefault = muiStyled(KeyboardArrowUpIcon)(
  ({ theme, isDown }) => `
    transform: ${isDown ? 'rotate(180deg)' : 'rotate(0deg)'};
    transition: all 0.4s ease-in-out;
    font-size: 20px;
  `,
)

export const PAHMegaDropdownMenu = styled.div`
  background: #fafafa;
  margin-top: 64px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  display: none;
  @media (min-width: 991px) {
    display: ${(props) => (props?.open ? 'flex' : 'none')};
  }
`

export const PAHMegaDropdownContainer = styled.div`
  width: 100%;
  padding: 20px 30px;
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr;
  justify-items: center;
`

export const PAHMegaDropdownText = styled.div`
  font-weight: ${(props) => (props?.main ? '500' : '400')};
  font-size: 12px;
  cursor: ${(props) => (props?.main ? 'default' : 'pointer')};
  text-decoration: none;
  color: #374253;
`

export const PAHMegaDropdownLink = styled(Link)`
  font-weight: ${(props) => (props?.main ? '500' : '400')};
  font-size: 12px;
  text-decoration: none;
  color: #374253;
  &:hover {
    text-decoration: underline;
  }
`
