import { Add } from '@mui/icons-material'
import { Button, Checkbox, FormControlLabel } from '@mui/material'
import _ from 'lodash'
import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { CustomInput, selectTypes } from 'src/consumer/components/helperComponents'
import { PMPSectionHeadingTabView } from '../../PrescriptionMakingPageStyles'
import {
  PrescriptionTableViewInputFormControlLabel,
  TableHeadingContainer,
} from './styledComponents'

export default function PrescriptionTableViewInputComponent({
  title,
  value = [],
  getOptionsResponse = (search, loadedOptions, { page, size }) => [],
  debounceTimeout = 200,
  searchPlaceholder,
  onChange,
  onCreate,
  getOptionValue = (optn) => optn?.value,
  getOptionLabel = (optn) => optn?.label,
  valueType = selectTypes.object,
  additional,
  stickyHeader = false,
  prescriptionViewSettings,
}) {
  const searchInputRef = useRef()
  const [searchString, setSearchString] = useState('')
  const [fetchedOptions, setFetchedOptions] = useState([])
  const [pageDetails, setPageDetails] = useState({ page: 1, hasMore: true })
  const availableOptions = useMemo(() => {
    return fetchedOptions
      ?.filter((optn) => !value?.find((val) => getOptionValue(val) === getOptionValue(optn)))
      .slice(0, 10)
  }, [fetchedOptions, value, getOptionValue])

  async function loadOptions(search, addnl) {
    const optionsResponse = await getOptionsResponse(search.trim(), fetchedOptions, addnl)
    setFetchedOptions(optionsResponse.options)
    setPageDetails((prev) => ({
      ...prev,
      hasMore: optionsResponse?.hasMore,
      page: optionsResponse?.additional?.page,
    }))
    // setFetchedOptions(optionsResponse.data)
  }

  const HeadingStyledComponent = useMemo(() => {
    return stickyHeader ? TableHeadingContainer : Fragment
  }, [stickyHeader])

  async function loadFirstIterationOptions() {
    const optionsResponse = await getOptionsResponse(searchString.trim(), fetchedOptions, {
      ...additional,
      page: 1,
      size: 20,
    })
    setFetchedOptions(optionsResponse.options)
    setPageDetails((prev) => ({
      ...prev,
      hasMore: optionsResponse?.hasMore,
      page: 2,
    }))
  }

  async function loadMoreOptions(currentValue) {
    if (!pageDetails?.hasMore) {
      return
    }
    if (currentValue?.length / 10 < 1) {
      console.log('currentValue', currentValue)
      return
    }

    const optionsResponse = await getOptionsResponse(searchString.trim(), fetchedOptions, {
      ...additional,
      page: currentValue?.length / 10 + 1,
      size: 10,
    })
    setFetchedOptions((prev) => [...prev, ...optionsResponse.options])
    setPageDetails((prev) => ({
      ...prev,
      hasMore: optionsResponse?.hasMore,
      page: optionsResponse?.additional?.page,
    }))
  }

  function onRemoveOption(optn) {
    const newValue = value.filter((val) => getOptionValue(val) !== getOptionValue(optn))
    searchInputRef.current.focus({ preventScroll: true })
    onChange(newValue)
  }

  function onCheckOption(item) {
    var newValue = [...value, item]
    if (searchString?.length > 0) {
      if (!newValue?.length) {
        loadFirstIterationOptions()
      } else {
        if (value.length % 10 === 0) loadMoreOptions(newValue)
      }
    }
    onChange(newValue)
    searchInputRef.current.focus({ preventScroll: true })
  }

  function onCreateOption(inputValue, e) {
    onCreate(inputValue, e)
    setSearchString('')
  }

  function resetSelectedOptions() {
    onChange([])
  }

  const getOptionsFromSearch = useCallback(
    _.debounce((searchInput, addnl) => {
      loadOptions(searchInput, addnl)
    }, debounceTimeout),
    [],
  )

  useEffect(() => {
    getOptionsFromSearch(searchString, additional)
  }, [searchString])

  useEffect(() => {
    loadOptions(searchString, additional)
  }, [additional])

  return (
    <div className="d-flex flex-column w-100 gap-2">
      <HeadingStyledComponent>
        {title && (
          <PMPSectionHeadingTabView>
            {title} {value?.length > 0 ? `(${value?.length})` : ''}
          </PMPSectionHeadingTabView>
        )}
        <SearchRow
          selectedValues={value}
          searchString={searchString}
          setSearchString={setSearchString}
          searchPlaceholder={searchPlaceholder}
          onReset={resetSelectedOptions}
          availableOptions={availableOptions}
          onCreate={onCreateOption}
          searchInputRef={searchInputRef}
        />
      </HeadingStyledComponent>
      <div className="d-flex flex-column w-100">
        {!searchString ? (
          <SelectedOptionsList
            values={value}
            searchString={searchString}
            getOptionLabel={getOptionLabel}
            getOptionValue={getOptionValue}
            onRemoveOption={onRemoveOption}
            prescriptionViewSettings={prescriptionViewSettings}
          />
        ) : (
          <OptionsList
            options={availableOptions}
            getOptionLabel={getOptionLabel}
            getOptionValue={getOptionValue}
            onCheckOption={onCheckOption}
            prescriptionViewSettings={prescriptionViewSettings}
          />
        )}
        {!!searchString ? (
          <SelectedOptionsList
            values={value}
            searchString={searchString}
            getOptionLabel={getOptionLabel}
            getOptionValue={getOptionValue}
            onRemoveOption={onRemoveOption}
            prescriptionViewSettings={prescriptionViewSettings}
          />
        ) : (
          <OptionsList
            options={availableOptions}
            getOptionLabel={getOptionLabel}
            getOptionValue={getOptionValue}
            onCheckOption={onCheckOption}
            prescriptionViewSettings={prescriptionViewSettings}
          />
        )}
      </div>
    </div>
  )
}

function SearchRow({
  selectedValues,
  searchString,
  setSearchString,
  onReset,
  searchPlaceholder,
  onCreate,
  availableOptions,
  searchInputRef,
}) {
  const searchAddButtonRef = useRef()
  const [isTyping, setIsTyping] = useState(false)

  const searchStringIsInOptions = availableOptions?.find(
    (optn) => optn?.label?.toLowerCase() === searchString?.toLowerCase(),
  )

  const showAddBtn = !!searchString && !searchStringIsInOptions && !isTyping

  function handleChangeInputString(e) {
    setSearchString(e.target.value)
    setIsTyping(true)
  }

  useEffect(() => {
    if (isTyping) {
      const timer = setTimeout(() => {
        setIsTyping(false)
      }, 500)

      return () => clearTimeout(timer)
    }
  }, [searchString])

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (
        (e.key === 'Enter' || e.keyCode === 13) &&
        document.activeElement === searchInputRef.current
      ) {
        searchAddButtonRef.current.click()
      }
    }

    var inputElement = searchInputRef?.current
    if (searchInputRef?.current) {
      inputElement.addEventListener('keydown', handleKeyDown)
    }

    return () => {
      inputElement?.removeEventListener('keydown', handleKeyDown)
    }
  }, [searchInputRef])

  return (
    <div className="d-flex align-items-center gap-2">
      <CustomInput
        fadedOutPlaceholder
        inputRef={searchInputRef}
        value={searchString}
        placeholder={searchPlaceholder}
        onChange={handleChangeInputString}
        inputAnsMessageContainerStyles={{
          padding: '2px 10px',
        }}
        showClearInputButton
      />
      {/* <Button
        variant="outlined"
        disabled={!selectedValues || selectedValues?.length === 0}
        sx={{ flexShrink: 0 }}
        onClick={(e) => onReset()}
      >
        Reset Selection
      </Button> */}
      <Button
        ref={searchAddButtonRef}
        variant="outlined"
        endIcon={<Add />}
        onClick={(e) => onCreate(searchString, e)}
        sx={{
          display: !showAddBtn ? 'none' : 'inline-flex',
          flexShrink: 0,
        }}
      >
        Add
      </Button>
    </div>
  )
}

function SelectedOptionsList({
  values = [],
  searchString,
  getOptionValue,
  getOptionLabel,
  onRemoveOption,
  prescriptionViewSettings,
}) {
  // TODO: PUT SUPPORT FOR STRING VALUES TOO HERE

  const getLabel = getOptionLabel

  return (
    <div className="d-flex flex-column">
      {values.map((value, idx) => (
        <div key={`${idx}-${getOptionValue(value)}`}>
          <PrescriptionTableViewInputFormControlLabel
            key={`${idx}-${getOptionValue(value)}`}
            // label={`${getOptionLabel(value)} ${
            //   !!value?.translations?.hi?.title ? `(${value?.translations?.hi?.title})` : ''
            // }`}
            label={getLabel(value)}
            sx={{ width: '100%', fontWeight: 600 }}
            control={<Checkbox checked={true} onChange={() => onRemoveOption(value)} />}
          />
          <hr style={{ margin: '5px auto', width: 'calc(100% - 40px)' }} />
        </div>
      ))}
    </div>
  )
}

function OptionsList({ options = [], getOptionValue, getOptionLabel, onCheckOption }) {
  // TODO: PUT SUPPORT FOR STRING VALUES TOO HERE
  const getLabel = useMemo(() => {
    return getOptionLabel
  }, [getOptionLabel])
  return (
    <div className="d-flex flex-column">
      {options.map((option, idx) => (
        <div key={`${idx}-${getOptionValue(option)}`}>
          <FormControlLabel
            // label={`${getOptionLabel(option)} ${
            //   !!option?.translations?.hi?.title ? `(${option?.translations?.hi?.title})` : ''
            // }`}
            label={getLabel(option)}
            sx={{ width: '100%' }}
            control={<Checkbox checked={false} onChange={() => onCheckOption(option)} />}
          />
          <hr style={{ margin: '5px auto', width: 'calc(100% - 40px)' }} />
        </div>
      ))}
      {!options?.length && (
        <div className="text-center">No options available, please add a new one.</div>
      )}
    </div>
  )
}
