import styled, { keyframes } from "styled-components";

const LoaderColors = {
  splashScreenBgColor: "#fff",

}

export const SplashScreenBackground = styled.div`
    width: 100%;
    height: 100vh;
    background: ${LoaderColors.splashScreenBgColor};
    display: flex;
    justify-content: center;
    align-items: center;
`

const sizeAnimation = keyframes`
  0% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1.2);
  }
`;

export const CenteredLogo = styled.img`
    width: 136.574px;
    height: 136.574px;
    border-radius: 21px;
    flex-shrink: 0;
    @media (min-width: 768px){
        width: 156.574px;
        height: 156.574px;
        border-radius: 21px;
    }
    @media (min-width: 1500px) {
        width: 199.787px;
        height: 199.787px;
        border-radius: 21px;
    }
    // animation: ${sizeAnimation} 1s forwards;
`