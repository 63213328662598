import { ChevronRightIcon } from '@heroicons/react/solid'
import {
  AddPhotoAlternate,
  AspectRatio,
  BrokenImage,
  CameraAlt,
  Cancel,
  CheckCircle,
  Close,
  CopyAll,
  Download,
  Edit,
  Pause,
  PlayArrow,
  Upload,
} from '@mui/icons-material'
import {
  Avatar,
  Badge,
  Box,
  Button,
  ButtonBase,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Grow,
  IconButton,
  Tooltip,
} from '@mui/material'
import { useEffect, useMemo, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import { IndeterminateLoading } from 'src/consumer/components/ConsumerProfilePage/ProfileLeftPannelStyle'
import { apiURL, mediaURL } from 'src/consumer/services'
import { postDataWithoutParams } from 'src/consumer/services/featureServices'
import {
  AvatarActionIcon,
  MentorProfileButton,
  MentorProfileButtonContainer,
  StyledMentorPhoneNumberInput,
} from 'src/mentor/components/MentorProfilePage/MentorProfilePageStyles'
import { getImageLink, sleep } from 'src/mentor/helpers/utilityFunctions'
import { InputBox, InputBoxContainer, InputBoxInsideContainer, NextIcon } from './Form/FormStyles'
import {
  AnimatedHamburgerIconContainer,
  AnimatedHamburgerIconHorizontalLine,
  AutocompleteHelperStyledButton,
  CircularProgressDocument,
  ConfirmationModalDialogPaper,
  ConfirmationModalPrimaryButton,
  ConfirmationModalSecondaryButton,
  CustomDocumentUploadButton,
  CustomImageAvatar,
  CustomImageAvatarButton,
  CustomImageAvatarContainer,
  CustomImageAvatarFallBack,
  CustomPlayerContainer,
  CustomPlayerControlsContainer,
  CustomPlayerSliderContainer,
  DocumentUploadActionsContainer,
  DownloadDocumentButton,
  EditDocumentButton,
  GenericLoadingOverlayBase,
  InputComponentContainer,
  QuillWrapper,
  RegisterButton,
  RegisterModalActions,
  RegisterModalContent,
  RegisterModalTitle,
  SectionGridItem,
  StyledAsyncCreatableSelect,
  StyledAsyncSelect,
  StyledCreatableSelect,
  StyledCustomCheckBox,
  StyledCustomHTMLOptions,
  StyledCustomHTMLSelect,
  StyledCustomInput,
  StyledCustomInputContainer,
  StyledCustomReactPlayer,
  StyledCustomReactPlayerSlider,
  StyledCustomSelect,
  StyledCustomTextArea,
  StyledLabel,
  StyledSubLabel,
  TrackUploadButton,
  ViewDocumentButton,
} from './helperComponentsStyles'

import AwsS3 from '@uppy/aws-s3'
import { Uppy } from '@uppy/core'
import '@uppy/core/dist/style.min.css'
import '@uppy/dashboard/dist/style.min.css'
import { Dashboard } from '@uppy/react'
import Webcam from '@uppy/webcam'
import '@uppy/webcam/dist/style.min.css'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginGetFile from 'filepond-plugin-get-file'
import 'filepond-plugin-get-file/dist/filepond-plugin-get-file.min.css'
import FilePondPluginImageEdit from 'filepond-plugin-image-edit'
import 'filepond-plugin-image-edit/dist/filepond-plugin-image-edit.css'
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import FilePondPluginImageTransform from 'filepond-plugin-image-transform'
import FilePondPluginImageValidateSize from 'filepond-plugin-image-validate-size'
import 'filepond/dist/filepond.min.css'
import { FilePond, registerPlugin } from 'react-filepond'
import ReCAPTCHA from 'react-google-recaptcha'
// import 'react-phone-input-2/lib/bootstrap.css'
import { isValidPhoneNumber } from 'react-phone-number-input'
import ReactPlayer from 'react-player'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { StyledPhoneInputContainer } from 'src/clinic/views/onboardng/ClinicOnboardingStyles'
import { TrackResponse } from 'src/common/tracks/TrackClass'
import { HLS_TRACK_TYPES, TRACK_TYPE_TO_MIME_TYPE } from 'src/consumer/constants'
import { getUppyUrl } from 'src/consumer/services/server_cdn_urls'
import { TrackUploadDialogPaper } from 'src/mentor/components/ActivityPlanComponent/components/styledComponents'
import { StyledMentorJoinVerifyLink } from 'src/mentor/components/MentorPublicPage/MentorPublicPageStyledComponents'
import '../../dist/doka/doka.css'
import { create } from '../../dist/doka/doka.js'
import {
  calculateMinWidthAndHeight,
  checkIfStringIsValid,
  getVideoFileMetaData,
  giveMediaTypeBasedOnMimeType,
} from '../helpers/utilFunctions'
// import 'react-phone-input-2/lib/bootstrap.css'
import screenfull from 'screenfull'
import { getDataWithParams } from '../services/profileService'
import './helperComponentsCss.css'

import { Spinner } from 'react-bootstrap'
import subtitleFile from 'src/assets/subtitles/subtitles_en.vtt'
// get default properties

registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginImageEdit,
  FilePondPluginGetFile,
  FilePondPluginFileValidateType,
  FilePondPluginImageTransform,
  FilePondPluginImageValidateSize,
)

export function RegisterModal({ onClickSignIn, onClickRegister, titleText, contentText, ...rest }) {
  return (
    <Dialog
      {...rest}
      PaperProps={{
        sx: { padding: '20px 10px', borderTop: '0.25rem solid #8247f5' },
      }}
    >
      <RegisterModalTitle>
        {titleText || 'Please register or sign in to continue.'}
      </RegisterModalTitle>
      <RegisterModalContent>
        {contentText || 'You need to register or sign in to access the features of AUMHUM.'}
      </RegisterModalContent>
      <RegisterModalActions>
        {onClickRegister && <RegisterButton onClick={onClickRegister}>Register</RegisterButton>}
        {onClickSignIn && <RegisterButton onClick={onClickSignIn}>Sign In</RegisterButton>}
      </RegisterModalActions>
    </Dialog>
  )
}

export function OnboardingInput({ rightArrowFunction, label, ...rest }) {
  return (
    <>
      {label && <StyledLabel>{label}</StyledLabel>}
      <InputBoxContainer>
        <InputBoxInsideContainer>
          <InputBox {...rest} />
          {rightArrowFunction && (
            <NextIcon disabled={!Boolean(rest?.value)} onClick={rightArrowFunction}>
              <ChevronRightIcon sx={{ color: '#fff' }} />
            </NextIcon>
          )}
        </InputBoxInsideContainer>
      </InputBoxContainer>
    </>
  )
}

export function CustomInput({
  label,
  subLabel,
  invalid,
  invalidMessage,
  inputRef,
  valid = false,
  component,
  componentContainer,
  inputAndMessageContainer,
  pureInputProps = {},
  inputAnsMessageContainerStyles = {},
  conponentContainerStyles: componentContainerStyles = {},
  fadedOutPlaceholder,
  showClearInputButton = false,
  checkingValidity,
  ...rest
}) {
  const CustomStyledInputComponentContainer = useMemo(() => {
    return componentContainer ? componentContainer : InputComponentContainer
  }, [componentContainer])

  const CustomStyledInputAndMessageContainer = useMemo(() => {
    return inputAndMessageContainer ? inputAndMessageContainer : StyledCustomInputContainer
  }, [inputAndMessageContainer])
  const CustomStyledInputComponent = useMemo(() => {
    return component ? component : StyledCustomInput
  }, [component])

  return (
    <CustomStyledInputComponentContainer style={componentContainerStyles}>
      {label && (
        <StyledLabel>
          {label} {rest?.required && '*'}
        </StyledLabel>
      )}
      {subLabel && <StyledSubLabel>{subLabel}</StyledSubLabel>}
      <CustomStyledInputAndMessageContainer
        invalid={invalid}
        style={inputAnsMessageContainerStyles}
      >
        <CustomStyledInputComponent
          {...rest}
          ref={inputRef}
          invalid={invalid}
          fadedOutPlaceholder={fadedOutPlaceholder}
          {...pureInputProps}
        />
        {showClearInputButton && !!rest?.value && (
          <IconButton
            size="small"
            onClick={() => rest?.onChange({ target: { value: '', id: rest?.id } })}
          >
            <Close sx={{ fontSize: '20px' }} />
          </IconButton>
        )}
        {checkingValidity && (
          <div style={{ minWidth: '30px' }}>
            <CircularProgress size={30} sx={{ height: '19.5px !important', color: '#4d4d4d' }} />
          </div>
        )}
        {valid && (
          <div style={{ minWidth: '30px' }}>
            <CheckCircle sx={{ color: 'green' }} />
          </div>
        )}
        {invalid && (
          <div style={{ minWidth: '30px' }}>
            <Cancel sx={{ color: 'red' }} />
          </div>
        )}
      </CustomStyledInputAndMessageContainer>
      {invalid && invalidMessage && <div>{invalidMessage}</div>}
    </CustomStyledInputComponentContainer>
  )
}

export function CustomTextArea({
  label,
  subLabel,
  invalidMessage,
  customTextAreaComponent,
  pureInputProps,
  textAreaRef,
  ...rest
}) {
  const TextAreaComponent = useMemo(() => {
    return customTextAreaComponent ? customTextAreaComponent : StyledCustomTextArea
  }, [customTextAreaComponent])

  return (
    <div className="d-flex flex-column justify-content-center align-items-start gap-2">
      {label && (
        <StyledLabel>
          {label} {rest?.required && '*'}
        </StyledLabel>
      )}
      {subLabel && <StyledSubLabel>{subLabel}</StyledSubLabel>}
      <TextAreaComponent {...rest} {...pureInputProps} ref={textAreaRef} />
      {rest?.invalid && <div>{invalidMessage}</div>}
    </div>
  )
}

export function CustomCheckBox({ label, makeWholeClickable = true, ...rest }) {
  const styledLabelHtmlFor = useMemo(() => {
    return makeWholeClickable ? rest?.id || '' : null
  }, [makeWholeClickable])

  return (
    <div className="d-flex justify-content-start align-items-center gap-2">
      <StyledCustomCheckBox {...rest} />
      {label && (
        <StyledLabel htmlFor={styledLabelHtmlFor}>
          {label} {rest?.required && '*'}
        </StyledLabel>
      )}
    </div>
  )
}

export function CustomCreatableSelect({
  id = 'id',
  label,
  subLabel,
  options,
  selectType = selectTypes.object,
  onChangeEvent,
  ...rest
}) {
  function handleChange(selectValue) {
    if (onChangeEvent) {
      var valueToPut
      if (selectType === selectTypes?.string) {
        valueToPut = rest.isMulti
          ? selectValue.map((val) => rest.getOptionValue(val))
          : rest.getOptionValue(selectValue)
      } else {
        valueToPut = selectValue
      }
      const event = {
        target: { value: valueToPut, id: id },
      }
      onChangeEvent(event)
    } else {
      rest?.onChange(selectValue)
    }
  }

  function getValue(selectValue) {
    if (selectType === selectTypes?.string) {
      if (rest?.isMulti) {
        return rest?.value?.map((val) => {
          const foundOption = options?.find((option) => rest.getOptionValue(option) === val)
          return foundOption
        })
      } else {
        return options?.find((option) => rest.getOptionValue(option) === rest?.value)
      }
    }
    return selectValue
  }

  return (
    <div className="d-flex flex-column justify-content-start align-items-stretch gap-2">
      <div className="d-flex flex-column justify-content-start align-items-stretch">
        {label && (
          <StyledLabel>
            {label} {rest?.required && '*'}
          </StyledLabel>
        )}
        {subLabel && <StyledSubLabel>{subLabel}</StyledSubLabel>}
      </div>
      <StyledCreatableSelect
        {...rest}
        value={getValue(rest?.value)}
        classNamePrefix="creatable-react-select"
        options={options}
        onChange={handleChange}
      />
    </div>
  )
}

export function CustomAsyncPaginate({
  id = 'id',
  label,
  subLabel,
  options,
  selectType = selectTypes.object,
  onChangeEvent,
  ...rest
}) {
  function handleChange(selectValue) {
    if (onChangeEvent) {
      var valueToPut
      if (selectType === selectTypes?.string) {
        valueToPut = rest.isMulti
          ? selectValue.map((val) => rest.getOptionValue(val))
          : rest.getOptionValue(selectValue)
      } else {
        valueToPut = selectValue
      }
      const event = {
        target: { value: valueToPut, id: id },
      }
      onChangeEvent(event)
    } else {
      rest?.onChange(selectValue)
    }
  }

  function getValue(selectValue) {
    if (selectType === selectTypes?.string) {
      if (rest?.isMulti) {
        return rest?.value?.map((val) => {
          const foundOption = options?.find((option) => rest.getOptionValue(option) === val)
          return foundOption
        })
      } else {
        return options?.find((option) => rest.getOptionValue(option) === rest?.value)
      }
    }
    return selectValue
  }

  return (
    <div className="d-flex flex-column justify-content-start align-items-stretch gap-2 __async-paginate-container">
      <div className="d-flex flex-column justify-content-start align-items-stretch">
        {label && (
          <StyledLabel>
            {label} {rest?.required && '*'}
          </StyledLabel>
        )}
        {subLabel && <StyledSubLabel>{subLabel}</StyledSubLabel>}
      </div>
      <StyledAsyncSelect
        {...rest}
        value={rest?.value}
        classNamePrefix="async-react-select"
        options={options}
        onChange={handleChange}
      />
    </div>
  )
}

export function CustomAsyncCreatablePaginate({
  id = 'id',
  label,
  subLabel,
  options,
  selectType = selectTypes.object,
  onChangeEvent,
  ...rest
}) {
  function handleChange(selectValue) {
    if (onChangeEvent) {
      var valueToPut
      if (selectType === selectTypes?.string) {
        valueToPut = rest.isMulti
          ? selectValue.map((val) => rest.getOptionValue(val))
          : rest.getOptionValue(selectValue)
      } else {
        valueToPut = selectValue
      }
      const event = {
        target: { value: valueToPut, id: id },
      }
      onChangeEvent(event)
    } else {
      rest?.onChange(selectValue)
    }
  }

  function getValue(selectValue) {
    if (selectType === selectTypes?.string) {
      if (rest?.isMulti) {
        return rest?.value?.map((val) => {
          const foundOption = options?.find((option) => rest.getOptionValue(option) === val)
          return foundOption
        })
      } else {
        return options?.find((option) => rest.getOptionValue(option) === rest?.value)
      }
    }
    return selectValue
  }

  return (
    <div className="d-flex flex-column justify-content-start align-items-stretch gap-2 __async-paginate-container">
      <div className="d-flex flex-column justify-content-start align-items-stretch">
        {label && (
          <StyledLabel>
            {label} {rest?.required && '*'}
          </StyledLabel>
        )}
        {subLabel && <StyledSubLabel>{subLabel}</StyledSubLabel>}
      </div>
      <StyledAsyncCreatableSelect
        {...rest}
        value={rest?.value}
        classNamePrefix="async-creatable-react-select"
        options={options}
        onChange={handleChange}
      />
    </div>
  )
}

export function CustomSelect({
  id = 'id',
  label,
  subLabel,
  options,
  selectType = selectTypes.object,
  onChangeEvent,
  customComponent,
  setFirstByDefault = false,
  value,
  ...rest
}) {
  const SelectComponent = useMemo(() => {
    return customComponent ? customComponent : StyledCustomSelect
  }, [customComponent])

  function handleChange(selectValue) {
    if (onChangeEvent) {
      var valueToPut
      if (selectType === selectTypes?.string) {
        valueToPut = rest.isMulti
          ? selectValue.map((val) => rest?.getOptionValue(val))
          : rest?.getOptionValue(selectValue)
      } else {
        valueToPut = selectValue
      }
      const event = {
        target: { value: valueToPut, id: id, type: 'react-select' },
      }
      onChangeEvent(event)
    } else {
      rest?.onChange(selectValue)
    }
  }

  function getValue(selectValue) {
    if (selectType === selectTypes?.string) {
      if (rest?.isMulti) {
        if (Boolean(value)) {
          return value?.map((val) => {
            return (
              options?.find((option) => rest?.getOptionValue(option) === val) || {
                value: val,
                id: val,
                title: val,
                name: val,
                label: val,
              }
            )
          })
        }
        return null
      } else {
        if (Boolean(value))
          return (
            options?.find((option) => rest?.getOptionValue(option) === value) || {
              value: value,
              id: value,
              title: value,
              name: value,
              label: value,
            }
          )
        else {
          return undefined
        }
      }
    }
    return selectValue
  }

  const currentValue = useMemo(() => {
    return getValue(value)
  }, [value, options])

  useEffect(() => {
    if (setFirstByDefault) {
      const value = getValue(value)
      if (rest?.isMulti) {
        if (!value || value.length === 0) {
          handleChange([options[0]])
        }
      } else {
        if (!value) {
          handleChange(options[0])
        }
      }
    }
  }, [setFirstByDefault, value, options])

  useEffect(() => {
    console.log({ currentValue })
  }, [currentValue])

  return (
    <div className="d-flex flex-column justify-content-start align-items-stretch gap-2 w-100">
      {(label || subLabel) && (
        <div className="d-flex flex-column justify-content-start align-items-stretch">
          {label && (
            <StyledLabel>
              {label} {rest?.required && '*'}
            </StyledLabel>
          )}
          {subLabel && <StyledSubLabel>{subLabel}</StyledSubLabel>}
        </div>
      )}
      <SelectComponent
        {...rest}
        value={currentValue}
        classNamePrefix="react-select"
        options={options}
        onChange={handleChange}
      />
    </div>
  )
}

export function SelectInsidePopout({ ...rest }) {
  const [isOpen, setIsOpen] = useState(false)

  const selectStyles = {
    control: (provided) => ({
      ...provided,
      minWidth: 240,
      margin: 8,
    }),
    menu: () => ({ boxShadow: 'inset 0 1px 0 rgba(0, 0, 0, 0.1)' }),
  }

  return (
    <Dropdown
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      target={
        <Button
          iconAfter={<ChevronDown />}
          onClick={() => setIsOpen((prev) => !prev)}
          isSelected={isOpen}
        >
          {rest?.value ? `State: ${rest?.getOptionValue(rest?.value)}` : 'Select a State'}
        </Button>
      }
    >
      <CustomSelect
        {...rest}
        autoFocus
        backspaceRemovesValue={false}
        components={{ DropdownIndicator, IndicatorSeparator: null }}
        controlShouldRenderValue={false}
        hideSelectedOptions={false}
        isClearable={false}
        menuIsOpen
        onChange={(newValue) => {
          rest.onChange(newValue)
          setIsOpen(false)
        }}
        styles={selectStyles}
        placeholder="Search..."
        tabSelectsValue={false}
      />
    </Dropdown>
  )
}

export const AUTOMATIC_LIST_ADDING_COMPONENT_DIRECTIONS = {
  VERTICAL: 'vertical',
  HORIZONTAL: 'horizontal',
}

export function AutomaticListAddingComponent({
  id,
  value = [],
  onChange,
  label,
  direction = AUTOMATIC_LIST_ADDING_COMPONENT_DIRECTIONS.VERTICAL,
  maxWidthOfItems = '100%',
  InputComponent = CustomInput,
  disabled,
}) {
  function changeData(e, idx) {
    var updatedValue = value
    if (idx >= value?.length) {
      // add the new value to the array
      updatedValue = [...(updatedValue || []), e.target.value]
    } else {
      //udpate the index item
      updatedValue = updatedValue?.map((val, updatedValIdx) => {
        return idx === updatedValIdx ? e.target.value : val
      })
    }

    //remove the empty items from array
    updatedValue = updatedValue?.filter((val) => !!val)
    onChange({
      target: {
        id: id,
        value: updatedValue,
      },
    })
  }

  const CLASSES_TO_USE = {
    [AUTOMATIC_LIST_ADDING_COMPONENT_DIRECTIONS.VERTICAL]: 'd-flex flex-column gap-1',
    [AUTOMATIC_LIST_ADDING_COMPONENT_DIRECTIONS.HORIZONTAL]: 'd-flex gap-1 flex-wrap',
  }

  return (
    <div>
      {label && <label>{label}</label>}
      <div
        className={
          CLASSES_TO_USE?.[direction] ||
          CLASSES_TO_USE?.[AUTOMATIC_LIST_ADDING_COMPONENT_DIRECTIONS.VERTICAL]
        }
      >
        {[...(value || []), '']?.map((val, idx) => (
          <InputComponent
            key={idx}
            value={val}
            showLabel={false}
            containerStyles={{
              maxWidth: maxWidthOfItems,
              width: '100%',
            }}
            disabled={disabled}
            id="val"
            valueId={id}
            onChange={(e) => {
              changeData(e, idx)
            }}
          />
        ))}
      </div>
    </div>
  )
}

const Menu = (props) => {
  const shadow = 'hsla(218, 50%, 10%, 0.1)'
  return (
    <div
      style={{
        backgroundColor: 'white',
        borderRadius: 4,
        boxShadow: `0 0 0 1px ${shadow}, 0 4px 11px ${shadow}`,
        marginTop: 8,
        position: 'absolute',
        zIndex: 2,
      }}
      {...props}
    />
  )
}
const Blanket = (props) => (
  <div
    style={{
      bottom: 0,
      left: 0,
      top: 0,
      right: 0,
      position: 'fixed',
      zIndex: 1,
    }}
    {...props}
  />
)

const Dropdown = ({ children, isOpen, target, onClose }) => (
  <div style={{ position: 'relative' }}>
    {target}
    {isOpen ? <Menu>{children}</Menu> : null}
    {isOpen ? <Blanket onClick={onClose} /> : null}
  </div>
)
const Svg = (p) => (
  <svg width="24" height="24" viewBox="0 0 24 24" focusable="false" role="presentation" {...p} />
)
const DropdownIndicator = () => (
  <div style={{ color: 'green', height: 24, width: 32 }}>
    <Svg>
      <path
        d="M16.436 15.085l3.94 4.01a1 1 0 0 1-1.425 1.402l-3.938-4.006a7.5 7.5 0 1 1 1.423-1.406zM10.5 16a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </Svg>
  </div>
)
const ChevronDown = () => (
  <Svg style={{ marginRight: -6 }}>
    <path
      d="M8.292 10.293a1.009 1.009 0 0 0 0 1.419l2.939 2.965c.218.215.5.322.779.322s.556-.107.769-.322l2.93-2.955a1.01 1.01 0 0 0 0-1.419.987.987 0 0 0-1.406 0l-2.298 2.317-2.307-2.327a.99.99 0 0 0-1.406 0z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </Svg>
)

export function CustomHTMLSelect({
  label,
  subLabel,
  options,
  selectComponent,
  optionComponent,
  getOptionLabel,
  getOptionValue,
  outerMostDivStyle = {},
  ...rest
}) {
  const HTMLSelectComponent = useMemo(() => {
    return selectComponent ? selectComponent : StyledCustomHTMLSelect
  }, [selectComponent])
  const OptionComponent = useMemo(() => {
    return optionComponent ? optionComponent : StyledCustomHTMLOptions
  }, [optionComponent])

  return (
    <div
      className="d-flex flex-column justify-content-start align-items-stretch gap-2"
      style={outerMostDivStyle}
    >
      {(label || subLabel) && (
        <div className="d-flex flex-column justify-content-start align-items-stretch">
          {label && (
            <StyledLabel>
              {label} {rest?.required && '*'}
            </StyledLabel>
          )}
          {subLabel && <StyledSubLabel>{subLabel}</StyledSubLabel>}
        </div>
      )}
      <HTMLSelectComponent {...rest}>
        {options.map((option, idx) => {
          return (
            <OptionComponent
              {...(option?.props || {})}
              key={idx}
              value={getOptionValue(option) || option?.id}
            >
              {getOptionLabel(option) || option?.name || option?.title}
            </OptionComponent>
          )
        })}
      </HTMLSelectComponent>
    </div>
  )
}

export function PictureUploadComponent({
  value,
  setFormState,
  onChange,
  aspectHeight,
  aspectWidth,
  title,
  id = 'imageId',
  hideRecommendedText = false,
  disabled,
  ...rest
}) {
  const inputRef = useRef(null)
  const [saving, setSaving] = useState(false)
  const [files, setFiles] = useState()
  const [fileIsThere, setFileIsThere] = useState(false)
  const cssAspectRatio = useMemo(() => {
    if (!aspectHeight && !aspectWidth) {
      return undefined
    }
    return aspectWidth && aspectHeight && aspectWidth / aspectHeight
  }, [aspectHeight, aspectWidth])
  const minDimensions =
    !Boolean(aspectWidth) && !Boolean(aspectHeight)
      ? null
      : calculateMinWidthAndHeight(cssAspectRatio)

  function handleClick() {
    inputRef.current.browse()
  }

  async function processImage(
    fieldName,
    file,
    metadata,
    load,
    error,
    progress,
    abort,
    transfer,
    options,
  ) {
    // fieldName is the name of the input field
    // file is the actual file object to send
    setSaving(true)
    const formData = new FormData()
    formData.append('file', file)
    const response = await postDataWithoutParams('/api/media/file/upload', formData)
    if (response?.status === 200) {
      await sleep(1000)
      onChange({
        target: { value: response.data.fileId, id: id },
      })
    } else {
      toast.error('Something went wrong')
      return 0
    }
    setSaving(false)
  }

  function handleOnData(formData) {
    const files = formData.getAll('files')?.[1]
    const toReturnFormData = new FormData()
    toReturnFormData.append('file', files)
    return toReturnFormData
  }

  async function handleOnLoad(resData) {
    await sleep(1000)
    const responseData = JSON.parse(resData)
    onChange({
      target: { value: responseData.fileId, id: id },
    })
  }

  useEffect(() => {
    if (!!value) {
      setFiles([
        {
          source: value,
          options: {
            type: 'local',
          },
        },
      ])
    } else {
      setFileIsThere(false)
      setFiles([])
    }
  }, [value])

  async function handleImageChange(event) {
    setSaving(true)
    const file = event.target.files[0]
    if (file) {
      const formData = new FormData()
      formData.append('file', file)
      const response = await postDataWithoutParams('/api/media/file/upload', formData)
      if (response?.status === 200) {
        await sleep(1000)
        onChange({
          ...event,
          target: { value: response.data.fileId, id: event.target.id },
        })
      } else {
        toast.error('Something went wrong')
        return 0
      }
    }
    event.target.value = ''
    setSaving(false)
  }

  console.log({ cssAspectRatio })

  return (
    <div
      className="d-flex flex-column align-items-center justify-content-center"
      style={{ gap: '18px' }}
    >
      <CustomImageAvatarButton
        onClick={handleClick}
        disabled={disabled}
        sx={{ p: 0, position: 'relative' }}
      >
        <Badge
          overlap="rectangular"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          badgeContent={
            <>
              <Grow in={!disabled}>
                <AvatarActionIcon imgIsThere={value}>
                  {value ? <Edit /> : <AddPhotoAlternate />}
                </AvatarActionIcon>
              </Grow>
            </>
          }
        >
          <CustomImageAvatarContainer aspectRatio={cssAspectRatio}>
            {value && (
              <CustomImageAvatar src={`${mediaURL}${value}`}>
                <BrokenImage sx={{ fontSize: { xs: 40, md: 50 } }} />
              </CustomImageAvatar>
            )}
            {!value && (
              <CustomImageAvatarFallBack>
                <AspectRatio />
                {!!hideRecommendedText ? (
                  'Upload Image'
                ) : (
                  <>
                    Recommended <br /> {aspectWidth} x {aspectHeight}
                  </>
                )}
              </CustomImageAvatarFallBack>
            )}
          </CustomImageAvatarContainer>
          {saving && <IndeterminateLoading />}
        </Badge>
      </CustomImageAvatarButton>
      <div style={{ display: 'none' }}>
        <FilePond
          allowImageEdit
          allowImageValidateSize
          imageValidateSizeMinWidth={minDimensions?.minWidth || undefined}
          imageValidateSizeMinHeight={minDimensions?.minHeight || undefined}
          imagePreviewHeight={aspectHeight}
          stylePanelLayout="compact"
          // imageEditInstantEdit
          instantUpload={true}
          // files={files}
          // onupdatefiles={setFiles}
          allowMultiple={false}
          server={{
            url: apiURL,
            // process: {
            //   url: '/media/file/upload',
            //   method: 'POST',
            //   // withCredentials: false,
            //   //   headers: {},
            //   //   timeout: 7000,
            //   onload: handleOnLoad,
            //   //   onerror: null,
            //   ondata: handleOnData,
            // },
            process: processImage,
            // load: (source, load, error, progress, abort, headers) => {
            //   var myRequest = new Request(`${mediaURL}${source}`, {
            //     headers: headers,
            //     method: 'GET',
            //     mode: 'cors',
            //   })
            //   if (myRequest) {
            //     fetch(`${mediaURL}${source}`).then(function (response) {
            //       console.log(response)
            //       response.blob().then(function (myBlob) {
            //         load(myBlob)
            //       })
            //     })
            //   }
            // },
          }}
          acceptedFileTypes={['image/*']}
          name="files"
          imageEditInstantEdit
          allowImageTransform
          styleItemPanelAspectRatio={cssAspectRatio ? 1 / cssAspectRatio : null}
          styleButtonProcessItemPosition="bottom"
          stylePanelAspectRatio={cssAspectRatio ? 1 / cssAspectRatio : null}
          labelIdle={'Drop Your Photo here or <span class="filepond--label-action">Browse</span>'}
          ref={inputRef}
          imageEditEditor={create({
            cropAspectRatio: !cssAspectRatio ? undefined : [1 / cssAspectRatio],
          })}
        />
      </div>
      <div style={{ textAlign: 'center', fontSize: 18, fontWeight: 600 }}>{title}</div>
      {/* <input
        type="file"
        style={{ display: 'none' }}
        onChange={handleImageChange}
        accept="image/*"
        {...rest}
      /> */}
    </div>
  )
}

export function PictureUploadButton({ onChange, aspectHeight, aspectWidth, ...rest }) {
  const inputRef = useRef(null)
  const [saving, setSaving] = useState(false)
  const [files, setFiles] = useState()
  const [fileIsThere, setFileIsThere] = useState(false)
  const cssAspectRatio = useMemo(() => {
    if (!aspectHeight && !aspectWidth) {
      return undefined
    }
    return aspectWidth && aspectHeight && aspectWidth / aspectHeight
  }, [aspectHeight, aspectWidth])
  const minDimensions =
    !Boolean(aspectWidth) && !Boolean(aspectHeight)
      ? null
      : calculateMinWidthAndHeight(cssAspectRatio)

  function handleClick() {
    inputRef.current.browse()
  }

  async function processImage(
    fieldName,
    file,
    metadata,
    load,
    error,
    progress,
    abort,
    transfer,
    options,
  ) {
    // fieldName is the name of the input field
    // file is the actual file object to send
    setSaving(true)
    const formData = new FormData()
    formData.append('file', file)
    const response = await postDataWithoutParams('/api/media/file/upload', formData)
    setSaving(false)
    if (response?.status === 200) {
      await sleep(1000)
      onChange({
        target: { value: response.data.fileId },
      })
    } else {
      toast.error('Something went wrong')
      return 0
    }
  }

  function handleOnData(formData) {
    const files = formData.getAll('files')?.[1]
    const toReturnFormData = new FormData()
    toReturnFormData.append('file', files)
    return toReturnFormData
  }

  async function handleImageChange(event) {
    setSaving(true)
    const file = event.target.files[0]
    if (file) {
      const formData = new FormData()
      formData.append('file', file)
      const response = await postDataWithoutParams('/api/media/file/upload', formData)
      if (response?.status === 200) {
        await sleep(1000)
        onChange({
          ...event,
          target: { value: response.data.fileId, id: event.target.id },
        })
      } else {
        toast.error('Something went wrong')
        return 0
      }
    }
    event.target.value = ''
    setSaving(false)
  }

  console.log({ cssAspectRatio })

  return (
    <div
      className="d-flex flex-column align-items-center justify-content-center"
      style={{ gap: '18px' }}
    >
      <CustomImageAvatarButton
        disabled={saving}
        onClick={handleClick}
        sx={{
          p: 0,
          position: 'relative',
          width: '64px',
          height: '64px',
          fontSize: '32px',
          border: '1px solid #cecece',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '8px',
        }}
      >
        {saving ? <CircularProgress size={20} sx={{ color: '#cecece' }} /> : '+'}
      </CustomImageAvatarButton>
      <div style={{ display: 'none' }}>
        <FilePond
          allowImageEdit
          allowImageValidateSize
          imageValidateSizeMinWidth={minDimensions?.minWidth || undefined}
          imageValidateSizeMinHeight={minDimensions?.minHeight || undefined}
          imagePreviewHeight={aspectHeight}
          stylePanelLayout="compact"
          // imageEditInstantEdit
          instantUpload={true}
          // files={files}
          // onupdatefiles={setFiles}
          allowMultiple={false}
          server={{
            url: apiURL,
            // process: {
            //   url: '/media/file/upload',
            //   method: 'POST',
            //   // withCredentials: false,
            //   //   headers: {},
            //   //   timeout: 7000,
            //   onload: handleOnLoad,
            //   //   onerror: null,
            //   ondata: handleOnData,
            // },
            process: processImage,
            // load: (source, load, error, progress, abort, headers) => {
            //   var myRequest = new Request(`${mediaURL}${source}`, {
            //     headers: headers,
            //     method: 'GET',
            //     mode: 'cors',
            //   })
            //   if (myRequest) {
            //     fetch(`${mediaURL}${source}`).then(function (response) {
            //       console.log(response)
            //       response.blob().then(function (myBlob) {
            //         load(myBlob)
            //       })
            //     })
            //   }
            // },
          }}
          acceptedFileTypes={['image/*']}
          name="files"
          imageEditInstantEdit
          allowImageTransform
          styleItemPanelAspectRatio={cssAspectRatio ? 1 / cssAspectRatio : null}
          styleButtonProcessItemPosition="bottom"
          stylePanelAspectRatio={cssAspectRatio ? 1 / cssAspectRatio : null}
          labelIdle={'Drop Your Photo here or <span class="filepond--label-action">Browse</span>'}
          ref={inputRef}
          imageEditEditor={create({
            cropAspectRatio: !cssAspectRatio ? undefined : [1 / cssAspectRatio],
          })}
        />
      </div>
    </div>
  )
}

export function ReCaptchaPhoneInput({ ...rest }) {
  return
}

export const PhoneOTPComponent = ({
  phoneValue,
  setPhoneValue,
  layout = 'grid',
  initialValue,
  setValidity,
  setValidatedPhone,
  userProfileData,
  setValidatedOtp = () => {},
}) => {
  const [profileData, setProfileData] = useState({
    ...userProfileData,
    mobileVerified: userProfileData?.mobileVerified || false,
  })
  const [captchaVerified, setCaptchaVerified] = useState(false)
  const [otpToUse, setOtpToUse] = useState()
  const [otp, setOtp] = useState('')
  const reCAPTCHAKey = '6LfrNj0pAAAAAAHFhLjzhXhLEXQOaKWYVgJosWck' // Replace with your reCAPTCHA site key
  const [phoneValid, setPhoneValid] = useState(true)
  const [verifyClick, setVerifyClick] = useState(false)

  useEffect(() => {
    const valid = isPhoneValid() && captchaVerified && otp?.length === 6
    setValidity(valid)
    if (valid) {
      setValidatedPhone(phoneValue)
      setValidatedOtp(otp)
    }
  }, [phoneValue, captchaVerified, otp])

  const isPhoneValid = () => {
    // Add your logic to validate phone number
    // const valid = phone?.length > 0 // Basic example, replace with actual validation
    console.log(phoneValue)

    // Check if phone starts with "+", if not, add it
    const formattedPhone = phoneValue?.startsWith('+') ? phoneValue : `+${phoneValue}`

    const valid = isValidPhoneNumber(formattedPhone || '')
    if (!!profileData?.mobileVerified) {
      setPhoneValid(true)
      return true
    }
    setPhoneValid(valid)
    return valid
  }

  const handleCaptchaChange = async (value) => {
    setCaptchaVerified(!!value)
    if (!value) {
      // Reset OTP when reCAPTCHA is reset
      setOtp('')
    } else {
      // Remove country code and "+" sign from phone number
      const phoneNumberWithoutCountryCode = phoneValue.replace(/^\+/, '').substring(2)
      const getOtpResponse = await getDataWithParams('/api/generate/otp', {
        phone: phoneNumberWithoutCountryCode,
      })
      setOtpToUse()
    }
  }

  const handlePhoneChange = (value) => {
    setPhoneValue(value)
    // Reset captcha and OTP if phone number changes
    if (value !== phoneValue) {
      setCaptchaVerified(false)
      setOtp('')
    }
  }

  useEffect(() => {
    setProfileData({
      ...userProfileData,
      mobileVerified: userProfileData?.mobileVerified || false,
    })
    console.log({ initialValue })
    if (!!initialValue) {
      setPhoneValue(initialValue)
    }
  }, [userProfileData, initialValue])

  return (
    <>
      {layout === 'grid' ? (
        <Grid container spacing={1}>
          <Grid item md={6}>
            <CustomInput
              country={'in'}
              label="Phone"
              inputAndMessageContainer={StyledPhoneInputContainer}
              component={StyledMentorPhoneNumberInput}
              value={phoneValue}
              onChange={handlePhoneChange}
              invalid={profileData?.mobileVerified ? false : !phoneValid || !captchaVerified}
              valid={profileData?.mobileVerified ? true : phoneValid && captchaVerified}
            />
            <div className="d-flex w-100 justify-content-start align-items-center">
              {phoneValid && !verifyClick && !profileData?.mobileVerified && (
                <StyledMentorJoinVerifyLink
                  variant={'text'}
                  onClick={() => {
                    setVerifyClick(true)
                  }}
                >
                  Click here to verify your phone number and continue
                </StyledMentorJoinVerifyLink>
              )}
            </div>
          </Grid>
          <Grid item md={6}>
            {phoneValid && verifyClick && !captchaVerified && (
              <ReCAPTCHA sitekey={reCAPTCHAKey} onChange={handleCaptchaChange} size="normal" />
            )}
          </Grid>
          <Grid item md={6}>
            {phoneValid && captchaVerified && (
              <CustomInput
                type="text"
                value={otp}
                label="Enter OTP"
                onChange={(e) => setOtp(e.target.value)}
                placeholder="Enter OTP"
                disabled={!captchaVerified}
              />
            )}
          </Grid>
          {/* {captchaVerified && otp.length !== 6 && (
            <p style={{ color: 'red' }}>Please enter a 6-digit OTP</p>
          )} */}
        </Grid>
      ) : (
        <>
          <CustomInput country={'in'} value={phoneValue} onChange={handlePhoneChange} />
          {!phoneValid && <p style={{ color: 'red' }}>Please enter a valid phone number</p>}

          {phoneValid && (
            <ReCAPTCHA sitekey={reCAPTCHAKey} onChange={handleCaptchaChange} size="normal" />
          )}

          {phoneValid && captchaVerified && (
            <CustomInput
              type="text"
              value={otp}
              label="Enter a 6-digit OTP"
              onChange={(e) => setOtp(e.target.value)}
              placeholder="Enter OTP"
              disabled={!captchaVerified}
            />
          )}
          {/* {captchaVerified &&  && (
            <p style={{ color: 'red' }}>Please enter a 6-digit OTP</p>
          )} */}
        </>
      )}
    </>
  )
}

export function ProfilePictureButtonComponent({ formState, setFormState, handleChange }) {
  const inputRef = useRef(null)
  const [saving, setSaving] = useState(false)
  const minDimensions = calculateMinWidthAndHeight(1)
  const value = formState?.profilePicId

  function handleClick() {
    inputRef.current.browse()
  }

  const [files, setFiles] = useState()

  async function handleImageChange(event) {
    setSaving(true)
    const file = event.target.files[0]
    if (file) {
      const formData = new FormData()
      formData.append('file', file)
      const response = await postDataWithoutParams('/api/media/file/upload', formData)
      if (response?.status === 200) {
        await sleep(1000)
        setFormState((prev) => ({ ...prev, [event.target.id]: response.data.fileId }))
      } else {
        toast.error('Something went wrong')
        return 0
      }
    }
    event.target.value = ''
    setSaving(false)
  }

  function handleOnData(formData) {
    const files = formData.getAll('files')?.[1]
    const toReturnFormData = new FormData()
    toReturnFormData.append('file', files)
    return toReturnFormData
  }

  async function handleOnLoad(resData) {
    await sleep(1000)
    const responseData = JSON.parse(resData)
    setFormState((prev) => ({ ...prev, profilePicId: responseData.fileId }))
  }

  async function processImage(
    fieldName,
    file,
    metadata,
    load,
    error,
    progress,
    abort,
    transfer,
    options,
  ) {
    // fieldName is the name of the input field
    // file is the actual file object to send
    setSaving(true)
    const formData = new FormData()
    formData.append('file', file)
    const response = await postDataWithoutParams('/api/media/file/upload', formData)
    if (response?.status === 200) {
      await sleep(1000)
      setFormState((prev) => ({ ...prev, profilePicId: response.data.fileId }))
    } else {
      toast.error('Something went wrong')
      return 0
    }
    setSaving(false)
  }

  useEffect(() => {
    if (!!value) {
      setFiles([
        {
          source: value,
          options: {
            type: 'local',
          },
        },
      ])
    } else {
      setFiles([])
    }
  }, [value])

  return (
    <>
      <IconButton onClick={handleClick} sx={{ p: 0, position: 'relative' }}>
        <Badge
          overlap="circular"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          badgeContent={
            <AvatarActionIcon imgIsThere={!!formState?.profilePicId}>
              {!!formState?.profilePicId ? <Edit /> : <CameraAlt />}
            </AvatarActionIcon>
          }
        >
          <MentorProfileButtonContainer>
            {!!formState?.profilePicId && (
              <MentorProfileButton src={`${mediaURL}${formState?.profilePicId}`}>
                <BrokenImage sx={{ fontSize: { xs: 10, md: 100 } }} />
              </MentorProfileButton>
            )}
            {!formState?.profilePicId && <MentorProfileButton />}
          </MentorProfileButtonContainer>
          {saving && <IndeterminateLoading />}
        </Badge>
      </IconButton>
      <div style={{ display: 'none' }}>
        <FilePond
          ref={inputRef}
          allowImageEdit
          allowImageValidateSize
          imageValidateSizeMinWidth={minDimensions?.minWidth}
          imageValidateSizeMinHeight={minDimensions?.minHeight}
          imageEditInstantEdit
          imagePreviewHeight={200}
          stylePanelLayout="compact circle"
          // imageEditInstantEdit
          instantUpload={true}
          // files={files}
          // onupdatefiles={setFiles}
          allowMultiple={false}
          server={{
            url: apiURL,
            // process: {
            //   url: '/media/file/upload',
            //   method: 'POST',
            //   // withCredentials: false,
            //   headers: {
            //     Authorization: `Bearer ${getToken()}`,
            //     ...commonHeadersWeb,
            //     'ef-user-country-code': localStorage.getItem('countryCode'),
            //     'ef-app-language': sessionStorage.getItem('languageCode'),
            //   },
            //   //   timeout: 7000,
            //   onload: handleOnLoad,
            //   //   onerror: null,
            //   ondata: handleOnData,
            // },
            process: processImage,
            // load: async (source, load, error, progress, abort, headers) => {
            //   // const headers = new Headers()
            //   // headers.append('Content-Type', '*/*')
            //   // headers.append('Authorization', 'Bearer YourAuthTokenHere')
            //   fetch(`${mediaURL}${source}`, {})
            //     .then(function (response) {
            //       console.log(response)
            //       if (!response.ok) {
            //         // Handle HTTP error
            //         throw new Error(`HTTP error! Status: ${response.status}`)
            //       }
            //       response
            //         .blob()
            //         .then(function (myBlob) {
            //           load(myBlob)
            //         })
            //         .catch(function (blobError) {
            //           error(blobError)
            //         })
            //     })
            //     .catch(function (fetchError) {
            //       error(fetchError)
            //     })
            //   // await axios
            //   //   .get(`${mediaURL}${source}`, {
            //   //     headers: { ...headers, Accept: '*/*', cache: 'cache' },
            //   //     withCredentials: true,
            //   //   })
            //   //   .then(function (response) {
            //   //     console.log(response)
            //   //     if (response.status !== 200) {
            //   //       // Handle HTTP error
            //   //       throw new Error(`HTTP error! Status: ${response.status}`)
            //   //     }
            //   //     return response.data
            //   //   })
            //   //   .then(function (data) {
            //   //     // Assuming 'load' and 'error' functions are defined elsewhere
            //   //     load(data)
            //   //   })
            //   //   .catch(function (error) {
            //   //     console.error(error)
            //   //     // Handle errors here
            //   //   })
            // },
          }}
          acceptedFileTypes={['image/*']}
          name="files"
          allowImageTransform
          styleItemPanelAspectRatio={1}
          // styleButtonProcessItemPosition="bottom"
          stylePanelAspectRatio={1}
          labelIdle={'Drop Your Photo here or <span class="filepond--label-action">Browse</span>'}
          imageEditEditor={create({
            cropAspectRatio: [1],
          })}
        />
      </div>
      {/* <input
        id="profilePicId"
        ref={inputRef}
        type="file"
        style={{ display: 'none' }}
        onChange={handleImageChange}
        accept="image/*"
      /> */}
    </>
  )
}

export function DocumentUploadComponent({
  value,
  onChange,
  handleView,
  handleEdit,
  showDownloadButton,
  fileType,
  customJustifyContent,
  ...rest
}) {
  const inputRef = useRef(null)
  const [saving, setSaving] = useState(false)
  const [fileName, setFileName] = useState('')

  function handleClick() {
    inputRef.current.click()
  }

  async function handleChangeDocument(event) {
    setSaving(true)
    const file = event.target.files[0]
    if (file) {
      const formData = new FormData()
      formData.append('file', file)
      const response = await postDataWithoutParams('/api/media/file/upload', formData)
      if (response?.status === 200) {
        await sleep(1000)
        onChange({
          ...event,
          target: { value: response.data.fileId, id: event.target.id },
        })
        setFileName(response.data.fileName)
      } else {
        toast.error('Something went wrong')
        return 0
      }
    }
    event.target.value = ''
    setSaving(false)
  }

  function handleDownload() {}

  return (
    <>
      {!!value ? (
        <DocumentUploadActionsContainer
          justifyContent={customJustifyContent ? customJustifyContent : 'center'}
        >
          {!!fileName && <div>Uploaded File: {fileName}</div>}
          <div className="d-flex justify-content-center align-items-center gap-3">
            {handleView && (
              <ViewDocumentButton disabled={saving} onClick={handleView}>
                View
              </ViewDocumentButton>
            )}
            <EditDocumentButton disabled={saving} onClick={handleEdit ? handleEdit : handleClick}>
              <span>Edit</span>
              <Edit />
            </EditDocumentButton>
            {showDownloadButton && (
              <a
                style={{ textDecoration: 'none' }}
                href={`${mediaURL}${value}`}
                rel="noreferrer"
                target="_blank"
              >
                <DownloadDocumentButton disabled={saving} onClick={handleDownload}>
                  <span>Download</span>
                  <Download />
                </DownloadDocumentButton>
              </a>
            )}
          </div>
          {saving && <CircularProgressDocument size={30} />}
        </DocumentUploadActionsContainer>
      ) : (
        <>
          <DocumentUploadActionsContainer
            justifyContent={customJustifyContent ? customJustifyContent : 'center'}
          >
            {!!fileName && <div>{fileName}</div>}
            <CustomDocumentUploadButton disabled={saving} onClick={handleClick}>
              <span>Upload</span>
              <Upload />
            </CustomDocumentUploadButton>
            {saving && <CircularProgressDocument size={30} />}
          </DocumentUploadActionsContainer>
        </>
      )}
      <input
        ref={inputRef}
        type="file"
        style={{ display: 'none' }}
        onChange={handleChangeDocument}
        accept={fileType || ''}
        {...rest}
      />
    </>
  )
}

export function ImageViewComponent({ imageId, ...rest }) {
  return (
    <Avatar sx={{ width: '100%', height: '100%', ...rest?.sx }} {...rest}>
      <BrokenImage />
    </Avatar>
  )
}

export const selectTypes = {
  object: 'object',
  string: 'string',
}

export function OverflowingArrayChips({ allItems, getTitle, maxChips }) {
  const allItemTitles = allItems?.map((item) => {
    return getTitle(item)
  })
  const titleToShowOutside = allItemTitles?.length > 3 ? allItemTitles?.slice(0, 3) : allItemTitles

  return (
    <>
      {allItemTitles?.length === 0 || !allItems ? (
        <div></div>
      ) : (
        <Tooltip title={allItemTitles?.join(', ')}>
          <div className="d-flex flex-wrap gap-2">
            {titleToShowOutside?.map((iTitle, idx) => {
              return <Chip label={iTitle} key={iTitle + idx} />
            })}
            {allItemTitles?.length > maxChips ? `+${allItemTitles?.length - maxChips}` : ''}
          </div>
        </Tooltip>
      )}
    </>
  )
}

export function DescriptionComponent({ description, maxLines }) {
  const [isExpanded, setIsExpanded] = useState(false)
  const [showMoreButton, setShowMoreButton] = useState(false)
  const descriptionRef = useRef(null)

  useEffect(() => {
    if (descriptionRef.current) {
      const lineHeight = parseInt(getComputedStyle(descriptionRef.current).lineHeight, 10)
      const totalHeight = descriptionRef.current.scrollHeight

      setShowMoreButton(totalHeight > maxLines * lineHeight)
    }
  }, [description, maxLines])

  const toggleExpand = () => {
    setIsExpanded(!isExpanded)
  }

  const truncatedStyle = {
    display: '-webkit-box',
    WebkitLineClamp: maxLines,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }

  useEffect(() => {
    if (descriptionRef.current) {
      // TODO: In the future, maybe make it responsive (if you change content to automatically decide whenever it should show content or not, that can be done by adding event listener on height change on main div -> divRef, and do the check bellow but inside eventListener)
      // Check the height, to know if you need to display showMoreButton or not.
      if (maxLines > descriptionRef.current.offsetHeight) {
        setShowMoreButton('hide')
      } else {
        setShowMoreButton(true)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <span ref={descriptionRef} style={isExpanded ? {} : truncatedStyle}>
        {description}
      </span>
      {showMoreButton && <button onClick={toggleExpand}>{isExpanded ? 'Less' : 'More'}</button>}
    </div>
  )
}

export default function ConfirmationModal({
  newImplementation,
  visibility,
  visibilitySetter,
  action,
  body,
  buttonColor,
  onSubmitFunctions,
}) {
  function handleVisibilitySetter(value) {
    if (newImplementation) {
      visibilitySetter((prev) => ({ ...prev, visibility: false }))
    } else {
      visibilitySetter(value)
    }
  }

  async function handleSubmit() {
    handleVisibilitySetter(false)
    await Promise.all(
      onSubmitFunctions.map(async (func) => {
        const AsyncFunction = (async () => {}).constructor
        const GeneratorFunction = function* () {}.constructor
        //checks if the function is an async function
        if (
          (func instanceof AsyncFunction &&
            AsyncFunction !== Function &&
            AsyncFunction !== GeneratorFunction) === true
        ) {
          await func()
          return 1
        } else {
          func()
          return 1
        }
      }),
    )
  }

  const finalButtonColor = buttonColor ? buttonColor : action === 'submit' ? 'success' : 'error'
  return (
    <Dialog
      open={visibility}
      PaperComponent={ConfirmationModalDialogPaper}
      onClose={() => {
        handleVisibilitySetter(false)
      }}
    >
      <DialogTitle>
        <h4>
          <strong>Confirm {action}?</strong>
        </h4>
      </DialogTitle>
      {body !== '' && body !== undefined && (
        <DialogContent>
          <div className="d-flex flex-column">{body}</div>
        </DialogContent>
      )}
      <DialogActions>
        <ConfirmationModalSecondaryButton
          color="secondary"
          onClick={() => handleVisibilitySetter(false)}
        >
          Close
        </ConfirmationModalSecondaryButton>
        <ConfirmationModalPrimaryButton
          color={finalButtonColor}
          variant="contained"
          onClick={handleSubmit}
        >
          Confirm
        </ConfirmationModalPrimaryButton>
      </DialogActions>
    </Dialog>
  )
}

export function SentryRefHandler({ hasNextPage, loading, sentryRef, children }) {
  return (
    hasNextPage &&
    !loading && (
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '10px 0px',
        }}
        className="__sentry_ref_handler"
        ref={sentryRef}
      >
        {children ? children : <CircularProgress />}
      </div>
    )
  )
}

export function TrackUploadComponent({ propUppy, trackObject, handleChange = () => {}, disabled }) {
  const [showModal, setShowModal] = useState(false)
  const [handlingUploads, setHandlingUploads] = useState(false)
  const currentTrackType = HLS_TRACK_TYPES?.find((trkTyp) => trkTyp.id === trackObject?.type)
  const [uppy] = useState(
    () =>
      propUppy ||
      new Uppy({
        debug: true,
        autoProceed: true,
        restrictions: {
          allowedFileTypes: [TRACK_TYPE_TO_MIME_TYPE?.[trackObject?.type] || ''],
          maxNumberOfFiles: 1,
        },
      })
        .use(Webcam)
        .use(AwsS3, {
          shouldUseMultipart: true,
          companionUrl: `${getUppyUrl()}/companion`,
          // companionUrl: 'http://172.16.45.7:3000/companion',
        }),
  )

  const [showTrackOnDialog, setShowTrackOnDialog] = useState(false)

  async function handleResults(result) {
    if (result.failed.length === 0) {
      const successfulFile = result.successful?.[0]
      const response = successfulFile?.response
      const s3MultiPart = successfulFile?.s3Multipart
      const uploadedRawFile = successfulFile?.data
      let videoDuration = 0
      let videoWidth = 0
      let videoHeight = 0
      let contentType = successfulFile.type
      let objectKey = s3MultiPart?.key
      let fileSize = successfulFile.size
      let accessLevel = 'private'
      let type = giveMediaTypeBasedOnMimeType(successfulFile.type)
      let fileName = successfulFile.name

      const setVideoDuration = (duration) => {
        videoDuration = duration
      }
      const setVideoWidth = (width) => {
        videoWidth = width
      }
      const setVideoHeight = (height) => {
        videoHeight = height
      }

      await getVideoFileMetaData(uploadedRawFile)
        .then(({ duration, width, height }) => {
          // handleChange({ target: { id: 'duration', value: duration } })
          setVideoDuration(duration)
          setVideoWidth(width)
          setVideoHeight(height)
        })
        .catch((error) => {
          console.error(error)
        })
      console.log({
        response,
        s3MultiPart,
        uploadedRawFile,
        videoDuration,
        videoWidth,
        videoHeight,
        successfulFile,
      })
      return {
        accessLevel,
        contentType,
        duration: videoDuration,
        fileSize,
        height: videoHeight,
        objectKey,
        type,
        width: videoWidth,
        fileName,
      }
    }
    return null
  }

  function toggleShowModal() {
    uppy.cancelAll()
    setShowModal((prev) => !prev)
  }

  function changeTrackType(e) {
    handleChange((prev) => ({ ...TrackResponse, type: e.target.value }))
    uppy.setOptions({
      restrictions: { allowedFileTypes: [TRACK_TYPE_TO_MIME_TYPE?.[e.target.value] || ''] },
    })
  }

  uppy?.off('complete', null)?.once('complete', async (result) => {
    const metaDataToUpload = await handleResults(result, trackObject)

    if (!!metaDataToUpload && Object.keys(metaDataToUpload)?.length > 0 && !handlingUploads) {
      setHandlingUploads(true)
      const resp = await postDataWithoutParams(
        '/api/media/file/upload/s3/complete',
        metaDataToUpload,
      )

      if (resp) {
        const respData = resp.data
        handleChange((prev) => ({
          ...prev,
          duration: respData?.duration,
          fileId: respData?.fileId,
          url: `/${respData?.transcoding?.hls?.url}`,
        }))
      }
      await sleep(5000)
      setHandlingUploads(false)
    }
  })

  useEffect(() => {
    !checkIfStringIsValid(trackObject?.type) &&
      handleChange({ target: { id: 'type', value: HLS_TRACK_TYPES?.[1]?.id } })
    uppy.setOptions({
      restrictions: { allowedFileTypes: [TRACK_TYPE_TO_MIME_TYPE?.[trackObject?.type] || ''] },
    })
  }, [trackObject?.type, disabled])

  return (
    <div className={'d-flex flex-column align-items-center gap-2 w-100'}>
      <div className={'d-flex gap-2 w-100 align-items-end'}>
        {disabled && (
          <div className="d-flex flex-column align-items-start gap-2">
            {currentTrackType ? (
              <ButtonBase
                disableTouchRipple
                sx={{ color: 'blue', textDecoration: 'underline' }}
                onClick={() => setShowTrackOnDialog(true)}
              >
                Track Type: {currentTrackType?.title}
              </ButtonBase>
            ) : (
              <div>Track Type: NA</div>
            )}
          </div>
        )}
        {!disabled && (
          <CustomSelect
            id="type"
            menuPortalTarget={document.body}
            value={trackObject?.type}
            isDisabled={disabled}
            label={'Track Type'}
            selectType={selectTypes.string}
            getOptionLabel={(option) => option?.title}
            getOptionValue={(option) => option?.id}
            options={HLS_TRACK_TYPES}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 99999 }) }}
            onChangeEvent={changeTrackType}
          />
        )}
        {!disabled && !!trackObject?.type && (
          <TrackUploadButton onClick={toggleShowModal} variant={'contained'}>
            <span>{trackObject?.url ? 'Change' : 'Upload'}</span> <Upload />
          </TrackUploadButton>
        )}
      </div>
      <Dialog open={showModal} onClose={toggleShowModal} PaperComponent={TrackUploadDialogPaper}>
        <DialogTitle>Upload {currentTrackType?.title}</DialogTitle>
        <DialogContent>
          <Dashboard
            uppy={uppy}
            plugins={['Webcam']}
            height={500}
            doneButtonHandler={toggleShowModal}
          />
        </DialogContent>
      </Dialog>
      {trackObject?.url && !disabled && (
        <div className={'d-flex flex-column gap-2 w-100 align-items-center'}>
          <h6 className={'align-self-start'}>
            Uploaded {currentTrackType?.title}{' '}
            <Tooltip title={`${trackObject?.url}`}>
              <IconButton
                onClick={() => {
                  navigator.clipboard.writeText(`${currentTrackType?.title} URL`, trackObject.url)
                }}
              >
                <CopyAll />
              </IconButton>
            </Tooltip>
          </h6>
          <ReactPlayer
            url={`${trackObject?.url}`}
            style={{
              padding: '10px',
              maxWidth: '400px',
              maxHeight: '200px',
            }}
            height={trackObject?.type === HLS_TRACK_TYPES?.[0].id ? '70px' : '200px'}
            width={'100%'}
            controls={true}
          />
        </div>
      )}
      <Dialog open={showTrackOnDialog} onClose={() => setShowTrackOnDialog(false)}>
        <DialogContent>
          <ReactPlayer
            url={`${trackObject?.url}`}
            style={{
              padding: '10px',
              maxWidth: '400px',
              maxHeight: '200px',
            }}
            height={trackObject?.type === HLS_TRACK_TYPES?.[0].id ? '70px' : '200px'}
            width={'100%'}
            controls={true}
          />
        </DialogContent>
      </Dialog>
    </div>
  )
}

export function RichTextEditorQuill({
  id,
  rawId,
  htmlContent,
  initialContent,
  handleChangeHtml = () => {},
  handleChangeRaw = () => {},
  toolbarOptionsOverride,
  disabled,
}) {
  const [quillSelectionData, setQuillSelectionData] = useState({
    range: null,
    source: null,
    editor: null,
  })
  const quillRef = useRef(null)

  const quillFormat = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
  ]

  const quillModules = {
    toolbar: {
      container: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
        ['link', 'image'],
        ['clean'],
      ],
      // handlers: {
      //   image: selectLocalImage,
      // },
    },
  }

  function selectLocalImage() {
    const input = document.createElement('input')
    input.setAttribute('type', 'file')
    input.setAttribute('accept', 'image/*')
    input.click()

    // Listen upload local image and save to server
    input.onchange = () => {
      const file = input.files[0]

      // file type is only image.
      if (/^image\//.test(file.type)) {
        saveToServer(file)
      } else {
        console.warn('You could only upload images.')
      }
    }
  }
  async function saveToServer(file) {
    const fd = new FormData()
    fd.append('file', file)

    const resp = await postDataWithoutParams('/api/media/file/upload', fd)
    if (resp.status === 200) {
      insertToEditor(resp?.data?.fileId)
    }

    // xhr.onload = () => {
    //   if (xhr.status === 200) {
    //     // this is callback data: url
    //     const url = JSON.parse(xhr.responseText).data
    //     insertToEditor(url)
    //   }
    // }
    // xhr.send(fd)
  }
  function insertToEditor(url) {
    // push image url to rich editor.
    const range = quillRef?.current.editor.getSelection(true)
    quillRef?.current.editor.insertEmbed(range.index, 'image', `${mediaURL}${url}`)
  }

  function handleChangeHtmlValue(value) {
    const quillEvent = { target: { value, id } }
    handleChangeHtml(quillEvent)
  }

  useEffect(() => {
    console.log({ quillRef })
    if (quillRef?.current) {
      const quillToolbar = quillRef.current.editor.getModule('toolbar')
      quillToolbar.addHandler('image', selectLocalImage)
    }
  }, [quillRef])

  return (
    <QuillWrapper>
      <ReactQuill
        ref={quillRef}
        readOnly={disabled}
        style={{ height: '100%' }}
        formats={quillFormat}
        modules={quillModules}
        theme={'snow'}
        value={htmlContent}
        onChange={handleChangeHtmlValue}
        onChangeSelection={(range, source, editor) => {
          setQuillSelectionData({ range, source, editor })
        }}
      ></ReactQuill>
    </QuillWrapper>
  )
}

export const AnimatedHamburgerIcon = ({ openNow, clickFunction }) => {
  return (
    <AnimatedHamburgerIconContainer onClick={clickFunction}>
      <AnimatedHamburgerIconHorizontalLine topLine openNow={openNow} />
      <AnimatedHamburgerIconHorizontalLine centerLine openNow={openNow} />
      <AnimatedHamburgerIconHorizontalLine bottomLine openNow={openNow} />
    </AnimatedHamburgerIconContainer>
  )
}

export function CustomReactPlayer({
  url,
  controls,
  defaultBackgroundImageUrl,
  mediaTitle,
  onFinish = () => {},
  containerStyles = {},
  playerControlsStyles = {},
  ...rest
}) {
  const [isPlaying, setIsPlaying] = useState(false)
  const [loading, setLoading] = useState(true)
  const [played, setPlayed] = useState(0)
  const playerRef = useRef()
  const duration = playerRef.current ? playerRef.current.getDuration() : 0
  const [hasVideo, setHasVideo] = useState(false)
  const [subtitles, setSubtitles] = useState([])

  const sub = subtitleFile

  const [backgroundImageUrlToUse, setBackgroundImageUrlToUse] = useState()

  function handleSeek(e, newValue) {
    const value = newValue
    playerRef.current.seekTo(value)
    setPlayed(value)
  }

  function onClickFullscreen() {
    screenfull.request(playerRef.current)
  }

  async function detectMediaType() {
    try {
      const response = await fetch(url)
      const text = await response.text()
      const videoCodecs = ['avc1', 'hev1', 'hvc1', 'vp8', 'vp9', 'av01']
      const videoRegex = new RegExp(`CODECS="[^"]*(${videoCodecs.join('|')})`, 'i')
      const hasVideoStream = videoRegex.test(text)
      console.log({ hasVideoStream, text })
      setHasVideo(hasVideoStream)
    } catch (error) {
      console.error('Error fetching or parsing the manifest file:', error)
      setHasVideo(true)
    }
  }

  async function Setup() {
    var backgroundImgUrl = defaultBackgroundImageUrl
    if (!backgroundImgUrl) {
      backgroundImgUrl = await getImageLink(undefined, undefined)
    }
    setBackgroundImageUrlToUse(backgroundImgUrl)
    detectMediaType()
  }

  useEffect(() => {
    const interval = setInterval(() => {
      const currentTime = playerRef.current ? playerRef.current.getCurrentTime() : 0
      setPlayed(currentTime)
    }, 500)

    Setup()
    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    if (isPlaying && duration !== 0 && duration !== undefined && duration !== null) {
      if (played === duration) {
        onFinish()
      }
    }
  }, [played])

  return (
    <CustomPlayerContainer style={containerStyles}>
      <StyledCustomReactPlayer
        ref={playerRef}
        playing={isPlaying}
        light={backgroundImageUrlToUse}
        style={{
          backgroundImage: `url(${backgroundImageUrlToUse})`,
          backgroundSize: 'cover',
        }}
        config={{
          file: {
            attributes: {
              crossOrigin: 'anonymous',
            },
            tracks: [
              {
                kind: 'subtitles',
                src: url.split('/').slice(0, -1).join('/') + '/subtitles_en.vtt?version=1.11',
                // src: subtitleFile,
                srcLang: 'en',
                default: true,
              },
            ],
          },
        }}
        url={url}
        height={'100%'}
        width={'100%'}
        onClickPreview={() => {
          setLoading(false)
          setIsPlaying(true)
        }}
        {...rest}
      />
      {!loading && (
        <>
          <CustomPlayerControlsContainer style={playerControlsStyles}>
            <IconButton
              sx={{ color: '#fff', p: '4px' }}
              onClick={() => setIsPlaying((prev) => !prev)}
            >
              {isPlaying ? <Pause /> : <PlayArrow />}
            </IconButton>
            <CustomPlayerSliderContainer>
              {/* <PrettoSlider
            value={played}
            onChangeCommitted={handleSeekCommitted}
            marks={marks}
            onChange={handleSeek}
          /> */}
              <span className="duration-text">
                {Math.floor((played / 60) % 60)}:{String(Math.floor(played % 60)).padStart(2, '0')}
              </span>
              <StyledCustomReactPlayerSlider
                value={played}
                onChange={handleSeek}
                max={duration}
                size="small"
              />
              {/* <IconButton onClick={onClickFullscreen}>
                <Fullscreen />
              </IconButton> */}
            </CustomPlayerSliderContainer>
          </CustomPlayerControlsContainer>
        </>
      )}
    </CustomPlayerContainer>
  )
}

export function MuiInfinitySymbolIcon({ ...rest }) {
  return (
    <svg focusable="false" aria-hidden="true" viewBox="0 0 24 24" {...rest}>
      <path d="M18.6 6.62c-1.44 0-2.8.56-3.77 1.53L12 10.66 10.48 12h.01L7.8 14.39c-.64.64-1.49.99-2.4.99-1.87 0-3.39-1.51-3.39-3.38S3.53 8.62 5.4 8.62c.91 0 1.76.35 2.44 1.03l1.13 1 1.51-1.34L9.22 8.2C8.2 7.18 6.84 6.62 5.4 6.62 2.42 6.62 0 9.04 0 12s2.42 5.38 5.4 5.38c1.44 0 2.8-.56 3.77-1.53l2.83-2.5.01.01L13.52 12h-.01l2.69-2.39c.64-.64 1.49-.99 2.4-.99 1.87 0 3.39 1.51 3.39 3.38s-1.52 3.38-3.39 3.38c-.9 0-1.76-.35-2.44-1.03l-1.14-1.01-1.51 1.34 1.27 1.12c1.02 1.01 2.37 1.57 3.82 1.57 2.98 0 5.4-2.41 5.4-5.38s-2.42-5.37-5.4-5.37"></path>
    </svg>
  )
}

export function MuiCustomTabPanel(props) {
  const { children, tabValue, currentTab, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={tabValue !== currentTab}
      id={`simple-tabpanel-${currentTab}`}
      aria-labelledby={`simple-tab-${currentTab}`}
      {...other}
    >
      {tabValue === currentTab && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  )
}

// function addTracks() {
//   const videoElement = playerRef.current.getInternalPlayer()
//   if (videoElement) {
//     // Clear existing tracks
//     while (videoElement.firstChild) {
//       videoElement.removeChild(videoElement.firstChild)
//     }

//     videoElement.crossOrigin = 'anonymous'
//     // Add subtitle tracks
//     const enTrack = document.createElement('track')
//     enTrack.kind = 'subtitles'
//     enTrack.label = 'English'
//     enTrack.srclang = 'en'
//     // enTrack.src = `
//     //   https://hls.aumhum.xyz/prod/MEDIA-da6015b7fa4e443f9af292911c3e5774/1716462466653/`

//     const trackSrc = url.split('/').slice(0, -1).join('/') + '/subtitles_en.vtt?version=1.11'
//     enTrack.src = trackSrc
//     enTrack.default = true
//     videoElement.appendChild(enTrack)

//     // const esTrack = document.createElement('track')
//     // esTrack.kind = 'subtitles'
//     // esTrack.label = 'Spanish'
//     // esTrack.srclang = 'es'
//     // esTrack.src =
//     //   'https://your-cloudfront-distribution-domain/hls/prod/subtitles_es.vtt?Expires=timestamp&Signature=signature&Key-Pair-Id=key-pair-id'
//     // videoElement.appendChild(esTrack)
//   }
// }

// useEffect(() => {
//   if (playerRef.current.getInternalPlayer()) {
//     // Wait for the video element to be ready
//     playerRef.current.getInternalPlayer().addEventListener('loadedmetadata', addTracks)
//   }
//   return () => {
//     if (playerRef.current.getInternalPlayer()) {
//       playerRef.current.getInternalPlayer().removeEventListener('loadedmetadata', addTracks)
//     }
//   }
// }, [url, playerRef, isPlaying])

// useEffect(() => {
//   const fetchSubtitles = async () => {
//     try {
//       const response = await fetch(url)
//       const text = await response.text()

//       const subtitleTracks = []
//       const regex = /#EXT-X-MEDIA:TYPE=SUBTITLES,.*?(?:URI="(.*?)")/g
//       let match
//       while ((match = regex.exec(text)) !== null) {
//         const subtitleUrl = match[1]
//         const labelMatch = /NAME="(.*?)"/.exec(match[0])
//         const langMatch = /LANGUAGE="(.*?)"/.exec(match[0])

//         if (subtitleUrl && labelMatch && langMatch) {
//           subtitleTracks.push({
//             src: subtitleUrl,
//             label: labelMatch[1],
//             srclang: langMatch[1],
//             default: match[0].includes('DEFAULT=YES'),
//           })
//         }
//       }

//       setSubtitles(subtitleTracks)
//     } catch (error) {
//       console.error('Error fetching HLS manifest:', error)
//     }
//   }

//   fetchSubtitles()
// }, [url])

// useEffect(() => {
//   const videoElement = playerRef.current.getInternalPlayer()
//   if (videoElement) {
//     subtitles.forEach((sub) => {
//       const track = document.createElement('track')
//       track.kind = 'subtitles'
//       track.label = sub.label
//       track.srclang = sub.srclang
//       track.src = sub.src
//       track.default = sub.default
//       videoElement.appendChild(track)
//     })
//   }
// }, [subtitles])

export const GenericLoadingOverlay = ({ backgroundColor, spinnerVariant }) => {
  return (
    <GenericLoadingOverlayBase backgroundColor={backgroundColor}>
      <Spinner animation="border" variant={spinnerVariant || 'dark'} size="md" />
    </GenericLoadingOverlayBase>
  )
}

export function GridSectionWrapper({
  headerComponents,
  heading,
  subheading,
  shadow = true,
  children,
  sectionContainerSx = {},
  sectionContainerProps = {},
}) {
  return (
    <SectionGridItem
      xs={12}
      {...sectionContainerProps}
      sx={sectionContainerSx}
      item
      shadow={shadow}
    >
      <Grid container spacing={2}>
        {heading && !headerComponents ? (
          <Grid item xs={12}>
            <h5>{heading}</h5>
          </Grid>
        ) : (
          headerComponents && (
            <Grid item xs={12}>
              {headerComponents}
            </Grid>
          )
        )}
        {children}
      </Grid>
    </SectionGridItem>
  )
}

export const AutocompleteHelperButton = ({ text, Icon, iconProps, clickFunction }) => {
  return (
    <AutocompleteHelperStyledButton onClick={clickFunction}>
      <span style={{ marginRight: '8px' }}>{text}</span>
      <Icon {...iconProps} />
    </AutocompleteHelperStyledButton>
  )
}
