import React, { useState } from "react";

export const InformationContext = React.createContext();

export const InformationContextProvider = ({ children }) => {

    const [informationModalData, setInformationModalData] = useState({ showModal: false, data: {} });

    return (
        <InformationContext.Provider value={{ informationModalData, setInformationModalData }}>
            {children}
        </InformationContext.Provider>
    );
};