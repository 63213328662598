import React, { useState } from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { RENDERING_ORDER_OF_DAYS } from 'src/consumer/constants';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function MultipleSelectCheckmarks(props) {

    const { options, selected, handleMutliSelectEventsChange } = props;

    return (
        <div>
            <FormControl sx={{ m: 0, width: 250 }}>
                <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    displayEmpty
                    value={selected}
                    onChange={handleMutliSelectEventsChange}
                    input={<OutlinedInput label="Tag" />}
                    renderValue={(selected) => {
                        if (selected?.length === 0) {
                            return <span style={{ color: 'red' }}>No events selected</span>
                        } else {
                            return (
                                `${selected?.length} events selected`
                            )
                        }
                    }}
                    MenuProps={MenuProps}
                >
                    {options?.map((option, index) => (
                        <MenuItem key={index} value={option?.uid}>
                            <Checkbox checked={selected?.indexOf(option?.uid) > -1} />
                            <ListItemText primary={option?.title} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );

}


export const addSlotTimeToData = (data) => {
    RENDERING_ORDER_OF_DAYS?.forEach((item) => {
        if (data?.weeklyHourMap?.[item]?.available) {
            data.weeklyHourMap[item].slotList = [{ "startTime": data?.startTime, "endTime": data?.endTime }]
        } else {
            data.weeklyHourMap[item].slotList = []
        }
    })
    return data;
}