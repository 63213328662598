const { getCPDataWithParams } = require('src/clinic/services')
const { findDrugsByRelatedName } = require('src/emr/database/indexedDbDao')

async function getRelatedDrugs(drug, clinicId, clinicBrandId) {
  const relatedDrugsFromIdb = await findDrugsByRelatedName(drug?.relatedName) // Search all drugs from IndexedDB
  if (Array.isArray(relatedDrugsFromIdb) && relatedDrugsFromIdb?.length > 0) {
    return [drug, ...relatedDrugsFromIdb?.filter((relatedDrug) => relatedDrug?.id !== drug?.id)]
  }

  if (!drug?.relatedName) {
    return [drug]
  }

  const resp = await getCPDataWithParams('/api/secure/drug/related', {
    'related-name': drug?.relatedName,
    clinicId,
    clinicBrandId,
  })
  if (resp.status === 200) {
    const drugsWithoutTheInputDrug = resp.data?.filter((relDrug) => {
      return relDrug?.id !== drug?.id
    })
    return [drug, ...(drugsWithoutTheInputDrug || [])]
  }

  const data = [drug]

  return data
}

export async function getAndSetDrugWithRelatedNames(
  doctorSpecializations,
  ddnValue,
  clinicId,
  clinicBrandId,
  setSelectedDrug,
) {
  const emrSettingSwitch = doctorSpecializations?.includes?.('rheumatologist')

  if (!ddnValue || !ddnValue?.id) {
    setSelectedDrug(ddnValue)
    return
  }
  // const response = await getCPDataWithoutParams(`/api/secure/drug/related-names/${ddnValue?.[ddnValue?.length - 1]?.id}`)

  setSelectedDrug(ddnValue)

  if (!emrSettingSwitch) {
    return
  }

  const relatedDrugData = await getRelatedDrugs(ddnValue, clinicId, clinicBrandId)

  const drugName = relatedDrugData
    ?.map((drug) => {
      return drug?.title
    })
    ?.join(' / ')
  setSelectedDrug({ ...ddnValue, label: drugName })
}

export async function getAndSetDrugWithRelatedNamesForPillInput(
  doctorSpecializations,
  ddnValue,
  allDdnValues,
  clinicId,
  clinicBrandId,
  setSelectedDrug,
) {
  const emrSettingSwitch = doctorSpecializations?.includes?.('rheumatologist')

  if (!ddnValue || !ddnValue?.id) {
    setSelectedDrug(allDdnValues)
    return
  }
  // const response = await getCPDataWithoutParams(`/api/secure/drug/related-names/${ddnValue?.[ddnValue?.length - 1]?.id}`)

  setSelectedDrug(allDdnValues)

  if (!emrSettingSwitch) {
    return
  }

  const relatedDrugData = await getRelatedDrugs(ddnValue, clinicId, clinicBrandId)

  const drugName = relatedDrugData
    ?.map((drug) => {
      return drug?.title
    })
    ?.join(' / ')

  setSelectedDrug(
    allDdnValues?.map((item) =>
      item?.id === ddnValue?.id ? { ...ddnValue, label: drugName } : item,
    ),
  )
}

export function getDrugAdviceEntitiesTitleToItemMap({ drugAdviceItems, prescriptionViewSettings }) {
  const drugAdviceItemsMap = {}
  drugAdviceItems?.forEach((item) => {
    const title = getDrugItemOptionValue(item, prescriptionViewSettings)
    drugAdviceItemsMap[title] = item
  })
  return drugAdviceItemsMap
}

export function getDrugItemOptionLabel(item, prescriptionViewSettings) {
  return `${item?.title}${
    prescriptionViewSettings?.preferredPrescriptionLanguages?.includes('hi') &&
    !!item?.translations?.hi?.title
      ? ` (${item?.translations?.hi?.title})`
      : ''
  }`
}

export function getDrugItemOptionValue(item, prescriptionViewSettings) {
  return `${item?.title}${
    prescriptionViewSettings?.preferredPrescriptionLanguages?.includes('hi') &&
    !!item?.translations?.hi?.title
      ? ` (${item?.translations?.hi?.title})`
      : ''
  }`
}
