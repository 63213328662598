export function setSessionId(sessionId) {
  sessionStorage.setItem('sessionId', `session-${sessionId}`)
}

export function getSessionId() {
  return sessionStorage.getItem('sessionId')
}

export function setSessionCreationTime(time) {
  sessionStorage.setItem('sessionCreationTime', time)
}

export function getSessionCreationTime() {
  return sessionStorage.getItem('sessionCreationTime')
}

export function getAuthToken() {
  return localStorage.getItem('token')
}
