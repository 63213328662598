import React, { useEffect, useState } from 'react'
import { ModalProvider } from 'styled-react-modal'
import {
  StyledQuoteModal,
  FadingBackground,
  ModalCloseButton,
  VisibleDrawerBackground,
  DrawerQuestion,
  DrawerDate,
  DrawerItemsContainer,
  DrawerRuledLines,
} from './QuoteAndReflectionStyles'
import { QuoteComponent, ReflectiveQuestion } from './QuoteAndReflectionComponents'
import Spinner from 'react-bootstrap/Spinner'
import { getData } from 'src/consumer/services/profileService'
import { JournalContext } from 'src/consumer/context/JournalContext'
import { ProfileContext } from 'src/consumer/context/ProfileContext'
import { useContext } from 'react'
import moment from 'moment'
import { useOutletContext } from 'react-router-dom'
import { getFeatureItemBySlug } from 'src/consumer/services/featureServices'
import { CongratulatoryCardContext } from 'src/consumer/context/CongraulatoryCardContext'

export default function QuoteAndReflectionBase({
  isQuoteModalOpen,
  setIsQuoteModalOpen,
  data,
  callSource,
  callSourceId,
  showCongratulatoryMessage = true,
  afterSubmissionFunction = () => {},
  journalData,
  setJournalData,
  userFavoriteData,
  congratulatoryCardData,
  setCongratulatoryCardData,
  ...props
}) {
  const [opacity, setOpacity] = useState(0)
  const [isFavorite, setIsFavorite] = useState(false)
  const [questionOpen, setIsQuestionOpen] = useState(false)
  const [questionData, setQuestionData] = useState('')
  const { profileData } = useContext(ProfileContext)

  let date = new moment()

  const toggleModal = (e) => {
    setOpacity(0)
    setIsQuoteModalOpen(!isQuoteModalOpen)
  }
  async function getJournal() {
    const response = await getFeatureItemBySlug('journal', data?.journalId, false)
    if (response.status === 200) {
      setQuestionData({
        ...response?.data?.data?.journal,
        coins: data?.coins,
        completed: data?.completed,
        category: data?.category,
      })
    }
  }

  const afterOpen = () => {
    async function handleIfQuoteFavorite() {
      if (userFavoriteData?.quote?.[data?.id]) {
        setIsFavorite(true)
      }
    }

    handleIfQuoteFavorite()
    setTimeout(() => {
      setOpacity(1)
    }, 100)
  }

  const beforeClose = () => {
    return new Promise((resolve) => {
      setOpacity(0)
      setTimeout(resolve, 300)
    })
  }

  useEffect(() => {
    if (!!data?.journalId) {
      getJournal()
    }
  }, [data?.journalId])

  return (
    <ModalProvider backgroundComponent={FadingBackground}>
      <StyledQuoteModal
        isOpen={isQuoteModalOpen}
        afterOpen={afterOpen}
        beforeClose={beforeClose}
        onBackgroundClick={toggleModal}
        onEscapeKeydown={toggleModal}
        opacity={opacity}
        backgroundProps={{ opacity }}
      >
        <QuoteComponent
          data={data}
          isFavorite={isFavorite}
          toggleFavorite={() => setIsFavorite(!isFavorite)}
        />
        <ModalCloseButton src={require('src/assets/close_icon_purple.png')} onClick={toggleModal} />
        {!questionOpen && (
          <VisibleDrawerBackground
            onClick={() => {
              if (
                profileData?.subscriptionTypeId === 'subscription_free' &&
                questionData?.subscription?.id !== 'subscription_free'
              ) {
                setIsQuoteModalOpen(false)
                setJournalData({
                  showJournal: true,
                  callSource: '',
                  data: '',
                  pageState: 'new_journal',
                  subscription: questionData?.subscription?.id,
                })
              } else {
                setIsQuestionOpen(true)
              }
            }}
          >
            {questionData !== '' ? (
              <DrawerItemsContainer>
                <DrawerDate>{date?.format('DD MMM, h:mm a')}</DrawerDate>
                <DrawerQuestion> {questionData?.journalQusAns?.[0]?.question} </DrawerQuestion>
                <DrawerRuledLines src={require('src/assets/ruled_lines.png')} />
              </DrawerItemsContainer>
            ) : (
              <Spinner animation="border" variant="dark" size="md" />
            )}
          </VisibleDrawerBackground>
        )}

        <ReflectiveQuestion
          questionOpen={questionOpen}
          showCongratulatoryMessage={showCongratulatoryMessage}
          setIsQuestionOpen={(bool) => setIsQuestionOpen(bool)}
          questionData={questionData}
          toggleQuoteModal={() => setIsQuoteModalOpen((prev) => !prev)}
          callSource={callSource || 'daily-play'}
          callSourceId={callSourceId || 'default'}
          afterSubmissionFunction={afterSubmissionFunction}
          congratulatoryCardData={congratulatoryCardData}
          setCongratulatoryCardData={setCongratulatoryCardData}
        />
      </StyledQuoteModal>
    </ModalProvider>
  )
}

export function QuoteAndReflection({ ...restProps }) {
  const { journalData, setJournalData } = useContext(JournalContext)
  const { congratulatoryCardData, setCongratulatoryCardData } =
    useContext(CongratulatoryCardContext)
  const { userFavoriteData } = useOutletContext()
  return (
    <QuoteAndReflectionBase
      {...restProps}
      journalData={journalData}
      setJournalData={setJournalData}
      userFavoriteData={userFavoriteData}
      congratulatoryCardData={congratulatoryCardData}
      setCongratulatoryCardData={setCongratulatoryCardData}
    />
  )
}
export function QuoteAndReflectionStandaloneComponent({ userFavoriteData, ...restProps }) {
  const [journalData, setJournalData] = useState()

  return (
    <QuoteAndReflectionBase
      {...restProps}
      journalData={journalData}
      setJournalData={setJournalData}
      userFavoriteData={userFavoriteData}
      showCongratulatoryMessage={() => {}}
    />
  )
}
