import { useEffect, useMemo, useRef, useState } from 'react'
import { StyledCardInfoSeparator } from 'src/consumer/components/BrowserByCategory/BrowserByCategoryStyle'
import {
  HorizontalScrollLeftButton,
  HorizontalScrollRightButton,
} from 'src/consumer/components/CardList/CardListStyle'
import { defaultColor } from 'src/consumer/constants'
import { getColorStyle } from 'src/consumer/helpers/tileCardHelper'
import { getImageLink } from 'src/mentor/helpers/utilityFunctions'
import TileV2Header from '../TileV2Header'
import {
  SquareCardActionButton,
  SquareMidCardBackground,
  SquareMidCardContainer,
  SquareMidCardContainerAsAnchor,
  SquareMidCardDetails,
  SquareMidCardSecondaryText,
  SquareMidCardsContainer,
  SquareMidTitle,
} from './squareMidStyledComponents'

export const CardListComponent = ({ items = [], ...restProps }) => {
  return items?.map((item, index) => {
    const toRoute = restProps?.getTo ? restProps?.getTo(item) : ''

    return <SquareMidCardSingleCard item={item} key={index} to={toRoute} {...restProps} />

    // if (item?.slug) {
    //   return <Link to={`/c/view/${item?.slug}`}></Link>
    // } else {
    //   return (
    //     <Link to={`/c/view/id/${item?.assessmentId}`} key={index}>
    //       <SquareMidCardSingleCard
    //         key={index}
    //         item={item}
    //         {...restProps}
    //         cardBackgroundStyles={{ width: 'unset !important' }}
    //       />
    //     </Link>
    //   )
    // }
  })
}

export default function SquareMidTileComponent({
  headingLogicAndData,
  areTileItemsHovered,
  logicAndData,
}) {
  return (
    <>
      <TileV2Header
        logicAndData={{
          ...headingLogicAndData,
          isOnBackground: headingLogicAndData?.isOnBackground,
        }}
      />
      <SquareCardCarouselComponent
        areTileItemsHovered={areTileItemsHovered}
        logicAndData={logicAndData}
      />
    </>
  )
}

export function SquareCardCarouselComponent({ areTileItemsHovered, logicAndData }) {
  const cardRef = useRef()
  const [leftButtonVisible, setLeftButtonVisible] = useState(false)
  const [rightButtonVisible, setRightButtonVisible] = useState(false)
  const handleScrollLeft = () => {
    if (cardRef.current) {
      const containerWidth = cardRef.current.offsetWidth
      const scrollDistance = containerWidth * 0.75
      cardRef.current.scrollLeft -= scrollDistance // adjust the scroll distance as needed
    }
  }
  const handleScrollRight = () => {
    if (cardRef.current) {
      const containerWidth = cardRef.current.offsetWidth
      const scrollDistance = containerWidth * 0.75
      cardRef.current.scrollLeft += scrollDistance // adjust the scroll distance as needed
    }
  }
  const handleScroll = (event) => {
    const container = event.target
    const scrollPosition = container.scrollLeft
    const maxScrollLeft = container.scrollWidth - container.clientWidth
    const reachedLeftEnd = scrollPosition === 0
    const reachedRightEnd = scrollPosition === maxScrollLeft
    setLeftButtonVisible(!reachedLeftEnd)
    setRightButtonVisible(!reachedRightEnd)
  }
  useEffect(() => {
    const container = cardRef.current
    const scrollPosition = container.scrollLeft
    const maxScrollLeft = container.scrollWidth - container.clientWidth
    const reachedLeftEnd = scrollPosition === 0
    const reachedRightEnd = scrollPosition === maxScrollLeft
    setLeftButtonVisible(!reachedLeftEnd)
    setRightButtonVisible(!reachedRightEnd)
  }, [])
  return (
    <SquareMidCardsContainer ref={cardRef} onScroll={handleScroll}>
      {areTileItemsHovered && leftButtonVisible && (
        <HorizontalScrollLeftButton onClickFunction={handleScrollLeft} />
      )}

      <CardListComponent {...logicAndData} />

      {areTileItemsHovered && rightButtonVisible && (
        <HorizontalScrollRightButton onClickFunction={handleScrollRight} />
      )}
    </SquareMidCardsContainer>
  )
}

export const SquareMidCardSingleCard = ({
  item,
  getTitleField,
  backgroundColor,
  onClick,
  getSecondaryText,
  getMainImage,
  getActionButtonLogic,
  cardBackgroundStyles = {},
  isAnchor,
  to,
}) => {
  const ComponentToRender = isAnchor ? SquareMidCardContainerAsAnchor : SquareMidCardContainer
  const noPointer = useMemo(() => {
    if (!onClick) {
      if (isAnchor) {
        return false
      }
    }
    return true
  }, [isAnchor, onClick])

  return (
    <ComponentToRender
      nopointer={noPointer}
      href={to}
      bgColor={getColorStyle(defaultColor, '', backgroundColor, 'solid')}
      onClick={onClick ? () => onClick(item) : () => {}}
      className="_square-mid-card-single-card-container_"
    >
      {getMainImage && (
        <SquareMidCardBackground
          style={cardBackgroundStyles}
          src={getImageLink(getMainImage(item))}
          alt='squareBackground'
        />
      )}
      <SquareMidCardDetails>
        {getSecondaryText && (
          <>
            <SquareMidCardSecondaryText>{getSecondaryText(item)}</SquareMidCardSecondaryText>
            <StyledCardInfoSeparator />
          </>
        )}
        {getTitleField && (
          <SquareMidTitle>
            {getTitleField(item)?.length > 30
              ? `${getTitleField(item)?.substring(0, 30)}...`
              : getTitleField(item)}
          </SquareMidTitle>
        )}
        {getActionButtonLogic && (
          <SquareCardActionButton onClick={() => getActionButtonLogic(item).onClick(item)}>
            {getActionButtonLogic(item).text}
          </SquareCardActionButton>
        )}
      </SquareMidCardDetails>
    </ComponentToRender>
  )
}
